<template>
  <rs-layout class="form-loader" align-center justify-center fill-height data-test-id="application-loading-overlay">
    <rs-progress-circular class="mb-5" :size="70" :width="7" indeterminate></rs-progress-circular>
  </rs-layout>
</template>

<script>
export default {
  name: 'Loader',
};
</script>

<style lang="sass">
.form-loader
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: rgba(255,255,255,0.9)
  z-index: 1
</style>
