import { ref, watch, computed } from 'vue';

export default function useModelWrapper(props, emit, propName = 'modelValue', defaultValue = null) {
  // Local component model. Allows a component to work without requiring the parent component to provide a v-model
  const _model = ref(props[propName] ?? defaultValue);

  // Model to be referenced in the local component. Ties local state and prop state together
  const model = computed({
    get: () => _model.value,
    set: (value) => {
      _model.value = value;

      updateModel(value);
    },
  });

  // One-way sync from the prop updating the local component model
  watch(
    () => props[propName],
    (value) => {
      _model.value = value;
    },
  );

  function updateModel(value) {
    emit(`update:${propName}`, value);
  }

  return [model, updateModel];
}
