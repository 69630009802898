<template>
  <div class="curation mt-5">
    <div class="curation__filters">
      <rs-autocomplete :items="interests" label="Interests" v-model="state.selectedInterest" clearable />
      <rs-autocomplete :items="geo" label="Location" v-model="state.selectedGeo" clearable />
    </div>

    <div class="curation__content">
      <h1 class="h4 ma-0 mb-2">Rating content</h1>
      <ul class="body-1 mb-3">
        <li>
          <rs-icon color="#008000">thumb_up</rs-icon>: <span class="font-weight-bold">Yes</span>, content aligns with
          the interest (even if it is not upper-funnel content)
        </li>
        <li>
          <rs-icon color="#8B0000">thumb_down</rs-icon>: <span class="font-weight-bold">No</span>, content
          <span class="underline">does not</span> align with the interest
        </li>
        <li>Skip: Decline to rate content</li>
      </ul>

      <curation-item
        v-for="post in curationPosts"
        :key="post.id"
        :post-id="post.media_object_ids[0]"
        :video-src="post.media?.passthrough_media_cdn_url"
        :profile-data="post.profile"
        :caption="post.caption"
        :post-url="post.share_url"
        @score="onScoredPost"
        @skip="onSkipPost"
      />

      <div v-if="curationPosts.length === 0 && !isLoadingInterestData">
        <div class="no-results-view">
          <h2 class="headline my-2">No Results</h2>

          <p>No posts were returned for this category. Please select a different category.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { watch, nextTick } from 'vue';
import CurationItem from '../components/CurationItem.vue';
import { useCurationStore } from '../../stores/curation';
import { storeToRefs } from 'pinia';
import { INTERESTS, GEO } from '../constants';
import { useStorage } from '@vueuse/core';
const geo = GEO;
const interests = INTERESTS;
const curationStore = useCurationStore();
const { curationPosts, isLoadingInterestData } = storeToRefs(curationStore);
const state = useStorage(
  'ltk-curation',
  {
    selectedInterest: interests[0].value,
    selectedGeo: geo[0].value,
  },
  localStorage,
  { mergeDefaults: true },
);

watch(
  state.value,
  async (newValue) => {
    await nextTick();
    await curationStore.getDataForInterest(newValue.selectedInterest, newValue.selectedGeo);
  },
  { immediate: true, deep: true },
);

/**
 * Handle reviewer skipping post, don't send rate call and just fetch next item
 */
const onSkipPost = () => {
  curationStore.getDataForInterest(state.value.selectedInterest, state.value.selectedGeo);
};

/**
 * Handle reviewer scoring post as applicable or not
 *
 * @param {string} postId
 * @param {boolean} isApplicable - true if user upvoted, false if downvoted
 */
const onScoredPost = (postId, isApplicable) => {
  curationStore.ratePost(
    state.value.selectedInterest,
    [{ post_id: postId, is_applicable: isApplicable }],
    state.value.selectedGeo,
  );
};
</script>

<style lang="scss">
.curation {
  margin: 0 auto;

  &__filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    & > .v-input {
      margin: 0 8px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    width: 80vw;
    margin-left: 24px;

    .underline {
      text-decoration: underline;
    }
  }
}
</style>
