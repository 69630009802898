<template>
  <rs-dialog :value="true" persistent max-width="650px" :fullscreen="$vuetify.breakpoint.xsOnly" v-if="account">
    <rs-card>
      <rs-card-title class="accounts__modal-header justify-center">
        <h3 class="headline font-weight-medium ma-0">{{ title }}</h3>
      </rs-card-title>
      <rs-divider />
      <rs-form
        ref="form"
        style="position: relative"
        class="dialog__form px-5 py-4"
        @submit.prevent="onSubmit"
        data-test-id="dialog-subscription-billing-form"
      >
        <loader v-if="loading" data-test-id="dialog-subscription-billing-loader" />
        <rs-card-text class="pa-0">
          <rs-card v-if="isScaleRoleSelected" style="border: none">
            <rs-checkbox v-model="hasCustomizedLimits" label="Customize subscription limitations"></rs-checkbox>
            <rs-expansion-panel v-model="toggleCustomizeLimitsPanel" expand class="elevation-0 pb-3">
              <rs-expansion-panel-content>
                <rs-text-field
                  v-model="attributeOverride.offer_limit.value"
                  label="Monthly offer limit"
                  type="text"
                  :mask="maskMonthlyOffer"
                  persistent-hint
                  :rules="offerLimitRules"
                  :hint="offerLimitHint"
                  data-test-id="dialog-monthly-offer-limit-field"
                ></rs-text-field>
                <rs-text-field
                  v-model="attributeOverride.annual_spend.value"
                  label="Annual spend limit"
                  type="text"
                  :mask="maskAnnualSpend"
                  persistent-hint
                  :rules="spendLimitRules"
                  :hint="spendLimitHint"
                  standard
                  data-test-id="dialog-annual-spend-limit-field"
                ></rs-text-field>
              </rs-expansion-panel-content>
            </rs-expansion-panel>
          </rs-card>
          <!-- eslint-disable vue/no-mutating-props -->
          <rs-text-field
            v-model="subscriptionRenewalDate"
            label="Subscription Renewal Date"
            :value="subscriptionRenewalDate || undefined"
            disabled
            data-test-id="dialog-subscription-renewal-date-text-field"
          ></rs-text-field>
          <!-- eslint-enable vue/no-mutating-props -->
          <rs-select
            class="dialog-account__custom-select"
            append-icon="rsfont-arrow-down_v2"
            label="Billing Interval"
            :disabled="subscriptionStatus === 'active'"
            :items="billingIntervals"
            :value="currentBillingInterval || undefined"
            :rules="[(v) => !!v || 'Billing Interval is required']"
            @input="handleBillingIntervalSelection"
          />
          <!-- eslint-disable vue/no-mutating-props -->
          <rs-text-field
            v-model="billingRenewalDate"
            label="Subscription Renewal Date"
            :value="subscriptionRenewalDate || undefined"
            disabled
            data-test-id="dialog-billing-renewal-date-text-field"
          ></rs-text-field>
          <!-- eslint-enable vue/no-mutating-props -->
          <template #header>
            <div class="v-label--active theme--light">Additional Info</div>
          </template>
          <div>
            <!-- eslint-disable vue/no-mutating-props -->
            <rs-text-field
              v-model="mssa"
              label="MSSA"
              :value="mssa"
              disabled
              data-test-id="dialog-subscription-billing-mssa-text-field"
            ></rs-text-field>
            <!-- eslint-enable vue/no-mutating-props -->
          </div>
        </rs-card-text>
        <rs-card-actions class="pa-0 my-3">
          <rs-btn inverted type="submit" :loading="submitting" data-test-id="dialog-subscription-billing-save-button"
            >Save</rs-btn
          >
          <rs-btn
            @click="
              () => {
                $emit('close');
              }
            "
            :disabled="submitting"
            data-test-id="dialog-subscription-billing-cancel-button"
            >Cancel</rs-btn
          >
        </rs-card-actions>
      </rs-form>
    </rs-card>
  </rs-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Loader from './Loader';
import loaderMixin from '@rscollabs/rs-core-library/src/mixins/rs-loader';
import { getRoleDisplayName } from '../util/roles.js';
import { generateMask } from '@/utils/helpers.js';

export default {
  name: 'EditSubscriptionBillingModal',
  props: {
    account: {
      type: Object,
      default: () => {},
    },
    billingRenewalDate: {
      type: String,
      default: '',
    },
    mssa: {
      type: String,
      default: '',
    },
    accountRole: {
      type: Object,
      default: () => {},
    },
    subscriptionRenewalDate: {
      type: String,
      default: '',
    },
    defaultBillingInterval: {
      type: String,
      default: 'Annual',
    },
    profile: {
      type: Object,
      default: () => {},
    },
    currentBillingInterval: {
      type: String,
      default: 'Annual',
    },
    subscriptionStatus: {
      type: String,
      default: '',
    },
    title: String,
  },
  data() {
    return {
      billingEditErrorMsg: '',
      chosenBillingInterval: '',
      currentRole: '',
      hasCustomizedLimits: false,
      attributeOverride: {
        offer_limit: {
          id: '',
          value: '',
        },
        annual_spend: {
          id: '',
          value: '',
        },
        unlimited: {
          value: -1,
        },
      },
      offerLimitHint:
        'Enter a monthly offer limit over 50 and less than 10,000. If left blank, will default to unlimited.',
      offerLimitRules: [(v) => v == '' || (v > 49 && v < 10001) || this.offerLimitHint],
      spendLimitHint:
        'Enter an annual spend limit over $100,000 and less than $500,000. If left blank, will default to unlimited.',
      spendLimitRules: [(v) => v == '' || (v > 99999 && v < 500001) || this.spendLimitHint],
      attributeInitValue: {
        offer_limit: '',
        annual_spend: '',
      },
      submitting: false,
    };
  },
  computed: {
    ...mapGetters('permissions', ['getAccountPermissions']),
    billingIntervals() {
      return ['Annual', 'Bi-Annual', 'Quarter'];
    },
    isAccManagement() {
      return this.$router.currentRoute.name === 'AccountManagement';
    },
    isScaleRoleSelected() {
      return this.currentRole == 'connect-scale';
    },
    toggleCustomizeLimitsPanel() {
      return [this.hasCustomizedLimits];
    },
    maskMonthlyOffer() {
      return generateMask(this.attributeOverride.offer_limit.value);
    },
    maskAnnualSpend() {
      return generateMask(this.attributeOverride.annual_spend.value, '$');
    },
    hasAnnualSpendChanged() {
      return (
        this.attributeInitValue['annual_spend'] !== this.attributeOverride.annual_spend.value &&
        !(
          this.attributeInitValue['annual_spend'] === this.attributeOverride.unlimited.value &&
          !this.attributeOverride.annual_spend.value
        )
      );
    },
    hasOfferLimitChanged() {
      return (
        this.attributeInitValue['offer_limit'] !== this.attributeOverride.offer_limit.value &&
        !(
          this.attributeInitValue['offer_limit'] === this.attributeOverride.unlimited.value &&
          !this.attributeOverride.offer_limit.value
        )
      );
    },
  },
  watch: {
    hasCustomizedLimits(val) {
      if (!val) {
        this.attributeOverride.offer_limit.value = '';
        this.attributeOverride.annual_spend.value = '';
      }
    },
  },
  created() {
    this.currentRole = this.accountRole.name;
  },
  async mounted() {
    await this.load();
  },
  components: {
    Loader,
  },
  mixins: [loaderMixin],
  methods: {
    ...mapActions('permissions', {
      postAttributeOverrides: 'postAttributeOverrides',
      patchAttributeOverrides: 'patchAttributeOverrides',
      deleteAttributeOverrides: 'deleteAttributeOverrides',
      fetchAccountPermissions: 'getAccountPermissions',
    }),
    async load() {
      try {
        this.loading = true;

        if (this.isScaleRoleSelected) {
          await this.getCustomizeLimits();
        }
      } catch (error) {
        this.$root.$emit('onError', error);
      } finally {
        this.loading = false;
      }
    },
    async updateAttributeOverrides(id, value) {
      const payload = {
        value,
      };
      await this.patchAttributeOverrides({
        id,
        accountId: this.account.id,
        payload,
      });
    },

    async createAttributeOverrides(id, value) {
      const payload = {
        overrider_id: this.account.id,
        value,
        override_type: 'account',
      };
      await this.postAttributeOverrides({ id, payload });
    },

    async createOrUpdateAttributeOverrides(id, value, initValue) {
      /* default value of attribute for Scale is -1 (unlimited)
        if value = -1 override it, else update the value
      */
      if (initValue !== this.attributeOverride.unlimited.value) {
        await this.updateAttributeOverrides(id, Number(value));
      } else {
        await this.createAttributeOverrides(id, Number(value));
      }
    },

    async onSubmit() {
      // validate form
      if (!this.$refs.form.validate()) return;

      try {
        this.submitting = true;

        if (this.chosenBillingInterval && this.chosenBillingInterval !== this.currentBillingInterval) {
          this.submitBillingInterval();
        }

        if (this.isScaleRoleSelected) {
          /* only create or update an attribute if value has been changed  */
          if (this.hasAnnualSpendChanged) {
            let id = this.attributeOverride.annual_spend.id;
            const value = this.attributeOverride.annual_spend.value;

            if (!id) {
              await this.getCustomizeLimits();
              id = this.attributeOverride.annual_spend.id;
            }

            if (!value) {
              await this.deleteAttributeOverrides({ id, accountId: this.account.id });
            } else {
              await this.createOrUpdateAttributeOverrides(id, value, this.attributeInitValue['annual_spend']);
            }
          }

          if (this.hasOfferLimitChanged) {
            let id = this.attributeOverride.offer_limit.id;
            const value = this.attributeOverride.offer_limit.value;

            if (!id) {
              await this.getCustomizeLimits();
              id = this.attributeOverride.offer_limit.id;
            }

            if (!value) {
              await this.deleteAttributeOverrides({ id, accountId: this.account.id });
            } else {
              await this.createOrUpdateAttributeOverrides(id, value, this.attributeInitValue['offer_limit']);
            }
          }
        }

        this.$emit('close');
      } catch (error) {
        console.error(error);
        this.$root.$emit('onError', error);
      } finally {
        this.submitting = false;
      }
    },
    async editBillingInterval() {
      let additionalParams;

      if (this.chosenBillingInterval) {
        const intervalMap = {
          Annual: 'year',
          'Bi-Annual': 'month',
          Quarter: 'month',
        };

        const intervalCountMap = {
          Annual: 1,
          'Bi-Annual': 6,
          Quarter: 3,
        };
        additionalParams = {
          value: {
            interval_count: intervalCountMap[this.chosenBillingInterval],
            recurring_interval_id: intervalMap[this.chosenBillingInterval],
          },
        };
      }

      try {
        const billingInterval = await this.$billing.updateCurrentUserPreferencesByType(
          this.account.id,
          'billing_interval',
          { ...additionalParams },
        );
        return { billingInterval };
      } catch (err) {
        console.error('err', err);
        let e = err.data ? err.data.error : err;
        if (e.indexOf('cannot update billing interval with an existing unique field') !== -1) {
          this.$root.$emit('onError', {
            data: {
              error: 'The Hubspot Company ID or Netsuite ID you have provided is already assigned to another company. ',
            },
          });
        }
      }
    },
    submitBillingInterval() {
      return this.editBillingInterval();
    },
    handleBillingIntervalSelection(input) {
      this.chosenBillingInterval = input;
    },
    setCustomizeLimit(name, value, id) {
      /* only set the value if user has not entered
      any number on the text field */
      if (!this.attributeOverride[name].value) {
        this.attributeOverride[name].value = value < 0 ? '' : value;
      }

      this.attributeOverride[name].id = id;
      this.attributeInitValue[name] = value;
    },
    async getCustomizeLimits() {
      await this.fetchAccountPermissions(this.account.id);
      const { permissions } = this.getAccountPermissions(this.account.id);
      const sendOfferPermission = permissions.find((p) => p.name === 'send-offers');

      const createCampainPermission = permissions.find((p) => p.name === 'create-campaigns');

      const setLimitBasedOnAttrName = (attributes) => {
        const attrName = attributes[0].name;
        const attrID = attributes[0].id;
        const attrValue = attributes[0].value;

        this.setCustomizeLimit(attrName, attrValue, attrID);
      };

      setLimitBasedOnAttrName(sendOfferPermission.attributes);
      setLimitBasedOnAttrName(createCampainPermission.attributes);

      this.hasCustomizedLimits = !!(
        this.attributeOverride.offer_limit.value || this.attributeOverride.annual_spend.value
      );
    },
  },
};
</script>

<style lang="sass" scoped>
.error-msg
  color: red

.v-select-list
  ::v-deep .theme--light.v-list .v-list__tile--link
    &:hover
      background-color: #808080
  ::v-deep .v-list__tile__title
    &:hover
      color: white !important
      height: 35px
      width: 100%
      font-family: Montserrat !important
      font-size: 14px !important
      line-height: 35px !important

::v-deep .v-list.v-list--dense.theme--light
  padding-top: 0
  padding-bottom: 0

::v-deep .v-select
  &__selections
    padding-top: 0 !important

::v-deep .v-input__append-inner
  i
    height: 13px
    width: 13px
    overflow: hidden
</style>
