<template>
  <rs-dialog v-model="isOpen" max-width="650px">
    <rs-card>
      <rs-card-title class="layout column">
        <rs-icon color="rs-information" large class="font-weight-bold">rsfont-alert-line_v2</rs-icon>
        <h4 class="text-xs-center mt-1 mb-0">
          <slot name="header" />
        </h4>
      </rs-card-title>

      <rs-card-text class="text-xs-center body-1 pt-0">
        <slot name="body" />
      </rs-card-text>

      <rs-card-actions class="pa-3 layout justify-center">
        <rs-btn inverted :block="false" @click="handleConfirm()">Yes</rs-btn>
        <rs-btn inverted :block="false" @click="handleDecline()">No</rs-btn>
      </rs-card-actions>
    </rs-card>
  </rs-dialog>
</template>
<script>
import { defineComponent } from 'vue';
import useModelWrapper from '@/composables/model-wrapper';

export default defineComponent({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: Boolean,
  },
  emits: {
    confirm: null,
    decline: null,
  },
  setup(props, { emit }) {
    const [isOpen] = useModelWrapper(props, emit);

    function closeModal() {
      isOpen.value = false;
    }

    function handleConfirm() {
      emit('confirm');

      closeModal();
    }

    function handleDecline() {
      emit('decline');

      closeModal();
    }

    return { isOpen, handleConfirm, handleDecline };
  },
});
</script>
