<template>
  <rs-container fluid fill-height>
    <rs-layout column>
      <rs-flex>
        <h2 class="display-3">{{ $route.meta.title }}</h2>
        <rs-breadcrumbs class="px-0" :items="$route.matched" divider=">">
          <rs-breadcrumbs-item
            slot="item"
            slot-scope="{ item }"
            :exact="true"
            :to="{ name: item.name, params: $route.params }"
          >
            {{ item.meta.title }}
          </rs-breadcrumbs-item>
        </rs-breadcrumbs>
      </rs-flex>

      <rs-flex xs12 mt-4>
        <rs-card>
          <rs-card-title>
            <h2 class="ma-0">Sales List</h2>
            <rs-spacer />
            <rs-btn
              :flat="false"
              :outline="false"
              :block="false"
              color="rs-success white--text"
              :to="{ name: 'LtkSaleAdd' }"
            >
              <rs-icon class="mr-1">ltkfont-plus-outline</rs-icon>
              Create New Sale
            </rs-btn>
          </rs-card-title>
          <rs-card-text>
            <rs-btn
              :flat="false"
              :outline="false"
              :block="false"
              color="rs-error white--text"
              small
              :disabled="isDeleteDisabled"
              @click="onDeleteClick()"
            >
              <rs-icon class="mr-1">ltkfont-delete-outline_1</rs-icon>
              Delete ({{ selectedRows.length }})
            </rs-btn>

            <sale-table v-model="selectedRows" @click-row="onEditClick($event)" />
          </rs-card-text>
        </rs-card>
      </rs-flex>
    </rs-layout>

    <sale-delete-modal v-model="isDeleteConfirmOpen" @confirm="onDeleteConfirm()" />
    <sale-edit-modal v-model="isEditConfirmOpen" @confirm="onEditConfirm()" />

    <rs-dialog v-model="isSheetOpen">
      <router-view />
    </rs-dialog>
  </rs-container>
</template>

<script>
import { defineComponent, onBeforeUnmount, ref, computed } from 'vue';

import { useRouter, useRoute } from 'vue-router/composables';
import useStore from '@/composables/vuex';

import SaleDeleteModal from '../components/modals/SaleDeleteModal.vue';
import SaleEditModal from '../components/modals/SaleEditModal.vue';
import SaleTable from '../components/SaleTable.vue';
import advertisersStore from '../store/advertisers';
import salesStore from '../store/sales';

export default defineComponent({
  components: { SaleDeleteModal, SaleEditModal, SaleTable },
  setup(_props, _context) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    store.registerModule('ltk-advertisers', advertisersStore);
    store.registerModule('ltk-sales', salesStore);

    onBeforeUnmount(() => {
      store.unregisterModule('ltk-sales');
      store.unregisterModule('ltk-advertisers');
    });

    const selectedRows = ref([]);
    const isEditConfirmOpen = ref(false);
    const isDeleteConfirmOpen = ref(false);

    const isEditDisabled = computed(() => selectedRows.value.length !== 1);
    const isDeleteDisabled = computed(() => selectedRows.value.length === 0);

    const isSheetOpen = computed({
      get() {
        return route.matched.length !== 1;
      },
      set() {
        router.push(route.matched[0].path);
      },
    });

    let pendingEditSaleId = null;

    function onEditClick(saleId) {
      const status = store.getters.saleStatus(saleId);
      pendingEditSaleId = saleId;

      if (status !== undefined && status === 'live') {
        isEditConfirmOpen.value = true;
      } else {
        onEditConfirm();
      }
    }

    function onEditConfirm() {
      const saleId = pendingEditSaleId;

      router.push({ name: 'LtkSaleManagement', params: { saleId } });
    }

    function onDeleteClick() {
      isDeleteConfirmOpen.value = true;
    }

    async function onDeleteConfirm() {
      const promises = selectedRows.value.map(({ id }) => {
        store.dispatch('deleteSale', id);
      });

      await Promise.allSettled(promises);
    }

    return {
      isEditConfirmOpen,
      isDeleteConfirmOpen,
      selectedRows,
      isEditDisabled,
      isDeleteDisabled,
      isSheetOpen,
      onEditClick,
      onDeleteClick,
      onEditConfirm,
      onDeleteConfirm,
    };
  },
});
</script>
