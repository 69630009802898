<template>
  <rs-button
    :rounded="rounded"
    :icon-only="iconOnly"
    :id="buttonId"
    @click="onClick"
    :class="dynamicClasses"
    :disabled="isDisabled"
  >
    <rs-icon v-if="iconOnly">{{ iconName }}</rs-icon>
    <slot></slot>
  </rs-button>
</template>

<script setup>
import RsButton from '../../internal-advertisers/lib/components/RsButton/RsButton.vue';
import { computed } from 'vue';

const props = defineProps({
  isDisabled: Boolean,
  onClick: Function,
  selectedId: String,
  buttonId: String,
  color: String,
  rounded: {
    type: Boolean,
    required: false,
    default: false,
  },
  iconOnly: {
    type: Boolean,
    required: false,
    default: false,
  },
  iconName: {
    type: String,
    required: false,
  },
});

const dynamicClasses = computed(() => ({
  [`selected selected_${props.color}`]: props.buttonId === props.selectedId,
}));
</script>

<style lang="scss" scoped>
.selected {
  color: #fff;

  &_green {
    background: #008000;
  }

  &_red {
    background: #8b0000;
  }

  &_gray {
    background: #a9a9a9;
    border: 1px solid #a9a9a9;
    font-style: italic;
  }

  .v-icon {
    color: #fff;
  }
}
</style>
