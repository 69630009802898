import clientFactory from './client';

const client = clientFactory('/api');

const useAdvertiserApi = (id) => {
  const rootUrl = '/v1/advertiser_control_panel';
  const makeQuery = (params) => {
    const queryParams = Object.entries({ ...params }).map(([key, value]) => {
      if (key === 'limit') {
        return `limit=${value}`;
      }
      if (key === 'page') {
        return `page=` + value;
      }
      return value || key !== 'active' ? `filter[${key}]=${value}` : '';
    });
    return queryParams.filter((q) => q).join('&');
  };

  const getAdvertisers = (filters) => {
    const response = client
      .get(`${rootUrl}/advertisers`, {
        params: {
          ...filters,
        },
        paramsSerializer: (params) => makeQuery(params),
      })
      .then((response) => {
        const advertisers = response.data.data.advertisers;
        const pagination = response.data.data.pagination;
        const result = advertisers.map((advertiser) => {
          return {
            ...advertiser,
            to: `/links/advertisers/${advertiser.id}`,
            searchKey: `${advertiser.name} - ${advertiser.id}`,
          };
        });
        return { result, pagination };
      });
    return response;
  };

  const getAdvertiser = async () => {
    const response = await client
      .get(`${rootUrl}/advertisers/${id}`, {})
      .then((response) => response.data.data.advertiser);
    return response;
  };

  const putAdvertiser = async (data) => {
    return client.put(`${rootUrl}/advertisers/${id}`, { data }).catch((error) => {
      console.warn('err', JSON.stringify(error));
      throw new Error(error.data.error.detail);
    });
  };

  const postNewAdvertiser = async (data) => {
    return client.post(`${rootUrl}/advertisers`, { data }).catch((error) => {
      throw new Error(error.data.error.detail);
    });
  };

  const getRegions = async () => {
    return await client.get(`${rootUrl}/regions`, {}).then((response) =>
      response.data.data.regions
        .filter((item) => item.name !== '')
        .map((item) => {
          return {
            ...item,
            display: `${item.name} [${item.abbreviation}]`,
          };
        }),
    );
  };

  const getSources = async () => {
    return await client.get(`${rootUrl}/sources`, {}).then((response) => {
      const sources = response.data.data.sources;
      const sourceData = sources
        .filter((item) => item.description !== null)
        .map((item) => {
          return {
            ...item,
            display: `${item.description} - ${item.id}`,
          };
        });
      return sourceData;
    });
  };

  const putMigration = async (prev, next) => {
    return client.put(`${rootUrl}/migrate/advertiser/division/${prev}/${next}`, {}).catch((error) => {
      throw new Error(error.data.error.detail);
    });
  };

  const postMigration = async (advertiserId, data) => {
    return client.post(`${rootUrl}/migrate/advertiser/division/${advertiserId}`, { data }).catch((error) => {
      throw new Error(error.data.error.detail);
    });
  };

  return {
    getAdvertisers,
    getAdvertiser,
    getRegions,
    putAdvertiser,
    putMigration,
    postMigration,
    postNewAdvertiser,
    getSources,
  };
};

export default useAdvertiserApi;
