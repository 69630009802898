import clientFactory from './client';
import { ref } from 'vue';
const client = clientFactory('/api');

const useDivisionApi = () => {
  const rootUrl = '/v1/advertiser_control_panel';
  const parents = ref([]);
  const divisions = ref([]);
  const errors = ref([]);

  const getDivisions = async (parent_id) => {
    const query = !!parent_id ? `?filter[retailer_id]=${parent_id}` : '';
    const response = await client.get(`${rootUrl}/divisions${query}`, {});
    const res = response.data.data.divisions;
    const divisionData = res.map((division) => {
      return {
        ...division,
        display_name: `${division.name} - ${division.id}`,
      };
    });
    divisions.value = divisionData;
    return response;
  };

  const postDivisions = async (data) => {
    return client
      .post(`${rootUrl}/divisions`, { data })
      .then((res) => {
        const data = res.data.data;
        if (res.data.data.error) {
          errors.value = data.error;
        }
        return data;
      })
      .catch((error) => {
        throw new Error(error.data.error.detail);
      });
  };

  const putDivision = (id, data) => {
    return client
      .put(`${rootUrl}/divisions/${id}`, { data })
      .then((res) => {
        const data = res.data.data;
        if (res.data.data.error) {
          errors.value = data.error;
        }
        return data;
      })
      .then((data) => {
        getDivisions(data.division.retailer_id);
      })
      .catch((error) => {
        throw new Error(error.data.error.detail);
      });
  };

  const getParents = async () => {
    const response = await client.get(`${rootUrl}/parents`, {});
    const parentResponse = response.data.data.parents;
    const parentData = parentResponse.map((parent) => {
      const res = {
        ...parent,
        display_name: `${parent.name} - ${parent.id}`,
      };
      return res;
    });
    parents.value = parentData;
    return parentData;
  };

  const postParents = async (data) => {
    return client
      .post(`${rootUrl}/parents`, { data })
      .then((res) => {
        const data = res.data.data;
        if (res.data.data.errors) {
          errors.value = data.errors;
        }
        return data;
      })
      .catch((error) => {
        throw new Error(error.data.error.detail);
      });
  };

  return { errors, parents, divisions, postParents, getParents, putDivision, postDivisions, getDivisions };
};

export default useDivisionApi;
