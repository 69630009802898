<template>
  <div class="quality-control-bar">
    <bar-chart :chart-data="chartData" :options="chartOptions" />
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { Bar, mixins } from 'vue-chartjs';
import useStore from '@/composables/vuex';

const { reactiveProp } = mixins;

// Extend the Bar component to create your custom chart component
const BarChart = {
  extends: Bar,
  mixins: [reactiveProp], // Make it reactive
  props: ['chartData', 'options'],
  mounted() {
    this.renderChart(this.chartData, this.options || {});
  },
};

export default defineComponent({
  name: 'QualityControlBar',
  components: {
    BarChart,
  },
  setup(_props, _context) {
    const store = useStore();

    const chartData = computed(() => {
      return store.getters.chartData;
    });

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [{ ticks: { beginAtZero: true } }],
      },
    };

    return {
      chartData,
      chartOptions,
    };
  },
});
</script>
