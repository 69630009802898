<template>
  <rs-form
    ref="form"
    style="position: relative"
    class="dialog__form mx-4 mb-4"
    data-test-id="dialog-manage-renewal-form"
  >
    <rs-card-text>
      <!-- eslint-disable-next-line vue/no-mutating-props -->
      <rs-text-field class="dialog-account__text-field" label="Subscription type" v-model="subscriptionType" disabled>
      </rs-text-field>
      <rs-text-field
        class="dialog-account__text-field"
        label="Subscription pricing"
        :prefix="currency"
        :value="subscriptionPriceDisplay"
        data-test-id="price-input"
        @keyup="handleSubscriptionPriceChange"
        @keydown="handleKeyDown"
        @click.right.prevent
        :error-messages="subscriptionPriceError"
      />
      <PriceBreakdownTable
        class="mb-3 mt-2"
        :msrp-price-dollars="msrpPriceDollars"
        :subscription-price-dollars="subscriptionPriceDollars"
      />
      <!-- eslint-disable vue/no-mutating-props -->
      <rs-text-field
        class="dialog-account__text-field"
        label="Billing interval"
        v-model="subscriptionBillingInterval"
        disabled
      />
      <rs-text-field
        class="dialog-account__text-field"
        label="Renewal date"
        v-model="subscriptionRenewalDate"
        disabled
      />
      <!-- eslint-enable vue/no-mutating-props -->
      <ManageRenewalModalRenewalText
        :account-name="account.name"
        :subscription-type="subscriptionType"
        :subscription-renewal-date="subscriptionRenewalDate"
        :subscription-interval-price-text="subscriptionIntervalPriceText"
      />
    </rs-card-text>
    <rs-card-actions>
      <rs-btn
        inverted
        class="mx-2 mb-2"
        :disabled="!formDirty"
        data-test-id="save-button"
        @click="
          () => {
            $emit('openReviewPriceModal');
          }
        "
        >Save</rs-btn
      >
      <rs-btn
        class="mx-2 mb-2"
        @click="
          () => {
            $emit('close');
          }
        "
        >Cancel</rs-btn
      >
    </rs-card-actions>
  </rs-form>
</template>

<script>
import { formatCurrencyExt } from '@/utils/helpers';
import PriceBreakdownTable from './PriceBreakdownTable.vue';
import ManageRenewalModalRenewalText from './ManageRenewalModalRenewalText.vue';

export default {
  name: 'ManageRenewalModalPriceForm',
  components: {
    PriceBreakdownTable,
    ManageRenewalModalRenewalText,
  },
  props: {
    account: {
      type: Object,
      default: () => {},
    },
    availableSubscriptionTypes: {
      type: Array,
      default: () => [],
    },
    subscriptionType: {
      type: String,
      default: '',
    },
    currency: {
      type: String,
      default: '',
    },
    subscriptionPriceDollars: {
      type: Number,
      default: 0,
    },
    nextPriceDollars: {
      type: Number,
      default: 0,
    },
    msrpPriceDollars: {
      type: Number,
      default: 0,
    },
    subscriptionBillingInterval: {
      type: String,
      default: 'Annual',
    },
    subscriptionRenewalDate: {
      type: String,
      default: '',
    },
    subscriptionIntervalPriceText: {
      type: String,
      default: '',
    },
    subscriptionPriceError: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      priceVal: this.subscriptionPriceDollars,
    };
  },
  watch: {
    subscriptionPriceDollars() {
      this.priceVal = this.subscriptionPriceDollars;
    },
  },
  computed: {
    availableSubscriptionTypeOptions() {
      return this.availableSubscriptionTypes.map((t) => t.name);
    },
    subscriptionPriceDisplay() {
      return formatCurrencyExt(this.priceVal, false);
    },
    formDirty() {
      return this.subscriptionPriceDollars != this.nextPriceDollars;
    },
  },
  methods: {
    handleKeyDown(event) {
      if (this.isInvalidKey(event.keyCode) || event.shiftKey === true) return event.preventDefault();
    },
    isInvalidKey(keyCode) {
      const isCommand = keyCode <= 31;
      const isNumber = 48 <= keyCode && keyCode <= 57;
      const isNumpadNumber = 96 <= keyCode && keyCode <= 105;
      return !(isNumber || isCommand || isNumpadNumber);
    },
    handleSubscriptionPriceChange(event) {
      const value = event.target.value.replace(/[^0-9]/g, '');
      this.priceVal = value;
      this.$emit('subscriptionPriceUpdated', value);
    },
  },
};
</script>
