<template>
  <rs-dialog v-model="dialog" width="700">
    <template #activator="{ on }">
      <rs-btn :loading="loading" :disabled="disabled || loading" v-on="on" class="mr-3">decline</rs-btn>
    </template>
    <rs-card>
      <rs-form>
        <rs-container>
          <rs-layout row wrap>
            <rs-flex xs6 offset-xs3>
              <rs-select
                v-model="selectedReason"
                :items="declineReasons"
                item-text="name"
                item-value="id"
                label="Decline Reasons"
              >
              </rs-select>
            </rs-flex>
            <rs-flex xs2 offset-xs10>
              <rs-btn @click="decline" :disabled="selectedReason === ''"> Decline </rs-btn>
            </rs-flex>
          </rs-layout>
        </rs-container>
      </rs-form>
    </rs-card>
  </rs-dialog>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
    selectedReason: '',
  }),
  methods: {
    decline() {
      this.dialog = false;
      this.$emit('decline', this.selectedReason);
    },
  },
  async mounted() {
    await this.$store.dispatch('loadResolutions');
  },
  computed: {
    declineReasons() {
      return Object.values(this.$store.state['influencer-application'].resolutionsMap).filter(
        (resolution) => resolution.status === 'declined',
      );
    },
  },
};
</script>
