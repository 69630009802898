<template>
  <rs-modal v-model="modal" size="md" @closed="onClose()">
    <rs-modal-header title="Migrate Advertiser" subtitle="Migrate to new advertiser entry" @close="onClose()" />
    <rs-modal-content class="pt-0">
      <rs-layout>
        <rs-flex xs12>
          <rs-button-group tabs full class="migration-content--selector">
            <rs-button tab full size="sm" :active="migration.type === 'existing'" @click="migrationType('existing')">
              Existing Advertiser
            </rs-button>
            <rs-button tab full size="sm" :active="migration.type === 'new'" @click="migrationType('new')">
              New Advertiser
            </rs-button>
          </rs-button-group>
        </rs-flex>
      </rs-layout>
      <rs-alert v-model="state.error" type="error" dismissible> {{ state.message }} </rs-alert>
      <rs-form ref="advertiserFormRef" v-model="rules.valid" v-if="migration.type === 'existing'">
        <rs-layout wrap class="migration-content--existing">
          <rs-flex sm6>
            <div class="migration-content__details">
              <field-metric label="From" :value="`${initialData.name} - ${initialData.id}`" class="span2" />
              <field-metric label="Affiliate ID" :value="initialData.affiliate_id" />
              <field-metric label="Source Name" :value="initialData.source_description" />
            </div>
          </rs-flex>
          <rs-flex sm6>
            <div class="migration-content__details">
              <field-metric
                label="To"
                :value="migration.to ? `${migration.to.name} - ${migration.to.id}` : '--'"
                class="span2"
              />
              <field-metric
                label="Affiliate ID"
                :value="migration.to.affiliate_id ? migration.to.affiliate_id : '--'"
              />
              <field-metric label="Source Name" :value="migration.to ? migration.to.source_description : '--'" />
            </div>
          </rs-flex>
          <rs-flex xs12>
            <rs-divider class="mt-4 mb-4" />
          </rs-flex>
          <rs-flex xs12>
            <rs-autocomplete
              :items="data.advertisers"
              label="Migrate to"
              :search-input.sync="migration.searchTo"
              v-model="migration.to"
              :loading="migration.loading"
              item-text="name"
              item-value="id"
              :rules="[rules.required, rules.different]"
              return-object
            >
              <template #item="advertiser">
                <rs-list-tile-content v-bind="advertiser.attrs" :input-value="advertiser.selected">
                  <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
                  <rs-list-tile-title v-html="advertiser.item.name" />
                  <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
                  <rs-list-tile-sub-title v-html="advertiser.item.id" />
                </rs-list-tile-content>
                <rs-chip v-if="advertiser.item.id === initialData.id" small>Current</rs-chip>
              </template>
            </rs-autocomplete>
          </rs-flex>
        </rs-layout>
      </rs-form>
      <rs-form ref="advertiserFormRef" v-model="rules.valid" v-if="migration.type === 'new'">
        <rs-layout class="migration-content--new" wrap>
          <rs-flex xs12>
            <rs-autocomplete
              label="Source"
              :items="sources"
              item-text="display"
              item-value="id"
              v-model.number="data.migrationData.source"
              :rules="[rules.required]"
              clearable
            />
          </rs-flex>
          <rs-flex xs6>
            <rs-text-field
              label="Affiliate ID"
              v-model="data.migrationData.affiliate_id"
              class="mr-2"
              :rules="[rules.required]"
            />
          </rs-flex>
          <rs-flex xs6>
            <rs-text-field
              label="Deeplink ID"
              class="ml-1"
              v-model="data.migrationData.deeplink_advertiser"
              :rules="[rules.required]"
            />
          </rs-flex>
        </rs-layout>
        <migration-success
          @close="onClose"
          @return="onComplete"
          @complete="onExistingComplete"
          :success="state.success"
          :type="migration.type"
        />
      </rs-form>
    </rs-modal-content>
    <rs-modal-footer justify="space-between">
      <div class="migration-footer--start">
        <rs-button @click="onReset" size="sm" v-if="migration.type === 'new'"> Reset </rs-button>
      </div>
      <rs-button-group :grouped="false">
        <rs-button @click="onClose()"> Cancel </rs-button>
        <rs-button primary @click="onSubmit()" :disabled="!rules.valid"> Migrate </rs-button>
      </rs-button-group>
    </rs-modal-footer>
  </rs-modal>
</template>

<script>
import { defineComponent, watch, ref, reactive, nextTick, onMounted } from 'vue';
import RsButtonGroup from '../lib/components/RsButton/RsButtonGroup.vue';
import RsButton from '../lib/components/RsButton/RsButton.vue';
import RsModal from '../lib/components/RsModal/RsModal.vue';
import RsModalHeader from '../lib/components/RsModal/RsModalHeader.vue';
import RsModalContent from '../lib/components/RsModal/RsModalContent.vue';
import RsModalFooter from '../lib/components/RsModal/RsModalFooter.vue';
import useAdvertiserApi from '../composables/useAdvertiserApi';
import MigrationSuccess from './MigrationSuccess.vue';
import FieldMetric from './FieldMetric.vue';
import { useRouter } from 'vue-router/composables';

export default defineComponent({
  name: 'MigrationModal',
  model: {
    prop: 'active',
    event: 'update:active',
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    initialData: {
      type: Object,
      default: () => ({}),
    },
    sources: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    RsModal,
    RsModalHeader,
    RsModalContent,
    RsModalFooter,
    RsButton,
    RsButtonGroup,
    MigrationSuccess,
    FieldMetric,
  },
  setup(props, { emit }) {
    const $router = useRouter();
    const modal = ref(false);
    const { postMigration, putMigration, getAdvertisers } = useAdvertiserApi();

    const searchKey = [
      {
        label: 'Name',
        value: 'name',
      },
      {
        label: 'Advertiser ID',
        value: 'id',
      },
    ];

    const data = reactive({
      advertiserData: {},
      migrationData: {
        source: null,
        affiliate_id: null,
        deeplink_advertiser: null,
      },
      advertisers: [],
    });

    const state = reactive({
      success: false,
      error: false,
      message: false,
    });

    const migration = reactive({
      type: 'existing',
      filterBy: 'name',
      from: '',
      to: '',
      searchTo: '',
      loading: false,
    });

    const rules = reactive({
      valid: true,
      required: (value) => !!value || 'Required',
      different: (value) => value.id !== props.initialData.id || 'Cannot be the same as the source',
    });

    watch(
      () => props.active,
      async (next) => {
        await nextTick();
        if (props.active) {
          modal.value = props.active;
          emit('update:active', next);
        }
      },
      { immediate: true },
    );

    watch(
      () => props.initialData,
      (prev, next) => {
        if (prev || next) {
          migration.from = props.initialData.id;
          migration.searchFrom = props.initialData.name;
          data.advertiserData = {
            ...props.initialData,
            division: null,
            parent_id: null,
          };
        }
      },
      {
        immediate: true,
      },
    );

    const migrationType = (type) => {
      state.error = false;
      migration.type = type;
    };

    const handleExisting = () => {
      putMigration(migration.from, migration.to.id)
        .then(() => {
          state.error = false;
          state.success = true;
          state.message = 'Advertiser Successfully Migrated';
        })
        .catch((error) => {
          state.success = false;
          state.error = true;
          state.message = error;
        });
    };

    const handleNew = () => {
      const payload = {
        ...data.advertiserData,
        ...data.migrationData,
      };
      postMigration(data.advertiserData.id, payload)
        .then(() => {
          state.success = true;
          state.error = false;
          state.message = 'Advertiser Successfully Migrated';
        })
        .catch((error) => {
          state.success = false;
          state.error = true;
          state.message = error;
        });
    };

    const onSubmit = () => {
      `${migration.type}` === 'existing' ? handleExisting() : handleNew();
    };

    const onReset = () => {
      data.migrationData = {
        ...props.initialData,
        division: null,
        parent_id: null,
        source: null,
        affiliate_id: null,
        deeplink_advertiser: null,
      };
    };

    // Success Modal
    const onClose = async () => {
      await nextTick();
      modal.value = false;
      emit('update:active', false);
    };

    const onComplete = () => {
      $router.push('/links/advertisers');
    };

    const onExistingComplete = () => {
      nextTick(() => {
        $router.push(`/links/advertisers/${migration.to.id}`);
        location.reload();
      });
    };

    const searchAdvertisers = (filter) => {
      if (migration.loading) return;
      const formattedFilter = filter ? { [migration.filterBy]: filter } : null;
      migration.loading = true;
      getAdvertisers(formattedFilter)
        .then((response) => {
          data.advertisers = response.result.filter((response) => response.name);
        })
        .catch((err) => console.warn(err))
        .finally(() => (migration.loading = false));
    };

    watch(
      () => migration.searchTo,
      (next) => {
        if (next) {
          searchAdvertisers(next);
        }
      },
      {
        flush: 'post',
      },
    );

    onMounted(() => {
      searchAdvertisers();
    });

    return {
      searchKey,
      data,
      migration,
      state,
      modal,
      onClose,
      onSubmit,
      onReset,
      onComplete,
      onExistingComplete,
      migrationType,
      rules,
    };
  },
});
</script>

<style lang="scss">
@use '../scss/breakpoints';
@import '../scss/typography';
@import '../scss/colors';

.migration-content {
  &__details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    :not(:last-child) {
      padding-right: 8px;
    }
    .span2 {
      grid-column: span 2;
    }
  }
  &--close {
    position: absolute;
    right: 16px;
    top: 16px;
  }
  &--selector {
    margin-bottom: 16px;
  }
  &--existing,
  &--new {
    @include breakpoints.up('sm') {
      min-width: 480px;
    }
  }
  &--existing {
    p {
      text-align: center;
    }
  }
  &--complete {
    background-color: var(--green-1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid var(--green-3);
    border-radius: 3px;
  }
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
