<template>
  <div>
    <rs-flex class="sub-input-wrapper">
      <editor-menu class="editor-menu" :class="disabled ? 'disabled' : ''" :editor="editor" />
      <div class="message-sub-input mr-2 ml-2">
        <rs-flex class="text-input">
          <div class="input-box-wrapper" data-test-id="input-text-box">
            <editor-text-box :editor="editor" :class="disabled ? 'input-box__disabled' : 'input-box'" />
          </div>
        </rs-flex>
      </div>
    </rs-flex>
  </div>
</template>
<script>
import { v1 as uuidv1 } from 'uuid';
import { isEmpty } from 'lodash';
import { Editor } from 'tiptap';
import {
  Bold,
  BulletList,
  Italic,
  OrderedList,
  ListItem,
  Underline,
  History,
  Placeholder,
  Link,
} from 'tiptap-extensions';
import EditorMenu from './InsightEditorMenu.vue';
import EditorTextBox from './InsightEditorTextBox.vue';

export default {
  name: 'InsightInput',
  components: {
    EditorMenu,
    EditorTextBox,
  },
  props: {
    isActiveChannelArchived: Boolean,
    sendMediaCallback: {
      type: Function,
      default: () => {},
    },
    sendMessageCallback: {
      type: Function,
      default: () => {},
    },
    noteText: {
      type: String,
      default: '',
    },
    channelList: {
      type: Array,
      default: () => [],
    },
    archivedState: {
      type: String,
      default: 'Active',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      msgInput: '',
      inputRows: 1,
      sending: false,
      localStorageDraftMapKey: 'msgDraftsByCollabId',
      message_context_id: null,
      mediaFiles: [],
      editor: new Editor({
        content: '',
        disablePasteRules: true,
        disableInputRules: true,
        autoFocus: false,
        extensions: [
          new Bold(),
          new BulletList(),
          new ListItem(), // Needed for bullet and ordered lists to work.
          new Italic(),
          new OrderedList(),
          new Underline(),
          new History(),
          new Link(),
          new Placeholder({
            emptyNodeText: () => {
              if (this.isActiveChannelArchived && this.archivedState === 'Archived') {
                return 'Unarchive conversation to message this Creator.';
              } else if (!this.collaborationId && this.channelList.length > 0) {
                return 'Please select a messaging thread.';
              } else if (!this.collaboration && this.channelList.length < 1) {
                return '';
              } else {
                return 'Type a message...';
              }
            },
            showOnlyWhenEditable: false,
          }),
        ],
        onUpdate: ({ getHTML }) => {
          // get new content on update
          const newContent = getHTML();
          this.msgInput = newContent;
        },
      }),
    };
  },
  watch: {
    noteText(newVal, oldVal) {
      this.editor.clearContent();
      this.editor.setContent(newVal);
    },
  },
  mounted() {},
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {},
};
</script>

<style lang="sass" scoped>
@media only screen and (max-width: 500px)

  .send-progress-bar
    margin: 0px

  input[type=file]
    display: none

  .disabled
    cursor: not-allowed !important
    pointer-events: none

  .fixed-message-input
    width: 100vw
    background: #fff
    position: fixed
    bottom: 0
    border-top: 1px solid rgba(0, 0, 0, 0.12)

  .message-sub-input
    display: inline-flex
    float: left
    width: 100%

  .message-input
    max-height: calc(100vh * 0.8)
    max-width: 100vw
    flex: 0 1 auto
    border: 12px solid rgba(0, 0, 0, 0.12)

  .text-input
    display: block
    overflow-y: auto
    resize: none
    line-height: 22px
    width: calc(100% - 64px)
    &.disabled
      cursor: not-allowed

  .text
    line-height: 22px
    ::placeholder
      color: #C0C0C0 !important
      text-align: left
      font-size: 14px
    .file-upload
      display: block
      float: left
      text-align: center

  .input-box-wrapper
    overflow-y: scroll
    &::-webkit-scrollbar
      width: 12px
      background-color: #fff
    &::-webkit-scrollbar-track
      background-color: #fff
      border: none
    &::-webkit-scrollbar-thumb
      background-color: #babac0
      border-radius: 16px
      border: 2px solid #fff

  .input-box
    max-width: 100%
    max-height: 350px
    overflow-x: visible
    word-break: break-word
    &__disabled
      pointer-events: none
      color: #9E9E9E

@media only screen and (min-width: 501px)

  input[type=file]
    display: none

  .disabled
    cursor: not-allowed !important
    pointer-events: none !important

  .send-progress-bar
    margin: 0

  .text-input
    display: flex
    width: calc(100% - 64px)
    line-height: 22px
    align-self: start
    &.disabled
      cursor: not-allowed

  .send-button
    display: flex
    align-self: flex-end

  .send-btn
    font-size: 16px
    font-weight: 600
    text-decoration: none
    &.disabled
      cursor: not-allowed

  .file-upload
    display: flex

  .text
    line-height: 22px
    ::placeholder
      color: #C0C0C0 !important
      text-align: left
      font-weight: 600
      font-size: 14px

  .message-sub-input
    align-items: center
    display: flex
    background: #fff
    flex-direction: row

  .sub-input-wrapper
    border: 1px solid rgba(0, 0, 0, 0.12)
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.12)
    padding:4px 0 0
  .input-box-wrapper
    overflow-y: scroll
    width:100%
    padding-left: 12px
    &::-webkit-scrollbar
      width: 12px
      background-color: #fff
    &::-webkit-scrollbar-track
      background-color: #fff
      border: none
    &::-webkit-scrollbar-thumb
      background-color: #babac0
      border-radius: 16px
      border: 2px solid #fff

  .input-box
    max-width: 100%
    max-height: 350px
    overflow-x: visible
    word-break: break-word
    &__disabled
      pointer-events: none
      color: #9E9E9E

  ::v-deep .editor li p:last-child
      padding-bottom: 10px !important

::v-deep .editor p.is-editor-empty:first-child::before
      content: attr(data-empty-text)
      float: left
      color: #C0C0C0 !important
      pointer-events: none
      height: 0
      font-family: SofiaPro
      font-weight: 600
      font-size: 14px

.file-upload
  width: 24px
  label
    height:24px
.disabled
  i
    color: rgba(0,0,0,0.2) !important
  &.editor-menu
    opacity:0.4
</style>
