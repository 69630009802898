import { getClient } from '../utils/client';
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

const useCurationStore = defineStore('Curation Store', () => {
  const posts = ref([]);
  const mediaObjects = ref([]);
  const profiles = ref([]);
  const pagination = ref({ next: undefined, isLastPage: false });
  const isLoadingInterestData = ref(true);
  const isLoadingScorePost = ref(false);
  const client = getClient({
    host: window.rewardStyle.API_GATEWAY,
    basePath: '/api/pub/v3',
    includeAuth: true,
  });
  const internalClient = getClient({
    host: window.rewardStyle.API_GATEWAY,
    basePath: '/api/int/v3',
    includeAuth: true,
  });

  const getDataForInterest = async (interest = 'travel_fashion', geo = 'all') => {
    isLoadingInterestData.value = true;

    // TODO: request more than 1 to get some preloading in there? TBD depending on where UI lands
    try {
      const response = await client.get(
        `/activities/posts/interest/${interest}?includes%5B%5D=posts&includes%5B%5D=media_objects&includes%5B%5D=profiles&limit=1&geo=${geo}${
          pagination.value.next ? `&next=${pagination.value.next}` : ''
        }&styled=true&video=true`,
      );

      profiles.value = response.data.profiles;
      posts.value = response.data.posts;
      mediaObjects.value = response.data.media_objects;
      pagination.value = {
        next: response.data.meta?.next,
        // if the response doesn't have a next meta value then we are at the last page
        isLastPage: !response.data.meta?.next,
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`getDataForInterest (${interest}) error:`, error);
    } finally {
      isLoadingInterestData.value = false;
    }
  };

  /**
   * Generic helper to find object in array given objects id
   *
   * @param id - id of object we are looking for
   * @param dataList - array we are searching in
   */
  const getDataById = (id, dataList) => {
    const index = dataList.findIndex((media) => media.id === id);

    if (index < 0) {
      return {};
    }

    return dataList[index];
  };

  /**
   * Combine post and media data for given post into single object for easier access and use in components
   */
  const curationPosts = computed(() => {
    const postAndMediaData = posts.value.map((post) => {
      const mediaData = getDataById(post.media_object_ids[0], mediaObjects.value);
      const profileData = getDataById(post.profile_id, profiles.value);

      return {
        ...post,
        media: mediaData,
        profile: profileData,
      };
    });

    return postAndMediaData;
  });

  /**
   *
   * @param interest
   * @param scoredPosts - list of posts with post_id and is_applicable true/false depending on whether post was up or downvoted
   * @param selectedGeo - currently selected geolocation
   */
  const ratePost = (interest, scoredPosts, selectedGeo) => {
    isLoadingScorePost.value = true;

    internalClient
      .post('/activities/interest/tools/score_posts', {
        interest: interest,
        scored_posts: scoredPosts,
      })
      .then(() => {
        if (!pagination.value.next) {
          // eslint-disable-next-line no-console
          console.log('No next item to rate');
          // TODO: show some sort of "you rated them all" UI to user
          return;
        }
        // if voting was success get next item
        getDataForInterest(interest, selectedGeo);
      })
      .catch((error) => {
        // TODO: proper error handling, clear selected button and show error to user
        // eslint-disable-next-line no-console
        console.warn('ratePost err: ', error);
      })
      .finally(() => {
        isLoadingScorePost.value = false;
      });
  };

  return {
    getDataForInterest,
    ratePost,
    curationPosts,
    pagination,
    isLoadingInterestData,
    isLoadingScorePost,
  };
});

export { useCurationStore };
