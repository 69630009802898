<template>
  <div
    class="image-upload__container"
    :class="{ 'image-upload__container--bubble': typeBubble, 'image-upload__container--banner': !typeBubble }"
  >
    <rs-button
      full
      data-test-id="open-button"
      @click="open"
      type="button"
      :disabled="validating || localFile.length > 0"
      >Upload Image</rs-button
    >
    <div class="image-upload__selected-image">
      <img class="image-upload__preview-image" v-if="localUrl" :src="localUrl" />
      <span>{{ isEditing ? 'Current Image' : imageText }}</span>
      <span v-if="validating">...Validating</span>
      <rs-icon
        v-if="localFile.length > 0"
        class="image-upload__remove-image"
        @click="clearFiles"
        data-test-id="close-button"
      >
        close
      </rs-icon>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';
import { useRoute } from 'vue-router/composables';
import RsButton from '@/internal-advertisers/lib/components/RsButton/RsButton.vue';
import { useFileDialog } from '@vueuse/core';
import usePsApi from '@/services/usePsApi.js';

export default defineComponent({
  name: 'HashtagsImageUpload',
  components: { RsButton },
  emits: { 'img-update': () => true },
  props: {
    type: {
      type: String,
      required: true,
    },
    file: {
      type: Array,
      default: () => [],
    },
    fileName: {
      type: String,
      default: () => null,
    },
    url: {
      type: String,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const route = useRoute();

    const localFile = ref(props.file);
    const localFileName = ref(props.fileName);
    const localUrl = ref(props.url);
    const presignedId = ref('');

    const typeBubble = ref(props.type === 'bubble');
    const isEditing = route.name === 'BubblesEdit';

    const imageText = computed(() => {
      return localFile.value[0] ? localFile.value[0].name : 'No Image Selected';
    });

    const presentationService = usePsApi();

    const { files, open, onChange } = useFileDialog({
      accept: 'image/*',
      directory: false,
      multiple: false,
    });

    onChange((files) => {
      localFile.value = [...files];
      localFileName.value = files[0].name;
      uploadMedia();
    });

    const validating = ref(false);

    async function uploadMedia() {
      validating.value = true;
      try {
        const { presigned_id } = await presentationService.uploadMedia(localFile.value[0]);
        presignedId.value = presigned_id;
        pollUpload();
      } catch (e) {
        console.warn('ERROR: ', e);
        validating.value = false;
      }
    }

    async function pollUpload() {
      const intervalId = setInterval(async () => {
        const { url } = await presentationService.pollMediaService(presignedId.value);
        if (url) {
          localUrl.value = url;
          clearInterval(intervalId); // Stop the interval if value is true
          validating.value = false;
          updateImage();
          return;
        }
      }, 2000);
    }

    function updateImage() {
      emit('img-update', {
        file: localFile.value,
        fileName: localFileName.value,
        url: localUrl.value,
      });
    }

    const clearFiles = () => {
      localFile.value = [];
      localFileName.value = null;
      localUrl.value = null;
      validating.value = false;
      emit('img-update', {
        file: localFile.value,
        fileName: localFileName.value,
        url: localUrl.value,
      });
    };

    return { files, imageText, isEditing, localFile, localUrl, typeBubble, validating, clearFiles, open, updateImage };
  },
});
</script>

<style lang="scss">
.image-upload {
  &__container--bubble {
    display: flex;
    flex-direction: column;
    grid-column-start: 10;
    grid-column-end: 13;
  }
  &__container--banner {
    grid-column: span 3;
  }
  &__container {
    position: relative;
    .rs--button.is--full {
      margin-top: 4px;
    }
    span {
      display: block;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  &__selected-image {
    flex: 0 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__preview-image {
    height: 50px;
    padding-right: 8px;
  }
  &__remove-image {
    font-size: 16px;
    padding-left: 4px;
  }
}
</style>
