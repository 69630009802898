<template>
  <rs-modal v-model="active" size="md" @closed="onClose()" aside>
    <rs-modal-header title="Manage Divisions" subtitle="Create or edit divisions" @close="onClose()" />
    <rs-modal-content>
      <divisions-add
        :parents="parents"
        :parent-id="parentId"
        @changed="onChanged"
        @valid="(event) => onValid('parent', event)"
      />
    </rs-modal-content>
    <rs-modal-footer justify="space-between">
      <rs-button full @click="onClose">Close</rs-button>
    </rs-modal-footer>
  </rs-modal>
</template>

<script>
import { defineComponent, reactive, ref, computed, watch, nextTick } from 'vue';
import RsButton from '../lib/components/RsButton/RsButton.vue';
import RsModal from '../lib/components/RsModal/RsModal.vue';
import RsModalContent from '../lib/components/RsModal/RsModalContent.vue';
import RsModalHeader from '../lib/components/RsModal/RsModalHeader.vue';
import RsModalFooter from '../lib/components/RsModal/RsModalFooter.vue';
import DivisionsAdd from './DivisionsAdd.vue';

export default defineComponent({
  name: 'DivisionsModal',
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    parents: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    parentId: {
      type: Number,
      default: null,
    },
    isNewDivision: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    RsModal,
    RsModalContent,
    RsModalHeader,
    RsModalFooter,
    RsButton,
    DivisionsAdd,
  },
  emits: ['update:modelValue', 'added'],
  setup(props, { emit }) {
    const active = ref(false);
    const rules = reactive({
      divisionsValid: false,
      edited: false,
    });

    const onValid = (cxt, event) => {
      if (cxt === 'divisions') {
        rules.divisionsValid = event;
      }
    };

    const isValid = computed(() => {
      return rules.divisionsValid;
    });

    watch(
      () => props.modelValue,
      async (next) => {
        await nextTick();
        if (next) {
          active.value = next;
        }
      },
      { immediate: true },
    );

    const onClose = () => {
      active.value = false;
      emit('update:modelValue', active.value);
      emit('closed');
    };

    const divisionPayload = ref({});

    const onChanged = (event) => {
      emit('changed', event);
    };

    return {
      active,
      onClose,
      onChanged,
      divisionPayload,
      onValid,
      isValid,
    };
  },
});
</script>

<style lang="scss"></style>
