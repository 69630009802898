<template>
  <rs-layout column>
    <rs-layout align-center>
      <rs-text-field
        :value="operatorsStatFrom"
        required
        label="Start Date* (Central Time - CT, YYYY-MM-DD)"
        class="mx-2"
        @focus="isPickerOpen = true"
        @keydown.esc.stop="isPickerOpen = false"
      />
      &mdash;
      <rs-text-field
        :value="operatorsStatTo"
        required
        label="End Date* (Central Time - CT, YYYY-MM-DD)"
        class="ml-2"
        @focus="isPickerOpen = true"
        @keydown.esc.stop="isPickerOpen = false"
      />
    </rs-layout>
    <rs-date-picker
      v-if="isPickerOpen"
      :open.sync="isPickerOpen"
      :months-to-show="2"
      date-format="yyyy-MM-dd"
      :date-one.sync="operatorsStatFrom"
      :date-two.sync="operatorsStatTo"
    />
  </rs-layout>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import useStore from '@/composables/vuex';

export default defineComponent({
  setup(_props, _context) {
    const store = useStore();
    const operatorsStatFrom = computed({
      get: () => store.getters.operatorsStatFrom,
      set: (operator) => {
        store.dispatch('updateOperatorsStatFrom', operator);
      },
    });
    const operatorsStatTo = computed({
      get: () => store.getters.operatorsStatTo,
      set: (operator) => {
        store.dispatch('updateOperatorsStatTo', operator);
      },
    });
    let isPickerOpen = ref(false);

    return { operatorsStatFrom, operatorsStatTo, isPickerOpen };
  },
  methods: {
    toggleModal() {
      this.isPickerOpen = !this.isPickerOpen;
    },
  },
});
</script>
