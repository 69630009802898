import countries from '@/internal-hashtags/fixtures/countries.json';

export function addUTCMidnight(date) {
  const timestamp = Date.parse(date);
  if (Number.isNaN(timestamp)) {
    return date;
  }
  return `${date}T00:00:00`;
}

export function removeTime(datetime) {
  return datetime.split('T')[0];
}

export function getFormattedDates() {
  let today = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  // Format the dates in 'YYYY-MM-DD' format, with start/end as today/tomorrow
  const formatDate = (date) => {
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1)
      .toString()
      .padStart(2, '0');
    let day = String(date.getDate()).toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  let formattedToday = formatDate(today);
  let formattedTomorrow = formatDate(tomorrow);
  return { formattedToday, formattedTomorrow };
}

export function convertFromUtcToLocal(countryCode, date) {
  const utcDate = new Date(date);

  // Parse the timezone offset string
  const timezoneOffset = countries.find((c) => c.code === countryCode)?.offset || '+00:00';
  const [hours, minutes] = timezoneOffset.split(':').map(Number);

  // Adjust to local timezone
  utcDate.setUTCHours(utcDate.getUTCHours() + hours);
  utcDate.setUTCMinutes(utcDate.getUTCMinutes() + minutes);

  // Format the date in 'YYYY-MM-DDTHH:MM:SS' format
  return utcDate.toISOString().slice(0, 19);
}
