<template>
  <rs-layout :justify-end="$vuetify.breakpoint.smAndUp">
    <rs-dialog :value="true" persistent max-width="650px" :fullscreen="$vuetify.breakpoint.xsOnly">
      <rs-card>
        <rs-layout row wrap>
          <rs-flex xs12 mx-4>
            <rs-layout row justify-space-between>
              <rs-flex font-weight-medium mt-4 pt-2 mb-3 body-2 data-test-id="qa-import-modal-header"
                >Import Accounts</rs-flex
              >
              <rs-icon size="23" data-test-id="qa-import-modal-close-btn" @click="handleCloseBtnClick()"
                >rsfont-close_v2</rs-icon
              >
            </rs-layout>
          </rs-flex>
          <rs-flex v-show="!successCnt && !failCnt" font-weight-medium mx-4 mb-4 body-1 data-test-id="qa-account-count"
            >{{ accountIds.length }} {{ accountIds.length === 1 ? 'Hubspot Account' : 'Hubspot Accounts' }}</rs-flex
          >
          <rs-flex v-show="successCnt && imports.length" xs10 font-weight-medium mx-4 mb-4 body-1>
            <rs-icon color="#1B8B49" size="20">rsfont-checkmark</rs-icon>
            The following Hubspot Accounts have been successfully imported:
            <div v-for="account in imports" :key="account.id">
              <a :href="`/collabs/account/${account.id}`" target="_blank">{{
                account.name + ` (${account.hubspot_company_id})`
              }}</a>
            </div>
          </rs-flex>
          <rs-flex v-show="failCnt" xs10 font-weight-medium mx-4 mb-4 body-1>
            <rs-icon color="#B50404" size="19">rsfont-close_v2</rs-icon>
            {{ failCnt }}
            {{ failCnt === 1 ? 'Hubspot Account has' : 'Hubspot Accounts have' }}
            failed to import
          </rs-flex>
          <rs-flex v-for="err in errors" :key="err.id">
            <rs-alert :value="true" type="warning" color="#FEE7C2" class="black--text full-width mx-4">{{
              err.message
            }}</rs-alert>
          </rs-flex>
          <rs-flex
            xs12
            :class="{
              'mb-4': $vuetify.breakpoint.smAndUp,
              'mb-2': $vuetify.breakpoint.xs,
            }"
          >
            <rs-divider></rs-divider>
          </rs-flex>
          <rs-flex xs12 mx-4 mb-0>
            <rs-textarea
              ref="hubspot-account-input"
              v-model="textAreaData"
              data-test-id="qa-id-input-field"
              clearable
              clear-icon="rsfont-delete_v2"
              placeholder="Enter one Hubspot Account ID per line"
              hint="Enter one Hubspot Account ID per line"
              outline
              persistent-hint
              :rules="inputRules"
            ></rs-textarea>
          </rs-flex>
          <rs-flex xs12 align-self-end ma-4>
            <rs-layout row wrap justify-end>
              <rs-flex
                :class="{
                  xs3: $vuetify.breakpoint.smAndUp,
                  xs12: $vuetify.breakpoint.xs,
                }"
              >
                <rs-btn data-test-id="qa-cancel-import-btn" font-medium @click="handleCloseBtnClick()">Cancel</rs-btn>
              </rs-flex>
              <rs-flex
                :class="{
                  xs3: $vuetify.breakpoint.smAndUp,
                  'ml-3': $vuetify.breakpoint.smAndUp,
                  xs12: $vuetify.breakpoint.xs,
                }"
              >
                <rs-btn
                  data-test-id="batch-import-button"
                  inverted
                  font-medium
                  :loading="loading"
                  @click="handleImportClick()"
                >
                  import
                </rs-btn>
              </rs-flex>
            </rs-layout>
          </rs-flex>
        </rs-layout>
      </rs-card>
    </rs-dialog>
  </rs-layout>
</template>

<script>
import { mapActions } from 'vuex';
const ErrorTypeCreateAccount = 'CREATE_ACCOUNT';
const ErrorTypeHubspotRead = 'HUBSPOT_READ';
const ErrorTypeDuplicateImport = 'DUPLICATE_IMPORT';
const ErrorTypeMissingID = 'MISSING_ID'; // not an actual return from the service

export default {
  name: 'AccountImportModal',
  data() {
    return {
      failCnt: 0,
      failedImports: [],
      loading: false,
      successCnt: 0,
      imports: [],
      textAreaData: '',
      inputRules: [
        (v) =>
          (v && /^\d+$/.test(this.accountIds.join(''))) ||
          'Account IDs must contain only numbers and newline characters',
      ],
    };
  },
  computed: {
    accountIds() {
      const ids = this.textAreaData ? this.textAreaData.split('\n').filter((id) => id !== '') : [];
      return ids.map(Number);
    },
    hasError() {
      return this.$refs['hubspot-account-input'].hasError;
    },
    errors() {
      return this.failedImports.map((x) => {
        if (x.error_type === ErrorTypeCreateAccount) {
          return {
            ...x,
            message: `Failed to create account ${x.company_id}`,
          };
        } else if (x.error_type === ErrorTypeHubspotRead) {
          return {
            ...x,
            message: `Failed to read Hubspot account ${x.company_id}`,
          };
        } else if (x.error_type === ErrorTypeDuplicateImport) {
          return {
            ...x,
            message: `Account ${x.company_id} already imported`,
          };
        } else if (x.error_type === ErrorTypeMissingID) {
          return {
            ...x,
            message: `Company ID ${x.company_id} not found in Hubspot`,
          };
        }
      });
    },
  },
  watch: {
    async textAreaData(newVal, oldVal) {
      if (newVal && newVal.length) {
        await this.$nextTick();
        this.textAreaData = newVal.replace(/[^\r\n0-9]/g, '').replace(/[\r\n]+/g, '\n');
      }
    },
  },
  methods: {
    ...mapActions('accounts', ['importAccountByID', 'importAccountByBatch']),
    handleCloseBtnClick() {
      this.$emit('close');
    },
    async handleImportClick() {
      this.loading = true;
      const { data } = (await this.importAccountByBatch(this.accountIds)) || {};
      const { accounts, errors } = data || {};
      if (errors?.length) {
        this.failCnt = errors?.length;
        this.failedImports = errors;
      } else {
        this.successCnt = accounts?.length;
        this.imports = accounts || [];
        // if the HS company ID is not found, the service returns a 201 anyway, so we need to check for that
        if (this.accountIds.length > this.imports.length) {
          this.failCnt = this.accountIds.length - this.imports.length;
          const missingIds = this.accountIds.filter((id) => !this.imports.find((x) => x.company_id === id));
          this.failedImports = missingIds.map((id) => ({
            company_id: id,
            error_type: ErrorTypeMissingID,
          }));
        }
      }
      this.loading = false;
    },
    formatTextAreaData(dataArray) {
      return dataArray.join('\n');
    },
    resetInput() {
      this.textAreaData = '';
      this.$refs['hubspot-account-input'].resetValidation();
    },
  },
};
</script>

<style lang="sass" scoped>
.v-card__title
  padding: 24px 24px 24px 0px
  font-size: 18px
:deep .v-input__slot
  border: 1px solid #C0C0C0 !important
:deep .rsfont-delete_v2
  font-size: 23px !important
:deep textarea
  margin-top: 16px !important
  padding: 0px !important
</style>
