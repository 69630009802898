import { render, staticRenderFns } from "./QualityControlStatTable.vue?vue&type=template&id=62f79da7&scoped=true&"
import script from "./QualityControlStatTable.vue?vue&type=script&lang=js&"
export * from "./QualityControlStatTable.vue?vue&type=script&lang=js&"
import style0 from "./QualityControlStatTable.vue?vue&type=style&index=0&id=62f79da7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62f79da7",
  null
  
)

export default component.exports