<template>
  <div class="migration-content--complete" v-if="success">
    <rs-icon class="migration-content--close" tabindex="0" @click="onClose">close</rs-icon>
    <rs-icon size="90" style="color: var(--green)">check</rs-icon>
    <p class="header-2 pb-3">Migration Successful</p>
    <rs-button-group stacked>
      <rs-button @click="onReturn">Return to Advertisers</rs-button>
      <rs-button primary @click="onComplete" v-if="type === 'existing'"> View Migrated Advertiser </rs-button>
    </rs-button-group>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import RsButton from '../lib/components/RsButton/RsButton.vue';
import RsButtonGroup from '../lib/components/RsButton/RsButtonGroup.vue';

export default defineComponent({
  name: 'MigrationSuccess',
  emits: ['close', 'return', 'complete'],
  components: { RsButton, RsButtonGroup },
  props: {
    success: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'existing',
    },
  },
  setup(props, { emit }) {
    const onClose = () => {
      emit('close');
    };
    const onReturn = () => {
      emit('return');
    };
    const onComplete = () => {
      emit('complete');
    };

    return {
      onClose,
      onReturn,
      onComplete,
    };
  },
});
</script>

<style lang="scss">
.migration-content {
  &--complete {
    background-color: var(--green-1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid var(--green-3);
    border-radius: 3px;
  }
}
</style>
