<template>
  <!-- Campaign Credit details -->
  <rs-card class="my-5">
    <rs-card-title class="pa-4">
      <rs-layout class="font-weight-bold justify-space-between">
        <h3 class="headline font-weight-bold ma-0">Credits</h3>
      </rs-layout>
      <rs-layout class="justify-end">
        <rs-btn class="px-4 add-credit" @click="openModal" data-test-id="add-credit-open-btn">ADD CREDIT</rs-btn>
      </rs-layout>
    </rs-card-title>

    <rs-divider />

    <rs-data-table
      :items="credits"
      :headers="campaignCreditTableHeaders"
      class="elevation-0 data-table mb-4"
      item-key="id"
      data-test-id="credit-table"
      :items-per-page="billingPagination.rowsPerPage"
    >
      <template #items="props">
        <tr :data-test-id="`credit-item-header-${props.index}`">
          <td :class="'title'">
            {{ creditDetails(props.item.credit_type) }}
          </td>
          <td :class="'text-xs-left title'">
            {{ toDate(props.item.date_created) }}
          </td>
          <td :class="'text-xs-left title'">
            {{ toDate(props.item.date_expired) }}
          </td>
          <td :class="'text-xs-right title'">${{ creditBalanceInDollars(props.item.amount) }}</td>
          <td :class="'text-xs-right title'">
            ${{ creditBalanceInDollars(props.item.summary.available_credit_cents) }}
          </td>
          <td :class="'text-xs-center title'">
            <rs-btn
              :block="false"
              borderless
              fab
              small
              v-if="showDeleteAction(props.item.amount, props.item.summary.available_credit_cents)"
              :data-test-id="`credit-delete-cta-${props.index}`"
              @click="onDelete(props.item.id)"
            >
              <rs-icon size="22">rsfont-delete_v2</rs-icon>
            </rs-btn>
          </td>
        </tr>
      </template>
    </rs-data-table>

    <rs-dialog max-width="522px" v-model="showAddCreditDialog" data-test-id="add-credit-dialog">
      <rs-card>
        <rs-card-title class="pa-4">
          <rs-flex class="pa-0">
            <h4 class="header-h4-sofiapro ma-0">Add credit</h4>
          </rs-flex>
          <rs-spacer />
          <rs-icon color="#808080" size="16" @click="closeModal" data-test-id="add-credit-close-icon">
            rsfont-close_v2
          </rs-icon>
        </rs-card-title>
        <rs-divider />

        <rs-form ref="form" @submit.prevent="onSubmit" class="pa-4" data-test-id="add-credit-form">
          <rs-select
            append-icon="rsfont-arrow-down_v2"
            label="Credit type"
            class="mx-3 body-body1-sofiapro"
            :use-custom-slots="true"
            :items="creditTypes"
            v-model="selectedType"
            data-test-id="add-credit-types"
          >
            <template #item="{ item }">
              <rs-flex class="d-flex justify-space-between">
                {{ item.text }}
                <rs-icon
                  v-if="item.value === selectedType"
                  class="justify-end-icon"
                  data-test-id="add-credit-choose-type-icon"
                  >rsfont-check-mark-right</rs-icon
                >
              </rs-flex>
            </template>
          </rs-select>
          <rs-select
            append-icon="rsfont-arrow-down_v2"
            label="Amount"
            class="mx-3"
            :use-custom-slots="true"
            :items="creditAmounts"
            v-model="selectedAmount"
            data-test-id="add-credit-amounts"
          >
            <template #item="{ item }">
              <rs-flex class="d-flex justify-space-between">
                {{ item.text }}
                <rs-icon
                  v-if="item === selectedAmount"
                  class="justify-end-icon"
                  data-test-id="add-credit-choose-amount-icon"
                  >rsfont-check-mark-right</rs-icon
                >
              </rs-flex>
            </template>
          </rs-select>
          <rs-card-actions class="mx-2 my-3 justify-space-between">
            <rs-btn
              :loading="loading"
              :disabled="!isFormValid"
              type="submit"
              inverted
              borderless
              data-test-id="add-credit-submit-btn"
              class="mr-1"
            >
              SAVE
            </rs-btn>
            <rs-btn @click="closeModal" data-test-id="add-credit-cancel-btn"> CANCEL </rs-btn>
          </rs-card-actions>
        </rs-form>
      </rs-card>
    </rs-dialog>
  </rs-card>
</template>
<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import { formatCurrencyExt } from '@/utils/helpers';

export default {
  components: {},
  name: 'AccountCredits',
  props: ['accountId'],
  data() {
    return {
      campaignCreditTableHeaders: [
        {
          text: 'Credit type',
          value: 'credit_type',
          sortable: false,
          width: '20%',
          class: 'font-weight-regular',
        },
        {
          text: 'Date issued',
          value: 'date_issued',
          sortable: false,
          width: '15%',
          class: 'font-weight-regular',
        },
        {
          text: 'Expiration date',
          value: 'date_expired',
          sortable: false,
          width: '15%',
          class: 'font-weight-regular',
        },
        {
          text: 'Amount issued',
          value: 'amount_issued',
          sortable: false,
          align: 'right',
          width: '10%',
          class: 'font-weight-regular',
        },
        {
          text: 'Credit remaining',
          value: 'credit_remaining',
          sortable: false,
          align: 'right',
          width: '15%',
          class: 'font-weight-regular',
        },
        {
          text: 'Actions',
          value: '',
          sortable: false,
          align: 'center',
          width: '10%',
          class: 'font-weight-regular',
        },
      ],
      billingPagination: {
        descending: true,
        rowsPerPage: 5,
        page: 0,
      },
      creditTypes: [
        {
          text: 'LTK Connect Campaign Credit',
          value: 'PromotionalConnectPaymentBalance',
        },
      ],
      creditAmounts: [100, 250, 500, 1000, 1500, 2000, 2400, 2500, 3000].map((item) => ({
        text: formatCurrencyExt(item),
        value: item,
      })),
      showAddCreditDialog: false,
      loading: false,
      selectedType: '',
      selectedAmount: '',
    };
  },
  computed: {
    ...mapGetters('billing', ['getCreditByAccountId']),
    isFormValid() {
      return this.selectedType && this.selectedAmount;
    },
    credits() {
      return this.getCreditByAccountId(this.accountId);
    },
  },
  mounted() {
    this.fetchCreditsByAccountID(this.accountId);
  },
  methods: {
    ...mapActions('billing', ['fetchCreditsByAccountID', 'createCredit', 'deleteCredit']),
    formatCurrencyExt,
    creditDetails(creditType) {
      return this.creditTypes.find((type) => type.value === creditType).text;
    },
    toDate(val) {
      return moment(val).format('MMM D, YYYY');
    },
    creditBalanceInDollars(cents) {
      return (cents / 100).toLocaleString('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    showDeleteAction(amount, remaining) {
      return amount === remaining;
    },
    closeModal() {
      this.selectedType = '';
      this.selectedAmount = '';
      this.showAddCreditDialog = false;
    },
    openModal() {
      this.showAddCreditDialog = true;
    },
    async onSubmit() {
      if (!this.isFormValid) return;
      this.loading = true;
      const payload = {
        platform_account_id: this.accountId,
        amount: this.selectedAmount * 100,
        deal_id: Math.random().toString(),
        credit_type: this.selectedType,
      };
      await this.createCredit(payload);

      await this.fetchCreditsByAccountID(this.accountId);
      this.closeModal();
      this.loading = false;
    },
    onDelete(creditId) {
      this.loading = true;
      this.deleteCredit(creditId).then(() => {
        this.loading = false;
        this.fetchCreditsByAccountID(this.accountId);
      });
    },
  },
};
</script>
<style lang="sass" scoped>
.data-table
  width: 100%
.credit-title
  font-weight: 700
  padding-top: 32px
  padding-bottom: 32px
::v-deep table.v-datatable thead th
  line-height: 170%
.justify-end-icon
  justify-content: flex-end !important
.add-credit
  max-width: 288px
</style>
