<template>
  <div :class="['division-item', selectedClasses]">
    <template v-if="state.editing">
      <rs-text-field v-model="state.name" class="mr-4" />
      <rs-button size="sm" @click="handleCancel" icon-only>
        <rs-icon>close</rs-icon>
        <rs-tip placement="top-end">Cancel</rs-tip>
      </rs-button>
      <rs-button size="sm" icon-only primary @click.prevent="handleSave(data.id)" :disabled="!saveable">
        <rs-icon>check</rs-icon>
        <rs-tip placement="top-end">Save</rs-tip>
      </rs-button>
    </template>
    <template v-else>
      <dl>
        <dd class="text-1">{{ state.name }}</dd>
        <dt class="text-2">{{ state.id }}</dt>
      </dl>
      <rs-chip v-if="state.selected">Selected</rs-chip>
      <rs-button size="sm" @click="toggleEdit" icon-only>
        <rs-icon style="font-weight: normal">rsfont-edit</rs-icon>
      </rs-button>
    </template>
  </div>
</template>

<script>
import { defineComponent, reactive, watch, computed } from 'vue';
import RsButton from '../lib/components/RsButton/RsButton.vue';
import RsTip from '../lib/components/RsTip/RsTip.vue';
import useDivisionApi from '../composables/useDivisionApi';

export default defineComponent({
  components: { RsButton, RsTip },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['saved'],
  setup(props, { emit }) {
    const { putDivision } = useDivisionApi();
    const state = reactive({
      editing: false,
      name: '',
      id: null,
      saved: false,
      selected: false,
    });

    watch(
      () => props.data,
      () => {
        state.name = props.data.name;
        state.id = props.data.id;
      },
      { immediate: true },
    );
    const saveable = computed(() => state.name !== props.data.name);
    const toggleEdit = () => {
      state.editing = !state.editing;
    };

    const handleClick = () => {
      state.editing = true;
    };

    const handleCancel = () => {
      state.editing = false;
      state.name = props.data.name;
    };

    const selectedClasses = computed(() => {
      return {
        'is--selected': state.selected,
      };
    });

    const handleSave = (id) => {
      putDivision(id, { name: state.name }).then((res) => {
        state.saved = true;
        state.editing = false;
        emit('saved', { name: state.name, id: state.id });
      });
    };

    return {
      state,
      toggleEdit,
      saveable,
      handleSave,
      handleClick,
      handleCancel,
      selectedClasses,
    };
  },
});
</script>

<style lang="scss">
.division-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 0;
  max-width: 100%;
}
</style>
