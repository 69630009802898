<template>
  <rs-card>
    <rs-card-title> Edit Reporting Date Range {{ campaign.title && '- ' + campaign.title }}</rs-card-title>
    <rs-date-picker
      v-if="reportingStartDate || reportingEndDate"
      :date-one.sync="reportingStartDate"
      :date-two.sync="reportingEndDate"
      :min-date="minReportingDate"
      :months-to-show="2"
      date-format="yyyy-MM-dd"
      open
      block
    />
    <rs-card-actions>
      <rs-btn @click="save" inverted>Save</rs-btn>
    </rs-card-actions>
  </rs-card>
</template>

<script>
export default {
  name: 'RsReportingDatePicker',
  props: {
    campaign: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    reportingStartDate: '',
    reportingEndDate: '',
    minReportingDate: '',
  }),
  mounted() {
    this.minReportingDate = this.formatDate(this.campaign.display_campaign_start_date);
    this.reportingStartDate = this.formatDate(this.campaign.display_campaign_reporting_start_date);
    this.reportingEndDate = this.formatDate(this.campaign.display_campaign_reporting_end_date);
  },
  methods: {
    formatDate(date) {
      const dateArr = date.split('/');
      return `${dateArr[2]}-${dateArr[0]}-${dateArr[1]}`;
    },
    save() {
      this.$emit('save', {
        id: this.campaign.id,
        reporting_start_date: this.reportingStartDate,
        reporting_end_date: this.reportingEndDate,
      });
    },
  },
};
</script>

<style lang="sass"></style>
