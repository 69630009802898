export function formatForEdit(modules) {
  const validFields = [
    'enabled',
    'end_date',
    'id',
    'major_version',
    'minor_version',
    'next_id',
    'notes',
    'options',
    'options_type',
    'pre_staged',
    'prev_id',
    'start_date',
  ];

  const validOptionFields = [
    'catalogIds',
    'retailerIds',
    'actions',
    'key',
    'url',
    'type',
    'title',
    'subtitle',
    'testTag',
    'testDescription',
    'shouldHideOverlay',
    'shouldUseDarkTitle',
    'requireTag',
  ];

  let formattedModules = [];
  modules.forEach((module) => {
    const validModule = {};
    const validOptions = {};
    for (const key in module) {
      if (validFields.includes(key)) {
        if (key === 'options') {
          for (const optionsKey in module[key]) {
            if (validOptionFields.includes(optionsKey)) {
              validOptions[optionsKey] = module[key][optionsKey];
            }
          }
        } else {
          validModule[key] = module[key];
        }
      }
    }
    validModule['options'] = validOptions;
    formattedModules.push(validModule);
  });

  return formattedModules;
}
