var render = function render(){var _vm=this,_c=_vm._self._c;return _c('rs-dialog',{attrs:{"value":_vm.isOpen,"max-width":"650px","data-test-id":"qa-list-name-accounts-modal","persistent":""},on:{"input":(v) => v || _vm.handleClose()}},[_c('rs-card',{attrs:{"data-test-id":"qa-offer-warning-modal","width":"100%"}},[_c('rs-layout',{attrs:{"column":"","fill-height":""}},[_c('rs-flex',{attrs:{"shrink":""}},[_c('rs-layout',{attrs:{"ma-4":"","justify-end":""}},[_c('rs-flex',{attrs:{"align-self-center":"","display-2":""}},[_vm._v("Add Creators to "+_vm._s(_vm.listDetails[_vm.listName].title))]),_c('rs-flex',{attrs:{"shrink":"","text-xs-right":""}},[_c('rs-icon',{attrs:{"color":"#000000","size":"30"},on:{"click":_vm.handleClose}},[_vm._v(" close ")])],1)],1)],1),_c('rs-divider'),(_vm.failedListNameAccounts.length)?_c('rs-flex',{staticClass:"mx-4 mt-4"},[_c('rs-layout',{staticClass:"pt-2 px-3 creator-id-container column"},[_c('rs-flex',{staticClass:"subheading account-id-message__error"},[_vm._v(" "+_vm._s(_vm.failedListNameAccounts.length)+" Creator IDs have failed to import ")]),_c('rs-flex',{staticClass:"subheading text-truncate_2"},[_c('span',[_vm._v(_vm._s(_vm.displayFailedAccountIds)+" ")])]),_c('rs-layout',{attrs:{"align-center":"","mb-2":""}},[_c('rs-flex',{staticClass:"xs3"},[_c('rs-btn',{staticClass:"copy-btn",on:{"click":_vm.handleCopy}},[_vm._v("COPY ID LIST")])],1),(_vm.isCopied)?_c('rs-flex',{staticClass:"account-id-message__success subheading",attrs:{"mx-3":""}},[_vm._v("Copied to clipboard!")]):_vm._e()],1)],1)],1):_vm._e(),_c('rs-textarea',{staticClass:"mx-4 mt-4 text-area",attrs:{"height":"200","outline":"","full-width":"","placeholder":"Enter one Account ID per line","row-height":"18","hint":"Enter one Account ID per line","rules":_vm.inputRules},model:{value:(_vm.textAreaData),callback:function ($$v) {_vm.textAreaData=$$v},expression:"textAreaData"}}),_c('rs-flex',{class:{
          'ma-4': _vm.$vuetify.breakpoint.xs,
        },attrs:{"d-flex":""}},[_c('rs-layout',{class:{
            column: _vm.$vuetify.breakpoint.xs,
            'mt-2': true,
          },attrs:{"justify-end":""}},[_c('rs-flex',{class:{
              'mr-3': _vm.$vuetify.breakpoint.smAndUp,
            },attrs:{"xs3":""}},[_c('rs-btn',{staticClass:"ma-0 mb-4",attrs:{"small":"","flat":""},on:{"click":_vm.handleClose}},[_vm._v(" Cancel ")])],1),_c('rs-flex',{class:{
              'mr-4': _vm.$vuetify.breakpoint.smAndUp,
            },attrs:{"xs3":""}},[_c('rs-btn',{staticClass:"ma-0 mb-4",attrs:{"inverted":"","data-test-id":"qa-submit-account_ids","disabled":!_vm.textAreaData},on:{"click":_vm.submitAccountIDs}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }