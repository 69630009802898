<template>
  <tr class="sale-retailer--row">
    <td>
      <retailer-input placeholder="(retailer id)" :rules="retailerIdRules" v-model="retailer.retailer_id" />
    </td>
    <td>
      <rs-textarea
        placeholder="(promo description)"
        v-model="retailer.promo_description"
        rows="1"
        auto-grow
        required
        :rules="promoDescriptionRules"
        :maxlength="descriptionMaxLength"
        counter
      />
    </td>
    <td>
      <rs-text-field
        placeholder="(promo code)"
        v-model="retailer.promo_code"
        :rules="promoCodeRules"
        :maxlength="promoCodeMaxLength"
      />
    </td>
    <td width="1px">
      <rs-btn icon :block="false" small color="rs-error" @click="$emit('delete')">
        <rs-icon>ltkfont-delete-outline</rs-icon>
      </rs-btn>
    </td>
  </tr>
</template>

<script>
import { computed, defineComponent } from 'vue';
import useModelWrapper from '@/composables/model-wrapper';
import { makeRequired, makeMaxLength } from '@/utils/form-validation';
import RetailerInput from './RetailerInput.vue';

const promoCodeMaxLength = 128;
const descriptionMaxLengthWithPromo = 75;
const descriptionMaxLengthWithoutPromo = 100;

export default defineComponent({
  components: {
    RetailerInput,
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: Object,
  },
  emits: {
    'update:modelValue': Object,
  },
  setup(props, { emit }) {
    const [retailer] = useModelWrapper(props, emit);

    function updateField(fieldName, newValue) {
      const newRetailer = Object.assign({}, retailer.value, { [fieldName]: newValue });

      retailer.value = newRetailer;
    }

    const descriptionMaxLength = computed(() => {
      return retailer.value.promo_code ? descriptionMaxLengthWithPromo : descriptionMaxLengthWithoutPromo;
    });

    const retailerIdRules = [makeRequired('Retailer ID is required')];
    const promoDescriptionRules = computed(() => [
      makeRequired('Promo Description is required'),
      makeMaxLength(
        descriptionMaxLength.value,
        `Promo Description must be no more than ${descriptionMaxLength.value} characters`,
      ),
    ]);
    const promoCodeRules = [
      makeMaxLength(promoCodeMaxLength, `Promo Code must be no more than ${promoCodeMaxLength} characters`),
    ];

    return {
      retailer,
      descriptionMaxLength,
      promoCodeMaxLength,
      retailerIdRules,
      promoDescriptionRules,
      promoCodeRules,
      updateField,
    };
  },
});
</script>

<style scoped lang="scss">
.sale-retailer--row {
  cursor: pointer;
  user-select: none;
}
</style>
