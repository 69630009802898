import { ref, reactive, watch, nextTick, onMounted, onUnmounted, watchEffect, onUpdated } from 'vue';
import { createPopper } from '@popperjs/core';
import useKeyboardFocus from './useKeyboardFocus.js';

const usePopper = (props, emit) => {
  const targetRef = ref(null);
  const contentRef = ref(null);
  const parentRef = ref(null);
  const shouldRender = ref(false);
  const hasFocus = useKeyboardFocus(parentRef);
  const popper = reactive({
    instance: null,
    target: null,
    content: null,
  });

  // Popper Options
  const options = reactive({
    placement: props.placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: props.offset,
        },
      },
      {
        name: 'preventOverflow',
        options: {
          boundary: 'viewport',
          rootBoundary: 'document',
        },
      },
    ],
  });

  // create popper
  const makePopper = () => {
    popper.instance = createPopper(popper.target, popper.content, options);
  };

  // kill your creation
  const killPopper = () => {
    if (popper.instance) {
      popper.instance.destroy();
      popper.instance = null;
    }
  };

  // bind popper instance at the next repaint
  const bindPopper = () => {
    nextTick().then(() => {
      if (popper.target) {
        makePopper();
      }
    });
  };

  const resetPopper = () => {
    if (popper.instance) {
      killPopper();
      makePopper();
    }
  };

  const show = () => {
    shouldRender.value = true;
    emit('opened');
    emit('update:active', true);
  };

  const hide = () => {
    shouldRender.value = false;
    emit('closed');
    emit('update:active', false);
  };

  const touched = () => {
    shouldRender.value = !shouldRender.value;
  };

  const outsideTouch = (e) => {
    if (shouldRender.value && popper.target !== e.target) {
      shouldRender.value = false;
    }
  };

  // In some cases the direct parent is not the node that we want to bind the event listener to
  // This may need to be expanded on to support more use cases as they come up.
  // This is mostly relavent to elements which have a deep dom structure and are focusable.

  const setTargetEl = () => {
    const parentNode = targetRef.value.$el.parentNode;
    const grandParentNode = targetRef.value.$el.parentNode.parentNode;
    const grandparentClasses = grandParentNode.classList.value;

    if (grandparentClasses.includes('rs--button')) {
      return grandParentNode;
    }
    return parentNode;
  };

  const mountEventBindings = async () => {
    await nextTick().then(() => {
      if (targetRef.value.$el.parentNode instanceof HTMLElement) {
        parentRef.value = setTargetEl();
        popper.target = parentRef.value;
        if (props.autoOpen && popper.target) {
          document.body.addEventListener('touchstart', outsideTouch, { passive: true });
          popper.target.addEventListener('touchstart', touched, { passive: true });
          popper.target.addEventListener('mouseenter', show, { passive: true });
          popper.target.addEventListener('mouseleave', hide, { passive: true });
        }
      }
    });
  };

  watchEffect(() => {
    if (props.active) {
      show();
    } else {
      hide();
    }
  });

  watch(
    () => hasFocus.value,
    (next) => {
      if (next) {
        show();
      } else {
        hide();
      }
    },
  );

  watch(
    () => shouldRender.value,
    (next) => {
      emit('update:active', next);
    },
  );

  onMounted(() => {
    mountEventBindings();
    resetPopper();
  });

  // This runs in the onUpdated lifecycle since the
  // contentRef is not available until after the component is rendered
  // and shouldRender.value returns true
  // This is not required in Vue3

  onUpdated(() => {
    if (shouldRender.value) {
      nextTick().then(() => {
        popper.content = contentRef.value;
        bindPopper();
      });
    }
  });

  onUnmounted(() => {
    if (props.autoOpen) {
      document.body.removeEventListener('touchstart', outsideTouch);
      popper.target.removeEventListener('touchstart', touched);
      popper.target.removeEventListener('mouseenter', show);
      popper.target.removeEventListener('mouseleave', hide);
    }
  });
  return { contentRef, targetRef, shouldRender };
};

export default usePopper;
