import { inject } from 'vue';

export const storeKey = Symbol('vuex:store');

/**
 * Shim for @vue/composition-api based Vue apps accessing vuex store in setup functions
 * Avoids having to process through the deprecated {SetupContext.root}, and this function
 * itself will be useless once upgraded to vue>=2.7 and vuex>=4.0.0
 *
 * @returns {Store<any>} The base vuex store (Vue.$store)
 */
export default function useStore() {
  return inject(storeKey);
}
