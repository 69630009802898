<template>
  <rs-card class="mb-4">
    <rs-card-title class="pa-4">
      <rs-flex>
        <h3 class="headline font-weight-medium ma-0">Subscription and billing</h3>
      </rs-flex>
      <rs-spacer />

      <rs-btn
        small
        flat
        :block="false"
        @click="showManageSubscriptionAndBillingModal = true"
        data-test-id="account-users-add-account-button"
        v-if="editSubscriptionAndBillingModalEnabled"
      >
        <rs-icon size="19" class="add-btn">rsfont-edit_v2</rs-icon>
        <div class="mx-2">Edit Subscription and Billing</div>
      </rs-btn>
    </rs-card-title>

    <rs-divider />
    <rs-card-text class="py-0 subscription-type-container">
      <rs-list two-line class="layout row wrap">
        <rs-flex xs6 px-4 mb-2>
          <rs-list-tile>
            <rs-list-tile-content>
              <rs-list-tile-sub-title>Subscription type</rs-list-tile-sub-title>
              <rs-list-tile-title class="body-1">{{ subscriptionType || '--' }}</rs-list-tile-title>
            </rs-list-tile-content>
          </rs-list-tile>
          <rs-divider></rs-divider>
        </rs-flex>

        <rs-flex xs6 px-4 mb-2>
          <rs-list-tile>
            <rs-list-tile-content>
              <rs-list-tile-sub-title>Subscription pricing</rs-list-tile-sub-title>
              <rs-list-tile-title data-test-id="subscription-pricing-text" class="body-1">{{
                currentAnnualPriceDollarsFormatted
              }}</rs-list-tile-title>
            </rs-list-tile-content>
          </rs-list-tile>
          <rs-divider></rs-divider>
        </rs-flex>

        <rs-flex xs6 px-4 mb-2 v-if="subscriptionAutoRenewOn" data-test-id="subscription-renewal-date">
          <rs-list-tile>
            <rs-list-tile-content>
              <rs-list-tile-sub-title data-test-id="subscription-renewal-date-title"
                >Subscription renewal date</rs-list-tile-sub-title
              >
              <rs-list-tile-title class="body-1" data-test-id="subscription-renewal-date-value">{{
                subscriptionRenewalDate
              }}</rs-list-tile-title>
            </rs-list-tile-content>
          </rs-list-tile>
          <rs-divider></rs-divider>
        </rs-flex>

        <rs-flex xs6 px-4 mb-2 data-test-id="billing-date">
          <rs-list-tile>
            <rs-list-tile-content>
              <rs-list-tile-sub-title data-test-id="billing-date-title">{{
                subscriptionAutoRenewOn ? 'Next billing date' : 'Expires'
              }}</rs-list-tile-sub-title>
              <rs-list-tile-title class="body-1" data-test-id="billing-date-value">{{
                nextBillingDate
              }}</rs-list-tile-title>
            </rs-list-tile-content>
          </rs-list-tile>
          <rs-divider></rs-divider>
        </rs-flex>

        <rs-flex xs6 px-4 mb-2>
          <rs-list-tile>
            <rs-list-tile-content>
              <rs-list-tile-sub-title>Billing interval</rs-list-tile-sub-title>
              <rs-list-tile-title class="body-1">{{ billingInterval || '--' }}</rs-list-tile-title>
            </rs-list-tile-content>
          </rs-list-tile>
          <rs-divider></rs-divider>
        </rs-flex>

        <rs-flex xs6 px-4 mb-2>
          <rs-list-tile>
            <rs-list-tile-content>
              <rs-list-tile-sub-title>MSSA</rs-list-tile-sub-title>
              <rs-list-tile-title class="body-1">{{ formattedMSSA || '--' }}</rs-list-tile-title>
            </rs-list-tile-content>
          </rs-list-tile>
          <rs-divider></rs-divider>
        </rs-flex>

        <rs-flex xs6 px-4 mb-2 v-if="subscriptionWillRenew">
          <rs-list-tile>
            <rs-list-tile-content>
              <ManageRenewalModalRenewalText
                :account-name="account.name"
                :subscription-type="subscriptionType"
                :subscription-renewal-date="subscriptionRenewalDate"
                :subscription-interval-price-text="subscriptionIntervalPriceText"
                data-test-id="renewal-information-text"
              />
            </rs-list-tile-content>
          </rs-list-tile>
        </rs-flex>
        <rs-flex xs12 />
        <rs-flex xs12 sm6 md6 px-4>
          <rs-list-tile>
            <rs-layout row wrap align-center>
              <rs-flex xs12 sm6 md6 v-if="shouldDisplayAutoRenewToggle">
                <rs-layout row align-center>
                  <span class="body-2 font-weight-bold mt-2 pr-4">Auto-renew</span>
                  <rs-switch
                    v-model="subscriptionAutoRenewOn"
                    color="white"
                    :class="`auto-renew-switch${subscriptionAutoRenewOn ? '-on' : '-off'}`"
                    data-test-id="qa-auto-renew-switch"
                    @click="showAutoRenewalModal = true"
                  />
                </rs-layout>
              </rs-flex>

              <rs-flex xs12 sm6 md6>
                <rs-btn
                  v-if="manageRenewalModalEnabled"
                  flat
                  :disabled="!subscriptionAutoRenewOn"
                  @click="showReviewRenewalModal = true"
                  data-test-id="manage-renewal-dialog-button"
                >
                  Manage Renewal
                </rs-btn>
              </rs-flex>
            </rs-layout>
          </rs-list-tile>
        </rs-flex>
      </rs-list>
    </rs-card-text>

    <!-- Subscription and Billing Modals -->
    <!-- eslint-disable vue/no-mutating-props -->
    <EditSubscriptionBillingModal
      title="Edit Subscription and Billing Information"
      :account-role.sync="accountRole"
      :account.sync="account"
      :billing-renewal-date.sync="subscriptionRenewalDate"
      :current-billing-interval.sync="billingInterval"
      :default-account-type="account.type || ''"
      :mssa.sync="formattedMSSA"
      :profile.sync="profile"
      :subscription-status.sync="subscriptionStatus"
      v-if="showManageSubscriptionAndBillingModal"
      @close="
        () => {
          showManageSubscriptionAndBillingModal = false;
          load();
        }
      "
    />
    <!-- eslint-enable vue/no-mutating-props -->
    <ManageRenewalModal
      v-if="showReviewRenewalModal"
      title="Manage renewal"
      :account="account"
      :billing-interval="billingInterval"
      @close="handleManageRenewalModalClose"
      :subscription-type="subscriptionType"
      :subscription-interval-price-text="subscriptionIntervalPriceText"
      :subscription-renewal-date="subscriptionRenewalDate"
      :next-price-dollars="nextSubscriptionPriceDollars"
    />

    <SubscriptionAutoRenewalModal
      v-if="showAutoRenewalModal"
      :loading="loading"
      :subscription-auto-renew-on="subscriptionAutoRenewOn"
      :subscription-renewal-date="subscriptionRenewalDate"
      @toggle-auto-renew="toggleSubscriptionAutoRenew"
      @close="showAutoRenewalModal = false"
    />
  </rs-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import accountTypeIcon from '../assets/account_type_icon.svg';
import { formatDate, formatTitle } from '../util/helpers.js';
import { formatCurrencyExt } from '@/utils/helpers';
import { getRoleDisplayName } from '../util/roles.js';

// Components
import EditSubscriptionBillingModal from './EditSubscriptionBillingModal.vue';
import ManageRenewalModal from './ManageRenewalModal.vue';
import ManageRenewalModalRenewalText from './ManageRenewalModalRenewalText.vue';
import SubscriptionAutoRenewalModal from './SubscriptionAutoRenewalModal.vue';

export default {
  name: 'AccountBilling',
  components: {
    EditSubscriptionBillingModal,
    ManageRenewalModal,
    ManageRenewalModalRenewalText,
    SubscriptionAutoRenewalModal,
  },
  props: {
    account: {
      type: Object,
      default: () => {},
    },
    accountId: {
      type: String,
      default: '',
    },
    permissions: {
      type: Object,
      default: () => {},
    },
    profile: {
      type: Object,
      default: () => {},
    },
    roles: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      accountTypeIcon,
      showManageSubscriptionAndBillingModal: false,
      showReviewRenewalModal: false,
      billingIntervalPreferenceType: 'billing_interval',
      nextSubscriptionPriceDollars: 0,
      currentSubscriptionPriceDollars: 0,
      showAutoRenewalModal: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters('accounts', ['accountMSSA']),
    ...mapGetters('billing', [
      'getBrandPaymentsMetaData',
      'getCurrentBrandPayments',
      'getCurrentPlan',
      'getCurrentUserPreferencesByType',
      'getRedirectURL',
      'getSubscription',
    ]),
    ...mapGetters('permissions', ['getRole', 'getRoles']),
    accountRole: {
      get: function () {
        let role = this.currentRole ? this.allRoles.find((x) => x.name == this.currentRole.name) : '';
        return role ? role : { name: '', label: '' };
      },
      set: async function (role) {
        await this.fetchAccountRoles(this.accountId);
        return this.allRoles.find((x) => x.name == role);
      },
    },
    allRoles() {
      const { roles } = this.getRoles();
      let result = [];
      for (let roleId in roles) {
        let role = this.getRole(roleId);
        result.push({
          text: getRoleDisplayName(role.name),
          value: role.name,
          ...role,
        });
      }
      result.push({ text: '-- No Role --', value: '' });
      result.sort((a, b) => {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
      return result;
    },
    subscriptionWillRenew() {
      return this.subscription !== null && this.subscription?.cancel_at_period_end !== true;
    },
    billingInterval() {
      const intervalId = this.preferences?.value?.recurring_interval_id;
      const intervalCount = this.preferences?.value?.interval_count;
      let interval = '--';

      if (intervalId === 'month' && intervalCount === 3) {
        interval = 'Quarter';
      } else if (intervalId === 'month' && intervalCount === 6) {
        interval = 'Bi-Annual';
      } else if (intervalId === 'year') {
        interval = 'Annual';
      } else if (!intervalId && this.subscription) {
        // if the subscription exists but the preferences have not been set, default to Annual
        interval = 'Annual';
      }
      return interval;
    },
    billingIntervalCount() {
      const intervalCount = this.preferences?.value?.interval_count;
      return intervalCount;
    },
    currentPlan() {
      return this.getCurrentPlan();
    },
    currentRole() {
      return this.roles && this.roles.length > 0 ? this.roles.at(-1) : { name: '', text: '' };
    },
    subscriptionAutoRenewOn() {
      return !this.currentPlan?.cancel_at_period_end;
    },
    formattedMSSA() {
      if (this.mssa && this.mssa.date_signed) {
        const signedDate = formatDate(new Date(this.mssa.date_signed)),
          signatureRequired = this.mssa.signature_required,
          userName = formatTitle(this.mssa.user_name),
          userTitle = formatTitle(this.mssa.title);
        if (signatureRequired) return 'Signature Required';
        return `Yes, ${signedDate}, ${userName} ${userTitle ? ' - ' + userTitle : ''}`;
      } else {
        return '--';
      }
    },
    mssa() {
      const { mssa } = this.accountMSSA(this.accountId);
      return mssa;
    },
    nextBillingDate() {
      const date = this.currentPlan?.current_period_end;
      return date ? moment(date).format('MMM D, YYYY') : '--';
    },
    preferences() {
      return this.getCurrentUserPreferencesByType(this.billingIntervalPreferenceType)[0];
    },
    subscription() {
      let plan = this.getCurrentPlan();
      if (plan && !plan.info) {
        plan.info = this.getSubscription.filter((subTier) => subTier.id === plan.product_id)[0];
      }
      return plan;
    },
    subscriptionType() {
      return this.subscription?.info?.name;
    },
    subscriptionStatus() {
      return this.currentPlan?.subscription_status;
    },
    editSubscriptionAndBillingModalEnabled() {
      return this.apptimize.isFeatureFlagEnabled('edit_subscription_and_billing_modal');
    },
    manageRenewalModalEnabled() {
      const isConnectScale = this.getCurrentPlan()?.product?.platform_role_id === 'connect-scale';
      const isFeatureEnabled = this.apptimize.isFeatureFlagEnabled('manage_renewal_modal');
      const hasSubscription = !!this.getCurrentPlan();
      return isFeatureEnabled && !isConnectScale && hasSubscription;
    },
    subscriptionRenewalDate() {
      const date = this.getCurrentPlan()?.billing_cycle_anchor;
      return date ? moment(date).add(1, 'year').format('MMM D, YYYY') : '--';
    },
    subscriptionIntervalPriceText() {
      const intervalPrice = formatCurrencyExt(this.getIntervalPriceForAnnualPrice(this.nextSubscriptionPriceDollars));
      switch (this.billingInterval) {
        case 'Quarter':
          return `${intervalPrice} per quarter`;
        case 'Bi-Annual':
          return `${intervalPrice} every six months`;
        default:
          return `${intervalPrice} per year`;
      }
    },
    nextPriceDollars() {
      if (this.currentProduct === null) return 0;
      const advertisedPriceCents = this.currentProduct.price?.advertised_interval_price_cents / 100;
      return Math.ceil(this.getAnnualPriceForInterval(advertisedPriceCents));
    },
    currentPriceDollars() {
      const currentPriceCents = this.currentProduct?.price?.active_interval_price_cents / 100;
      return Math.ceil(this.getAnnualPriceForInterval(currentPriceCents));
    },
    currentIntervalPriceDollarsFormatted() {
      if (this.currentPriceDollars > 0) {
        return formatCurrencyExt(this.getIntervalPriceForAnnualPrice(this.currentPriceDollars));
      }
      return '--';
    },
    currentAnnualPriceDollarsFormatted() {
      if (this.currentPriceDollars > 0) {
        return formatCurrencyExt(this.currentPriceDollars);
      }
      return '--';
    },
    availableSubscriptionTypes() {
      return this.getSubscription.filter((product) => product.price !== null && product.display_product);
    },
    currentProduct() {
      const product = this.availableSubscriptionTypes.find((s) => {
        return this.getCurrentPlan()?.product?.platform_role_id === s.platform_role_id;
      });
      if (!product) return null;
      return product;
    },
    isManageAutoRenewEnabled() {
      return this.apptimize.isFeatureFlagEnabled('manage_autorenewal');
    },
    shouldDisplayAutoRenewToggle() {
      return this.isManageAutoRenewEnabled && !!this.currentPlan;
    },
  },
  async mounted() {
    await this.load();
    this.updatePreferredPrice();
    this.currentSubscriptionPriceDollars = this.currentIntervalPriceDollarsFormatted;
  },
  methods: {
    ...mapActions('accounts', ['getAccountMSSA']),
    ...mapActions('billing', [
      'fetchCurrentSubscription',
      'fetchCurrentUserPreferencesByType',
      'fetchSubscriptions',
      'updateSubscription',
      'resetCreatePreferenceError',
    ]),
    ...mapActions('permissions', {
      fetchAccountRoles: 'getAccountRoles',
    }),
    async load() {
      await this.getAccountMSSA(this.accountId);
      await this.fetchSubscriptions(this.accountId);
      await this.fetchCurrentSubscription(this.accountId);
      await this.fetchCurrentUserPreferencesByType({
        accountId: this.accountId,
        type: this.billingIntervalPreferenceType,
      });
    },
    updatePreferredPrice() {
      this.nextSubscriptionPriceDollars = this.nextPriceDollars;
    },
    async handleManageRenewalModalClose() {
      this.resetCreatePreferenceError();
      this.showReviewRenewalModal = false;
      await this.fetchSubscriptions(this.accountId);
      this.updatePreferredPrice();
    },
    getIntervalPriceForAnnualPrice(price) {
      switch (this.billingInterval) {
        case 'Quarter':
          return Math.ceil(price / 4);
        case 'Bi-Annual':
          return Math.ceil(price / 2);
        default:
          return price;
      }
    },
    getAnnualPriceForInterval(price) {
      switch (this.billingInterval) {
        case 'Quarter':
          return price * 4;
        case 'Bi-Annual':
          return price * 2;
        default:
          return price;
      }
    },
    async toggleSubscriptionAutoRenew() {
      try {
        this.loading = true;
        await this.updateSubscription({
          subscriptionID: this.currentPlan?.id,
          query: { cancel_at_period_end: !this.currentPlan?.cancel_at_period_end, platform_account_id: this.accountId },
        });

        await this.fetchCurrentSubscription(this.accountId);

        this.showAutoRenewalModal = false;
      } catch (err) {
        console.error(err);
        this.$root.$emit('onError', {
          data: {
            error: 'Failed to update subscription auto renew setting. Please try again',
          },
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.subscription-type-container
  .theme--light
    .v-list__tile__title
      position: relative
      top: 8px
    .v-divider
      border-color: #757575 !important
::v-deep .v-input--switch__track
  height: 31px
  width: 51px
  top: -3.5px
  border-radius: 27px
  opacity: 1

::v-deep .v-input--switch__thumb
  width: 27px !important
  height: 27px !important
  top: calc(50% - 27px/2)

.auto-renew-switch-off
  ::v-deep .v-input--switch__track
    background-color: #F0F0F0

  ::v-deep .v-input--switch__thumb
    left: calc(50% - 27px/2 - 2px)

.auto-renew-switch-on
  ::v-deep .v-input--switch__track
    background-color: #527A70

  ::v-deep .v-input--switch__thumb
    left: calc(50% - 27px/2 + 2px)
</style>
