/**
 * Creates a date/time formatter function to output a localized date string.
 * Formatter will accept a Date or a number representing miliseconds since the epoch.
 *
 * @param {Locale} locale Native Date parsable string, e.g. ISO-8601 String
 * @param {string} timeZone Timezone to use when formatting the date.
 *  Can affect the rendered day if a different timezone is given than input date's timezone.
 *
 * @returns {(date: string) => string} Pre-configured date formatter function.
 */
export default function useDateFormatter(locale = 'en-US', timeZone = 'UTC') {
  const formatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone,
  });

  return (date) => formatter.format(Date.parse(date));
}
