export const greyRGB = [128, 128, 128];
export const greenRGB = [0, 255, 0];
export const redRGB = [255, 0, 0];

/**
 * Calculates the color at the specified percentage point between two colors
 */
export function colorGradient(p, rgb_beginning, rgb_end) {
  const w = p * 2 - 1;

  const w1 = (w + 1) / 2.0;
  const w2 = 1 - w1;

  const rgb = [
    parseInt(rgb_beginning[0] * w1 + rgb_end[0] * w2),
    parseInt(rgb_beginning[1] * w1 + rgb_end[1] * w2),
    parseInt(rgb_beginning[2] * w1 + rgb_end[2] * w2),
  ];
  return rgb;
}

export function calculateFeatureColor(weight) {
  const maxrgb = Number(weight) < 0 ? redRGB : greenRGB;
  const color = colorGradient(Math.abs(weight), maxrgb, greyRGB);
  return `rgba(${color.join(',')},0.5)`;
}
