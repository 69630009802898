const GEO = [
  {
    text: 'All',
    value: 'all',
  },
  {
    text: 'New York',
    value: 'new_york',
  },
  {
    text: 'Dallas',
    value: 'dallas',
  },
  {
    text: 'Los Angeles',
    value: 'los_angeles',
  },
  {
    text: 'Atlanta',
    value: 'atlanta',
  },
  {
    text: 'Chicago',
    value: 'chicago',
  },
  {
    text: 'London',
    value: 'london',
  },
  {
    text: 'Miami',
    value: 'miami',
  },
  {
    text: 'San Francisco',
    value: 'san_francisco',
  },
  {
    text: 'Boston',
    value: 'boston',
  },
  {
    text: 'Sydney',
    value: 'sydney',
  },
  {
    text: 'Berlin',
    value: 'berlin',
  },
  {
    text: 'Paris',
    value: 'paris',
  },
];

const getGeo = (geo) => {
  return GEO.find((i) => i.value === geo).text;
};

export { GEO, getGeo };
