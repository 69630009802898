export const capitalizeWord = (word) => word.substr(0, 1).toUpperCase() + word.substr(1);
export const capitalizeWords = (string) => string.split(' ').map(capitalizeWord).join(' ');

export const recursiveLabel = (map, id) => {
  const item = map[id];
  if (item === undefined) {
    return '';
  }

  if (item.parent === undefined) {
    return item.name;
  }

  return recursiveLabel(map, item.parent) + '//' + item.name;
};
