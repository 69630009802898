<template>
  <sale-confirm-modal v-bind="$attrs" v-on="$listeners">
    <template #header>
      Are you sure you want to Publish this sale? Once you Publish a sale, it will become live in the app when the
      current date is within the start and end date range of the sale.
    </template>
    <template #body>Do you want to continue?</template>
  </sale-confirm-modal>
</template>

<script>
import { defineComponent } from 'vue';
import SaleConfirmModal from './SaleConfirmModal.vue';

export default defineComponent({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  components: { SaleConfirmModal },
  setup(_props, _context) {
    return {};
  },
});
</script>
