<template>
  <tr class="sale-table--item">
    <td>
      <rs-checkbox v-model="isSelected" primary hide-details @click.stop />
    </td>
    <td>{{ item.promo_title }}</td>
    <td class="text-no-wrap text-xs-center">
      <rs-layout justify-center>
        <span>{{ formatDate(item.start_date) }}</span>
        <span> - </span>
        <span>{{ formatDate(item.end_date) }}</span>
      </rs-layout>
    </td>
    <td class="text-xs-center">
      <sale-status :status="status" class="text-xs-center" />
    </td>
  </tr>
</template>

<script>
import { defineComponent, computed } from 'vue';
import SaleStatus from './SaleStatus.vue';
import useDateFormatter from '@/composables/date-format';
import useModelWrapper from '@/composables/model-wrapper';
import useStore from '@/composables/vuex';

export default defineComponent({
  components: { SaleStatus },
  props: {
    selected: Boolean,
    id: String,
  },
  setup(props, { emit }) {
    const store = useStore();
    const formatDate = useDateFormatter(navigator.language);
    const [isSelected] = useModelWrapper(props, emit, 'selected');
    const item = computed(() => store.state['ltk-sales'].saleMap[props.id] ?? {});

    const status = computed(() => item.value?.status);

    return { item, status, isSelected, formatDate };
  },
});
</script>

<style scoped lang="scss">
.sale-table--item {
  cursor: pointer;
  user-select: none;
}
</style>
