<template>
  <rs-text-field :value="modelValue" @input="onUpdateModelValue" hide-details single-line>
    <template #label>
      <div class="d-flex align-center">
        <rs-icon class="mr-1" size="20"> ltkfont-edit-outline </rs-icon>
        <span>Add notes</span>
      </div>
    </template>
  </rs-text-field>
</template>

<script>
import { computed, defineComponent } from 'vue';

export default defineComponent({
  emits: ['input'],
  props: {
    value: String,
  },
  setup(props, ctx) {
    const modelValue = computed(() => props.value);

    const onUpdateModelValue = (value) => {
      ctx.emit('input', value);
    };

    return { modelValue, onUpdateModelValue };
  },
});
</script>
