import Vue from 'vue';

// TODO: [sc-240473] Remove when the actual API requests are implemented.
const mockBubbles = [
  { id: '1', title: 'mock-live', end_date: '2023-03-12', start_date: '2023-03-07', status: 'live' },
  { id: '2', title: 'mock-draft', end_date: '2023-03-12', start_date: '2023-03-07', status: 'draft' },
  { id: '3', title: 'mock-published', end_date: '2023-03-12', start_date: '2023-03-07', status: 'published' },
];

export default {
  state: {
    bubblesById: {},
    status: {
      isLoading: false,
      error: false,
    },
  },
  getters: {
    getBubbles: (state) => () => {
      return Object.values(state.bubblesById);
    },
    getBubble: (state) => (bubbleId) => {
      return state.bubblesById[bubbleId];
    },
    getStatus: (state) => () => {
      return state.status;
    },
  },
  actions: {
    async fetchBubbles(context) {
      try {
        context.commit('UPDATE_STATUS', { isLoading: true, error: false });
        // TODO: [sc-240473] Implement the actual API requests.
        await new Promise((resolve) => setTimeout(resolve, 3000));
        context.commit('SET_BUBBLES', mockBubbles);
        context.commit('UPDATE_STATUS', { isLoading: false });
      } catch {
        context.commit('UPDATE_STATUS', { isLoading: false, error: true });
      }
    },
    createBubble(context, bubble) {
      // TODO: [sc-240473] Implement the actual API requests.
      context.commit('UPDATE_BUBBLE', bubble);
    },
    updateBubble(context, bubble) {
      // TODO: [sc-240473] Implement the actual API requests.
      context.commit('UPDATE_BUBBLE', bubble);
    },
    deleteBubble(context, bubbleId) {
      // TODO: [sc-240473] Implement the actual API requests.
      context.commit('REMOVE_BUBBLE', bubbleId);
    },
  },
  mutations: {
    SET_BUBBLES(state, bubbles) {
      const bubblesById = bubbles.reduce((map, bubble) => ({ ...map, [bubble.id]: bubble }), {});
      Vue.set(state, 'bubblesById', Object.assign({}, state.bubblesById, bubblesById));
    },
    UPDATE_BUBBLE(state, bubble) {
      Vue.set(state, 'bubblesById', Object.assign({}, state.bubblesById, { [bubble.id]: bubble }));
    },
    REMOVE_BUBBLE(state, bubbleId) {
      Vue.delete(state.bubblesById, bubbleId);
    },
    UPDATE_STATUS(state, status) {
      Vue.set(state, 'status', Object.assign({}, state.status, status));
    },
  },
};
