import { onMounted, ref, computed } from 'vue';
import clientFactory from './client';

const getUserRole = async () => {
  const client = clientFactory('/api');
  const rootUrl = '/v1/advertiser_control_panel';
  const request = await client.get(`${rootUrl}/permcheck`, {}).then((response) => response.data.data.perms);
  return request;
};

const useAdvertiserRoleCheck = () => {
  const userPermissions = ref([]);
  const hasWriteAccess = computed(() => userPermissions.value?.includes('update-advertiser'));
  const hasCreateAccess = computed(() => userPermissions.value?.includes('create-advertiser'));

  const handleGetUserRole = async () => {
    userPermissions.value = await getUserRole();
  };
  onMounted(async () => {
    await handleGetUserRole();
  });

  return { userPermissions, hasWriteAccess, hasCreateAccess };
};

export { useAdvertiserRoleCheck, getUserRole };
