<template>
  <rs-dialog :value="true" persistent data-test-id="qa-auto-renew-modal" max-width="656px">
    <rs-card>
      <rs-layout justify-end pr-4 pt-4>
        <rs-icon color="#808080" size="20" @click="close"> rsfont-close_v2 </rs-icon>
      </rs-layout>

      <rs-layout row wrap pt-2 class="auto-renewal-modal__content">
        <rs-flex headline font-weight-bold data-test-id="qa-auto-renew-modal-title">
          Are you sure you want to {{ autoRenewOn ? 'turn off' : 'turn on' }} auto-renew?
        </rs-flex>
        <rs-flex pt-4 body-2>
          <p v-if="autoRenewOn" data-test-id="qa-auto-renew-modal-description">
            By turning auto-renewal off, the subscription will expire on {{ subscriptionRenewalDate }}. <br />
            If discounts were applied, they will no longer apply and be lost.
          </p>
          <p v-else data-test-id="qa-auto-renew-modal-description">
            By turning on auto-renewal, the subscription will be automatically renewed each year on the date that the
            subscription started.
          </p>
        </rs-flex>
      </rs-layout>

      <rs-layout
        justify-end
        row
        :column="$vuetify.breakpoint.smAndDown"
        :reverse="$vuetify.breakpoint.smAndDown"
        class="auto-renewal-modal__actions"
      >
        <rs-flex xs12 sm3 md3>
          <rs-btn data-test-id="qa-cancel-auto-renew-btn" @click="close"> CANCEL </rs-btn>
        </rs-flex>
        <rs-flex xs12 sm3 md3 ml-0 ml-md-3>
          <rs-btn :loading="loading" inverted data-test-id="qa-confirm-auto-renew-btn" @click="toggleAutoRenew()">
            CONFIRM
          </rs-btn>
        </rs-flex>
      </rs-layout>
    </rs-card>
  </rs-dialog>
</template>

<script>
export default {
  name: 'SubscriptionAutoRenewalModal',
  props: {
    subscriptionAutoRenewOn: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    subscriptionRenewalDate: {
      type: String,
    },
  },
  data() {
    return {
      autoRenewOn: this.subscriptionAutoRenewOn,
    };
  },
  methods: {
    toggleAutoRenew() {
      this.$emit('toggle-auto-renew');
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="sass" scoped>
.auto-renewal-modal
  &__content
   padding-left: 40px
   padding-right: 40px

  &__actions
    padding: 32px
</style>
