<template>
  <rs-data-table
    v-model="selectedRows"
    :headers="headings"
    :items="items"
    item-key="id"
    class="sale-table"
    :loading="isLoading"
  >
    <template #items="props">
      <sale-table-item
        :id="props.item.id"
        :selected="props.selected"
        :key="props.item.id"
        @click.native="$emit('click-row', props.item.id)"
        @update:selected="props.selected = $event"
      />
    </template>
  </rs-data-table>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue';
import SaleTableItem from './SaleTableItem.vue';
import useModelWrapper from '@/composables/model-wrapper';
import useStore from '@/composables/vuex';

export default defineComponent({
  components: { SaleTableItem },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: Array,
  },
  emits: {
    'update:modelValue': Array,
  },
  setup(props, { emit }) {
    const store = useStore();
    const headings = ref([
      { width: '80px', sortable: false },
      { text: 'Sale Name', value: 'name', sortable: false },
      { text: 'Dates', value: 'dates', sortable: false, align: 'center' },
      { text: 'Status', value: 'status', width: '132px', sortable: false, align: 'center' },
    ]);
    const items = computed(() => store.state['ltk-sales'].saleIds.map((id) => ({ id })));

    const [selectedRows] = useModelWrapper(props, emit);
    const isLoading = computed(() => store.getters.salesFetchState('list').pending);

    onMounted(async () => {
      await store.dispatch('fetchSales');
    });

    return { selectedRows, headings, items, isLoading };
  },
});
</script>
