<template>
  <rs-toolbar :clipped-left="$vuetify.breakpoint.mdAndUp" class="rs-header d-flex" color="white" dense flat app fixed>
    <rs-flex xs3>
      <rs-icon @click="$emit('nav-open')">menu</rs-icon>
    </rs-flex>
    <rs-flex xs6>
      <router-link to="/">
        <rs-img :src="display.rsLogo" height="84" contain />
      </router-link>
    </rs-flex>
    <rs-flex xs3></rs-flex>
  </rs-toolbar>
</template>

<script>
// svg import
import rsLogo from '@/assets/LTK_Logo.svg';

export default {
  name: 'RsHeader',
  data() {
    return {
      display: {
        rsLogo,
      },
      rewardStyle: window.rewardStyle,
    };
  },
};
</script>

<style lang="sass">
.application
  .rs-header
    border-bottom: 1px solid #eaedf0
    a
      text-decoration: none
</style>
