import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import usePsApi from '@/services/usePsApi.js';

const emptyHashtagFactory = () => ({
  enabled: true,
  country_code: 'us',
  start_date: '',
  end_date: '',
  page: 'v1_hashtags',
  group_label: '',
  feature_flag: null,
  major_version: 1,
  minor_version: 0,
  options_type: 'v1_hashtag',
  options: {
    key: '',
    url: '',
    type: 2,
    testTag: 'testTag',
    testDescription: 'Test Description',
    title: '',
  },
  notes: 'insert notes here',
});

export const useHashtagsStore = defineStore('Hashtags Store', () => {
  const hashtagsByCountry = ref([]);
  const hashtagsByGroup = ref([]);
  const { getByCountry, getByGroup, createMany, editOne, editMany, deleteMany } = usePsApi();

  const getHashtagsByCountry = async (country) => {
    try {
      const hashtagsResponse = await getByCountry('v1_hashtags', country);
      hashtagsByCountry.value = [];
      hashtagsResponse.forEach((hashtag) => {
        hashtagsByCountry.value.push(hashtag);
      });
    } catch (e) {
      console.warn('ERROR: ', e);
    }
  };

  const getHashtagsByGroup = async (name) => {
    try {
      const hashtagsResponse = await getByGroup('v1_hashtags', name);
      hashtagsByGroup.value = [];
      hashtagsResponse.forEach((hashtag) => {
        hashtagsByGroup.value.push(hashtag);
      });
    } catch (e) {
      console.warn('ERROR: ', e);
    }
  };

  const createHashtags = async ({ group_label, modules }) => {
    try {
      const page = 'v1_hashtags';
      const response = await createMany({ group_label, modules, page });
      await getHashtagsByCountry();
      return response.length;
    } catch (e) {
      console.warn('ERROR: ', e);
    }
  };

  const editHashtags = async (modules) => {
    try {
      const response = await editMany(modules);
      return response;
    } catch (e) {
      console.warn('ERROR: ', e);
    }
  };

  const deleteHashtags = async (modules) => {
    try {
      const response = await deleteMany(modules);
      return response;
    } catch (e) {
      console.warn('ERROR: ', e);
    }
  };

  const getHashtagById = (id, type = 'country') => {
    return computed(() => {
      let hashtag;
      if (type === 'country') {
        hashtag = hashtagsByCountry.value.filter((hashtag) => hashtag.id === id)[0];
      } else if (type === 'group') {
        hashtag = hashtagsByGroup.value.filter((hashtag) => hashtag.id === id)[0];
      }
      return hashtag ?? emptyHashtagFactory();
    });
  };

  const editHashtag = async (update) => {
    try {
      const res = await editOne(update);
      return res;
    } catch (e) {
      console.warn('error', e);
    }
  };

  return {
    hashtagsByCountry,
    hashtagsByGroup,
    createHashtags,
    editHashtags,
    editHashtag,
    getHashtagsByCountry,
    getHashtagsByGroup,
    getHashtagById,
    deleteHashtags,
  };
});
