<template>
  <rs-container class="accounts">
    <Loader v-if="loading" />
    <rs-layout>
      <h2 class="display-3">{{ $route.meta.title }}</h2>
    </rs-layout>

    <rs-card>
      <rs-card-title class="pa-5">
        <h3 class="display-2 font-weight-medium ma-0">Brand Accounts</h3>
        <rs-flex xs3 ml-5>
          <rs-text-field
            v-model="pagination.searchTerm"
            class="font-weight-medium"
            placeholder="Search"
            :clearable="pagination.searchTerm !== ''"
            regular
            flat
            prepend-inner-icon="rsfont-search_v2"
            clear-icon="cancel"
            @keyup.enter="getAccountList"
            data-test-id="search"
          />
        </rs-flex>
        <rs-spacer />
        <rs-btn
          small
          :block="false"
          @click="showImportAccountModal = true"
          data-test-id="accounts-view-import-account-button"
        >
          <rs-icon size="19">rsfont-import-influencers</rs-icon>
          <div class="mx-2">Import</div>
        </rs-btn>
        <rs-btn
          small
          :block="false"
          @click="showAddAccountModal = true"
          data-test-id="accounts-view-add-account-button"
        >
          <rs-icon size="19" class="add-btn ml-1">add</rs-icon>
          <div class="mx-2">Create New Account</div>
        </rs-btn>
      </rs-card-title>

      <rs-card-text class="pt-0">
        <rs-data-table
          class="accounts-data-table mb-4"
          :headers="headers"
          :items="accounts"
          :total-items="pagination.searchTerm ? 0 : accountsMeta.total_rows || 0"
          :must-sort="true"
          :rows-per-page-items="[5, 10, 25]"
          :pagination.sync="pagination"
        >
          <template slot="items" slot-scope="props">
            <router-link
              class="row-link"
              tag="tr"
              :to="{
                name: 'AccountManagement',
                params: {
                  accountId: props.item.id,
                },
              }"
              :data-test-id="`accounts-view-account-management-link-${props.index}`"
            >
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.website || '--' }}</td>
              <td>{{ props.item.id || '--' }}</td>
              <td>{{ props.item.hubspot_company_id || '--' }}</td>
              <td>{{ props.item.netsuite_entity_id || '--' }}</td>
              <td>{{ props.item.users || '--' }}</td>
              <td>
                <rs-btn borderless fab small :block="false" class="ma-0">
                  <rs-icon size="25">rsfont-edit_v2</rs-icon>
                </rs-btn>
              </td>
            </router-link>
          </template>
        </rs-data-table>
      </rs-card-text>
    </rs-card>

    <!-- Page Modals -->
    <dialog-account
      title="Add Account"
      v-if="showAddAccountModal"
      @close="
        () => {
          showAddAccountModal = false;
          getAccountList();
        }
      "
    />
    <account-import-modal
      title="Import Accounts"
      v-if="showImportAccountModal"
      @close="
        () => {
          showImportAccountModal = false;
          getAccountList();
        }
      "
    />
  </rs-container>
</template>

<script>
// Components
import dialogAccount from '../components/DialogAccount';
import AccountImportModal from '../components/AccountImportModal';
import Loader from '../components/Loader';

// Mixins
import loaderMixin from '@rscollabs/rs-core-library/src/mixins/rs-loader';

export default {
  name: 'Accounts',
  components: {
    AccountImportModal,
    dialogAccount,
    Loader,
  },
  mixins: [loaderMixin],
  data() {
    return {
      showAddAccountModal: false,
      showImportAccountModal: false,
      headers: [
        { text: 'Company', value: 'name' },
        { text: 'Website', value: 'website', sortable: false },
        { text: 'Account ID', value: 'account_id', sortable: false },
        { text: 'Hubspot ID', value: 'hubspot_company_id', sortable: false },
        { text: 'Netsuite ID', value: 'netsuite_entity_id', sortable: false },
        { text: 'Users', value: 'users', sortable: false },
        { text: 'Edit', sortable: false },
      ],
      accounts: [],
      accountsMeta: {},
      pagination: {
        sortBy: 'name',
        descending: false,
        page: 1,
        rowsPerPage: 5,
        searchTerm: '',
      },
    };
  },
  mounted() {
    this.getAccountList();
  },

  methods: {
    async getAccountList() {
      const emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
      const phoneRegex = new RegExp('^[0-9]+$');
      const inputSearch = this.pagination.searchTerm.trim();
      if (inputSearch) {
        // remove old filter conditions
        delete this.pagination.searchTerm;
        delete this.pagination.user_email;
        delete this.pagination.hubspot_company_id;
        // assign new filter condition
        if (emailRegex.test(inputSearch)) {
          this.pagination.user_email = inputSearch;
        } else if (phoneRegex.test(inputSearch)) {
          this.pagination.hubspot_company_id = inputSearch;
        } else {
          this.pagination.searchTerm = inputSearch;
        }
      } else {
        this.pagination.searchTerm = '';
      }
      const { data } = await this.loader(this.$identity.getAccounts, [this.pagination]);
      this.accountsMeta = data.meta;
      this.accounts = data.accounts;
    },
  },
  watch: {
    pagination: {
      handler: function (oldValue, newValue) {
        if (newValue.searchTerm) {
          this.pagination.page = 1;
          return;
        } else if (this.accounts.length) {
          this.getAccountList();
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="sass">
@media only screen and (min-width: 1904px)
  .container
    min-width: 1680px
    max-width: fit-content !important
.accounts
  height: 100%
  width: 100%
  width: -moz-available
  width: -webkit-fill-available
.row-link
  cursor: pointer
</style>
