<template>
  <rs-dialog :value="true" persistent max-width="550px" :fullscreen="$vuetify.breakpoint.xsOnly" v-if="post">
    <rs-card>
      <rs-card-title class="posts__modal-header justify-center">
        <h3 class="headline font-weight-medium ma-0">Edit Post</h3>
      </rs-card-title>

      <rs-divider />

      <rs-form
        ref="form"
        v-if="post"
        style="position: relative"
        class="dialog__form py-3 px-5"
        @submit.prevent="onSubmit"
        data-test-id="dialog-post-edit-form"
      >
        <rs-card-text class="pa-0">
          <rs-text-field
            v-model="post.influencer_id"
            label="Influencer ID"
            disabled
            data-test-id="dialog-post-influencer-id-text-field"
            :loading="isLoading"
          ></rs-text-field>
          <rs-text-field
            :value="post.channel | properCase"
            label="Channel"
            disabled
            data-test-id="dialog-channel-text-field"
            :loading="isLoading"
          ></rs-text-field>
          <rs-text-field
            v-model="post.submitted_url"
            label="Submitted URL"
            disabled
            data-test-id="dialog-submitted-url-text-field"
            :loading="isLoading"
          ></rs-text-field>
        </rs-card-text>

        <div ref="rslinks" v-if="links && links.length" style="max-height: 250px; overflow-y: scroll">
          <rs-layout v-for="(item, idx) in links" :key="idx" align-center class="channel-req-row" ma-0 pr-2>
            <rs-flex xs10>
              <rs-text-field
                v-model="links[idx]"
                label="RewardStyle Link"
                class="mt-0 mr-2"
                auto-grow
                autofocus
                :loading="isLoading"
                :rules="[(v) => !!v || 'RewardStyle link is required', duplicateCheck]"
                :data-test-id="`posts-rstyle_links-${idx}`"
              />
            </rs-flex>
            <rs-flex shrink>
              <rs-btn small class="title font-weight-regular" @click="removeRstyleLink(idx)">
                <rs-icon size="16" class="mr-2">rsfont-delete_v2</rs-icon>
                Delete
              </rs-btn>
            </rs-flex>
          </rs-layout>
        </div>

        <rs-btn :block="false" small :large="$vuetify.breakpoint.smAndUp" @click="addNewRstyleLink()">
          + Add New Rstyle Link
        </rs-btn>

        <rs-card-actions class="pa-0 my-3 pt-4">
          <rs-btn inverted type="submit" data-test-id="dialog-post-save-button"> Save </rs-btn>
          <rs-btn @click="onCancel" data-test-id="dialog-post-cancel-button">Cancel</rs-btn>
        </rs-card-actions>
      </rs-form>
    </rs-card>
  </rs-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'EditPost',
  props: {
    postId: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      post: {
        type: Object,
        default: () => ({}),
      },
      links: {
        type: Array,
        default: () => [],
      },
    };
  },
  async mounted() {
    await this.fetchPost(this.postId);
    this.post = this.getPostById(this.postId);
    this.links = this.post.rstyle_links || [];
  },
  computed: {
    ...mapGetters('posts', ['getPostById']),
    isLoading() {
      const { meta } = this.getPostById(this.postId);
      return meta.isFetching;
    },
  },
  methods: {
    ...mapActions('posts', {
      fetchPost: 'getPost',
      editRsLinks: 'updateRsLinks',
    }),
    addNewRstyleLink() {
      this.links.push('');
      this.$nextTick(() => {
        var container = this.$refs.rslinks;
        container.scrollTop = container.scrollHeight + 120;
      });
    },
    removeRstyleLink(idx) {
      this.links.splice(idx, 1);
    },
    duplicateCheck(val) {
      const withHttp = (url) => (!/^https?:\/\//i.test(url) ? `http://${url}` : url);
      let l = this.links.map((link) => withHttp(link));
      let count = l.reduce((n, x) => n + (x === withHttp(val)), 0);
      return count > 1 ? 'Link already exists' : true;
    },
    async updateEditingPost() {
      await this.editRsLinks({
        postId: this.postId,
        request: {
          postId: this.postId,
          rstyle_links: this.links,
        },
      });
    },
    async onCancel() {
      await this.fetchPost(this.postId);
      this.post = this.getPostById(this.postId);
      this.links = this.post.rstyle_links;
      this.$emit('close');
      this.$router.push({
        name: 'Posts',
      });
    },
    async onSubmit() {
      // validate form
      if (!this.$refs.form.validate()) return;

      await this.updateEditingPost();

      this.$emit('close');
      this.$router.push({
        name: 'Posts',
      });
    },
  },
  filters: {
    properCase: (value) => {
      if (!value) return '--';
      value = value.toString().toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
};
</script>

<style lang="sass">
.error-msg
  color: red
</style>
