<template>
  <rs-snackbar
    v-model="error"
    auto-height
    :color="color"
    :multi-line="multiLine"
    :persistent="persistent"
    :timeout="timeout"
    :top="position === 'top'"
  >
    <rs-layout row wrap justify-space-between align-center :column="$vuetify.breakpoint.smAndDown">
      <rs-flex>
        <span v-if="!includeDetails" class="white--text text-xs-center">
          An error has occured. Please try again. If the issue persists please submit a
          <a href="https://rsbrands.zendesk.com/hc/en-us" target="_blank" class="white--text">support ticket.</a>
        </span>
        <span v-else class="white--text text-xs-center">
          {{ details }}
        </span>
      </rs-flex>
    </rs-layout>
    <rs-btn v-if="timeout === 0" @click="error = false" color="white" borderless class="headline ml-0">
      <rs-icon medium color="white">clear</rs-icon>
    </rs-btn>
  </rs-snackbar>
</template>
<script>
export default {
  name: 'ErrorMessage',
  data() {
    return {
      color: 'red',
      details: '',
      error: false,
      includeDetails: false,
    };
  },
  mounted() {
    this.$root.$on('onError', (state) => {
      this.error = state;
      if (typeof state.data.error === 'string') {
        this.includeDetails = true;
        this.details = state.data.error.charAt(0).toUpperCase() + state.data.error.slice(1);
      }
    });
  },
  props: {
    multiLine: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String,
      default: 'top',
    },
    timeout: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    closeSnackBar() {
      this.$emit('closeSnackBar');
    },
  },
};
</script>
<style lang="sass">
.v-snack
  font-size: 14px
  margin: 0
  width: 100%
.v-snack__content
  height: auto
.v-snack__wrapper
  border-radius: 0px !important
  line-height: 24px
  margin: 0 !important
  max-width: 100%
  text-align: center
  width: 100%
</style>
