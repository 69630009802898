<template>
  <tr :active="props.selected">
    <td>
      <!-- eslint-disable vue/no-mutating-props -->
      <rs-checkbox
        v-model="props.selected"
        :disabled="isCheckBoxDisabled"
        :readonly="isCheckBoxReadonly"
        primary
        hide-details
      ></rs-checkbox>
      <!-- eslint-enable vue/no-mutating-props -->
    </td>

    <td>{{ props.item.id }}</td>
    <td>{{ props.item.company }}</td>
    <td>{{ props.item.blog }}</td>
    <td>{{ props.item.blog_name }}</td>
    <td>{{ props.item.country }}</td>

    <td>
      <!-- eslint-disable vue/no-mutating-props -->
      <rs-select
        @change="$emit('changeOffice')"
        v-model="props.item.office.office"
        class="mt-4"
        :items="OFFICES"
        :disabled="isSelectDisabled"
        flat
        solo
        dense
      ></rs-select>
      <!-- eslint-enable vue/no-mutating-props -->
    </td>

    <td>
      {{ props.item.segment }}
    </td>

    <td>
      <!-- eslint-disable vue/no-mutating-props -->
      <rs-select
        @change="$emit('changePod')"
        v-model="props.item.office.pod"
        class="mt-4"
        :items="PODS"
        :disabled="isSelectDisabled"
        flat
        solo
        dense
      >
      </rs-select>
      <!-- eslint-enable vue/no-mutating-props -->
    </td>

    <td>
      <!-- eslint-disable vue/no-mutating-props -->
      <rs-select
        @change="$emit('changeSubPod')"
        v-model="props.item.office.sub_pod"
        class="mt-4"
        :items="subPods"
        :disabled="isSelectDisabled"
        flat
        solo
        dense
      >
        <!-- eslint-enable vue/no-mutating-props -->
        <template slot="item" slot-scope="data">
          {{ data.item ? data.item : 'None' }}
        </template>
      </rs-select>
    </td>
    <td>
      <!-- eslint-disable vue/no-mutating-props -->
      <rs-select
        @change="$emit('changeSubRegion')"
        v-model="props.item.office.sub_region"
        class="mt-4"
        :items="subRegions"
        :disabled="isSelectDisabled"
        flat
        solo
        dense
      >
        <!-- eslint-enable vue/no-mutating-props -->
        <template slot="item" slot-scope="data">
          {{ data.item ? data.item : 'None' }}
        </template>
      </rs-select>
    </td>
  </tr>
</template>

<script>
import { OFFICES, SEGMENTS, PODS, getSubRegionsFor, getSubPodsFor } from '@/internal-pod-management/utils/attributes';

export default {
  props: ['props', 'selectedRows', 'editingAccounts'],
  data() {
    return {
      OFFICES,
      SEGMENTS,
      PODS,
    };
  },
  computed: {
    subRegions() {
      const item = this.props.item;
      const office = item?.office?.office ?? '';
      return getSubRegionsFor(office);
    },
    subPods() {
      const item = this.props.item;
      const office = item?.office?.office ?? '';
      const subRegion = item?.office?.sub_region ?? '';
      return getSubPodsFor(office, subRegion);
    },
    isSelectDisabled() {
      const item = this.props.item;
      return this.selectedRows.length > 0 && !this.selectedRows.some((account) => account.id === item.id);
    },
    isCheckBoxDisabled() {
      const item = this.props.item;
      return this.editingAccounts.length > 0 && !this.selectedRows.some((account) => account.id === item.id);
    },
    isCheckBoxReadonly() {
      const item = this.props.item;
      return this.editingAccounts.length > 0 && this.selectedRows.some((account) => account.id === item.id);
    },
  },
};
</script>
