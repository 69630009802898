<template>
  <rs-select v-model="territory" :items="territories" @change="onSelect" label="Office Location" hide-details />
</template>

<script>
export const territoryMap = {
  'United States': ['us', 'ca'],
  'United Kingdom': [
    'be',
    'bg',
    'cy',
    'cz',
    'dk',
    'ee',
    'fi',
    'gb',
    'gr',
    'hr',
    'hu',
    'ie',
    'lt',
    'lu',
    'lv',
    'mt',
    'no',
    'pl',
    'pt',
    'ro',
    'ru',
    'se',
    'si',
    'sk',
    'ua',
  ],
  China: ['cn', 'hk', 'id', 'jp', 'kh', 'mm', 'mn', 'mo', 'my', 'ph', 'sg', 'th', 'vn', 'tw', 'ko', 'kp', 'kr'],
  Brazil: [
    'ar',
    'aw',
    'bl',
    'bo',
    'br',
    'cl',
    'co',
    'cr',
    'cu',
    'do',
    'ec',
    'gn',
    'gp',
    'gt',
    'hn',
    'ht',
    'mf',
    'mq',
    'mx',
    'ni',
    'pa',
    'pe',
    'pr',
    'py',
    'uy',
    've',
  ],
  ROW: [
    'ae',
    'af',
    'bh',
    'dj',
    'dz',
    'eg',
    'id',
    'il',
    'in',
    'iq',
    'ir',
    'jo',
    'jp',
    'kh',
    'kp',
    'kw',
    'kz',
    'la',
    'lb',
    'ly',
    'ma',
    'md',
    'mm',
    'mn',
    'mv',
    'my',
    'om',
    'ph',
    'pk',
    'qa',
    'sa',
    'sg',
    'sy',
    'th',
    'tm',
    'tn',
    'tr',
    'uz',
    'vn',
    'ye',
    'ye',
    'za',
  ],
};
export default {
  props: {
    preset: { type: String, default: null },
  },
  data() {
    return {
      territory: null,
    };
  },

  watch: {
    preset: {
      handler(newValue) {
        this.territory = newValue;
      },
      immediate: true,
    },
  },

  computed: {
    territories() {
      return Object.keys(territoryMap);
    },
  },

  methods: {
    onSelect(territory) {
      this.$emit('select-territory', territory);
    },
  },
};
</script>
