<template>
  <div class="curation-item">
    <div class="curation-item__media column">
      <div class="curation-item__header">
        <img :src="profileData.avatar_url" loading="lazy" width="32" height="32" alt="" class="c-image loaded" />
        <a :href="profileUrl" target="_blank" rel="noreferrer noopener" class="ml-2 pl-1 body-1">
          {{ profileData.display_name }}
        </a>
      </div>

      <div class="curation-item__body my-1">
        <video ref="videoRef" width="100%" height="fit-content" controls />
      </div>

      <div class="curation-item__footer">
        <p class="body-1">{{ caption }}</p>
        <p class="body-1"><a :href="postUrl" target="_blank" rel="noreferrer noopener">View post on LTK</a></p>
      </div>
    </div>
    <div class="curation-item__cta column">
      <div class="button-wrapper">
        <rating-button
          rounded
          icon-only
          button-id="up"
          color="green"
          icon-name="thumb_up"
          :on-click="handleUp"
          :selected-id="selectedId"
          :is-disabled="isLoadingScorePost"
        >
          <rs-tip>Content aligns with interest</rs-tip>
        </rating-button>
        <rating-button
          button-id="skip"
          color="gray"
          :on-click="handleSkip"
          :selected-id="selectedId"
          :is-disabled="isLoadingScorePost"
        >
          <span>Skip</span>
          <rs-tip>Decline to rate</rs-tip>
        </rating-button>
        <rating-button
          rounded
          icon-only
          button-id="down"
          color="red"
          icon-name="thumb_down"
          :on-click="handleDown"
          :selected-id="selectedId"
          :is-disabled="isLoadingScorePost"
        >
          <rs-tip>Content does not align with interest</rs-tip>
        </rating-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useMediaControls } from '@vueuse/core';
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useCurationStore } from '../../stores/curation';
import RsTip from '@/internal-advertisers/lib/components/RsTip/RsTip.vue';
import RatingButton from './RatingButton.vue';

const props = defineProps({
  postId: String,
  videoSrc: String,
  // data of profile that made post
  profileData: Object,
  caption: String,
  postUrl: String,
});

const emit = defineEmits(['score', 'skip']);
const curationStore = useCurationStore();
const { isLoadingScorePost } = storeToRefs(curationStore);
const videoRef = ref(null);
const selectedId = ref('');
const { playing } = useMediaControls(videoRef, {
  src: props.videoSrc,
});
const profileUrl = computed(() => {
  const currentEnv = window.rewardStyle.ENVIRONMENT.toLowerCase();

  return `https://www.${currentEnv === 'prod' ? '' : currentEnv}shopltk.com/explore/${props.profileData.display_name}`;
});

const handleUp = () => {
  selectedId.value = 'up';
  emit('score', props.postId, true);
};

const handleSkip = () => {
  selectedId.value = 'skip';
  emit('skip');
};

const handleDown = () => {
  selectedId.value = 'down';
  emit('score', props.postId, false);
};
</script>

<style lang="scss" scoped>
.curation-item {
  display: flex;
  flex-direction: row;
  gap: 24px;
  min-height: 500px;

  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__cta {
    margin-top: 36px;

    .button-wrapper {
      display: flex;
      flex-direction: row;
      gap: 12px;
      align-items: center;
    }

    #up {
      &:hover,
      &:focus,
      &:active {
        background: rgba(#008000, 0.7);
        border-color: rgba(#008000, 0.7);

        .v-icon {
          color: #fff;
        }
      }
    }

    #down {
      &:hover,
      &:focus,
      &:active {
        background: rgba(#8b0000, 0.7);
        border-color: rgba(#8b0000, 0.7);

        .v-icon {
          color: #fff;
        }
      }
    }
  }

  video {
    max-height: 500px;
  }
}
</style>
