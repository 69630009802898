import Vue from 'vue';
import salesService from '../../services/sales';

export function emptySaleRetailerFactory() {
  return {
    retailer_id: '',
    promo_description: '',
    promo_code: '',
  };
}

function emptyFetchStateFactory() {
  return {
    pending: false,
    error: null,
    timestamp: 0,
  };
}

function fetchStateDecorator(id, fn) {
  return async function wrappedAction(store, ...rest) {
    const fetchState = {
      pending: true,
      error: null,
      timestamp: 0,
    };

    store.commit('SET_SALES_FETCH_STATE', { id, fetchState });

    try {
      const result = fn.call(this, store, ...rest);
      if (result instanceof Promise) {
        await result;
      }
    } catch (error) {
      fetchState.error = error;
    }

    fetchState.timestamp = Date.now();
    fetchState.pending = false;
    store.commit('SET_SALES_FETCH_STATE', { id, fetchState });
  };
}

export default {
  state: {
    // [ id ] list of sales by id
    saleIds: [],
    // { id: Sale } Mapping
    saleMap: {},

    fetchState: {
      list: emptyFetchStateFactory(),
    },
  },
  getters: {
    saleStatus(state) {
      return (saleId) => state.saleMap[saleId]?.status;
    },
    salesFetchState(state) {
      return (id = 'list') => state.fetchState[id] || emptyFetchStateFactory();
    },
  },
  actions: {
    fetchSales: fetchStateDecorator('list', async function fetchSales({ commit }) {
      const response = await salesService.get();
      commit('SET_SALES_PAGE', response.data);
    }),

    async createSale(store, sale) {
      await fetchStateDecorator('add', async function ({ dispatch, commit }, sale) {
        const response = await salesService.post({
          ...sale,
          badge_text_color: 'FFFFFF',
          promo_color: 'C45500',
          promo_title_color: '000000',
        });

        commit('UPDATE_SALE_ITEM', response.data.sale);
        dispatch('fetchSales');
      })(store, sale);
    },

    async updateSale(store, sale) {
      await fetchStateDecorator(sale.id, async function ({ commit }, sale) {
        const response = await salesService.put({
          ...sale,
          badge_text_color: 'FFFFFF',
          promo_color: 'C45500',
          promo_title_color: '000000',
        });

        commit('UPDATE_SALE_ITEM', response.data.sale);
      })(store, sale);
    },

    async deleteSale(store, saleId) {
      await fetchStateDecorator(saleId, async function ({ commit }, saleId) {
        await salesService.delete(saleId);

        commit('REMOVE_SALE_ITEM', saleId);
      })(store, saleId);
    },
  },
  mutations: {
    SET_SALES_FETCH_STATE(state, { id, fetchState }) {
      Vue.set(state.fetchState, id, Object.assign({}, fetchState));
    },

    SET_SALES_PAGE(state, response) {
      const saleIds = response.sales.map(({ id }) => id);
      const saleMap = response.sales.map((sale) => ({ [sale.id]: sale }));

      Vue.set(state, 'saleMap', Object.assign({}, state.saleMap, ...saleMap));
      state.saleIds.splice(0, state.saleIds.length, ...saleIds);
    },

    UPDATE_SALE_ITEM(state, sale) {
      Vue.set(state.saleMap, sale.id, sale);
    },

    REMOVE_SALE_ITEM(state, saleId) {
      // Remove from list first as that will update iterations and remove rendered/referenced models
      const saleIndex = state.saleIds.findIndex((id) => id === saleId);
      if (saleIndex !== -1) {
        state.saleIds.splice(saleIndex, 1);
      }

      Vue.delete(state.saleMap, saleId);
    },
  },
};
