import { getClient } from '@/utils/client';

const usePsApi = () => {
  const client = {
    // run `npm run serve:dev:ltk` to hit DEV Presentation Service.
    v1: getClient({
      host: window.rewardStyle.TYK_GATEWAY_HOST,
      basePath: '/api/admin/v1/presentation',
    }),
    /*
      Does not include Authorization header value,
        this is to avoid conflicting authentication methods with presigned urls
    */
    v2: getClient({
      host: window.rewardStyle.TYK_GATEWAY_HOST,
      basePath: '/api/admin/v1/presentation',
      includeAuth: false,
    }),
  };

  const getByCountry = async (type, country = 'us') => {
    const response = await client.v1.get(`/modules/${type}/country/${country}`);
    return response.data.modules;
  };

  const getByGroup = async (type, groupLabel) => {
    const response = await client.v1.get(`/modules/${type}/group/${groupLabel}`);
    return response.data.modules;
  };

  const createMany = async ({ group_label, modules, page }) => {
    const response = await client.v1.put('/modules', { group_label, modules, page });
    return response.data.modules;
  };

  const editMany = async (modules) => {
    const response = await client.v1.post('/modules/edit', modules);
    return response.data.modules;
  };

  const deleteMany = async (modules) => {
    const response = await client.v1.post('/modules/delete', { modules });
    return response.data;
  };

  const editOne = async (updateModule) => {
    const { id, update } = updateModule;
    // Note: This route uses a singular `module` in path
    const response = await client.v1.patch(`/module/${id}`, update);
    return { data: response.data, status: response.status };
  };

  const createMedia = async () => {
    const response = await client.v1.post(`/media`, { notes: 'Media created via Operator Tools' });
    return { data: response.data, status: response.status };
  };

  const uploadMedia = async (file) => {
    const { presigned_id, presigned_url } = await getPresignedUrl();
    const fileData = Buffer.from(await file.arrayBuffer());
    const response = await client.v2.put(presigned_url, fileData, {
      headers: { 'Content-Type': file.type },
    });
    return { data: response.data, status: response.status, presigned_id };
  };

  const getPresignedUrl = async () => {
    try {
      const { data, status } = await createMedia();
      const presigned_id = data.presentation_service.ID;
      const presigned_url = data.media_service.upload_authorizations[0].presigned_upload_url;
      return { presigned_id, presigned_url, status };
    } catch (e) {
      console.warn('ERROR: ', e);
    }
  };

  const pollMediaService = async (presignedId) => {
    try {
      const { data } = await client.v1.get(`/media/${presignedId}`);
      const { media } = data;
      return { url: media.URL };
    } catch (e) {
      console.warn('Error: ', e);
    }
  };

  return {
    getByCountry,
    getByGroup,
    createMany,
    editOne,
    editMany,
    deleteMany,
    createMedia,
    uploadMedia,
    pollMediaService,
  };
};

export default usePsApi;
