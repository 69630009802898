<template>
  <rs-modal v-model="active" size="md" @closed="onClose()">
    <rs-modal-header title="Add New Parent" subtitle="Create a new parent and division" @close="onClose()" />
    <rs-modal-content>
      <parents-add :parents="parents" @saved="handleParentsReq" @valid="(event) => onValid(event)" />
    </rs-modal-content>
    <rs-modal-footer justify="space-between">
      <rs-button @click="onClose">Cancel</rs-button>
      <rs-button primary @click="onSubmit(parentPayload)" :disabled="!isValid"> Submit </rs-button>
    </rs-modal-footer>
  </rs-modal>
</template>

<script>
import { defineComponent, reactive, ref, watch, nextTick, computed } from 'vue';
import RsButton from '../lib/components/RsButton/RsButton.vue';
import RsModal from '../lib/components/RsModal/RsModal.vue';
import RsModalContent from '../lib/components/RsModal/RsModalContent.vue';
import RsModalHeader from '../lib/components/RsModal/RsModalHeader.vue';
import RsModalFooter from '../lib/components/RsModal/RsModalFooter.vue';
import ParentsAdd from './ParentsAdd.vue';

import useDivisionApi from '../composables/useDivisionApi';
export default defineComponent({
  name: 'ParentsModal',
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    parents: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    parentId: {
      type: Number,
      default: null,
    },
  },
  components: {
    RsModal,
    RsModalContent,
    RsModalHeader,
    RsModalFooter,
    RsButton,
    ParentsAdd,
  },
  emits: ['update:modelValue', 'saved', 'closed'],
  setup(props, { emit }) {
    const { postParents } = useDivisionApi();
    const active = ref(false);

    const rules = reactive({
      parentValid: false,
    });

    const onValid = (event) => {
      rules.parentValid = event;
    };

    const isValid = computed(() => {
      return rules.parentValid;
    });

    const onClose = () => {
      active.value = false;
      emit('update:modelValue', false);
      emit('closed');
    };

    watch(
      () => props.modelValue,
      async (next) => {
        await nextTick();
        if (next) {
          active.value = next;
        }
      },
      { immediate: true },
    );

    const parentPayload = ref({});

    const handleParentsReq = (event) => {
      parentPayload.value = event;
      emit('saved', event);
    };

    const onSubmit = (payload) => {
      if (payload.parent_id || payload.parent) {
        postParents(payload).then((response) => {
          if (response) {
            emit('saved', response);
            onClose();
          }
        });
      }
    };

    return {
      active,
      onClose,
      onSubmit,
      handleParentsReq,
      parentPayload,
      onValid,
      isValid,
    };
  },
});
</script>

<style></style>
