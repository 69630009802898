<template>
  <div :style="{ width: '100%' }">
    <div class="hashtags-manage" v-if="$vuetify.breakpoint.mdAndUp">
      <div class="hashtags-manage__wrapper">
        <div class="hashtags-manage__header">
          <rs-button icon-only flat rounded size="sm" @click="back">
            <rs-icon>arrow_back</rs-icon>
          </rs-button>
          <h1>{{ pageTitle }}</h1>
        </div>
        <div class="hashtags-manage__body">
          <rs-form ref="form" v-model="isFormValid" @submit.prevent="handleSaveUpdates()">
            <hashtags-fields
              v-if="!loading"
              :group-label="hashtagData.group_label"
              :start-date="hashtagData.start_date"
              :end-date="hashtagData.end_date"
              :editing="isEditing"
              :status="status"
              @change="(e) => onHashtagChange(e)"
            />
            <hashtags-country-fields
              v-for="(data, index) in hashtagCountries"
              :countries="unselectedCountries"
              :country-code="data.country_code"
              :removable="index >= groupStartingLength"
              :group-label="hashtagData.group_label"
              :key="index"
              :options="data.options"
              @change="(e) => onCountryChange(e, index)"
              @deleted="handleDeleteCountry(index)"
            />
          </rs-form>
          <div class="hashtags-manage__btns">
            <rs-button
              size="sm"
              data-testid="hashtags-manage-add-btn"
              @click="handleAddCountry(unselectedCountries[0].code)"
              :disabled="hashtagCountries.length === countries.length"
            >
              <rs-icon>add</rs-icon>
              <span>Add another country</span>
            </rs-button>
            <rs-button
              size="sm"
              class="hashtags-manage__add-all-btn"
              data-testid="hashtags-manage-add-all-btn"
              @click="handleAddAllCountries()"
              :disabled="hashtagCountries.length > 1"
            >
              <rs-icon>add</rs-icon>
              <span>Add all countries</span>
            </rs-button>
          </div>
        </div>
        <HashtagsFooter v-model="valid" @cancel="back()" @saved="handleSaveUpdates()" />
      </div>
    </div>
    <hashtags-desktop-notice v-if="$vuetify.breakpoint.smAndDown" />
  </div>
</template>

<script>
import { ref, computed, defineComponent, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import RsButton from '@/internal-advertisers/lib/components/RsButton/RsButton.vue';
import HashtagsFields from '@/internal-hashtags/components/HashtagsFields.vue';
import HashtagsCountryFields from '@/internal-hashtags/components/HashtagsCountryFields.vue';
import HashtagsFooter from '@/internal-hashtags/components/HashtagsFooter.vue';
import HashtagsDesktopNotice from '@/internal-hashtags/components/HashtagsDesktopNotice.vue';
import countries from '@/internal-hashtags/fixtures/countries.json';
import { addUTCMidnight, getFormattedDates, convertFromUtcToLocal } from '@/internal-hashtags/utils/changeTimeZone';
import { useHashtagsStore } from '@/stores/hashtags';
import { storeToRefs } from 'pinia';
import { formatForEdit } from '@/internal-hashtags/utils/formatForEdit';
export default defineComponent({
  name: 'HashtagsManage',
  components: {
    RsButton,
    HashtagsFields,
    HashtagsCountryFields,
    HashtagsFooter,
    HashtagsDesktopNotice,
  },
  props: {
    groupLabel: {
      type: String,
      default: '',
    },
    defaultCountry: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const isEditing = props.groupLabel !== '';

    const hashtagStore = useHashtagsStore();
    const { hashtagsByGroup } = storeToRefs(hashtagStore);

    // Format the dates in 'YYYY-MM-DD' format, with start/end as today/tomorrow
    const { formattedToday, formattedTomorrow } = getFormattedDates();

    const hashtagData = ref({
      group_label: props.groupLabel,
      enabled: false,
      pre_staged: false,
      start_date: formattedToday,
      end_date: formattedTomorrow,
    });

    const valid = computed(() => {
      return (
        hashtagData.value.group_label !== '' &&
        hashtagData.value.start_date !== '' &&
        hashtagData.value.end_date !== '' &&
        isFormValid.value === true
      );
    });

    const groupStartingLength = ref(0);
    const hashtagCountries = ref([]);
    const status = ref('disabled');
    const loading = ref(true);

    function setDefaultCountry() {
      const defaultCountry = unselectedCountries.value.filter((country) => country.code === props.defaultCountry)[0];
      handleAddCountry(defaultCountry.code);
    }

    const unselectedCountries = computed(() => {
      const selectedCountryCodes = hashtagCountries.value.map((country) => country.country_code);
      const filteredCountries = countries.filter((country) => !selectedCountryCodes.includes(country.code));
      return filteredCountries;
    });

    const pageTitle = computed(() => {
      if (route.name === 'HashtagsAdd') {
        return 'Add Hashtags';
      } else if (route.name === 'HashtagsEdit') {
        return 'Edit Hashtags';
      }
      return '';
    });

    const back = () => router.push(`/hashtags?defaultCountry=${props.defaultCountry}`);

    const readStatus = (status) => {
      if (status === 'qa') {
        hashtagData.value.enabled = true;
        hashtagData.value.pre_staged = true;
      } else if (status === 'prod') {
        hashtagData.value.enabled = true;
        hashtagData.value.pre_staged = false;
      } else if (status === 'disabled') {
        hashtagData.value.enabled = false;
        hashtagData.value.pre_staged = false;
      }
    };

    const setStatus = () => {
      if (hashtagData.value.enabled && hashtagData.value.pre_staged) {
        status.value = 'qa';
      } else if (hashtagData.value.enabled && !hashtagData.value.pre_staged) {
        status.value = 'prod';
      } else if (!hashtagData.value.enabled && !hashtagData.value.pre_staged) {
        status.value = 'disabled';
      }
    };

    const onHashtagChange = (event) => {
      if (!isEditing) {
        hashtagData.value.group_label = event.group_label;
      }
      hashtagData.value.start_date = event.start_date;
      hashtagData.value.end_date = event.end_date;
      readStatus(event.status);
    };

    const onCountryChange = (event, index) => {
      hashtagCountries.value[index].country_code = event.selected_country;
      hashtagCountries.value[index].options.key = event.options.key;
    };

    function handleAddAllCountries() {
      unselectedCountries.value.forEach((country) => {
        handleAddCountry(country.code);
      });
    }

    const handleAddCountry = (countryCode) => {
      hashtagCountries.value.push({ country_code: countryCode, options: { key: '' } });
    };

    const handleDeleteCountry = (index) => {
      hashtagCountries.value.splice(index, 1);
    };

    const buildNewModules = (modulesToBuild) => {
      const startDate = addUTCMidnight(hashtagData.value.start_date);
      const endDate = addUTCMidnight(hashtagData.value.end_date);
      const modules = [];
      modulesToBuild.forEach((hashtagCountry) => {
        const timezoneOffset = countries.find((c) => c.code === hashtagCountry.country_code)?.offset || '+00:00';
        const optionKey =
          hashtagCountry.options.key === '' ? `#${hashtagData.value.group_label}` : `#${hashtagCountry.options.key}`;
        const newCountryModule = {
          country_code: hashtagCountry.country_code,
          enabled: hashtagData.value.enabled,
          end_date: `${endDate}${timezoneOffset}`,
          major_version: 1,
          minor_version: 0,
          notes: 'Created via Operator Tools',
          options: {
            // If there is no localized string default to the group label
            key: optionKey,
            testTag: 'testTag',
            testDescription: 'Description for Unit Test',
            // Note: Even though some fields are left blank they are still required by the backend validator
            title: '',
            type: 2,
            url: '',
          },
          options_type: 'v1_hashtag',
          pre_staged: hashtagData.value.pre_staged,
          start_date: `${startDate}${timezoneOffset}`,
        };
        modules.push(newCountryModule);
      });
      return modules;
    };

    const form = ref();
    const isFormValid = ref(false);

    async function handleSaveUpdates() {
      if (form.value.validate() === false || valid.value === false) {
        return;
      }

      if (isEditing) {
        /*
          Because a user can create new hashtags on the Edit page
            we need to handle hashtag creation and editing separately
        */
        const modulesToUpdate = hashtagCountries.value.slice(0, groupStartingLength.value);
        const modulesToBuild = hashtagCountries.value.slice(groupStartingLength.value);
        await editHashtags(modulesToUpdate);
        // Check if new countries were added and will need to be created
        if (modulesToBuild.length > 0) {
          await createHashtags(modulesToBuild);
        }
      } else {
        await createHashtags(hashtagCountries.value);
      }
      router.push(`/hashtags?defaultCountry=${props.defaultCountry}`);
    }

    async function editHashtags(modulesToUpdate) {
      /*
        Hashtags can only have their start and end times, localized string,
          enabled, and pre_staged values edited on this screen.
        Localized strings are updated on a single hashtag as a user enters new values,
          everything else is updated based off the shared settings from hashtagData object.
        Since we do not maintain unsaved states we only need to update values on submit.
      */
      const startDate = addUTCMidnight(hashtagData.value.start_date);
      const endDate = addUTCMidnight(hashtagData.value.end_date);
      modulesToUpdate.forEach((hashtagCountry) => {
        const timezoneOffset = countries.find((c) => c.code === hashtagCountry.country_code)?.offset || '+00:00';
        hashtagCountry.start_date = `${startDate}${timezoneOffset}`;
        hashtagCountry.end_date = `${endDate}${timezoneOffset}`;
        hashtagCountry.enabled = hashtagData.value.enabled;
        hashtagCountry.pre_staged = hashtagData.value.pre_staged;
        if (hashtagCountry.options.key === '') {
          hashtagCountry.options.key = `#${hashtagData.value.group_label}`;
        }
      });

      // Remove fields not needed/valid for edit
      const formattedModulesToUpdate = formatForEdit(modulesToUpdate);

      // Make POST call to bulk edit endpoint
      try {
        await hashtagStore.editHashtags({ modules: formattedModulesToUpdate });
      } catch (err) {
        console.warn('Error', err);
      }
    }

    async function createHashtags(modulesToBuild) {
      try {
        const group_label = hashtagData.value.group_label;
        const modules = buildNewModules(modulesToBuild);
        await hashtagStore.createHashtags({ group_label, modules });
      } catch (err) {
        console.warn('Error', err);
      }
    }

    onMounted(async () => {
      /*
        If in Edit View:
          Make initial fetch and they load existing hashtag data.
      */
      if (isEditing) {
        await hashtagStore.getHashtagsByGroup(props.groupLabel);
        groupStartingLength.value = hashtagsByGroup.value.length;
        hashtagCountries.value = hashtagStore.hashtagsByGroup;
        hashtagData.value.pre_staged = hashtagCountries.value[0].pre_staged;
        hashtagData.value.enabled = hashtagCountries.value[0].enabled;
        const localStart = convertFromUtcToLocal(
          hashtagCountries.value[0].country_code,
          hashtagCountries.value[0].start_date,
        );
        const localEnd = convertFromUtcToLocal(
          hashtagCountries.value[0].country_code,
          hashtagCountries.value[0].end_date,
        );
        hashtagData.value.start_date = localStart.split('T')[0];
        hashtagData.value.end_date = localEnd.split('T')[0];
        setStatus();
      } else {
        /*
          If in Create view:
            Hydrate hashtagCountries with default country from Hashtags view
        */
        setDefaultCountry();
      }
      loading.value = false;
    });

    return {
      countries,
      form,
      groupStartingLength,
      hashtagCountries,
      hashtagData,
      hashtagsByGroup,
      isEditing,
      isFormValid,
      loading,
      pageTitle,
      status,
      unselectedCountries,
      valid,
      back,
      createHashtags,
      handleAddAllCountries,
      handleAddCountry,
      handleDeleteCountry,
      onHashtagChange,
      handleSaveUpdates,
      onCountryChange,
      readStatus,
      setDefaultCountry,
    };
  },
});
</script>

<style lang="scss">
.hashtags-manage {
  width: 100%;
  padding: 0 16px;
  &__wrapper {
    max-width: 1244px;
    margin: 0 auto;
    margin-top: 48px;
    margin-bottom: 108px;
  }
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    & > h1 {
      margin: 0;
      padding: 0;
      line-height: 32px;
      font-size: 24px;
      font-weight: 700;
    }
  }
  &__btns {
    padding-top: 24px;
  }
  &__add-all-btn {
    margin-left: 8px;
  }
}
</style>
