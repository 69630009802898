const pluralize = (count, word) => {
  if (count === 0) return '';
  return count === 1 ? `${count}${word}` : `${count}${word}s`;
};

const convertToDays = (value) => {
  if (!value) return '';
  const days = Math.floor(value / 24);
  const hours = Math.floor(value % 24);
  const minutes = Math.ceil(60 * (value % 1));
  return `${pluralize(days, 'day')} ${pluralize(hours, 'hr')} ${pluralize(minutes, 'min', false)}`;
};

export { convertToDays, pluralize };
