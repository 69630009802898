<template>
  <rs-card class="mb-5">
    <rs-card-title class="pa-4">
      <h3 class="headline font-weight-medium ma-0">
        <rs-icon :size="25" class="mr-2">account_box</rs-icon>
        System Users
      </h3>
      <rs-spacer />
      <rs-btn
        small
        flat
        :block="false"
        :disabled="disabled"
        @click="showUserModal = true"
        data-test-id="account-users-add-account-button"
      >
        <rs-icon size="19" class="add-btn">add</rs-icon>
        <div class="mx-2">Add System User</div>
      </rs-btn>
    </rs-card-title>

    <rs-divider />

    <rs-data-table
      class="accounts-data-table mb-4 px-2"
      :headers="tableHeaders"
      :items="users"
      :must-sort="true"
      :rows-per-page-items="[5, 10, 25]"
      :total-items="usersMeta.total_rows || 0"
      :pagination.sync="pagination"
    >
      <template slot="no-data">
        <rs-layout class="no-users-data" justify-center align-center>
          <rs-icon class="mr-2">people</rs-icon>
          No system users yet
        </rs-layout>
      </template>

      <template slot="items" slot-scope="props">
        <tr :class="{ 'table-row--pending': props.item.status == 'pending' }">
          <td>{{ props.item.first_name || '--' }}</td>
          <td>{{ props.item.last_name || '--' }}</td>
          <td>{{ props.item.email || '--' }}</td>
          <td>{{ props.item.title || '--' }}</td>
          <td>{{ props.item.hubspot_owner_id || '--' }}</td>
          <td>{{ props.item.phone || '--' }}</td>
          <td>{{ props.item.status || '--' }}</td>
          <td class="layout justify-end align-center">
            <rs-btn
              borderless
              fab
              small
              :block="false"
              class="ma-0"
              @click="
                () => {
                  selectedUser = props.item;
                  showUserModal = true;
                }
              "
              :data-test-id="`account-users-edit-account-button-${props.index}`"
            >
              <rs-icon size="25">rsfont-edit_v2</rs-icon>
            </rs-btn>
            <rs-btn
              borderless
              fab
              small
              :block="false"
              class="ma-0"
              @click="$emit('open-delete-user-dialog', props.item)"
              :data-test-id="`account-users-delete-account-button-${props.index}`"
            >
              <rs-icon size="25">rsfont-delete_v2</rs-icon>
            </rs-btn>
          </td>
        </tr>
      </template>
    </rs-data-table>

    <DialogUser
      :show-dialog="showUserModal"
      :user.sync="selectedUser"
      :account-id="accountId"
      @close="
        () => {
          showUserModal = false;
          selectedUser = {};
          getUsers();
        }
      "
      data-test-id="account-users-account-add-edit-dialog"
    />
  </rs-card>
</template>
<script>
import DialogUser from './DialogUser';

import loaderMixin from '@rscollabs/rs-core-library/src/mixins/rs-loader';

export default {
  name: 'AccountUsers',
  props: {
    accountId: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    userDeleted: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    DialogUser,
  },
  mixins: [loaderMixin],
  data() {
    return {
      users: [],
      usersMeta: {},
      selectedUser: {},
      showUserModal: false,
      tableHeaders: [
        { text: 'First Name', value: 'first_name' },
        { text: 'Last Name', value: 'last_name' },
        { text: 'Email', value: 'email' },
        { text: 'Title', value: 'title', sortable: false },
        { text: 'Hubspot Owner ID', value: 'hubspot_owner_id', sortable: false },
        { text: 'Phone', value: 'phone', sortable: false },
        { text: 'Status', value: 'status' },
        { text: 'Actions', align: 'right', sortable: false },
      ],
      pagination: {
        sortBy: 'first_name',
        descending: false,
        page: 1,
        rowsPerPage: 5,
      },
    };
  },
  watch: {
    pagination() {
      if (this.users.length) this.getUsers();
    },
    userDeleted(newVal) {
      this.getUsers();
    },
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    async getUsers() {
      const { data } = await this.loader(this.$identity.getUsers, [this.accountId, this.pagination]);
      this.users = data.users;
      this.usersMeta = data.meta;
    },
  },
};
</script>
<style lang="sass">
.table-row--pending
  opacity: .5

.no-users-data
  opacity: 0.35
  padding: 200px 0

button.v-btn[disabled]
  background-color: lightgrey
  color: lightgrey
  filter: invert(1)
  opacity: 0.6
</style>
