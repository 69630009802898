<template>
  <div class="advertiser-regions">
    <label>Regions</label>
    <template v-for="(item, index) in formattedRegions">
      <div class="advertiser-regions__item" :key="index" v-if="(index < 5 || showMore) && item.active">
        <rs-chip label disabled text-color="black">{{ item.name }}</rs-chip>
        <rs-chip small label color="primary" disabled text-color="white" v-for="(page, j) in item.pages" :key="j">
          <span>{{ page }}</span>
        </rs-chip>
      </div>
    </template>
    <div class="advertiser-regions__expand">
      <rs-button size="xs" @click.stop="showMore = !showMore" v-if="formattedRegions.length > 5">
        Show {{ showMore ? ' less' : `all` }} {{ showMore ? '' : formattedRegions.length }}
      </rs-button>
    </div>
  </div>
</template>

<script>
import { computed, inject, ref } from 'vue';
import RsButton from '../lib/components/RsButton/RsButton.vue';
export default {
  name: 'AdvertiserItemRegion',
  props: {
    regions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { slots }) {
    const showMore = ref(false);
    const formattedRegions = computed(() => {
      return props.regions
        .filter((item) => item.region && item.active)
        .map((item) => {
          const selected = regionsList.value.find((region) => item.region === region.abbreviation);
          return {
            ...item,
            name: selected?.name,
          };
        });
    });

    const hasPages = computed(() => {
      return props.regions.pages?.length > 0;
    });

    const regionsList = inject('regions');

    return { hasPages, regionsList, formattedRegions, showMore };
  },
  components: { RsButton },
};
</script>

<style lang="scss">
.advertiser-regions {
  label {
    line-height: 20px;
    font-size: 14px;
    padding-top: 8px;
    margin-bottom: 4px;
    white-space: nowrap;
    color: var(--text-2);
    text-align: left;
  }
  &__item {
    margin-bottom: 8px;
  }
  &__expand {
    display: flex;
    justify-content: center;
    position: relative;
  }
}
.v-chip__content {
  text-transform: inherit;
}
</style>
