/**
 * @file Influencer Account Attributes
 */

/**
 * @description offices are the keys used to determine sub-regions and sub-pods.
 */
export const OFFICES = Object.freeze(['AU', 'BR', 'CN', 'DE', 'US', 'ES', 'FR', 'IT', 'KR', 'NL', 'ROW', 'UK']);

export const SEGMENTS = Object.freeze(['Bronze', 'Silver', 'Gold', 'Diamond']);
export const PODS = Object.freeze(['Activation', 'Advance', 'Acceleration', 'Amplification']);

/**
 * @description Computes the sub-regions for a given office.
 * @param {string} office
 * @returns {string[]} list of sub-regions
 */
export function getSubRegionsFor(office) {
  const SUB_REGIONS = {
    CN: [
      'Chinese_residing_overseas',
      'Chinese_residing_in_China',
      'Korean_residing_in_Korea',
      'Korean_residing_overseas',
      'Taiwanese_residing_in_Taiwan',
      'Taiwanese_residing_overseas',
      'Other_asia',
      '',
    ],
    KR: [
      'Chinese_residing_overseas',
      'Chinese_residing_in_China',
      'Korean_residing_in_Korea',
      'Korean_residing_overseas',
      'Taiwanese_residing_in_Taiwan',
      'Taiwanese_residing_overseas',
      'Other_asia',
      '',
    ],
    UK: ['UK_and_IE', 'FR', 'AUS', 'DACH', ''],
    BR: ['Spanish', 'Portuguese', ''],
    US: [''],
  };

  return SUB_REGIONS[office] ?? [];
}

/**
 * @description Computes the sub-pods for a given office and sub-region.
 * @param {string} office
 * @param {string} subRegion
 * @returns {string[]} list of sub-pods
 */
export function getSubPodsFor(office, subRegion) {
  const SUB_PODS = {
    CN: [
      'Group A',
      'Group B',
      'Group C',
      'Group D',
      'Group E',
      'Group F',
      'Group J',
      'Group L',
      'Group N',
      'Group H',
      '',
    ],
    KR: [
      'Group A',
      'Group B',
      'Group C',
      'Group D',
      'Group E',
      'Group F',
      'Group J',
      'Group L',
      'Group N',
      'Group H',
      '',
    ],
    UK: [
      'Acceleration',
      'Grow',
      'Insta',
      'Non_LTK',
      'Non_LTK_acceleration',
      'LTK',
      'Group A',
      'Group B',
      'Group C',
      '',
    ],
    US: [''],
  };

  /**
   * @description brazilian sub-pods are broken down by sub-region values: Spanish and Portuguese.
   */
  const BRAZILIAN_SUB_PODS = {
    Spanish: ['Group A', 'Group B', ''],
    Portuguese: ['Group A', 'Group B', 'Group C', 'Brazilians_in_the_US', ''],
  };

  switch (office) {
    case 'BR':
      return BRAZILIAN_SUB_PODS[subRegion] ?? [];
    default:
      return SUB_PODS[office] ?? [];
  }
}
