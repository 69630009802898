<template>
  <rs-data-table
    :headers="headers"
    :items="content"
    :loading="loading"
    v-model="selected"
    :search="search"
    id="sortable-data-table"
    hide-actions
    :pagination.sync="pagination"
    disable-initial-sort
    select-all
  >
    <template #items="data">
      <tr :class="sortDisabled(data.item) || activeItem.disabled ? 'is--filtered' : ''" :key="data.item.id">
        <td class="sortable-data-table__actions">
          <rs-icon
            v-if="draggable"
            class="sortable-data-table__handle"
            :class="sortDisabled(data.item) || activeItem.disabled ? 'is--filtered' : ''"
            :key="data.item.id"
          >
            reorder
          </rs-icon>
          <rs-checkbox
            v-if="!data.item.reserved"
            :data-testid="`select-row-${data.item.id}`"
            v-model="data.selected"
            primary
            hide-details
            :disabled="activeItem.sorting"
          />
        </td>
        <td>{{ data.item.group_label }}</td>
        <td>{{ data.item.start_date }}</td>
        <td>{{ data.item.end_date }}</td>
        <td>
          <span :class="`status ${displayStatusStyle(data.item.status)}`">{{ data.item.status }}</span>
        </td>
        <td class="sortable-data-table__cell--right">
          <rs-button
            :id="`edit-button-${data.item.id}`"
            :data-testid="`edit-button-${data.item.id}`"
            @click="onEditClick(data.item.id)"
            flat
          >
            <rs-icon class="edit-button-icon">edit</rs-icon>
          </rs-button>
        </td>
      </tr>
    </template>
  </rs-data-table>
</template>

<script>
import { ref, reactive, defineComponent, nextTick, watch } from 'vue';
import { useSortable, moveArrayElement } from '@vueuse/integrations/useSortable';
import RsButton from '@/internal-advertisers/lib/components/RsButton/RsButton.vue';

export default defineComponent({
  name: 'SortableTable',
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    search: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    draggable: {
      type: Boolean,
      default: true,
    },
  },
  components: { RsButton },
  emits: { sorting: () => true, selected: () => true, edit: () => true },
  setup(props, { emit }) {
    const activeItem = reactive({
      id: null,
      initialIndex: null,
      currentIndex: null,
      nextId: null,
      prevId: null,
      sorting: false,
      disabled: false,
    });
    const content = ref([]);
    const selected = ref([]);

    watch(
      () => selected.value,
      (next) => {
        if (!next) return;
        activeItem.disabled = next.length > 0 ? true : false;
        emit('selected', next);
      },
    );

    const handleReset = () => {
      activeItem.id = null;
      activeItem.initialIndex = null;
      activeItem.currentIndex = null;
      activeItem.nextId = null;
      activeItem.prevId = null;
      activeItem.sorting = false;
      activeItem.disabled = false;
    };

    const reset = async () => {
      await moveArrayElement(content.value, activeItem.currentIndex, activeItem.initialIndex);
      handleReset();
    };

    watch(
      () => props.items,
      (next) => {
        if (next) {
          reset();
          content.value = next;
        }
      },
      {
        immediate: true,
      },
    );

    watch(
      () => activeItem.sorting,
      async (next) => {
        emit('sorting', next);
        if (next) {
          document.addEventListener('keydown', onKeyboard);
        } else {
          document.removeEventListener('keydown', onKeyboard);
        }
      },
    );

    const sortDisabled = (item) => {
      return (
        pagination.value.sortBy ||
        props.search ||
        (activeItem.id !== null && activeItem.id !== item.id && activeItem.initialIndex !== activeItem.currentIndex)
      );
    };

    const onKeyboard = (e) => {
      if (e.key === 'Escape') {
        reset();
      }
    };

    const onEditClick = (id) => {
      emit('edit', id);
    };

    const moveItem = async (list, prevIndex, nextIndex) => {
      moveArrayElement(list, prevIndex, nextIndex);
      await nextTick();
      const beforeItem = list[nextIndex + 1];
      const afterItem = list[nextIndex - 1];
      if (activeItem.initialIndex === nextIndex) {
        handleReset();
        return;
      }
      if (activeItem.initialIndex === null) {
        activeItem.initialIndex = prevIndex;
      }
      activeItem.id = content.value[nextIndex].id;
      activeItem.nextId = beforeItem ? beforeItem.id : null;
      activeItem.prevId = afterItem ? afterItem.id : null;
      activeItem.currentIndex = nextIndex;
      activeItem.sorting = activeItem.initialIndex !== nextIndex;
      emit('onMove', activeItem);
    };

    const { option } = useSortable('#sortable-data-table tbody', content, {
      handle: '.sortable-data-table__handle',
      filter: '.is--filtered',
      animation: 150,
      onUpdate: async (e) => {
        await moveItem(content.value, e.oldIndex, e.newIndex);
      },
    });

    const displayStatusStyle = (status) => {
      switch (status) {
        case 'Live in app':
          return 'status-prod';
        case 'QA':
          return 'status-qa';
        default:
          return 'status-disabled';
      }
    };

    const pagination = ref({
      rowsPerPage: -1,
      sortBy: '',
      totalItems: content.value.length,
    });

    return {
      selected,
      activeItem,
      content,
      reset,
      sortDisabled,
      onEditClick,
      moveItem,
      displayStatusStyle,
      pagination,
    };
  },
});
</script>

<style lang="scss" scoped>
#sortable-data-table {
  tr {
    &.is--filtered {
      .v-icon {
        color: #e0e0e0;
        cursor: not-allowed;
        &.edit-button-icon {
          color: initial;
          cursor: pointer;
        }
      }
    }
  }
}

.sortable-data-table {
  &__actions {
    display: flex;
    align-items: center;
  }
  &__handle {
    margin-right: 16px;
    margin-left: -16px;
    cursor: move;
  }
  &__cell {
    &--right {
      text-align: right;
    }
  }
}
.status {
  margin-bottom: 0;
  display: inline-block;
  padding: 2px 12px;
  border-radius: 30px;
  &-prod {
    background-color: #91e3c1;
    color: #0f4b32;
  }
  &-qa {
    background-color: #fed983;
    color: #664800;
  }
  &-disabled {
    background-color: #9e9e9e;
    color: white;
  }
}
</style>
