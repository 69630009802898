import { ref, computed, onMounted } from 'vue';

const useKeyboardFocus = (el) => {
  const currentElement = ref(null);
  let eventTarget = null;
  let hasEvents = false;
  let supportsPassiveEvent = { passive: false };

  const checkPassiveEventSupport = () => {
    try {
      const opts = Object.defineProperty({}, 'passive', {
        get() {
          supportsPassiveEvent = { passive: true };
        },
      });
      window.addEventListener('ghost', () => {}, opts);
    } catch (e) {
      console.error(e);
    }
  };

  const setKeyboardInteraction = (event) => {
    currentElement.value = event.target;
  };

  const setMouseAndTouchInteraction = () => {
    currentElement.value = null;
  };

  const createKeyboardEvents = () => {
    eventTarget.addEventListener('keyup', setKeyboardInteraction);
  };

  const createPointerEvents = () => {
    eventTarget.addEventListener('pointerup', setMouseAndTouchInteraction);
  };

  const createMouseAndTouchEvents = () => {
    eventTarget.addEventListener('mouseup', setMouseAndTouchInteraction);
    if ('ontouchend' in window) {
      eventTarget.addEventListener('touchend', setMouseAndTouchInteraction, supportsPassiveEvent);
    }
  };

  const bindEvents = () => {
    if (window.PointerEvent) {
      createPointerEvents();
    } else {
      createMouseAndTouchEvents();
    }

    createKeyboardEvents();
  };

  const createEvents = () => {
    if (!hasEvents) {
      eventTarget = document.body;
      checkPassiveEventSupport();
      bindEvents();
      hasEvents = true;
    }
  };

  const hasFocus = computed(() => {
    // For some reason this resolves to true while the app is mounting. Checking if all of the values are available appears to fix this.
    if (el.value && currentElement.value) {
      return el.value === currentElement.value || document.activeElement.focus === currentElement.value;
    } else {
      return false;
    }
  });

  onMounted(() => {
    createEvents();
  });

  return hasFocus;
};

export default useKeyboardFocus;
