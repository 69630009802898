<template>
  <rs-container class="advertisers" my-0>
    <div class="advertisers__header">
      <h1 class="header-1">Advertisers</h1>
      <rs-button v-if="hasWriteAccess" icon-only href="/links/advertisers/new" rounded>
        <rs-icon>add</rs-icon><rs-tip placement="left">Add Advertiser</rs-tip>
      </rs-button>
    </div>
    <advertiser-action-bar :init="$route.query" :regions="regions" @query="(e) => onSearch(e)" />
    <!-- Start Content -->
    <rs-layout>
      <rs-flex md12 v-if="advertisers.length > 0 && !state.hasError">
        <template v-for="item in advertisers">
          <advertiser-item v-if="item.name" v-bind="item" :to="item.to" :key="item.id" :read-only="!hasWriteAccess" />
        </template>
      </rs-flex>
      <rs-flex md12 v-else-if="advertisers.length === 0 && !state.hasError">
        <div class="error-panel">
          <div class="error-panel__content">
            <p class="header-3 my-2">0 Results found</p>
            <p class="text-1">{{ state.errorMessage }}</p>
          </div>
        </div>
      </rs-flex>
      <rs-flex md12 v-else>
        <div class="error-panel">
          <rs-icon>error_outline</rs-icon>
          <div class="error-panel__content">
            <p class="header-3 my-2">Something went wrong</p>
            <p class="text-1">{{ state.errorMessage }}</p>
          </div>
        </div>
      </rs-flex>
    </rs-layout>
    <div class="pagination">
      <div class="pagination__main">
        <rs-pagination v-model="state.page" :length="computedPageCount" @input="onPageChange" :total-visible="8" />
      </div>
      <div class="pagination__limit">
        <p class="text-1">
          <span class="mr-2">Show</span>
          <rs-select class="pagination__limit--field" :items="limits" v-model="state.limit" solo flat dense />
          <span class="ml-2">
            of
            <strong>
              {{ pagination.total }}
            </strong>
            results
          </span>
        </p>
      </div>
    </div>
  </rs-container>
</template>

<script>
import { defineComponent, onMounted, reactive, computed, provide, toRefs, toRef, watch } from 'vue';
import useAdvertiserApi from '../composables/useAdvertiserApi';
import AdvertiserActionBar from '../components/AdvertisterActionBar.vue';
import AdvertiserItem from '../components/AdvertiserItem.vue';
import RsButton from '../lib/components/RsButton/RsButton.vue';
import RsTip from '../lib/components/RsTip/RsTip.vue';
import shallowEqual from '../utils/shallow-equals';
import { useRouter, useRoute } from 'vue-router/composables';
import useAdvertiserRouterQuery from '../composables/useAdvertiserRouterQuery';
import { useAdvertiserRoleCheck } from '../composables/useAdvertiserRoleCheck';
export default defineComponent({
  name: 'Advertisers',
  setup() {
    const route = useRoute();
    const router = useRouter();

    const { getAdvertisers, getRegions } = useAdvertiserApi();
    const { hasWriteAccess } = useAdvertiserRoleCheck();
    const { page, limit } = useAdvertiserRouterQuery(route.query);

    const data = reactive({
      advertisers: [],
      regions: [],
      pagination: {},
    });

    const state = reactive({
      hasError: false,
      errorMessage: '',
      search: {},
      page: page.value || 1,
      limit: limit.value || 50,
    });

    const limits = [25, 50, 75, 100];
    const computedPageCount = computed(() => {
      const total = Math.round(data.pagination.total / state.limit);
      return total || 0;
    });

    const handleAdvertisers = (filter) => {
      getAdvertisers(filter)
        .then((response) => {
          data.advertisers = response.result;
          data.pagination = response.pagination;
        })
        .catch((error) => {
          if (error) {
            state.hasError = true;
            state.errorMessage = error;
            console.error(error);
          }
        });
    };

    const handleRegions = () => {
      getRegions().then((response) => {
        data.regions = response;
      });
    };

    const onSearch = (params) => {
      state.search = params;
      state.page = 1;
    };

    const onPageChange = () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto',
      });
      handleAdvertisers(searchParams.value);
    };

    const searchParams = computed(() => {
      return {
        ...state.search,
        page: encodeURIComponent(state.page),
        limit: encodeURIComponent(state.limit),
      };
    });

    watch(
      () => searchParams.value,
      (next) => {
        if (next) {
          const isUnchanged = shallowEqual(route.query, searchParams.value);
          handleAdvertisers(searchParams.value);
          if (!isUnchanged) {
            router.replace({
              path: '/links/advertisers',
              query: searchParams.value,
            });
          }
        }
      },
    );

    watch(
      () => route,
      (next) => {
        if (next) {
          handleAdvertisers(searchParams.value);
        }
      },
    );

    onMounted(() => {
      handleRegions();
      handleAdvertisers(searchParams.value);
    });

    provide('regions', toRef(data, 'regions'));

    return {
      ...toRefs(data),
      state,
      limits,
      searchParams,
      onSearch,
      onPageChange,
      computedPageCount,
      handleAdvertisers,
      hasWriteAccess,
    };
  },
  components: {
    AdvertiserItem,
    RsButton,
    RsTip,
    AdvertiserActionBar,
  },
});
</script>

<style lang="scss">
@use '../scss/colors';
@use '../scss/breakpoints';

.advertisers {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.error-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  &__content {
    padding-left: 16px;
  }
  .v-icon {
    font-size: 40px;
    color: var(--red);
  }
}

.v-input.v-select--chips {
  width: 100% !important;
  .v-chip__content {
    text-transform: inherit !important;
  }
  .v-input__icon {
    display: none;
  }
}

.pagination {
  padding: 16px 0 0;
  &__limit {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    p {
      width: 100%;
      text-align: center;
    }
    &--field {
      margin-top: 0;
      max-width: 100px;
      align-self: inherit;
      display: inline-flex;
      .v-label {
        top: 4px;
      }
      .v-text-field__details {
        display: none;
      }
      .v-input__slot {
        margin: 0;
        padding: 0;
        &:before {
          display: none !important;
        }
        &:after {
          display: none !important;
        }
      }
    }
  }
  &__main {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
  }
}
</style>
