// formatDate - returns Date string formatted mm/dd/yyyy
const formatDate = (date) => {
  const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0');
  };
  return [padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate()), date.getFullYear()].join('/');
};

// formatTitle - capitalize the first letter of every word
const formatTitle = (str) => {
  return str.replace(/(^|\s)\S/g, function (t) {
    return t.toUpperCase();
  });
};

export { formatDate, formatTitle };
