<template>
  <div :class="['advertiser__item', computedClasses]">
    <div class="advertiser__content">
      <div
        class="advertiser__body"
        ref="advertiserCard"
        @click="onNavigate"
        @keydown.enter="onNavigate"
        :tabindex="readOnly ? -1 : 0"
      >
        <dl class="advertiser__item-header">
          <dt class="advertiser__name">
            {{ name && !isLoading ? name : '--' }}
          </dt>
          <dd class="advertiser__alias">
            {{ display && !isLoading ? display : '--' }}
          </dd>
        </dl>
        <field-metric label="Affiliate ID" :value="affiliate_id" />
        <field-metric label="Advertiser ID" :value="id" />
        <field-metric label="Division ID" :value="division" />
        <field-metric label="Parent ID" :value="parent_id" />
        <field-metric
          label="Commission"
          :value="parsedCommission"
          :color="active && commission_rate < 10 ? 'red' : ''"
        />
        <field-metric class="field-metric--active" label="Status" :value="activeStatus" :color="activeColor" />
      </div>
      <rs-button class="advertiser__action" size="sm" icon-only rounded @click="toggleExpanded">
        <rs-icon>{{ expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</rs-icon>
      </rs-button>
    </div>
    <transition name="fade">
      <div class="advertiser__extended" v-if="expanded">
        <rs-divider />
        <div class="advertiser__section">
          <field-metric label="Median Lead Time" align="flex-start" :value="getMedianLeadTime" />
          <field-metric label="Cookie Window" :value="getCookieWindow" align="flex-start" />
          <field-metric label="Source" :value="source_description" />
          <field-metric label="Deeplink ID" :value="deeplink_advertiser" align="flex-start" />
        </div>
        <rs-divider />
        <div class="advertiser__section">
          <field-metric label="Commission Freeze" align="flex-start" inline>
            <template #value>
              <rs-icon :color="commission_freeze ? 'success' : ''">
                {{ commission_freeze ? 'check_circle_outline' : 'highlight_off' }}
              </rs-icon>
            </template>
          </field-metric>
          <field-metric label="Deeplink Freeze" align="flex-start" inline>
            <template #value>
              <rs-icon :color="deeplink_freeze ? 'success' : ''">
                {{ deeplink_freeze ? 'check_circle_outline' : 'highlight_off' }}
              </rs-icon>
            </template>
          </field-metric>
          <field-metric label="Freeze State" align="flex-start" inline>
            <template #value>
              <rs-icon :color="freeze_state ? 'success' : ''">
                {{ freeze_state ? 'check_circle_outline' : 'highlight_off' }}
              </rs-icon>
            </template>
          </field-metric>
        </div>

        <rs-divider />
        <div class="advertiser__section">
          <field-metric label="Product Feed" align="flex-start" inline>
            <template #value>
              <rs-icon :color="product_feed ? 'success' : ''">
                {{ product_feed ? 'check_circle_outline' : 'highlight_off' }}
              </rs-icon>
            </template>
          </field-metric>

          <field-metric label="Link Ninja Eligible" align="flex-start" inline>
            <template #value>
              <rs-icon :color="link_ninja_enabled ? 'success' : ''">
                {{ link_ninja_enabled ? 'check_circle_outline' : 'highlight_off' }}
              </rs-icon>
            </template>
          </field-metric>

          <field-metric label="Mobile Enabled" align="flex-start" inline>
            <template #value>
              <rs-icon :color="mobile_eligible ? 'success' : ''">
                {{ mobile_eligible ? 'check_circle_outline' : 'highlight_off' }}
              </rs-icon>
            </template>
          </field-metric>
        </div>

        <rs-divider v-if="attributes" />

        <div class="advertiser__section" v-if="attributes">
          <field-metric label="In App Tracking" align="flex-start" inline>
            <template #value>
              <rs-icon :color="attributes.in_app_tracking ? 'success' : ''">
                {{ attributes.in_app_tracking ? 'check_circle_outline' : 'highlight_off' }}
              </rs-icon>
            </template>
          </field-metric>
          <field-metric label="Mobile App Shoppable" align="flex-start" inline>
            <template #value>
              <rs-icon :color="attributes.mobile_app_shoppable ? 'success' : ''">
                {{ attributes.mobile_app_shoppable ? 'check_circle_outline' : 'highlight_off' }}
              </rs-icon>
            </template>
          </field-metric>
        </div>

        <rs-divider />
        <div class="advertiser__section">
          <advertiser-item-region :regions="regions" />
        </div>

        <rs-divider />
        <div class="advertiser__section">
          <field-metric class="regions-metric" label="Other Homepages" inline>
            <template #value>
              <template v-for="(item, index) in otherHomepages">
                <rs-chip small label :key="index" color="primary" text-color="white" disabled v-if="item">
                  {{ item }}
                </rs-chip>
              </template>
            </template>
          </field-metric>
        </div>
        <rs-divider />
        <div class="advertiser__section">
          <field-metric class="regions-metric" label="Quicklink" inline @click="onCopy">
            <template #value>
              <small>
                {{ sharableLink }}
              </small>
              <rs-button icon-only size="xs" @click="onCopy">
                <rs-icon size="small">file_copy</rs-icon>
                <rs-tip :active.sync="copyTooltip.show" placement="right" :offset="[0, 4]" :auto-open="false">
                  {{ copyTooltip.text }}
                </rs-tip>
              </rs-button>
            </template>
          </field-metric>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref, reactive, nextTick } from 'vue';
import useKeyboardFocus from '../lib/composables/useKeyboardFocus';
import RsButton from '../lib/components/RsButton/RsButton.vue';
import FieldMetric from '../components/FieldMetric.vue';
import AdvertiserItemRegion from './AdvertiserItemRegion.vue';
import RsTip from '../lib/components/RsTip/RsTip.vue';
import { convertToDays } from '../utils/convert-to-days';
import { useRoute, useRouter } from 'vue-router/composables';

export default defineComponent({
  name: 'AdvertiserItem',
  props: {
    name: {
      type: String,
      default: undefined,
    },
    display: {
      type: String,
      default: undefined,
    },
    id: {
      type: [String, Number],
    },
    affiliate_id: {
      type: [String, Number],
    },
    parent_id: {
      type: [String, Number],
      default: 0,
    },
    deeplink_advertiser: {
      type: String,
    },
    deeplink_freeze: {
      type: Boolean,
    },
    division: {
      type: Number,
    },
    source: {
      type: Number,
    },
    source_description: {
      type: String,
    },
    commission_rate: {
      type: Number,
      default: 0,
    },
    commission_freeze: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: '',
    },
    regions: {
      type: Array,
      default: () => [],
    },
    link_ninja_enabled: {
      type: Boolean,
      default: false,
    },
    attributes: {
      type: Object,
      default: () => ({}),
    },
    mobile_eligible: {
      type: Boolean,
      default: false,
    },
    freeze_state: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    product_feed: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();

    const advertiserCard = ref(null);
    const isFocused = useKeyboardFocus(advertiserCard);
    const expanded = ref(false);
    const otherHomepages = ref([]);
    const activeStatus = computed(() => {
      return props.active ? 'Active' : 'Inactive';
    });

    const activeColor = computed(() => {
      if (props.active && props.commission_rate <= 9) {
        return 'red';
      } else if (props.active && props.commission_rate >= 10) {
        return 'green';
      } else {
        return 'gray';
      }
    });

    const computedClasses = computed(() => {
      return {
        'is--focused': isFocused.value,
        'is--read-only': props.readOnly,
      };
    });

    const parsedCommission = computed(() => {
      return `${props.commission_rate}%`;
    });

    const toggleExpanded = () => {
      expanded.value = !expanded.value;
    };

    const onNavigate = () => {
      !props.readOnly ? router.push(props.to) : toggleExpanded();
    };

    const getOtherHomepages = () => {
      const whitelist = props.regions.filter((item) => item.region === null);
      otherHomepages.value = whitelist[0].pages;
    };

    const regionCount = computed(() => {
      const selectedRegions = props.regions.filter((item) => !!item.region);
      if (selectedRegions.length > 6) {
        return selectedRegions.length - 6;
      }
      return undefined;
    });

    const sharableLink = computed(() => {
      const url = window.location.origin;
      return `${url}${route.path}/?id=${props.id}`;
    });

    const copyTooltip = reactive({
      text: 'Copied!',
      show: false,
    });

    const copyToClipboard = (value) => {
      navigator.clipboard.writeText(value);
    };

    const onCopy = () => {
      copyTooltip.show = true;
      copyToClipboard(sharableLink.value);
      setTimeout(async () => {
        await nextTick();
        copyTooltip.show = false;
      }, 1500);
    };

    const getCookieWindow = computed(() => {
      if (props.attributes && props.attributes.cookie_window) {
        return `${props.attributes.cookie_window} days`;
      }
      return '--';
    });

    const getMedianLeadTime = computed(() => {
      if (props.attributes && props.attributes.median_purchase_leadtime) {
        return convertToDays(props.attributes.median_purchase_leadtime);
      }
      return '--';
    });

    onMounted(() => {
      if (route.query.id && route.query.id === decodeURIComponent(props.id)) {
        expanded.value = true;
      }
      getOtherHomepages();
    });

    return {
      computedClasses,
      activeStatus,
      activeColor,
      parsedCommission,
      advertiserCard,
      regionCount,
      expanded,
      otherHomepages,
      sharableLink,
      copyTooltip,
      onCopy,
      toggleExpanded,
      onNavigate,
      getMedianLeadTime,
      getCookieWindow,
    };
  },
  components: { FieldMetric, RsButton, AdvertiserItemRegion, RsTip },
});
</script>

<style lang="scss">
@use '../scss/breakpoints';
@use '../scss/typography';

.advertiser {
  &__action {
    position: absolute;
    right: 8px;
    top: 16px;
    @include breakpoints.up('md') {
      top: 12px;
    }
  }
  &__item {
    background-color: var(--bg);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--bg-3);
    margin-bottom: 4px;
    position: relative;
    &.is--read-only {
      .advertiser__content {
        &:hover {
          cursor: initial;
        }
      }
    }
    &.is--focused {
      background-color: var(--bg-1);
      box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.16) !important;
      border: 1px solid rgba(0, 0, 0, 0.25) !important;
    }
    &:hover:not(.is--read-only) {
      background-color: var(--bg-1);
      border: 1px solid rgba(0, 0, 0, 0.25) !important;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
      .advertiser__item--end {
        @include breakpoints.down('sm') {
          border-top: 1px solid var(--white);
        }
      }
    }
  }

  &__content {
    width: 100%;
    padding: 0 0 0 16px;
  }

  &__section {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding: 8px 0;
    column-gap: 16px;
    & > .field-metric {
      grid-column: span 3;
      @include breakpoints.down('sm') {
        grid-column: 4 span;
      }
      @include breakpoints.down('xs') {
        grid-column: 6 span;
      }
    }
    .advertiser-regions {
      grid-column: 12 span;
    }
    .regions-metric {
      grid-column: 9 span;
    }
  }

  &__body {
    padding: 0;
    transition: all 0.16s ease;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 16px;
    @include breakpoints.up('md') {
      margin-right: 50px;
    }
    &:focus {
      outline: none;
    }
    &:hover {
      cursor: pointer;
    }
    .field-metric {
      grid-column: 1 span;
      @include breakpoints.up('md') {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 90px;
      }
      @include breakpoints.down('sm') {
        grid-column: 2 span;
      }
      @include breakpoints.down('xs') {
        grid-column: span 3;
        &--active {
          order: -1;
          grid-column: span 4;
          padding-bottom: 8px;
          padding-top: 8px;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: -16px;
            right: 0;
            height: 1px;
            background-color: rgba(0, 0, 0, 0.12);
          }
        }
      }
    }
  }

  &__extended {
    padding-left: 16px;
  }
  &__item-header {
    grid-column: span 6;
    order: -1;
    position: relative;
    @include breakpoints.down('sm') {
      grid-column: span 12;
      padding-bottom: 8px;
      padding-top: 8px;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
    @include breakpoints.down('xs') {
      grid-column: 8 span;
    }
  }
  &__name {
    margin-top: 8px;
    margin-bottom: 4px;
    color: var(--text-1);
    @extend .text-1;
  }

  &__alias {
    color: var(--text-2);
    margin-bottom: 4px;
    @extend .text-2;
  }

  &__status {
    display: block;
    min-width: 8ch;
    &.is {
      &--inactive {
        color: var(--red-3);
      }
      &--active {
        color: var(--green-3);
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-leave-to {
  opacity: 0;
}
</style>
