<template>
  <div class="quality-control-compare-product-container">
    <div v-if="isSourceProduct" class="quality-control-compare-product-header-source">Source</div>
    <div v-else class="quality-control-compare-product-header-alt">Alternative</div>
    <div class="quality-control-compare-product-images">
      <rs-carousel v-model="carouselModel" hide-delimiters hide-controls :cycle="false" height="400" max="400">
        <rs-carousel-item v-for="(imageUrl, i) in imageUrls" :key="i">
          <rs-img :src="imageUrl" height="400" aspect-ratio="1" contain />
        </rs-carousel-item>
        <rs-button
          v-if="isPrevAvailable"
          class="quality-control-compare-product-images-button is--prev"
          icon-only
          rounded
          @click="onClickPrev"
        >
          <rs-icon size="16">ltkfont-left-chevron-outline</rs-icon>
        </rs-button>
        <rs-button
          v-if="isNextAvailable"
          class="quality-control-compare-product-images-button is--next"
          icon-only
          rounded
          @click="onClickNext"
        >
          <rs-icon size="16">ltkfont-right-chevron-outline</rs-icon>
        </rs-button>
      </rs-carousel>
    </div>
    <div class="quality-control-compare-product-row-bold">{{ productName }}</div>
    <div v-if="productDescription" class="quality-control-compare-product-row">{{ productDescription }}</div>
    <div v-if="productDetails" class="quality-control-compare-product-row">{{ productDetails }}</div>
    <div class="quality-control-compare-product-row">{{ productPrice }}</div>
    <div class="quality-control-compare-product-row">{{ productBrand }}</div>
    <div class="quality-control-compare-product-row">{{ productRetailer }}</div>
    <div class="quality-control-compare-product-row-link">
      <a class="table-data-link mt-auto" :href="productUrl" target="_blank" @click.stop>
        {{ productUrl }}
      </a>
    </div>
  </div>
</template>

<script>
import { formatCurrencyExt } from '@/utils/helpers';
import { computed, defineComponent, ref } from 'vue';

import useStore from '@/composables/vuex';
import RsButton from '@/internal-advertisers/lib/components/RsButton/RsButton.vue';

export default defineComponent({
  name: 'QualityControlCompareProduct',
  components: {
    RsButton,
  },
  props: {
    workItemId: String,
    type: String,
    product: Object,
    matchStatus: String,
    matchNotes: String,
  },
  setup(props) {
    const carouselModel = ref(0);
    const isSourceProduct = computed(() => props.type === 'source');

    const imageUrls = computed(() => props.product.imageUrls);
    const productName = computed(() => props.product.title);
    const productUrl = computed(() => props.product.productUrl);
    const brandName = computed(() => props.product.brand);
    const productDescription = computed(() => props.product.description);
    const productDetails = computed(() => props.product.details);
    const productPrice = computed(() => formatCurrencyExt(props.product.price));
    const productBrand = computed(() => props.product.brand);
    const productRetailer = computed(() => props.product.retailer);

    const isPrevAvailable = computed(() => carouselModel.value > 0);
    const isNextAvailable = computed(() => carouselModel.value < imageUrls.value.length - 1);

    const onClickPrev = () => {
      if (isPrevAvailable.value) {
        carouselModel.value -= 1;
      }
    };

    const onClickNext = () => {
      if (isNextAvailable.value) {
        carouselModel.value += 1;
      }
    };

    return {
      carouselModel,
      isSourceProduct,
      imageUrls,
      productName,
      productUrl,
      brandName,
      productDescription,
      productDetails,
      productPrice,
      productBrand,
      productRetailer,
      isPrevAvailable,
      isNextAvailable,
      onClickPrev,
      onClickNext,
    };
  },
});
</script>

<style lang="scss" scoped>
.quality-control-compare-product {
  &-container {
    flex: 1;
    border: 1px solid #cccccc;
    border-radius: 12px;
    overflow: hidden;
    height: max-content;
  }

  &-header {
    &-source {
      background: #000000;
      color: #ffffff;
    }

    &-alt {
      background: #fafafa;
      color: #000000;
    }

    &-source,
    &-alt {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      text-transform: uppercase;
      font-weight: 700;
      border-bottom: 1px solid #cccccc;
    }
  }

  &-images {
    position: relative;
    height: 400px;
    overflow: hidden;

    &-button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 40px;
      width: 40px;
      box-shadow: 0px 3px 12px 0px #0000001a;

      background-color: #f0f0f0;
      color: #525252;
      border-color: #f0f0f0;

      i {
        color: #525252;
      }

      &:hover,
      &:focus,
      &.is--active {
        background-color: #525252;
        color: white;
        border-color: #525252;

        i {
          color: white;
        }
      }

      &.is--prev {
        left: 16px;
      }
      &.is--next {
        right: 16px;
      }
    }
  }

  &-row,
  &-row-bold,
  &-row-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 10px;
    border-top: 1px solid #cccccc;
    text-align: center;
  }

  &-row-bold {
    font-weight: 700;
  }

  &-row-link > a {
    width: 100%;
    color: #000000;
    text-decoration: none;
    word-break: break-all;
  }
}
</style>
