import axios from 'axios';
import Cookie from 'js-cookie';
import { LOCAL_STORAGE_KEYS } from '@rscollabs/rs-core-library/src/services/auth0';
import { initUnauthorizedResponseInterceptor } from '@rscollabs/rs-core-library/src/services/interceptors';

const { API_GATEWAY } = window.rewardStyle;

// TODO: Migrate to global client.js util at @/utils/client.js
export default (basePath = '', host = API_GATEWAY) => {
  const baseURL = `${host}${basePath}`;
  const Authorization = `Bearer ${Cookie.get(LOCAL_STORAGE_KEYS.AUTH0_CODE)}`;
  const headers = { Authorization };
  const client = axios.create({ baseURL, headers });

  // Add a 401 response interceptor
  initUnauthorizedResponseInterceptor(client);

  return client;
};
