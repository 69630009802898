<template>
  <div class="new-parent">
    <rs-form v-model="rules.valid">
      <rs-layout>
        <rs-flex>
          <rs-text-field
            label="Parent Name"
            v-model="selected.parent.name"
            persistent-hint
            hint="Required"
            :rules="[rules.required, rules.duplicate]"
            required
          />
          <rs-text-field label="Division Name" v-model="selected.division[0].name" />
        </rs-flex>
      </rs-layout>
    </rs-form>
  </div>
</template>

<script>
import { defineComponent, reactive, watch, computed } from 'vue';

export default defineComponent({
  name: 'DivisionsNewParent',
  props: {
    parents: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const selected = reactive({
      parent: {
        name: '',
        id: null,
      },
      division: [
        {
          name: '',
          id: null,
        },
      ],
    });

    const rules = reactive({
      valid: false,
      required: (value) => !!value || 'Required',
      duplicate: (value) => !props.parents.map((item) => item.name).includes(value) || 'Parent already exists',
    });

    const payload = computed(() => {
      return {
        parent: selected.parent.name,
        division: selected.division.map((item) => item.name),
      };
    });

    watch(
      () => rules.valid,
      () => {
        emit('valid', rules.valid);
      },
    );

    watch(
      () => payload.value,
      (next) => {
        emit('saved', next);
        emit('valid', rules.valid);
      },
      {
        immediate: true,
      },
    );

    return {
      selected,
      rules,
    };
  },
});
</script>

<style lang="scss"></style>
