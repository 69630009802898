<template>
  <rs-container class="campaigns">
    <rs-card class="px-4 pt-5">
      <rs-card-title class="mb-5 pa-0">
        <h3 class="display-2 font-weight-medium ma-0">Campaigns</h3>
      </rs-card-title>

      <rs-card-text class="pa-0">
        <rs-layout row justify-space-between>
          <rs-flex mx-1 xs12 md6 lg4 xl3>
            <rs-text-field
              label="Search Campaigns"
              append-icon="search"
              placeholder="Search by Campaign Name"
              v-model="searchQuery"
              @keyup.enter.native="fetchCampaignsByQuery"
              @click:append="fetchCampaignsByQuery"
            />
          </rs-flex>
        </rs-layout>
        <rs-data-table
          class="campaigns-data-table mb-4 text-xs-left"
          :loading="isLoading"
          :headers="headers"
          :items="campaigns"
          :total-items="totalRows"
          :must-sort="true"
          :rows-per-page-items="[5, 10, 25, 50]"
          :pagination.sync="pagination"
        >
          <template slot="items" slot-scope="props">
            <td class="shortRow">{{ props.item.id }}</td>
            <td>{{ props.item.title }}</td>
            <td class="dates-row">
              {{ props.item.display_campaign_start_date }} - {{ props.item.display_campaign_end_date }}
            </td>
            <td>{{ getBrandName(props.item) }}</td>
            <td class="dates-row" @click="openModal(props.item.id)">
              <rs-layout row nowrap>
                <rs-icon class="pr-2 edit" size="15">rsfont-edit_v2</rs-icon>
                {{ props.item.display_campaign_reporting_start_date }} -
                {{ props.item.display_campaign_reporting_end_date }}
              </rs-layout>
            </td>
            <td data-test-id="campaign-insights-td">
              <rs-btn
                flat
                :block="true"
                @click="openCampaignInsights(props.item.id)"
                data-test-id="qa-campaign-insights-edit-button"
                class="extra-small-button"
              >
                EDIT
              </rs-btn>
            </td>
          </template>
        </rs-data-table>
      </rs-card-text>
    </rs-card>

    <rs-dialog
      v-model="showCampaignInsightsEditModal"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="656px"
    >
      <campaign-insights
        v-if="selectedCampaignId > 0"
        :campaign="selectedCampaign"
        :is-open="showCampaignInsightsEditModal"
        @close="closeCampaignInsights"
        :key="resetCampaignInsightsForm"
      />
    </rs-dialog>

    <rs-dialog v-model="showModal" max-width="615px">
      <reporting-date-picker v-if="selectedCampaignId > 0" :campaign="selectedCampaign" @save="saveReportingDate" />
    </rs-dialog>
  </rs-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import CampaignInsights from '../components/CampaignInsights.vue';
import ReportingDatePicker from '../components/ReportingDatePicker.vue';

export default {
  name: 'Campaigns',
  components: {
    ReportingDatePicker,
    CampaignInsights,
  },
  data() {
    return {
      headers: [
        { text: 'Campaign ID', value: 'id', sortable: false },
        { text: 'Campaign Name', value: 'title' },
        { text: 'Campaign Date Range', value: 'start_date' },
        { text: 'Brand Name', sortable: false },
        { text: 'Reporting Window', sortable: false },
        { text: 'Insights', sortable: false },
      ],
      pagination: {
        sortBy: 'title',
        descending: false,
        page: 1,
        rowsPerPage: 50,
      },
      totalRows: 0,
      searchQuery: '',
      filterBy: {
        options: ['id', 'title'],
        selected: '',
      },
      showModal: false,
      currentEditCampaign: null,
      selectedCampaignId: 0,
      showCampaignInsightsEditModal: false,
      resetCampaignInsightsForm: 0,
    };
  },
  computed: {
    ...mapGetters('campaigns', ['getCampaignsByQuery', 'getFetchMetaForQuery']),
    ...mapState('campaigns', {
      campaignsListMeta: 'campaignsListMeta',
    }),
    campaignsData() {
      return this.getCampaignsByQuery(this.query);
    },
    isLoading() {
      return !this.campaignsData.meta?.hasFetched;
    },
    campaigns() {
      return this.campaignsData.campaigns?.map((campaign) => {
        const reportingDates = this.getReportingDisplayDates(campaign);
        return {
          ...campaign,
          display_campaign_start_date: this.formatDate(campaign.start_date),
          display_campaign_end_date: this.formatDate(campaign.end_date),
          display_campaign_reporting_start_date: reportingDates.startDate,
          display_campaign_reporting_end_date: reportingDates.endDate,
        };
      });
    },
    selectedCampaign() {
      if (this.selectedCampaignId === 0) return {};
      return this.campaigns.find((campaign) => campaign.id === this.selectedCampaignId);
    },
    query() {
      const dir = this.pagination.descending ? 'desc' : 'asc';
      const queryObj = {
        include: ['account'],
        scope: 'all',
        state: 'all',
        // Pagination
        ...this.pagination,
        offset: (this.pagination.page - 1) * this.pagination.rowsPerPage,
        page_size: this.pagination.rowsPerPage,
        sort: `${this.pagination.sortBy}:${dir}`,
      };

      // search filter
      if (this.filterBy.selected) {
        queryObj.search_term = this.filterBy.selected;
      }

      // Submited URL filter query
      if (this.searchQuery) {
        queryObj.search_term = encodeURI(`%${this.searchQuery}%`);
      }

      return queryObj;
    },
    prevPagination() {
      const metaForQuery = this.getFetchMetaForQuery(this.query);
      return metaForQuery ? metaForQuery.prev : null;
    },
    nextPagination() {
      const metaForQuery = this.getFetchMetaForQuery(this.query);
      return metaForQuery ? metaForQuery.next : null;
    },
    currentPagination() {
      const metaForQuery = this.getFetchMetaForQuery(this.query);
      return metaForQuery ? metaForQuery.current : null;
    },
  },
  async mounted() {
    await this.fetchCampaignsByQuery();
  },
  methods: {
    ...mapActions('campaigns', ['getCampaignsV2', 'editCampaign']),
    async fetchCampaignsByQuery() {
      await this.getCampaignsV2({ params: this.query });
    },
    getBrandName(item) {
      let accountName = item.account ? item.account.name : '';
      let brandName = item.brand ? item.brand.name : '';
      return accountName ? accountName : brandName;
    },
    normalizeDate(value) {
      const date = new Date(value);
      return new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000));
    },
    formatDate(date) {
      return this.normalizeDate(date).toLocaleDateString('en-US');
    },
    getReportingDisplayDates(campaign) {
      const { start_date, reporting_start_date, end_date, reporting_end_date } = campaign;
      const startDateString = reporting_start_date ? reporting_start_date : start_date;
      const endDateString = reporting_end_date ? reporting_end_date : end_date;

      const reportingStartDate = this.normalizeDate(startDateString);
      const reportingEndDate = this.normalizeDate(endDateString);

      // if no reporting date is set, set the default to 6 weeks after end of campaign
      if (!reporting_end_date) {
        reportingEndDate.setDate(reportingEndDate.getDate() + 6 * 7);
      }

      return {
        startDate: this.formatDate(reportingStartDate),
        endDate: this.formatDate(reportingEndDate),
      };
    },
    async saveReportingDate(payload) {
      this.closeModal();
      await this.editCampaign(payload);
    },
    openModal(campaignId) {
      this.selectedCampaignId = campaignId;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedCampaignId = 0;
    },
    openCampaignInsights(campaignId) {
      this.selectedCampaignId = campaignId;
      this.showCampaignInsightsEditModal = true;
    },
    closeCampaignInsights() {
      this.showCampaignInsightsEditModal = false;
      this.resetCampaignInsightsForm++;
    },
  },
  watch: {
    showModal(newVal) {
      if (!newVal) {
        this.selectedCampaignId = 0;
      }
    },
    pagination(pagination, oldPagination) {
      if (JSON.stringify(pagination) !== JSON.stringify(oldPagination)) {
        this.fetchCampaignsByQuery();
      }
    },
    currentPagination(val) {
      if (val) {
        this.totalRows = val.total;
      }
    },
  },
};
</script>
<style lang="sass">
@media only screen and (min-width: 1904px)
  .container
    min-width: 1680px
    max-width: fit-content !important
.campaigns
  width: 100%
  width: -moz-available
  width: -webkit-fill-available
.edit
  display: inline-block
  padding: 0.3em 0.3em
  transform: translate(-2px, -2px)
  &:hover
    border-radius: 60px
    background-color: lightgrey
    cursor: pointer
.row-link
  cursor: pointer
.campaigns-data-table
  th
    white-space: nowrap
  th:first-child
    max-width: 100px
  th:nth-child(5), th:nth-child(6)
    max-width: 120px
    white-space: normal !important
  td
    max-width: 250px
    text-overflow: ellipsis
    overflow-wrap: break-word
    font-size: 12px
  td:nth-child(2)
    white-space: nowrap
    max-width: 350px
    overflow: hidden
    text-overflow: ellipsis
.shortRow
  max-width: 100px
.dates-row
  white-space: nowrap
  overflow: hidden
  max-width: max-content !important
.view-icon
  color: #404040
  display: inline-block
  padding: 0.25em 0.3em
  &:hover
    border-radius: 60px
    background-color: lightgrey
.v-input__control
  display: flex
  justify-content: center
  align-items: center
.extra-small-button
  padding-left: 8px
  padding-right: 8px
  width: 53px
  min-width: 53px
  height: 32px
</style>
