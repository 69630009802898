<template>
  <rs-container fluid fill-height>
    <rs-layout column>
      <rs-flex>
        <h2 class="display-3">{{ $route.meta.title }}</h2>
        <rs-breadcrumbs class="px-0" :items="$route.matched" divider=">">
          <rs-breadcrumbs-item
            slot="item"
            slot-scope="{ item }"
            :exact="true"
            :to="{ name: item.name, params: $route.params }"
          >
            {{ item.meta.title }}
          </rs-breadcrumbs-item>
        </rs-breadcrumbs>
      </rs-flex>

      <rs-flex xs12 mt-4>
        <rs-card>
          <rs-card-title>
            <h2 class="ma-0">Bubbles</h2>
            <rs-spacer />
            <rs-btn :flat="false" :outline="false" :block="false" color="rs-success white--text">
              <rs-icon class="mr-1">ltkfont-plus-outline</rs-icon>
              Create New Bubble
            </rs-btn>
          </rs-card-title>
          <rs-card-text>
            <rs-btn
              :flat="false"
              :outline="false"
              :block="false"
              color="rs-error white--text"
              small
              :disabled="isDeleteDisabled"
            >
              <rs-icon class="mr-1">ltkfont-delete-outline_1</rs-icon>
              Delete ({{ selectedRows.length }})
            </rs-btn>

            <bubble-table v-model="selectedRows" @click-row="onEditClick($event)" />
          </rs-card-text>
        </rs-card>
      </rs-flex>
    </rs-layout>
  </rs-container>
</template>

<script>
import { defineComponent, onBeforeUnmount, ref, computed } from 'vue';
import useStore from '@/composables/vuex';

import BubbleTable from '../components/BubbleTable.vue';
import bubblesStore from '../store/bubbles';

export default defineComponent({
  components: { BubbleTable },
  setup(_props, _context) {
    const store = useStore();

    store.registerModule('ltk-bubbles', bubblesStore);

    onBeforeUnmount(() => {
      store.unregisterModule('ltk-bubbles');
    });

    const selectedRows = ref([]);
    const isDeleteDisabled = computed(() => selectedRows.value.length === 0);

    function onEditClick(saleId) {
      // TODO: Add edit and edit confirmation modals.
    }

    function onDeleteClick() {
      // TODO: Add delete confirmation modals.
    }

    return {
      selectedRows,
      isDeleteDisabled,
      onEditClick,
      onDeleteClick,
    };
  },
});
</script>
