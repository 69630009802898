var render = function render(){var _vm=this,_c=_vm._self._c;return _c('rs-dialog',{attrs:{"value":_vm.isOpen,"max-width":"650px","persistent":""},on:{"input":function($event){return _vm.handleClose()}}},[_c('rs-card',{attrs:{"width":"100%"}},[_c('rs-layout',{attrs:{"column":""}},[_c('rs-flex',{attrs:{"xs12":"","mx-4":""}},[_c('rs-layout',{attrs:{"row":"","justify-space-between":""}},[_c('rs-flex',{attrs:{"display-2":"","my-4":"","data-test-id":"qa-delete-creator-header"}},[_vm._v(" Remove "+_vm._s(_vm.accountName)+" from following lists: ")]),_c('rs-icon',{attrs:{"size":"23"},on:{"click":function($event){return _vm.handleClose()}}},[_vm._v(" rsfont-close_v2 ")])],1)],1),_c('rs-divider'),(!_vm.isLoading)?_c('rs-flex',{attrs:{"column":"","px-4":""}},_vm._l((_vm.accountLists),function(list){return _c('rs-layout',{key:list.name,staticClass:"py-4 justify-space-between list-item",attrs:{"data-test-id":"qa-creator-list-item"}},[_c('span',{staticClass:"body-2 font-weight-bold"},[_vm._v(_vm._s(_vm.listDetails[list.name].title))]),_c('rs-checkbox',{staticClass:"shrink",attrs:{"dense":"","hide-details":""},model:{value:(_vm.selections[list.name]),callback:function ($$v) {_vm.$set(_vm.selections, list.name, $$v)},expression:"selections[list.name]"}})],1)}),1):_vm._e(),_c('rs-flex',{attrs:{"xs12":"","ma-4":""}},[_c('rs-layout',{attrs:{"row":"","wrap":"","justify-end":""}},[_c('rs-flex',{class:{
              xs3: _vm.$vuetify.breakpoint.smAndUp,
              xs12: _vm.$vuetify.breakpoint.xs,
            }},[_c('rs-btn',{staticClass:"ma-0",on:{"click":function($event){return _vm.handleClose()}}},[_vm._v("Cancel")])],1),_c('rs-flex',{class:{
              xs3: _vm.$vuetify.breakpoint.smAndUp,
              xs12: _vm.$vuetify.breakpoint.xs,
              'ml-3': _vm.$vuetify.breakpoint.smAndUp,
              'mt-3': _vm.$vuetify.breakpoint.xs,
            }},[_c('rs-btn',{staticClass:"ma-0",attrs:{"data-test-id":"qa-delete-btn","inverted":"","disabled":_vm.isAllDeselected,"loading":_vm.isRemoving},on:{"click":function($event){return _vm.handleDelete()}}},[_vm._v(" Remove ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }