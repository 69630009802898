// ROLE_NAMES maps role name to display label
export const ROLE_NAMES = {
  'bp-operator': 'Operator',
  'connect-pro': 'Connect Pro',
  'connect-scale': 'Connect Scale',
  'self-service': 'Self-Service Brand',
  connect: 'Connect',
  launch: 'Launch',
  managed: 'Managed Brand',
  zendesk: 'Preview Tier',
};

export function getRoleDisplayName(roleName) {
  // if not mapped in ROLE_NAMES const, convert name (kebab-case to Title Case)
  let displayName = ROLE_NAMES.hasOwnProperty(roleName)
    ? ROLE_NAMES[roleName]
    : roleName
        .replace(/\w+/g, (txt) => {
          return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
        })
        .replace(/-/g, ' ');
  return displayName;
}
