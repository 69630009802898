import Vue from 'vue';

import debounce from '@/internal-ltk-recommendations/utils/debounce';

import { WORK_ITEM_STATUS } from '../../constants';
import CatalogQualityControlService from '../../services/quality-control';
import {
  getCompleteWorkItemPaginationStorage,
  setCompleteWorkItemPaginationStorage,
  getIncompleteWorkItemPaginationStorage,
  setIncompleteWorkItemPaginationStorage,
} from '../../utils/storage';

export default {
  state: {
    group: 'RARI', // List of groups. Default is RARI. Possible values are RARI and C&D.
    operators: [], // List of operators that the current user can view work items for.
    selectedOperator: '', // The selected operator used to view work items for.
    selectedStatusTab: WORK_ITEM_STATUS.INCOMPLETE, // The selected tab used to filter enteries by completion state.
    completeWorkItemsTotal: 0, // Total number of completed work items.
    incompleteWorkItemsTotal: 0, // Total number of incomplete work items.
    workItemsPageList: [], // List of work item ids for the current page.
    workItemsMap: {}, // Normalized work item data.
    workItemsFetching: false, // Current fetch state of products.
    workItemsStatusMap: {}, // Normalized status data for work items.
    incompleteWorkItemsPagination: getIncompleteWorkItemPaginationStorage(), // The pagination object used by the work items data table for incomplete items.
    completeWorkItemsPagination: getCompleteWorkItemPaginationStorage(), // The pagination object used by the work items data table for complete items.
    operatorsStat: [], // Stat of operators that only admins can view in the stat tab.
    operatorsStatFrom: new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10), // The from date used to filter the stat data. By default set to a week ago from current date.
    operatorsStatTo: new Date().toISOString().slice(0, 10), // The to date used to filter the stat data. By default set to the current date.
    selectedOperatorStatFilter: '', // The selected operator used to fitler operator stats.
    unassignedCount: {},
  },
  getters: {
    currentGroup(state) {
      return state.group;
    },
    currentOperator(state) {
      return state.operators.find(({ id }) => id === state.selectedOperator);
    },
    currentCompleteWorkItemsPagination(state) {
      return state.completeWorkItemsPagination;
    },
    currentIncompleteWorkItemsPagination(state) {
      return state.incompleteWorkItemsPagination;
    },
    currentWorkItemsPage(state) {
      return state.workItemsPageList
        .map((workItemId) => state.workItemsMap[workItemId])
        .filter((workItem) => !!workItem);
    },
    currentTotalWorkItemsCount(state) {
      if (state.selectedStatusTab === WORK_ITEM_STATUS.INCOMPLETE) {
        return state.incompleteWorkItemsTotal;
      }
      return state.completeWorkItemsTotal;
    },
    isLoadingWorkItems(state) {
      return state.workItemsFetching;
    },
    isSavingWorkItem: (state) => (workItemId) => {
      return !!state.workItemsStatusMap[workItemId]?.isSaving;
    },
    isWorkItemPendingSave: (state) => (workItemId) => {
      return !!state.workItemsStatusMap[workItemId]?.hasPendingSave;
    },
    totalCompleteWorkItemsCount(state) {
      return state.completeWorkItemsTotal;
    },
    totalIncompleteWorkItemsCount(state) {
      return state.incompleteWorkItemsTotal;
    },
    currentSelectedTab(state) {
      return state.selectedStatusTab;
    },
    operators(state) {
      return state.operators;
    },
    operatorsStat(state) {
      return state.operatorsStat;
    },
    operatorsStatFrom(state) {
      return state.operatorsStatFrom;
    },
    operatorsStatTo(state) {
      return state.operatorsStatTo;
    },
    selectedOperatorStatFilter(state) {
      return state.operators.find(({ id }) => id === state.selectedOperatorStatFilter);
    },
    unassignedCount(state) {
      return state.unassignedCount;
    },
    chartData(state, gets) {
      const selectedOperatorFilter = gets.selectedOperatorStatFilter;
      const operatorsStat = gets.operatorsStat;
      if (!operatorsStat.length) return {};

      const labels = [];

      for (
        let date = new Date(gets.operatorsStatFrom);
        date <= new Date(gets.operatorsStatTo);
        date.setDate(date.getDate() + 1)
      ) {
        labels.push(date.toISOString().slice(0, 10));
      }
      // Initialize a map to hold the sum of statistics numbers of all operators for each date
      const sumCompletedMap = new Map();

      // Iterate over each operator's dates array
      operatorsStat.forEach((operatorStat) => {
        if (selectedOperatorFilter && operatorStat.operatorId !== selectedOperatorFilter.id) {
          return; // This will skip to the next iteration of the loop
        }
        // Iterate over the dates array to sum completed items for each date
        operatorStat.dates.forEach((entry) => {
          const date = entry.date;
          if (!sumCompletedMap.has(date)) {
            // Initialize with zero counts if the date is not yet in the map
            sumCompletedMap.set(date, {
              completedItems: 0,
              completedProducts: 0,
              completedMatches: 0,
              completedMatchesYes: 0,
              completedMatchesNo: 0,
            });
          }
          const sums = sumCompletedMap.get(date);
          sums.completedItems += entry.completedItems || 0;
          sums.completedProducts += entry.completedProducts || 0;
          sums.completedMatches += entry.completedMatches || 0;
          sums.completedMatchesYes += entry.completedMatchesYes || 0;
          sums.completedMatchesNo += entry.completedMatchesNo || 0;
        });
      });

      // Convert the summed completed items back into arrays corresponding to the dates array
      const dataCompletedItems = labels.map((date) => sumCompletedMap.get(date)?.completedItems || 0);
      const dataCompletedProducts = labels.map((date) => sumCompletedMap.get(date)?.completedProducts || 0);
      const dataCompletedMatches = labels.map((date) => sumCompletedMap.get(date)?.completedMatches || 0);
      const dataCompletedMatchesYes = labels.map((date) => sumCompletedMap.get(date)?.completedMatchesYes || 0);
      const dataCompletedMatchesNo = labels.map((date) => sumCompletedMap.get(date)?.completedMatchesNo || 0);

      return {
        labels: labels,
        datasets: [
          {
            label: 'Completed Items',
            backgroundColor: 'rgba(54, 162, 235, 0.7)',
            data: dataCompletedItems,
          },
          {
            label: 'Completed Products',
            backgroundColor: 'rgba(255, 206, 86, 0.7)',
            data: dataCompletedProducts,
          },
          {
            label: 'Completed Matches',
            backgroundColor: 'rgba(153, 102, 255, 0.7)',
            data: dataCompletedMatches,
          },
          {
            label: 'Completed Yes',
            backgroundColor: 'rgba(75, 192, 192, 0.7)',
            data: dataCompletedMatchesYes,
          },
          {
            label: 'Completed No',
            backgroundColor: 'rgba(255, 99, 132, 0.7)',
            data: dataCompletedMatchesNo,
          },
        ],
      };
    },
  },
  actions: {
    async fetchOperators({ commit, dispatch, state }) {
      try {
        const response = await CatalogQualityControlService.getOperators({
          group: state.group,
        });
        commit('SET_OPERATORS', response.data);
        const paginationObject =
          state.selectedStatusTab === WORK_ITEM_STATUS.COMPLETE
            ? state.completeWorkItemsPagination
            : state.incompleteWorkItemsPagination;
        dispatch('fetchWorkItemsPage', { ...paginationObject, clearExisting: true });
      } catch {
        // TODO: Inform the user of a failed fetch.
      }
    },
    async fetchWorkItemsPage(
      { commit, dispatch, state },
      { page, rowsPerPage, clearExisting = false, acquireMore = true },
    ) {
      if (!page) return;
      if (!state.selectedOperator) return;

      commit('SET_WORK_ITEMS_FETCHING', true);

      if (clearExisting) {
        commit('RESET_WORK_ITEMS_PAGE_LIST');
      }

      try {
        const response = await CatalogQualityControlService.get({
          group: state.group,
          operatorId: state.selectedOperator,
          completed: state.selectedStatusTab === WORK_ITEM_STATUS.COMPLETE,
          pageIndex: page - 1,
          pageSize: rowsPerPage,
        });
        commit('SET_WORK_ITEMS_PAGE_LIST', response.data);
        if (
          acquireMore &&
          response.data.totalPendingCount === 0 &&
          !state.operators.find(({ id }) => id === state.selectedOperator).isAdmin
        ) {
          dispatch('acquireMoreWorkItems');
        }
      } catch {
        commit('RESET_WORK_ITEMS_PAGE_LIST', { includeMeta: true });
      } finally {
        commit('SET_WORK_ITEMS_FETCHING', false);
      }
    },
    async fetchOperatorsStatPage({ commit, dispatch, state }) {
      try {
        const response = await CatalogQualityControlService.getOperatorsStat({
          group: state.group,
          from: state.operatorsStatFrom,
          to: state.operatorsStatTo,
        });
        commit('SET_OPERATORS_STAT', response.data);
      } catch {
        // TODO: Inform the user of a failed fetch.
      }
    },
    async fetchUnassignedCount({ commit, dispatch, state }) {
      try {
        const response = await CatalogQualityControlService.getUnassignedCount({
          group: state.group,
        });
        commit('SET_UNASSIGNED_COUNT', response.data);
      } catch {
        // TODO: Inform the user of a failed fetch.
      }
    },
    updateGroup({ commit, dispatch, state }, group) {
      commit('SET_GROUP', group);
      // Refetch the operators for the new group.
      dispatch('fetchOperators');
      // Set the page to 1 when the operator changes:
      state.completeWorkItemsPagination.page = 1;
      state.incompleteWorkItemsPagination.page = 1;
      // Refetch the page for the new operator.
      const paginationObject =
        state.selectedStatusTab === WORK_ITEM_STATUS.COMPLETE
          ? state.completeWorkItemsPagination
          : state.incompleteWorkItemsPagination;
      dispatch('fetchWorkItemsPage', { ...paginationObject, clearExisting: true });
      // Refetch the operator statistics for the new group.
      dispatch('fetchOperatorsStatPage');
      dispatch('fetchUnassignedCount');
    },
    updateOperator({ commit, dispatch, state }, operatorId) {
      commit('SET_OPERATOR', operatorId);
      // Set the page to 1 when the operator changes:
      state.completeWorkItemsPagination.page = 1;
      state.incompleteWorkItemsPagination.page = 1;
      // Refetch the page for the new operator.
      const paginationObject =
        state.selectedStatusTab === WORK_ITEM_STATUS.COMPLETE
          ? state.completeWorkItemsPagination
          : state.incompleteWorkItemsPagination;
      dispatch('fetchWorkItemsPage', { ...paginationObject, clearExisting: true });
    },
    updateSelectedTab({ commit, dispatch, state }, value) {
      commit('SET_SELECTED_TAB', value);
      // Refetch the page for the new selected tab.
      if (value === 0 || value === 1) {
        const paginationObject =
          state.selectedStatusTab === WORK_ITEM_STATUS.COMPLETE
            ? state.completeWorkItemsPagination
            : state.incompleteWorkItemsPagination;
        dispatch('fetchWorkItemsPage', { ...paginationObject, clearExisting: true });
      } else if (value === 2) {
        dispatch('fetchOperatorsStatPage');
        dispatch('fetchUnassignedCount');
      }
    },
    updateCompletedPagination({ state, commit, dispatch }, pagination) {
      if (state.workItemsFetching && state.workItemsPageList.length === 0) return;
      commit('SET_COMPLETE_PAGINATION', pagination);

      // Refetch the page for the new pagination values.
      dispatch('fetchWorkItemsPage', pagination);
    },
    updateIncompletedPagination({ state, commit, dispatch }, pagination) {
      if (state.workItemsFetching && state.workItemsPageList.length === 0) return;

      commit('SET_INCOMPLETE_PAGINATION', pagination);

      // Refetch the page for the new pagination values.
      dispatch('fetchWorkItemsPage', pagination);
    },
    updateMatchingProduct({ commit, dispatch, state }, { id, productId, match, notes }) {
      const workItem = state.workItemsMap[id];
      if (!workItem) return;

      const newMatchingProducts = { ...workItem.matchingProducts };
      // Update the matching product with the new values.
      newMatchingProducts[productId] = { ...newMatchingProducts[productId], match, notes };

      // Optimistically, save the updated work item value to the store.
      const newWorkItem = {
        ...workItem,
        matchingProducts: newMatchingProducts,
      };
      commit('SET_WORK_ITEM', newWorkItem);

      // If the work item is not marked as complete, make an API request to save the result changes.
      if (state.selectedStatusTab === WORK_ITEM_STATUS.INCOMPLETE) {
        dispatch('saveWorkItem', { id, matchingProducts: newMatchingProducts });
      }
      // Else, mark the workItem as pending an update.
      else {
        commit('SET_WORK_ITEM_STATUS', { id, status: { hasPendingSave: true } });
      }
    },
    saveWorkItem: debounce(async ({ commit, state }, { id, matchingProducts }) => {
      commit('SET_WORK_ITEM_STATUS', { id, status: { isSaving: true } });
      try {
        // Only send the match and notes paramters to the save endpoint.
        const newMatchingProducts = { ...matchingProducts };
        Object.keys(newMatchingProducts).forEach((key) => {
          newMatchingProducts[key] = { match: newMatchingProducts[key].match, notes: newMatchingProducts[key].notes };
        });
        await CatalogQualityControlService.putSave(id, {
          group: state.group,
          operatorId: state.selectedOperator,
          matchingProducts: newMatchingProducts,
        });
      } catch {
        // TODO: Inform the user of a failed save.
      } finally {
        commit('SET_WORK_ITEM_STATUS', { id, status: { isSaving: false } });
      }
    }, 1500),
    async completeWorkItem({ commit, dispatch, state }, { id }) {
      const workItem = state.workItemsMap[id];
      if (!workItem) return;

      commit('SET_WORK_ITEM_STATUS', { id, status: { isSaving: true } });
      // Remove the item from the screen if the operator tries to complete the item.
      if (!workItem.completed) {
        commit('EXCLUDE_COMPLETED_WORK_ITEM', id);
      }
      try {
        // Only send the match and notes paramters to the save endpoint.
        const newMatchingProducts = { ...workItem.matchingProducts };
        Object.keys(newMatchingProducts).forEach((key) => {
          newMatchingProducts[key] = { match: newMatchingProducts[key].match, notes: newMatchingProducts[key].notes };
        });
        await CatalogQualityControlService.putSave(id, {
          group: state.group,
          completed: true,
          operatorId: state.selectedOperator,
          matchingProducts: newMatchingProducts,
        });
        commit('SET_WORK_ITEM_STATUS', { id, status: { hasPendingSave: false, isSaving: false } });
      } catch {
        // TODO: Inform the user of a failed save.
        commit('SET_WORK_ITEM_STATUS', { id, status: { isSaving: false } });
      }
      const paginationObject =
        state.selectedStatusTab === WORK_ITEM_STATUS.COMPLETE
          ? state.completeWorkItemsPagination
          : state.incompleteWorkItemsPagination;
      await new Promise((resolve) => setTimeout(resolve, 250));
      dispatch('fetchWorkItemsPage', { ...paginationObject });
    },
    acquireMoreWorkItems: debounce(async ({ dispatch, state }) => {
      try {
        await CatalogQualityControlService.postAcquire({
          group: state.group,
          operatorId: state.selectedOperator,
        });
        const paginationObject =
          state.selectedStatusTab === WORK_ITEM_STATUS.COMPLETE
            ? state.completeWorkItemsPagination
            : state.incompleteWorkItemsPagination;
        dispatch('fetchWorkItemsPage', { ...paginationObject, clearExisting: true, acquireMore: false });
      } catch {
        // TODO: Inform the user of a failed aquire.
      }
    }, 300),
    releaseAllWorkItems: debounce(async ({ state }) => {
      try {
        await CatalogQualityControlService.postReleaseAll({
          group: state.group,
          operatorId: state.selectedOperator,
        });
        const paginationObject =
          state.selectedStatusTab === WORK_ITEM_STATUS.COMPLETE
            ? state.completeWorkItemsPagination
            : state.incompleteWorkItemsPagination;
        dispatch('fetchWorkItemsPage', { ...paginationObject, clearExisting: true, acquireMore: false });
      } catch {
        // TODO: Inform the user of a failed aquire.
      }
    }, 300),
    updateOperatorsStatFrom({ commit }, value) {
      commit('SET_OPERATORS_STAT_FROM', value);
    },
    updateOperatorsStatTo({ commit, dispatch, state }, value) {
      commit('SET_OPERATORS_STAT_TO', value);
      // Refetch the stat page for the new date range
      dispatch('fetchOperatorsStatPage');
    },
    updateSelectedOperatorStatFilter({ commit, dispatch, state }, operatorId) {
      commit('SET_SELECTED_OPERATOR_STAT_FILTER', operatorId);
    },
  },
  mutations: {
    SET_WORK_ITEMS_PAGE_LIST(state, response = { items: [], totalPendingCount: 0, totalCompletedCount: 0 }) {
      const workItemsPageList = response.items.map(({ id }) => id);
      const workItemsMap = response.items.map((workItem) => {
        // If local changes do not exist, return the work item
        if (!state.workItemsMap[workItem.id]) {
          return { [workItem.id]: workItem };
        }
        // Else, keep the local 'match' answers instead of the 'match' answers from the backend.
        const matchingProducts = Object.keys(state.workItemsMap[workItem.id].matchingProducts).reduce((prev, curr) => {
          prev[curr] = {
            ...workItem.matchingProducts[curr],
            match: state.workItemsMap[workItem.id].matchingProducts[curr].match,
          };
          return prev;
        }, {});

        return {
          [workItem.id]: {
            ...workItem,
            matchingProducts,
          },
        };
      });
      const completeWorkItemsTotal = response.totalCompletedCount;
      const incompleteWorkItemsTotal = response.totalPendingCount;

      Vue.set(state, 'workItemsMap', Object.assign({}, ...workItemsMap));
      Vue.set(state, 'workItemsPageList', workItemsPageList);
      Vue.set(state, 'completeWorkItemsTotal', completeWorkItemsTotal);
      Vue.set(state, 'incompleteWorkItemsTotal', incompleteWorkItemsTotal);
    },
    RESET_WORK_ITEMS_PAGE_LIST(state, { includeMeta = false } = {}) {
      Vue.set(state, 'workItemsPageList', []);
      Vue.set(state, 'workItemsMap', {});
      if (includeMeta) {
        Vue.set(state, 'completeWorkItemsTotal', 0);
        Vue.set(state, 'incompleteWorkItemsTotal', 0);
      }
    },
    EXCLUDE_COMPLETED_WORK_ITEM(state, id) {
      Vue.set(
        state,
        'workItemsPageList',
        state.workItemsPageList.filter((item) => item !== id),
      );
    },
    SET_WORK_ITEMS_FETCHING(state, fetching) {
      Vue.set(state, 'workItemsFetching', fetching);
    },
    SET_SELECTED_TAB(state, value) {
      Vue.set(state, 'selectedStatusTab', value);
    },
    SET_COMPLETE_PAGINATION(state, pagination) {
      Vue.set(state, 'completeWorkItemsPagination', pagination);
      setCompleteWorkItemPaginationStorage(pagination);
    },
    SET_INCOMPLETE_PAGINATION(state, pagination) {
      Vue.set(state, 'incompleteWorkItemsPagination', pagination);
      setIncompleteWorkItemPaginationStorage(pagination);
    },
    SET_WORK_ITEM(state, workItem) {
      Vue.set(state, 'workItemsMap', Object.assign({}, state.workItemsMap, { [workItem.id]: workItem }));
    },
    SET_WORK_ITEM_STATUS(state, { id, status }) {
      const workItemsStatusMap = { [id]: { ...state.workItemsStatusMap[id], ...status } };
      Vue.set(state, 'workItemsStatusMap', Object.assign({}, state.workItemsStatusMap, workItemsStatusMap));
    },
    SET_GROUP(state, group) {
      Vue.set(state, 'group', group);
    },
    SET_OPERATORS(state, operators) {
      Vue.set(state, 'operators', operators);
      Vue.set(state, 'selectedOperator', operators.find(({ myself }) => !!myself)?.id || '');
    },
    SET_OPERATOR(state, operatorId) {
      Vue.set(state, 'selectedOperator', operatorId);
    },
    SET_OPERATORS_STAT(state, stat) {
      Vue.set(state, 'operatorsStat', stat);
    },
    SET_OPERATORS_STAT_FROM(state, from) {
      Vue.set(state, 'operatorsStatFrom', from);
    },
    SET_OPERATORS_STAT_TO(state, to) {
      Vue.set(state, 'operatorsStatTo', to);
    },
    SET_UNASSIGNED_COUNT(state, data) {
      Vue.set(state, 'unassignedCount', data);
    },
    SET_SELECTED_OPERATOR_STAT_FILTER(state, operatorId) {
      Vue.set(state, 'selectedOperatorStatFilter', operatorId);
    },
  },
};
