<template>
  <div :style="{ width: '100%' }">
    <div class="bubbles-manage" v-if="$vuetify.breakpoint.mdAndUp">
      <div class="bubbles-manage__wrapper">
        <div class="bubbles-manage__header">
          <rs-button icon-only flat rounded size="sm" @click="back">
            <rs-icon>arrow_back</rs-icon>
          </rs-button>
          <h1>{{ pageTitle }}</h1>
        </div>
        <div class="bubbles-manage__body">
          <rs-form v-if="!loading" ref="form" v-model="isFormValid" @submit.prevent="handleSaveUpdates()">
            <hashtags-fields
              type="bubble"
              :group-label="bubbleData.group_label"
              :start-date="bubbleData.start_date"
              :end-date="bubbleData.end_date"
              :title="bubbleData.title"
              :subtitle="bubbleData.subtitle"
              :image-url="bubbleData.url"
              :should-use-dark-title="bubbleData.shouldUseDarkTitle"
              :should-hide-overlay="bubbleData.shouldHideOverlay"
              :editing="isEditing"
              :status="status"
              :can-edit="canEdit"
              @change="(e) => onBubbleChange(e)"
            />
            <hashtags-country-fields
              v-for="(data, index) in bubbleCountries"
              :countries="unselectedCountries"
              :country-code="data.country_code"
              :removable="index >= groupStartingLength"
              :key="index"
              :options="data.options"
              :can-edit="canEdit"
              :on-edit="isEditing"
              :feed-hashtag="getFeedHashtag(data)"
              :prev-retailers="data.retailers"
              @change="(e) => onCountryChange(e, index)"
              @deleted="handleDeleteCountry(index)"
              type="bubble"
            />
          </rs-form>
          <div class="bubbles-manage__btns">
            <rs-button
              size="sm"
              data-testid="bubbles-manage-add-btn"
              :disabled="!canEdit || bubbleCountries.length === countries.length"
              @click="handleAddCountry(unselectedCountries[0].code)"
            >
              <rs-icon>add</rs-icon>
              <span>Add another country</span>
            </rs-button>
            <rs-button
              size="sm"
              class="bubbles-manage__add-all-btn"
              data-testid="bubbles-manage-add-all-btn"
              :disabled="!canEdit || bubbleCountries.length > 1"
              @click="handleAddAllCountries()"
            >
              <rs-icon>add</rs-icon>
              <span>Add all countries</span>
            </rs-button>
          </div>
        </div>
        <HashtagsFooter v-model="valid" @cancel="back()" @saved="handleSaveUpdates()" />
      </div>
    </div>
    <hashtags-desktop-notice v-if="$vuetify.breakpoint.smAndDown" />
  </div>
</template>

<script>
import Vue, { ref, computed, defineComponent, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import RsButton from '@/internal-advertisers/lib/components/RsButton/RsButton.vue';
import HashtagsFooter from '@/internal-hashtags/components/HashtagsFooter.vue';
import HashtagsDesktopNotice from '@/internal-hashtags/components/HashtagsDesktopNotice.vue';
import HashtagsFields from '@/internal-hashtags/components/HashtagsFields.vue';
import HashtagsCountryFields from '@/internal-hashtags/components/HashtagsCountryFields.vue';
import { addUTCMidnight, getFormattedDates, convertFromUtcToLocal } from '@/internal-hashtags/utils/changeTimeZone';
import countries from '@/internal-hashtags/fixtures/countries.json';
import { useBubblesStore } from '@/stores/bubbles';
import { storeToRefs } from 'pinia';
import { formatForEdit } from '@/internal-hashtags/utils/formatForEdit';
import useAdvertiserApi from '@/internal-advertisers/composables/useAdvertiserApi';

export default defineComponent({
  name: 'BubblesManage',
  components: { RsButton, HashtagsFooter, HashtagsDesktopNotice, HashtagsFields, HashtagsCountryFields },
  props: {
    groupLabel: {
      type: String,
      default: '',
    },
    defaultCountry: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const { getAdvertisers } = useAdvertiserApi();

    const isEditing = props.groupLabel !== '';
    const loading = ref(true);
    const status = ref('disabled');
    const bubbleRetailers = ref([]);

    const pageTitle = computed(() => {
      if (route.name === 'BubblesAdd') {
        return 'Add Bubbles';
      } else if (route.name === 'BubblesEdit') {
        return 'Edit Bubbles';
      }
      return '';
    });

    const bubblesStore = useBubblesStore();
    const { bubblesByGroup } = storeToRefs(bubblesStore);

    // Format the dates in 'YYYY-MM-DD' format, with start/end as today/tomorrow
    const { formattedToday, formattedTomorrow } = getFormattedDates();
    const bubbleData = ref({
      enabled: false,
      end_date: formattedTomorrow,
      group_label: props.groupLabel,
      pre_staged: false,
      shouldHideOverlay: false,
      shouldUseDarkTitle: false,
      start_date: formattedToday,
      subtitle: '',
      title: '',
      url: null,
      actions: {
        type: '',
        actionType: '',
        feedId: '',
        feedRank: '',
        feedType: '',
      },
    });

    const groupStartingLength = ref(0);
    const bubbleCountries = ref([]);
    const validFeedHashtags = ref(false);

    watch(
      () => bubbleCountries,
      (next) => {
        if (next) {
          next.value.forEach((country) => {
            if (isEditing) {
              if (!country.options.key && !country.feedHashtag) {
                validFeedHashtags.value = false;
                return;
              }
            } else {
              if (!country.feedHashtag) {
                validFeedHashtags.value = false;
                return;
              }
            }
            validFeedHashtags.value = true;
          });
        }
      },
      {
        deep: true,
        immediate: true,
      },
    );

    const valid = computed(() => {
      if (
        bubbleData.value.group_label === '' ||
        bubbleData.value.title === '' ||
        bubbleData.value.subtitle === '' ||
        bubbleData.value.start_date === '' ||
        bubbleData.value.end_date === '' ||
        bubbleData.value.url === null ||
        isFormValid.value !== true
      ) {
        return false;
      }
      if (bubbleCountries.value.length === 0) {
        return false;
      }
      if (!validFeedHashtags.value) {
        return false;
      }
      return true;
    });

    function setDefaultCountry() {
      const defaultCountry = unselectedCountries.value.filter((country) => country.code === props.defaultCountry)[0];
      handleAddCountry(defaultCountry.code);
    }

    const unselectedCountries = computed(() => {
      const selectedCountryCodes = bubbleCountries.value.map((country) => country.country_code);
      const filteredCountries = countries.filter((country) => !selectedCountryCodes.includes(country.code));
      return filteredCountries;
    });

    const back = () => router.push(`/bubbles?defaultCountry=${props.defaultCountry}`);

    const getFeedHashtag = (data) => {
      if (isEditing) {
        // If editing an existing bubble we should start with the existing feedHashtag
        return data.options.actions && data.options.actions[0] && data.options.actions[0].feedHashtag
          ? data.options.actions[0].feedHashtag
          : '';
      } else {
        return data.feedHashtag;
      }
    };

    const readStatus = (status) => {
      if (status === 'qa') {
        bubbleData.value.enabled = true;
        bubbleData.value.pre_staged = true;
      } else if (status === 'prod') {
        bubbleData.value.enabled = true;
        bubbleData.value.pre_staged = false;
      } else if (status === 'disabled') {
        bubbleData.value.enabled = false;
        bubbleData.value.pre_staged = false;
      }
    };

    const setStatus = () => {
      if (bubbleData.value.enabled && bubbleData.value.pre_staged) {
        status.value = 'qa';
      } else if (bubbleData.value.enabled && !bubbleData.value.pre_staged) {
        status.value = 'prod';
      } else if (!bubbleData.value.enabled && !bubbleData.value.pre_staged) {
        status.value = 'disabled';
      }
    };

    const onCountryChange = (event, index) => {
      bubbleCountries.value[index].country_code = event.selected_country;
      // Because `options` and `actions` are still going to change in the near future and to minimize mutating
      // deeply nested objects, for now we are just saving the entire emitted `option` event and lifting
      // `actions` to the same level so we can use the value during creation/editing.
      Vue.set(bubbleCountries.value[index], 'options', event.options);
      Vue.set(bubbleCountries.value[index], 'feedHashtag', event.feedHashtag);
    };

    function handleAddAllCountries() {
      unselectedCountries.value.forEach((country) => {
        handleAddCountry(country.code);
      });
    }

    function handleAddCountry(countryCode) {
      bubbleCountries.value.push({ country_code: countryCode, options: { key: '' } });
    }

    const handleDeleteCountry = (index) => {
      bubbleCountries.value.splice(index, 1);
    };

    const onBubbleChange = (event) => {
      if (!isEditing) {
        bubbleData.value.group_label = event.group_label;
      }
      bubbleData.value.shouldHideOverlay = event.shouldHideOverlay;
      bubbleData.value.shouldUseDarkTitle = event.shouldUseDarkTitle;
      bubbleData.value.start_date = event.start_date;
      bubbleData.value.end_date = event.end_date;
      bubbleData.value.subtitle = event.subtitle;
      bubbleData.value.title = event.title;
      bubbleData.value.url = event.url;

      readStatus(event.status);
    };

    const createBubbles = async (modulesToBuild) => {
      try {
        const group_label = bubbleData.value.group_label;
        const modules = buildNewModules(modulesToBuild);
        await bubblesStore.create({ group_label, modules });
      } catch (err) {
        console.warn('Error', err);
      }
    };

    const buildNewModules = (modulesToBuild) => {
      const startDate = addUTCMidnight(bubbleData.value.start_date);
      const endDate = addUTCMidnight(bubbleData.value.end_date);
      const modules = [];
      modulesToBuild.forEach((bubbleCountry) => {
        // Retailer IDs are returned from Advertiser service as array of Int, we need an array of strings
        let retailerIdStrings = [];
        if (bubbleCountry.options.retailerIds && bubbleCountry.options.retailerIds.length > 0) {
          retailerIdStrings = bubbleCountry.options.retailerIds.map((id) => String(id));
        }
        const timezoneOffset = countries.find((c) => c.code === bubbleCountry.country_code)?.offset || '+00:00';
        const newCountryModule = {
          country_code: bubbleCountry.country_code,
          enabled: bubbleData.value.enabled,
          end_date: `${endDate}${timezoneOffset}`,
          major_version: 1,
          minor_version: 0,
          notes: 'Created via Operator Tools',
          options: {
            actions: [
              {
                // For now the only supported value for type and action_type is `v1_hashtag`
                type: 'v1_hashtag',
                // For ranked on edit we will need to make sure that actionType is `v1_feed` and is returned as it was received for both type and actionType
                actionType: 'v1_hashtag',
                feedHashtag: bubbleCountry.feedHashtag,
              },
            ],
            catalogIds: bubbleCountry.options.catalogIds ?? [],
            key: bubbleCountry.feedHashtag,
            requireTag: bubbleCountry.options.requireTag,
            retailerIds: retailerIdStrings,
            shouldHideOverlay: bubbleData.value.shouldHideOverlay,
            shouldUseDarkTitle: bubbleData.value.shouldUseDarkTitle,
            subtitle: bubbleCountry.options.subtitle ? bubbleCountry.options.subtitle : bubbleData.value.subtitle,
            testDescription: 'Description for Unit Test',
            testTag: 'testTag',
            title: bubbleCountry.options.title ? bubbleCountry.options.title : bubbleData.value.title,
            type: 2,
            subtitle: bubbleCountry.options.subtitle ? bubbleCountry.options.subtitle : bubbleData.value.subtitle,
            url: bubbleCountry.options.url ? bubbleCountry.options.url : bubbleData.value.url,
          },
          options_type: 'v1_bubble',
          pre_staged: bubbleData.value.pre_staged,
          start_date: `${startDate}${timezoneOffset}`,
        };
        modules.push(newCountryModule);
      });
      return modules;
    };

    const editBubbles = async (modulesToUpdate) => {
      /*
        When editing we only need to set bubble group data before submitting.
          Specific bubble data is updated on during user selection.
      */
      const startDate = addUTCMidnight(bubbleData.value.start_date);
      const endDate = addUTCMidnight(bubbleData.value.end_date);
      modulesToUpdate.forEach((bubbleCountry) => {
        // Retailer IDs are returned from Advertiser service as array of Int, we need an array of strings
        let retailerIdStrings = [];
        if (bubbleCountry.options.retailerIds && bubbleCountry.options.retailerIds.length > 0) {
          retailerIdStrings = bubbleCountry.options.retailerIds.map((id) => String(id));
        }
        // Check if new feedHashtag has been emitted up, if not use old one if country has one
        const oldFeedHashtag =
          bubbleCountry.options.actions && bubbleCountry.options.actions[0].feedHashtag
            ? bubbleCountry.options.actions[0].feedHashtag
            : '';
        const feedHashtag = bubbleCountry?.feedHashtag ? bubbleCountry.feedHashtag : oldFeedHashtag;

        const timezoneOffset = countries.find((c) => c.code === bubbleCountry.country_code)?.offset || '+00:00';
        bubbleCountry.options.type = 2;
        bubbleCountry.start_date = `${startDate}${timezoneOffset}`;
        bubbleCountry.end_date = `${endDate}${timezoneOffset}`;
        bubbleCountry.enabled = bubbleData.value.enabled;
        bubbleCountry.pre_staged = bubbleData.value.pre_staged;
        bubbleCountry.title = bubbleCountry.options.title ? bubbleCountry.options.title : bubbleData.value.title;
        bubbleCountry.subtitle = bubbleCountry.options.subtitle
          ? bubbleCountry.options.subtitle
          : bubbleData.value.subtitle;
        bubbleCountry.options.shouldHideOverlay = bubbleData.value.shouldHideOverlay;
        bubbleCountry.options.shouldUseDarkTitle = bubbleData.value.shouldUseDarkTitle;
        /*
          As noted above in onCountryChange, the entire `options` emit is being saved,
            so we manually set deeply nested values here.
        */
        if (canEdit.value) {
          // not ranked
          bubbleCountry.options.actions = [
            {
              type: bubbleData.value.actions.type,
              actionType: bubbleData.value.actions.actionType,
              feedHashtag: feedHashtag,
            },
          ];
          bubbleCountry.options.retailerIds = retailerIdStrings;
        } else {
          // ranked
          bubbleCountry.options.actions = [
            {
              type: bubbleData.value.actions.type,
              actionType: bubbleData.value.actions.actionType,
              feedId: bubbleData.value.actions.feedId,
              feedRank: bubbleData.value.actions.feedRank,
              feedType: bubbleData.value.actions.feedType,
            },
          ];
        }

        bubbleCountry.options.testTag = 'bubble_test_tag';
        bubbleCountry.options.testDescription = `${bubbleCountry.title} Test!`;
      });

      // Remove fields not needed/valid for edit
      const formattedModulesToUpdate = formatForEdit(modulesToUpdate);

      // Make POST call to bulk edit endpoint
      try {
        await bubblesStore.editMany({ modules: formattedModulesToUpdate });
      } catch (err) {
        console.warn('Error', err);
      }
    };

    const form = ref();
    const isFormValid = ref(false);

    const handleSaveUpdates = async () => {
      if (form.value.validate() === false || valid.value === false) {
        return;
      }

      if (isEditing) {
        const modulesToUpdate = bubbleCountries.value.slice(0, groupStartingLength.value);
        const modulesToBuild = bubbleCountries.value.slice(groupStartingLength.value);
        await editBubbles(modulesToUpdate);
        // Check if new countries were added and will need to be created
        if (modulesToBuild.length > 0) {
          await createBubbles(modulesToBuild);
        }
      } else {
        await createBubbles(bubbleCountries.value);
      }
      router.push(`/bubbles?defaultCountry=${props.defaultCountry}`);
    };

    /*
      Bubbles is the only tool that has reserved modules that should not be deleted by Operator Tool Users.
    */
    const checkIfEditable = (bubble) => {
      if (
        bubble &&
        bubble.options &&
        bubble.options.actions &&
        bubble.options.actions[0] &&
        bubble.options.actions[0].type
      ) {
        const type = bubble?.options?.actions[0]?.type;
        return type === 'v1_hashtag' ? true : false;
      }
    };

    const canEdit = ref(true);

    const getRetailers = (options) => {
      if (!options || !options.retailerIds) {
        return [];
      }
      /*
        Retailer IDs are returned from Advertiser service as array of Int, Presentation Service expects an array of strings
      */
      const retailerIntIds = options.retailerIds.map((id) => parseInt(id));
      const countryRetailers = bubbleRetailers.value.filter((bubbleRetailer) => {
        return retailerIntIds.includes(bubbleRetailer.id);
      });

      return countryRetailers ?? [];
    };

    onMounted(async () => {
      // If in Edit View:
      // Make initial fetch and they load existing bubbles data.
      if (isEditing) {
        await bubblesStore.getByGroup(props.groupLabel);
        groupStartingLength.value = bubblesByGroup.value.length;
        bubbleCountries.value = bubblesStore.bubblesByGroup;
        canEdit.value = checkIfEditable(bubbleCountries.value[0]);
        bubbleData.value.pre_staged = bubbleCountries.value[0].pre_staged;
        bubbleData.value.enabled = bubbleCountries.value[0].enabled;
        bubbleData.value.title = bubbleCountries.value[0].options.title;
        bubbleData.value.subtitle = bubbleCountries.value[0].options.subtitle;
        bubbleData.value.shouldHideOverlay = bubbleCountries.value[0].options.shouldHideOverlay;
        bubbleData.value.shouldUseDarkTitle = bubbleCountries.value[0].options.shouldUseDarkTitle;
        const localStart = convertFromUtcToLocal(
          bubbleCountries.value[0].country_code,
          bubbleCountries.value[0].start_date,
        );
        const localEnd = convertFromUtcToLocal(
          bubbleCountries.value[0].country_code,
          bubbleCountries.value[0].end_date,
        );
        bubbleData.value.start_date = localStart.split('T')[0];
        bubbleData.value.end_date = localEnd.split('T')[0];
        bubbleData.value.url = bubbleCountries.value[0].options.url;
        bubbleData.value.actions.type = bubbleCountries.value[0].options.actions[0].type;
        bubbleData.value.actions.actionType = bubbleCountries.value[0].options.actions[0].actionType;
        if (!canEdit.value) {
          // ranked
          bubbleData.value.actions.feedId = bubbleCountries.value[0].options.actions[0].feedId;
          bubbleData.value.actions.feedRank = bubbleCountries.value[0].options.actions[0].feedRank;
          bubbleData.value.actions.feedType = bubbleCountries.value[0].options.actions[0].feedType;
        }
        // Create a set of unique retailerIds from all bubbleCountries
        const retailerIds = new Set();
        bubbleCountries.value.forEach((bubbleCountry) => {
          if (bubbleCountry.options.retailerIds) {
            bubbleCountry.options.retailerIds.forEach((retailerId) => {
              retailerIds.add(retailerId);
            });
          }
        });
        /*
          useAdvertisers supports a filter[id]  parameter that takes a comma separated list,

          Note: `getAdvertisers` returns retailerIds as Ints and the Presentation Service expects them as Strings.

          As we are searching for retailerIds  we need to convert them to Ints. Before sending the retailerIds to the Presentation Service, we need to convert them back to Strings.
        */
        const res = await getAdvertisers({ id: [...retailerIds] });
        bubbleRetailers.value = res.result;
        bubbleCountries.value.forEach((bubbleCountry, index) => {
          const bubbleCountryCopy = { ...bubbleCountry };
          bubbleCountryCopy.retailers = getRetailers(bubbleCountry.options);
          Vue.set(bubbleCountries.value, index, bubbleCountryCopy);
        });
        setStatus();
      } else {
        // If in Create view:
        // Hydrate bubbleCountries with with default country from Bubbles view
        setDefaultCountry();
      }
      loading.value = false;
    });

    return {
      bubbleCountries,
      bubbleData,
      canEdit,
      countries,
      form,
      groupStartingLength,
      isEditing,
      isFormValid,
      loading,
      pageTitle,
      status,
      unselectedCountries,
      valid,
      validFeedHashtags,
      back,
      createBubbles,
      editBubbles,
      getFeedHashtag,
      handleAddAllCountries,
      handleAddCountry,
      handleDeleteCountry,
      handleSaveUpdates,
      onBubbleChange,
      onCountryChange,
      setDefaultCountry,
    };
  },
});
</script>

<style lang="scss">
.bubbles-manage {
  width: 100%;
  padding: 0 16px;
  &__wrapper {
    max-width: 1244px;
    margin: 0 auto;
    margin-top: 48px;
    margin-bottom: 108px;
  }
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    & > h1 {
      margin: 0;
      padding: 0;
      line-height: 32px;
      font-size: 24px;
      font-weight: 700;
    }
  }
  &__btns {
    padding-top: 24px;
  }
  &__add-all-btn {
    margin-left: 8px;
  }
}
</style>
