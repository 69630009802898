<template>
  <div class="campaign-header">
    <div class="campaign-header__content">
      <div class="campaign-header--start">
        <h1 class="my-0 py-0 font-weight-bold">{{ headerText }}</h1>
      </div>
      <div class="campaign-header--end">
        <rs-text-field
          date-testid="campaign-header-search"
          :disabled="isSorting"
          class="campaign-list__search"
          label="Search"
          type="search"
          v-model="search"
        />
        <rs-button data-testid="campaign-header-delete" flat @click="onDelete" :disabled="!isSelected">
          <rs-icon>delete</rs-icon>
        </rs-button>
        <rs-button data-testid="campaign-header-add" primary @click="onAdd">
          <rs-icon>add</rs-icon>
          <span>Add</span>
        </rs-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, defineComponent, watch } from 'vue';
import RsButton from '@/internal-advertisers/lib/components/RsButton/RsButton.vue';

export default defineComponent({
  name: 'ListActionsMast',
  components: {
    RsButton,
  },
  props: {
    headerText: {
      type: String,
      required: true,
    },
    isSorting: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  emits: { delete: () => true, add: () => true, 'changed:date': (date) => true, 'change:search': (value) => true },
  setup(props, { emit }) {
    const startDate = ref('');
    const startDateMenu = ref(false);
    const search = ref('');

    const onDelete = () => {
      emit('delete');
    };

    const onAdd = () => {
      emit('add');
    };

    const onSearchChange = (value) => {
      emit('change:search', value);
    };

    watch(
      () => search.value,
      (next) => {
        onSearchChange(next);
      },
      { immediate: true },
    );

    const onDateChange = (date) => {
      emit('change:date', date);
    };

    watch(
      () => startDate.value,
      (next) => {
        if (!next) return;
        onDateChange(next);
      },
      { immediate: true },
    );

    return {
      startDate,
      startDateMenu,
      search,
      onDelete,
      onAdd,
      onSearchChange,
    };
  },
});
</script>

<style lang="scss">
.campaign-header {
  background-color: #fff;
  padding: 0 16px;
  margin-bottom: 24px;
  &__datepicker {
    height: 370px;
    width: 305px;
  }
  &__content {
    margin-top: 50px;
    min-height: 20px;
    max-width: 1260px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 48px;
    padding-bottom: 24px;
    flex-wrap: wrap;
  }
  &--start {
    h1 {
      line-height: 32px;
      font-size: 24px;
    }
    margin: 0 16px 0 0;
    padding: 0;
    line-height: 1;
  }
  &--end {
    display: flex;
    align-items: center;
    flex-grow: 2;
    justify-content: flex-end;
  }
}
</style>
