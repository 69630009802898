import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import usePsApi from '@/services/usePsApi.js';

const emptyBannerFactory = () => ({
  country_code: 'us',
  enabled: true,
  end_date: '',
  feature_flag: null,
  group_label: '',
  major_version: 1,
  minor_version: 0,
  notes: 'Banner created via O.T.',
  options_type: 'v1_banner_image',
  options: {
    actions: [],
    key: '',
    url: '',
    type: 2,
    testTag: 'testTag',
    testDescription: 'Test Description',
    title: '',
  },
  page: 'v1_banners',
  start_date: '',
});

export const useBannersStore = defineStore('Banners Store', () => {
  const bannersByCountry = ref([]);
  const bannersByGroup = ref([]);
  const presentationService = usePsApi();

  const getByCountry = async (country) => {
    try {
      const bannerResponse = await presentationService.getByCountry('v1_banners', country);
      bannersByCountry.value = [];
      bannerResponse.forEach((banner) => {
        bannersByCountry.value.push(banner);
      });
    } catch (e) {
      console.warn('ERROR: ', e);
    }
  };

  const getByGroup = async (name) => {
    try {
      const bannerResponse = await presentationService.getByGroup('v1_banners', name);
      bannersByGroup.value = [];
      bannerResponse.forEach((banner) => {
        bannersByGroup.value.push(banner);
      });
    } catch (e) {
      console.warn('ERROR: ', e);
    }
  };

  const getById = (id, type = 'country') => {
    return computed(() => {
      let banner;
      if (type === 'country') {
        banner = bannersByCountry.value.filter((banner) => banner.id === id)[0];
      } else if (type === 'group') {
        banner = bannersByGroup.value.filter((banner) => banner.id === id)[0];
      }
      return banner ?? emptyBannerFactory();
    });
  };

  const create = async ({ group_label, modules }) => {
    try {
      const page = 'v1_banners';
      const response = await presentationService.createMany({ group_label, modules, page });
      return response.length;
    } catch (e) {
      console.warn('ERROR: ', e);
    }
  };

  const deleteMany = async (modules) => {
    try {
      const response = await presentationService.deleteMany(modules);
      return response;
    } catch (e) {
      console.warn('ERROR: ', e);
    }
  };

  const editOne = async (update) => {
    try {
      const res = await presentationService.editOne(update);
      return res;
    } catch (e) {
      console.warn('error', e);
    }
  };

  const editMany = async (modules) => {
    try {
      const response = await presentationService.editMany(modules);
      return response;
    } catch (e) {
      console.warn('ERROR: ', e);
    }
  };

  return {
    bannersByCountry,
    bannersByGroup,
    create,
    deleteMany,
    editOne,
    editMany,
    getByCountry,
    getByGroup,
    getById,
  };
});
