<template>
  <div>
    <rs-data-table
      class="table"
      :headers="headers"
      :items="items"
      :rows-per-page-items="[10, 15, 25]"
      :pagination.sync="pagination"
      :total-items="totalItemsCount"
      :loading="isLoadingItems"
      expand
    >
      <template #no-data>
        <tr v-if="isLoadingItems">
          <td colspan="8" class="text-sm-center">Loading…</td>
        </tr>
        <tr v-if="!isLoadingItems">
          <td colspan="8" class="text-sm-center fetch-more-btn">
            <div class="button-wrapper">
              <p>No more products assigned to you need to be treated.</p>
            </div>
          </td>
        </tr>
      </template>
      <template #items="props">
        <br v-if="props.index !== 0" />
        <quality-control-table-item
          v-bind="props"
          :expanded="expandedWorkItemIndexes[props.index]"
          @onRowExpanded="onToggleWorkItem(props.index)"
          @onCompleteWorkItem="onCompletedWorkItem"
        />
        <template v-if="expandedWorkItemIndexes[props.index]">
          <quality-control-table-item-match
            v-for="(matchingProductId, matchingProductIndex) in Object.keys(props.item.matchingProducts)"
            :key="matchingProductId"
            :work-item-id="props.item.id"
            :product-id="matchingProductId"
            @onOpenCompareModal="onOpenCompareModal(props.index, matchingProductIndex)"
          />
        </template>
      </template>
      <template #footer>
        <tr v-if="isIncompleteTabSelected">
          <td colspan="8" class="text-sm-center fetch-more-btn">
            <div class="button-wrapper">
              <rs-btn class="btn" @click="onAcquireMore"> Fetch 100 More Products </rs-btn>
            </div>
          </td>
        </tr>
        <tr v-if="isLoadingItems && items.length > 0" class="v-datatable__progress">
          <td colspan="8" class="pa-0">
            <rs-progress-linear class="ma-0" height="2" indeterminate></rs-progress-linear>
          </td>
        </tr>
      </template>
    </rs-data-table>
    <quality-control-compare-modal
      :active="isCompareModalActive && !!selectedWorkItemId && !!selectedProductId"
      :work-item-id="selectedWorkItemId"
      :product-id="selectedProductId"
      @onClose="onCloseCompareModal"
      @onChangeProductIndex="onChangeCompareModalProduct"
      @onCompleteWorkItem="onCompleteCompareModalWorkItem"
    />
  </div>
</template>

<script>
import { computed, defineComponent, ref, toRefs } from 'vue';
import useStore from '@/composables/vuex';

import { QUALITY_CONTROL_TABS } from '../constants';

import QualityControlCompareModal from './QualityControlCompareModal.vue';
import QualityControlTableItemMatch from './QualityControlTableItemMatch.vue';
import QualityControlTableItem from './QualityControlTableItem.vue';
import { useExpandedWorkItems } from '../composables/useExpandedWorkItems';

export default defineComponent({
  components: {
    QualityControlCompareModal,
    QualityControlTableItem,
    QualityControlTableItemMatch,
  },
  props: {
    tableName: String,
  },
  setup(props, _context) {
    const store = useStore();

    const { tableName } = toRefs(props);
    const headers = ref([
      { text: 'Image', value: 'image', sortable: false },
      { text: 'Title', value: 'title', sortable: false, width: '10%' },
      { text: 'Brand', value: 'brand', sortable: false, width: '10%' },
      { text: 'Retailer', value: 'retailer', sortable: false, width: '10%' },
      { text: 'Description', value: 'description', sortable: false },
      { text: 'Product details', value: 'details', sortable: false },
      { text: 'Price', value: 'price', sortable: false, width: '10%' },
      { text: 'Match', value: 'match', sortable: false },
    ]);
    const isCompareModalActive = ref(false);
    const selectedWorkItemIndex = ref(null);
    const selectedProductIndex = ref(null);

    const items = computed(() => store.getters.currentWorkItemsPage);
    const totalItemsCount = computed(() => store.getters.currentTotalWorkItemsCount);
    const isLoadingItems = computed(() => store.getters.isLoadingWorkItems);
    const isIncompleteTabSelected = computed(
      () => store.getters.currentSelectedTab === QUALITY_CONTROL_TABS.INCOMPLETE,
    );
    const pagination = computed({
      get: () => {
        return tableName.value === 'incompleted'
          ? store.getters.currentIncompleteWorkItemsPagination
          : store.getters.currentCompleteWorkItemsPagination;
      },
      set: (value) => {
        return tableName.value === 'incompleted'
          ? store.dispatch('updateIncompletedPagination', value)
          : store.dispatch('updateCompletedPagination', value);
      },
    });
    const selectedWorkItemId = computed(() =>
      selectedWorkItemIndex.value !== null ? items.value[selectedWorkItemIndex.value]?.id : undefined,
    );
    const selectedProductId = computed(() => {
      if (selectedWorkItemIndex.value === null || selectedProductIndex.value === null) {
        return undefined;
      }
      const selectedWorkItem = items.value[selectedWorkItemIndex.value];
      return selectedWorkItem ? Object.keys(selectedWorkItem.matchingProducts)[selectedProductIndex.value] : undefined;
    });

    const { expandedWorkItemIndexes, onToggleWorkItem } = useExpandedWorkItems(pagination);

    const onAcquireMore = () => {
      store.dispatch('acquireMoreWorkItems');
    };

    const onOpenCompareModal = (workItemIndex, productIndex) => {
      selectedWorkItemIndex.value = workItemIndex;
      selectedProductIndex.value = productIndex;
      isCompareModalActive.value = true;
    };

    const onCloseCompareModal = () => {
      isCompareModalActive.value = false;
      selectedWorkItemIndex.value = null;
      selectedProductIndex.value = null;
    };

    const onChangeCompareModalProduct = (index) => {
      selectedProductIndex.value = index;
    };

    const onCompletedWorkItem = async (workItemId) => {
      store.dispatch('completeWorkItem', { id: workItemId });
    };

    const onCompleteCompareModalWorkItem = (workItemId) => {
      selectedProductIndex.value = 0;
      onCompletedWorkItem(workItemId);
    };

    return {
      pagination,
      headers,
      items,
      isLoadingItems,
      totalItemsCount,
      isIncompleteTabSelected,
      isCompareModalActive,
      selectedWorkItemId,
      selectedProductId,
      expandedWorkItemIndexes,
      onAcquireMore,
      onOpenCompareModal,
      onCloseCompareModal,
      onChangeCompareModalProduct,
      onCompletedWorkItem,
      onCompleteCompareModalWorkItem,
      onToggleWorkItem,
    };
  },
});
</script>

<style lang="scss" scoped>
.table {
  &::v-deep .v-table {
    background: none;

    & tbody tr {
      border: 1px solid #ebebeb;

      &.v-datatable__expand-row {
        border: none;
      }
    }

    & tfoot tr {
      & .fetch-more-btn {
        text-align: center;

        & .button-wrapper {
          display: table;
          margin: auto;
        }
      }
    }

    & .v-datatable__actions {
      border: 1px solid #ebebeb;
    }
  }

  &::v-deep .v-datatable {
    & thead tr {
      border-bottom: none;
    }

    & .v-datatable__expand-col--expanded {
      border: 1px solid #ebebeb;
    }
  }
}
</style>
