<template>
  <div class="editor">
    <editor-content :editor="editor" class="editor__content" />
  </div>
</template>

<script>
import { EditorContent } from 'tiptap';

export default {
  components: {
    EditorContent,
  },
  props: {
    editor: {
      type: Object,
      default: () => {},
    },
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>
<style lang="sass" scoped>
::v-deep .editor__content .ProseMirror
  height: 150px
  outline: none !important
::v-deep.ProseMirror ul
  list-style-type: disc !important
::v-deep.ProseMirror:focus
  outline: none
.formattingButton
  border-style: solid
  border-width: 0.5px

::v-deep .editor__content p
  margin-bottom: 0 !important
  line-height: 24px !important
  font-size: 14px !important

::v-deep .editor__content li
  margin-bottom: 0 !important
  padding-bottom: 0 !important
  line-height: 22px !important
</style>
