<template>
  <rs-container my-0 mx-auto>
    <h2 class="display-3 ml-2">Operator Tools</h2>
    <rs-layout column>
      <template v-for="group in groupTree">
        <rs-flex xs12 md6 :key="group.name" v-if="!group.hidden">
          <rs-card class="mb-3 mx-2">
            <rs-card-title>
              <h3 class="headline font-weight-medium ma-0">{{ group.name }}</h3>
            </rs-card-title>
            <rs-card-text>
              <rs-layout row wrap>
                <rs-btn
                  v-for="link in group.links"
                  :key="link.name"
                  :to="link.to"
                  :href="link.href"
                  :block="false"
                  inverted
                >
                  {{ link.label ? link.label : link.name }}
                  <rs-icon class="pl-2" small v-if="link.href">rsfont-launch</rs-icon>
                </rs-btn>
              </rs-layout>
            </rs-card-text>
          </rs-card>
        </rs-flex>
      </template>
    </rs-layout>
  </rs-container>
</template>

<script>
import { groupTree } from '@/components/rsSideNav.vue';

export default {
  name: 'Nexus',
  data() {
    return {
      groupTree,
    };
  },
};
</script>
