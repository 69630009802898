<template>
  <div class="rs--modal__content">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RsModalContent',
});
</script>

<style lang="scss">
@import '../../../scss/colors';
.rs--modal__content {
  width: 100%;
  background-color: var(--bg);
  padding: 16px;
  flex-grow: 2;
  overflow-y: auto;
  overflow-x: hidden;
  &:only-child {
    border-radius: 6px;
  }
}
</style>
