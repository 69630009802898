<template>
  <rs-card class="mb-4">
    <rs-card-title class="pa-4">
      <rs-flex>
        <h3 class="headline font-weight-medium ma-0">
          <rs-icon class="mr-2">{{ icon }}</rs-icon>
          {{ title }}
        </h3>
      </rs-flex>
    </rs-card-title>
    <rs-divider />
    <rs-card-text class="pa-4">
      <div class="reporting__state__error__default">
        <div class="reporting__state__error__default__header mb-4">
          <rs-img :src="display.reportingErrorIcon" height="130" width="105px" contain />
        </div>
        <div class="reporting__state__error__default__content">
          <span class="display-1 mb-3">Looks like we’re having trouble</span>
          <span class="subheading">
            An error has occured. Please try again.
            <br />
            If the issue persists, please submit a
            <a href="https://rsbrands.zendesk.com/hc/en-us" target="_blank">support ticket.</a>
          </span>
        </div>
      </div>
    </rs-card-text>
  </rs-card>
</template>
<script>
import reportingErrorIcon from '@/assets/reporting_error_icon.svg';
export default {
  name: 'ErrorFallbackCard',
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      display: {
        reportingErrorIcon,
      },
    };
  },
};
</script>
