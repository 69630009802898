import numbro from 'numbro';

export const DEFAULT_ZERO_VALUE = 0;
export const DEFAULT_ZERO_VALUE_SYMBOL = '-';

export const EMPTY_VALUE_SYMBOL = '';

/**
 * Creates a new `count` object with two properties:
 *  - `input` of type `number`
 *  - `mask` of type `string`
 */
const getDefaultCount = () => ({
  input: DEFAULT_ZERO_VALUE,
  mask: DEFAULT_ZERO_VALUE_SYMBOL,
});

// Set the default symbol for the zero value: https://numbrojs.com/format.html#defaults
numbro.zeroFormat(DEFAULT_ZERO_VALUE_SYMBOL);

// List of numbro options: https://github.com/BenjaminVanRyseghem/numbro/blob/develop/src/formatting.js and https://github.com/BenjaminVanRyseghem/numbro/blob/develop/numbro.d.ts#L67
const NUMBRO_OPTIONS = {
  mantissa: 1,
  optionalMantissa: true,
  thousandSeparated: true,
  average: true,
  lowPrecision: false,
};

import ko from 'numbro/languages/ko-KR';
import zh from 'numbro/languages/zh-CN';

const languages = [ko, zh];

/**
 * Map of English number abbreviations.
 * @type {Object}
 */
const ABBREVIATIONS_ENGLISH = numbro.languages()['en-US'].abbreviations;

/**
 * Collection of International number abbreviations.
 *
 * Note: We are having to add international support for numbers since `numbro` does not seem to support simultaneous multiple language use, instead relying on a manual API with `registerLanguage` and `setLanguage`.
 *
 * @type {Object}
 */
const ABBREVIATIONS_INTERNATIONAL = languages.reduce(
  (abbreviations, language) => {
    Object.getOwnPropertyNames(abbreviations).forEach((name) => {
      // Note: Do not use spread operator as it causes issues with some of the foreign character abbreviations
      abbreviations[name] = abbreviations[name].concat(language.abbreviations[name]);
    });
    return abbreviations;
  },
  {
    thousand: [],
    million: [],
    billion: [],
    trillion: [],
  },
);

/**
 * Helper function for determining whether a `number` or `string` value can be formatted by `numbro`.
 * @param {number | string} x
 * @returns {boolean}
 */
const numbroValidate = (x, options = NUMBRO_OPTIONS) => numbro.validate(x, options);

/**
 * Helper function for converting a number to a formatted numeric string with `numbro`.
 * @param {number} n Number input
 * @returns {string} Formatted number string (or a default in case of error)
 */
const numbroFormat = (n, options = NUMBRO_OPTIONS) => {
  try {
    const canFormatNumber = numbroValidate(n);

    if (!canFormatNumber) {
      throw new Error();
    }

    return numbro(n).format(options);
  } catch (error) {
    return DEFAULT_ZERO_VALUE_SYMBOL;
  }
};

/**
 * Helper function for converting a numeric string to a number with `numbro`.
 * @param {string} s String input (or a default in case of error)
 * @returns {number} integer
 */
const numbroUnformat = (s) => {
  try {
    let n = numbro.unformat(s);

    const isValidNumber = n !== undefined && !isNaN(n);

    if (!isValidNumber) {
      throw new Error();
    }

    // Do not allow decimal values
    if (!Number.isInteger(n)) {
      n = Math.round(n);
    }

    return n;
  } catch (error) {
    return DEFAULT_ZERO_VALUE;
  }
};

/**
 * Parses and sanitizes the input string value entered by the user:
 *  - Remove whitespace
 *  - Replace International Abbreviations
 *  - Lowercase abbreviation symbols such as 'K', 'M', 'B', etc. for compatibility with `numbro`
 * @param {string} value
 * @returns {string}
 */
const parse = (value) => {
  value = value.trim();

  Object.entries(ABBREVIATIONS_INTERNATIONAL).forEach(([name, abbreviations]) => {
    abbreviations.forEach((abbreviation) => {
      value = value.replace(abbreviation, ABBREVIATIONS_ENGLISH[name]);
    });
  });

  return value.toLocaleLowerCase();
};

/**
 * Formats a number or string value and returns a new `count` object with a raw `input` value and a formatted `mask` as properties.
 * @param {number | string} value
 * @returns {object}
 */
const format = (x, options = NUMBRO_OPTIONS) => {
  let { input, mask } = getDefaultCount();

  // Numeric Input - From API Server
  if (typeof x === 'number') {
    // Do not allow decimal values
    if (!Number.isInteger(x)) {
      x = Math.round(x);
    }
    input = x;
    mask = numbroFormat(x, options);
  } // Alphanumeric Input - From User I/O
  else if (typeof x === 'string') {
    input = numbroUnformat(parse(x));
    mask = numbroFormat(input, options);
  }

  return { input, mask };
};

import { ref, watch } from 'vue';

/**
 * Composable for handling the parsing, formatting, masking, unmasking, and clearing of Follower Count Field input.
 *
 * @param {Object} context options
 * @param {Object} followerCount a Vue `Ref` containing a reactive `number` value
 * @returns {Object}
 */
export function useFollowerCountField({ emit }, followerCount) {
  const count = ref(getDefaultCount());

  /**
   * Inner field that stores binary count state:
   *  - `input` when unmasking
   *  - `mask` when masking
   *
   * The field toggles between the two states through user events.
   *
   * @type {string}
   */
  const current = ref(DEFAULT_ZERO_VALUE_SYMBOL);

  watch(
    () => followerCount.value,
    function updateCount(newValue, oldValue) {
      if (newValue !== count.value.input) {
        // Update the count and use the latest masked value
        count.value = format(newValue);
        current.value = count.value.mask;
      }
    },
    {
      immediate: true,
    },
  );

  /**
   * Vuetify Rule for validating numeric input with `numbro`.
   * @param {number | string} value
   * @returns {boolean | string} - `true` if valid or a (mostly truthy) `string` if `false`
   */
  const isValidNumbro = (value) => {
    if (typeof value === 'string') {
      value = parse(value ?? '');
    }

    const isValid = numbroValidate(value);

    if (!isValid) {
      return 'Please enter a valid number';
    }

    let n = value;

    if (typeof value === 'string') {
      n = numbroUnformat(value);
    }

    const isPositive = n >= 0;

    if (!isPositive) {
      return 'Please enter a positive number';
    }

    const isInteger = Number.isInteger(n);

    if (!isInteger) {
      return 'Number must be an integer';
    }

    return true;
  };

  /**
   * `unmask` - `focus` event handler
   */
  const unmask = (e) => {
    /**
     * Formatted Mask
     * @type {string}
     */
    const mask = e?.target?.value ?? '';

    // Zero Value Mask Toggle #1
    if (mask === DEFAULT_ZERO_VALUE_SYMBOL) {
      current.value = EMPTY_VALUE_SYMBOL;
      return;
    }

    // Disable unmasking for invalid values
    if (isValidNumbro(mask) !== true) {
      return;
    }

    /**
     * Unmask Numbro Format Options.
     *
     * Disable the following:
     *  - `average`
     *  - `lowPrecision`
     *
     * Note that `lowPrecision` must not be used if `average` isn't used, it leads to a runtime error.
     */
    const { average, lowPrecision, ...options } = NUMBRO_OPTIONS;

    // Unmask the number value for User I/O
    current.value = numbroFormat(count.value.input, {
      ...options,
    });
  };

  /**
   * `mask` - `blur` event handler
   */
  const mask = (e) => {
    /**
     * User Input
     * @type {string}
     */
    let input = e?.target?.value?.trim() ?? '';

    // Zero Value Mask Toggle #2
    if (!input || input === EMPTY_VALUE_SYMBOL) {
      input = DEFAULT_ZERO_VALUE_SYMBOL;
    }

    // Disable masking for invalid values
    if (isValidNumbro(input) !== true) {
      return;
    }

    // Update mask and input values
    count.value = format(input);

    // Mask the current value
    current.value = count.value.mask;

    // Emit latest values to parent component
    emit('input', count.value.input);
    emit('format', count.value.mask);
  };

  /**
   * `clear` - `click:clear` event handler
   */
  const clear = () => {
    // Reset the default values
    count.value = getDefaultCount();

    // Set the mask to empty due to the clear action retaining focus on the field (no new `focus` event is emitted to trigger unmasking)
    current.value = EMPTY_VALUE_SYMBOL;

    // Emit latest values to parent component
    emit('input', count.value.input);
    emit('format', count.value.mask);
  };

  return {
    count,
    current,
    isValidNumbro,
    unmask,
    mask,
    clear,
  };
}
