import Vue from 'vue';
import { influencerApplicationsService } from '@/internal-influencers-apps/services/influencer-applications-service';

const sortByAsc = (a, b) => a - b;

let currentPage = {};

import { FOLLOWER_COUNT_PLATFORM_DATA_TYPES } from '@/internal-influencers-apps/utils/followerCount';

export default {
  state: {
    countries: [],
    questions: [],
    countriesMap: {},
    platformsMap: {},
    resolutionsMap: {},
    applicantsMap: {},
    referralsMap: {},
    applicationIds: [],
    applicationsMap: {},
    applicationPlatformsMap: {},
    socialMetrics: [],
    applicationResolutionsMap: {},
    applicationMeta: {
      previous: '',
      next: '',
      limit: 25,
      total: 0,
    },
  },
  getters: {
    /**
     * Gets a list of Applications based on the Applicant ID.
     * @returns A list of Applications in ascending order based on `created_at`.
     */
    getApplicationsByApplicantId: (state) => (applicantId) =>
      Object.values(state.applicationsMap)
        .filter((application) => application.applicant_id === applicantId)
        .sort((a, b) => sortByAsc(new Date(a.created_at), new Date(b.created_at))),
    getApplicationResolutionByApplicationId: (state) => (applicationId) =>
      Object.values(state.applicationResolutionsMap).find(
        (applicationResolution) => applicationResolution.application_id === applicationId,
      ),
    getFollowerCountByApplicationPlatformId:
      (state) =>
      /**
       * Gets the Follower Count
       * @param {string} applicationPlatformId
       * @returns {number|undefined} count
       */
      (applicationPlatformId) =>
        state.socialMetrics
          .filter((metric) => Object.values(FOLLOWER_COUNT_PLATFORM_DATA_TYPES).includes(metric.platform_data_type_id))
          .find((metric) => metric.application_platform_id === applicationPlatformId)?.data_value,
    getExperienceQuestionsFor: (state) => (applicationId) => {
      const answers = state.applicationsMap[applicationId]?.application_answers ?? [];

      const result = answers
        .map((a) => {
          const question = state.questions.find((q) => q.question_id === a.question_id);
          const option = state.questions.flatMap((q) => q.options).find((o) => o.option_id === a.option_id);

          const isValidAnswer = question !== undefined && option !== undefined;

          const data = isValidAnswer
            ? {
                question_id: question.question_id,
                question_text: question.question_text,
                sort_order: question.sort_order,
                option_id: option.option_id,
                option_text: option.option_text,
              }
            : null;

          return data;
        })
        .filter((q) => q !== null)
        .sort((a, b) => sortByAsc(a.sort_order, b.sort_order));

      return result;
    },
    getPlatformNameFor: (state) => (platformId) => state.platformsMap[platformId]?.name ?? '',
  },
  mutations: {
    setCountries(state, countries) {
      state.countries = countries;
    },
    setQuestions(state, questions) {
      state.questions = questions;
    },
    setCountriesMap(state, countriesMap) {
      Vue.set(state, 'countriesMap', countriesMap);
    },
    setPlatforms(state, platforms) {
      Vue.set(state, 'platformsMap', platforms);
    },
    setResolutions(state, resolutions) {
      Vue.set(state, 'resolutionsMap', resolutions);
    },
    /**
     * Order in mutations matters in reactive applications. If any model has
     * a reference/link to another model then the linked model should be written
     * first. e.g. An application has an applicant; the applicant mutation should
     * happen first. If mutated backwards, then the app will react and try to show
     * data that doesn't exist in the store yet.
     */
    setApplications(state, data) {
      if (data.applicants !== undefined) {
        const applicants = Object.assign({}, state.applicantsMap, data.applicants);
        Vue.set(state, 'applicantsMap', applicants);
      }

      if (data.referrals !== undefined) {
        const referrals = Object.assign({}, state.referralsMap, data.referrals);
        Vue.set(state, 'referralsMap', referrals);
      }

      if (data.applicationPlatforms !== undefined) {
        const applicationPlatforms = Object.assign({}, state.applicationPlatformsMap, data.applicationPlatforms);
        Vue.set(state, 'applicationPlatformsMap', applicationPlatforms);
      }

      if (data.socialMetrics !== undefined) {
        const socialMetrics = data.socialMetrics;
        Vue.set(state, 'socialMetrics', socialMetrics);
      }

      if (data.applicationResolutions !== undefined) {
        const applicationResolutions = data.applicationResolutions;
        Vue.set(state, 'applicationResolutionsMap', applicationResolutions);
      }

      if (data.applications !== undefined) {
        const applications = Object.assign({}, state.applicationsMap, data.applications);
        Vue.set(state, 'applicationsMap', applications);
      }
    },
    setApplicationsPage(state, data) {
      // Overwrite meta
      Vue.set(state, 'applicationMeta', data.meta);
      // Update current page of ids
      state.applicationIds.splice(0, state.applicationIds.length, ...data.applicationIds);
    },
  },
  actions: {
    async loadCountries({ state, commit }, force = false) {
      // Ignore duplicate actions unless there is cause to refresh the list
      if (state.countries.length !== 0 && force !== true) {
        return;
      }

      const countries = await influencerApplicationsService.getCountries();
      commit('setCountries', countries);

      const countriesMap = {};
      countries.forEach((country) => {
        countriesMap[country.id] = country;
      });
      commit('setCountriesMap', countriesMap);
    },
    async loadQuestions({ state, commit }, force = false) {
      if (state.questions.length !== 0 && force !== true) {
        return;
      }

      const questions = await influencerApplicationsService.getQuestions();
      commit('setQuestions', questions);
    },
    async loadPlatforms({ state, commit }, force = false) {
      if (Object.keys(state.platformsMap).length !== 0 && force !== true) {
        return;
      }

      const platforms = await influencerApplicationsService.getPlatforms();
      const platformsMap = {};

      platforms.forEach((platform) => {
        platformsMap[platform.id] = platform;
      });
      commit('setPlatforms', platformsMap);
    },
    async loadResolutions({ state, commit }, force = false) {
      if (Object.keys(state.resolutionsMap).length !== 0 && force !== true) {
        return;
      }

      const resolutions = await influencerApplicationsService.getResolutions();
      const resolutionsMap = {};
      resolutions.forEach((resolution) => {
        resolutionsMap[resolution.id] = resolution;
      });
      commit('setResolutions', resolutionsMap);
    },
    async fetchApplicationPage(
      { state, commit },
      {
        countryIds,
        statuses,
        query,
        assignees,
        sortBy = 'created_at',
        descending = true,
        isNext = false,
        isPrevious = false,
        isCurrent = false,
      },
    ) {
      let next, previous;
      if (isNext === true) {
        next = state.applicationMeta.next;
      } else if (isPrevious === true) {
        previous = state.applicationMeta.previous;
      }

      const params = isCurrent
        ? currentPage
        : {
            countryIds,
            statuses,
            query,
            assignees,
            next,
            previous,
            sortBy,
            sortOrder: descending ? 'desc' : 'asc',
            limit: state.applicationMeta.limit,
          };

      // save the current state so requests are repeatable
      currentPage = params;

      const response = await influencerApplicationsService.getApplicationList(params);

      const data = {
        applicationIds: [],
        applications: {},
        applicationPlatforms: {},
        referrals: {},
        applicants: {},
        meta: response.meta,
      };
      response.applications?.forEach((application) => {
        data.applicationIds.push(application.id);
        data.applications[application.id] = application;
      });
      response.applicants?.forEach((applicant) => {
        data.applicants[applicant.id] = applicant;
      });
      response.application_platforms?.forEach((applicationPlatform) => {
        data.applicationPlatforms[applicationPlatform.id] = applicationPlatform;
      });
      response.referrals?.forEach((referral) => {
        data.referrals[referral.id] = referral;
      });

      commit('setApplications', data);
      commit('setApplicationsPage', data);
    },
    async fetchApplication({ state, commit }, applicationId) {
      // Create new array entity to avoid store mutation
      const applicationIds = [applicationId, ...(state.applicationsMap[applicationId]?.previous_application_ids ?? [])];

      const response = await influencerApplicationsService.getApplicationDetail(applicationIds);
      const data = {
        applications: {},
        applicationPlatforms: {},
        socialMetrics: [],
        applicationResolutions: {},
        referrals: {},
        applicants: {},
      };
      response.applications?.forEach((application) => {
        data.applications[application.id] = application;
      });
      response.applicants?.forEach((applicant) => {
        data.applicants[applicant.id] = applicant;
      });
      response.application_platforms?.forEach((applicationPlatform) => {
        data.applicationPlatforms[applicationPlatform.id] = applicationPlatform;
      });

      data.socialMetrics = response.social_metrics ?? [];

      response.referrals?.forEach((referral) => {
        data.referrals[referral.id] = referral;
      });
      response.application_resolutions?.forEach((resolution) => {
        data.applicationResolutions[resolution.id] = resolution;
      });

      commit('setApplications', data);
    },
    async updateSocialMetrics(context, { applicationId, data }) {
      await influencerApplicationsService.updateSocialMetrics(applicationId, data);
    },
    async createResolution({ dispatch }, { applicationId, resolutionId, isCommercialEntity }) {
      await influencerApplicationsService.createResolution(applicationId, resolutionId, isCommercialEntity);
      await dispatch('fetchApplication', applicationId);
    },
    async createBulkResolutions({ dispatch }, params) {
      await influencerApplicationsService.createBulkResolutions(params);
      params.forEach(async (app) => {
        await dispatch('fetchApplication', app.application_id);
      });
    },
  },
};
