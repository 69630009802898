export const listIdsDev = [4, 5, 6, 7, 8, 9, 10, 11, 12, 19500, 98, 2, 3];
export const listIdsProd = [105, 106, 107, 108, 109, 110, 111, 108492, 112, 113, 2];

export const listDetails = {
  'connect-default': {
    title: 'Connect Launch - Default',
    description: 'Launch, Pro, Scale',
  },
  'connect-ltk-all-stars': {
    title: 'Connect Launch - LTK All Stars',
    description: 'Launch, Pro, Scale',
  },
  'connect-ltk-rising-stars': {
    title: 'Connect Launch - LTK Rising Stars',
    description: 'Launch, Pro, Scale',
  },
  'connect-pro-default': {
    title: 'Connect Pro - Default',
    description: 'Pro, Scale',
  },
  'connect-pro-ltk-all-stars': {
    title: 'Connect Pro - LTK All Stars',
    description: 'Pro, Scale',
  },
  'connect-pro-ltk-rising-stars': {
    title: 'Connect Pro - LTK Rising Stars',
    description: 'Pro, Scale',
  },
  'connect-scale-default': {
    title: 'Connect Scale - Default',
    description: 'Scale',
  },
  'connect-scale-ltk-all-stars': {
    title: 'Connect Scale - LTK All Stars',
    description: 'Scale',
  },
  'connect-scale-ltk-rising-stars': {
    title: 'Connect Scale - LTK Rising Stars',
    description: 'Scale',
  },
  'liquid-marketplace-default': {
    title: 'Liquid Marketplace - Default',
    description: 'Liquid Marketplace Creator Access List',
  },
  test: {
    title: 'Test',
    description: 'Developers use only',
  },
  demo: {
    title: 'Demo',
    description: 'Developers use only',
  },
  denylist: {
    title: 'Deny List',
    description: 'Deny list',
  },
  'agency-allow-list': {
    title: 'Allow List',
    description: 'Allow list',
  },
};
