<template>
  <div class="divisions-add__content">
    <rs-form v-model="rules.valid">
      <rs-layout>
        <rs-flex>
          <rs-autocomplete
            label="Parent"
            :items="parents"
            item-text="display_name"
            item-value="id"
            v-model="selected.parent.id"
          />
          <rs-divider class="mb-4 mt-3" v-if="data.divisions.length > 0" />
          <section>
            <h2 class="divisions-list__header header-4 py-3 my-0">Divisions</h2>
            <ul class="divisions-list" v-if="data.divisions.length > 0">
              <li class="divisions-list__item" v-for="item in data.divisions" :key="item.id">
                <divisions-item :data="item" @saved="refreshDivisions" />
              </li>
            </ul>
            <rs-divider class="my-4" />
          </section>
          <section class="new-division">
            <h2 class="divisions-list__header header-4 py-3 my-0">Add New Divisions</h2>
            <div class="divisions-list__add" v-for="(item, index) in selected.division" :key="index">
              <rs-text-field
                label="Division Name"
                v-model="item.name"
                class="mr-4"
                :rules="[rules.required, rules.duplicate]"
              />
              <rs-button v-if="lastDivision !== index" size="sm" @click.prevent="removeNewDivision()" icon-only>
                <rs-icon style="font-weight: normal">close</rs-icon>
              </rs-button>
              <rs-button v-if="lastDivision === index" size="sm" primary @click.prevent="addNewDivision" icon-only>
                <rs-icon>add</rs-icon>
              </rs-button>
            </div>
            <div class="new-division__cta">
              <rs-button primary @click.prevent="submitNewDivisions">Save New Divisions</rs-button>
            </div>
          </section>
        </rs-flex>
      </rs-layout>
    </rs-form>
  </div>
</template>

<script>
import { defineComponent, reactive, watch, computed } from 'vue';
import RsButton from '../lib/components/RsButton/RsButton.vue';
import DivisionsItem from './DivisionsItem.vue';
import useDivisionApi from '../composables/useDivisionApi';

export default defineComponent({
  name: 'DivisionsNew',
  props: {
    parentId: {
      type: Number,
      default: null,
    },
    parents: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    RsButton,
    DivisionsItem,
  },
  setup(props, { emit }) {
    const { getDivisions, divisions, postParents } = useDivisionApi();

    const rules = reactive({
      valid: false,
      required: (value) => !!value || 'Required',
      duplicate: (value) => !data.divisions.map((item) => item.name).includes(value) || 'Division already exists',
    });

    const data = reactive({
      parents: [],
      divisions: [],
    });

    const selected = reactive({
      parent: {
        name: '',
        id: null,
      },
      division: [
        {
          name: '',
          id: null,
        },
      ],
      edited: false,
    });

    const addNewDivision = () => {
      selected.division.push({
        name: '',
      });
    };

    const removeNewDivision = (index) => {
      selected.division.splice(index, 1);
    };

    // Save parent id to local state
    watch(
      () => props.parentId,
      (next) => {
        if (next) {
          selected.parent.id = next;
        }
      },
      {
        immediate: true,
      },
    );

    // Request obj for new divisions
    const payload = computed(() => {
      return {
        parent_id: selected.parent.id,
        division: selected.division.filter((item) => item.name !== '').map((item) => item.name),
      };
    });

    const lastDivision = computed(() => {
      return selected.division.length - 1;
    });

    const refreshDivisions = async () => {
      await getDivisions(selected.parent.id);
      emit('edited', true);
      data.divisions = divisions;
    };

    watch(
      () => selected.parent.id,
      (next) => {
        if (next) {
          refreshDivisions();
        }
      },
      { immediate: true },
    );

    watch(
      () => rules.valid,
      () => {
        emit('valid', rules.valid);
      },
    );

    const submitNewDivisions = () => {
      if (payload.value) {
        postParents(payload.value).then((response) => {
          if (response) {
            emit('changed', response);
            refreshDivisions();
          }
        });
      }
    };

    return {
      data,
      selected,
      addNewDivision,
      removeNewDivision,
      divisions,
      rules,
      refreshDivisions,
      lastDivision,
      submitNewDivisions,
    };
  },
});
</script>

<style lang="scss">
.new-division__cta {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}
.divisions-list {
  margin: 0;
  list-style-type: none;
  margin-bottom: 16px;
  &__add {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  &__item {
    font-size: 14px;
    padding: 8px 0;
    display: flex;
    width: 100%;
    &:not(:last-child) {
      border-bottom: 1px solid #e0e0e0;
    }
  }
}
</style>
