<template>
  <div class="quality-control-compare-modal-header-match-select">
    <quality-control-match-button :value="matchStatus" @input="updateMatchStatus" />
    <quality-control-match-notes :value="matchNotes" @input="updateMatchNotes" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import useStore from '@/composables/vuex';

import QualityControlMatchButton from './QualityControlMatchButton.vue';
import QualityControlMatchNotes from './QualityControlMatchNotes.vue';

export default defineComponent({
  components: {
    QualityControlMatchButton,
    QualityControlMatchNotes,
  },
  props: {
    workItemId: String,
    productId: String,
    matchStatus: String,
    matchNotes: String,
  },
  setup(props) {
    const store = useStore();
    const updateMatchStatus = (value) => {
      store.dispatch('updateMatchingProduct', {
        id: props.workItemId,
        productId: props.productId,
        match: value,
        notes: props.matchNotes,
      });
    };

    const updateMatchNotes = (value) => {
      store.dispatch('updateMatchingProduct', {
        id: props.workItemId,
        productId: props.productId,
        match: props.matchStatus,
        notes: value,
      });
    };

    return {
      updateMatchStatus,
      updateMatchNotes,
    };
  },
});
</script>

<style lang="scss" scoped></style>
