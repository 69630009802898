import clientFactory from './client';

const client = clientFactory('/api/ltk/v2/profiles');

// Should be a noop in prod, but in lower envs where CDN logic cannot get exercised on data
// from prod, this will enable our image manipularion expectations to be true
const CDN_REWRITE_MAP = {
  'liketoknowit.s3.amazonaws.com/ltk-avatars': 'avatar-cdn.liketoknow.it', // prod
  's3.amazonaws.com/liketoknowit/ltk-avatars': 'avatar-cdn.liketoknow.it', // prod
  'liketoknowit-qa.s3.amazonaws.com/ltk-avatars': 'qa-avatar-cdn.liketoknow.it', // qa
  's3.amazonaws.com/liketoknowit-qa/ltk-avatars': 'qa-avatar-cdn.liketoknow.it', // qa
  'somehowitworks.s3.amazonaws.com/ltk-avatars': 'dev-avatar-cdn.somehowit.works', // dev
  's3.amazonaws.com/somehowitworks/ltk-avatars': 'dev-avatar-cdn.somehowit.works', // dev
};

function cdnRewriteAvatar(avatarUrl) {
  let newUrl = avatarUrl;

  Object.keys(CDN_REWRITE_MAP).forEach((prefix) => {
    newUrl = newUrl.replace(prefix, CDN_REWRITE_MAP[prefix]);
  });

  return newUrl;
}

/**
 * Mutates and handles any transformations necessary for profile data
 * such as forcing a CDN url for all avatar URLs.
 */
export function processProfile(data) {
  if (data.profiles !== undefined)
    data.profiles.forEach((profile) => {
      profile.avatar_url = cdnRewriteAvatar(profile.avatar_url);
    });

  if (data.profile !== undefined) {
    data.profile.avatarUrl = cdnRewriteAvatar(data.profile.avatar_url);
  }
}

class LtkProfileService {
  async get({ id = '', ids, display_names }) {
    const params = {};
    if (id === '') {
      params['ids'] = ids;
      params['display_names'] = display_names;
    }

    const response = await client.get(`/${id}`, { params });
    processProfile(response.data);
    return response;
  }
  async search(query) {
    const params = { query };

    return await client.get('/search', { params });
  }
}

export default new LtkProfileService();
