import axios from 'axios';
import Cookie from 'js-cookie';
import { LOCAL_STORAGE_KEYS } from '@rscollabs/rs-core-library/src/services/auth0';
import { initUnauthorizedResponseInterceptor } from '@rscollabs/rs-core-library/src/services/interceptors';

/**
 * There are two API Gateway options 😾
 */
const { TYK_GATEWAY_HOST, API_GATEWAY } = window.rewardStyle;

/**
 * Creates an Axios HTTP Client for making requests to our Gateway APIs.
 */
export const getClient = ({ host = TYK_GATEWAY_HOST, basePath = '', includeAuth = true }) => {
  const authCookie = Cookie.get(LOCAL_STORAGE_KEYS.AUTH0_CODE);
  const authCodeFromStorage = localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH0_CODE);

  const bearerToken = authCodeFromStorage ? authCodeFromStorage : authCookie;
  const headers = includeAuth ? { Authorization: `Bearer ${bearerToken}` } : {};

  const client = axios.create({
    baseURL: `${host}${basePath}`,
    headers: headers,
  });

  // Add a 401 Response interceptor
  initUnauthorizedResponseInterceptor(client);

  return client;
};
