import Vue from 'vue';
import Router from 'vue-router';
import AuthService from '@rscollabs/rs-core-library/src/services/auth0';
import Nexus from '@/views/Nexus.vue';
import { validateClaimsAccess } from '@/utils/check-claims';

import { getUserRole } from './internal-advertisers/composables/useAdvertiserRoleCheck';

Vue.use(Router);

const handleAuth = (to, from, next) => {
  try {
    validateClaimsAccess();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    next({ name: 'Logout' });
  }
  AuthService.handleRouteNeedsLogin(to, from, next);
};

const handleAdvertiserAccess = async (to, from, next) => {
  const ACP_ROLES = await getUserRole();
  ACP_ROLES?.includes('update-advertiser') || ACP_ROLES?.includes('create-advertiser')
    ? next()
    : next({ name: 'Advertisers' });
};

const routes = [
  {
    name: 'AccountManagement',
    path: '/collabs/account/:accountId',
    beforeEnter: handleAuth,
    component: () => import(/* webpackChunkName: "accounts" */ '@/internal-accounts/views/AccountManagement.vue'),
    props: true,
    meta: {
      title: 'Edit Account',
    },
  },
  {
    name: 'Accounts',
    path: '/collabs/accounts',
    beforeEnter: handleAuth,
    component: () => import(/* webpackChunkName: "accounts" */ '@/internal-accounts/views/Accounts.vue'),
    meta: {
      title: 'Account Management',
    },
  },
  {
    name: 'Posts',
    path: '/collabs/posts',
    beforeEnter: handleAuth,
    props: true,
    component: () => import(/* webpackChunkName: "posts" */ '@/internal-posts/views/Posts.vue'),
    meta: {
      title: 'Post Management',
    },
    children: [
      {
        name: 'EditPostDialog',
        path: ':postId',
        beforeEnter: handleAuth,
        props: true,
        meta: {
          showModal: true,
          title: 'Post Management',
        },
        component: () => import(/* webpackChunkName: "posts" */ '@/internal-posts/components/DialogPost.vue'),
      },
    ],
  },
  {
    name: 'Curation',
    path: '/activity/curation',
    component: () => import(/* webpackChunkName: "curation" */ '@/internal-curation/views/Labeling.vue'),
    meta: {
      title: 'Interest Training',
    },
  },
  {
    name: 'Campaigns',
    path: '/collabs/campaigns',
    beforeEnter: handleAuth,
    props: true,
    component: () => import(/* webpackChunkName: "campaigns" */ '@/internal-campaigns/views/Campaigns.vue'),
    meta: {
      title: 'Campaign Management',
    },
  },
  {
    name: 'CastingLists',
    path: '/collabs/casting',
    beforeEnter: handleAuth,
    props: true,
    component: () => import(/* webpackChunkName: "campaigns" */ '@/internal-casting/views/Casting.vue'),
    meta: {
      title: 'Casting Lists',
    },
  },
  {
    name: 'CastingListDetail',
    path: '/collabs/casting/:listName',
    beforeEnter: handleAuth,
    component: () => import(/* webpackChunkName: "accounts" */ '@/internal-casting/views/CastingDetail.vue'),
    props: true,
    meta: {
      title: 'Casting List Detail',
    },
  },
  {
    name: 'LtkRecommendations',
    path: '/ltk/recommendations/',
    beforeEnter: handleAuth,
    component: () =>
      import(/* webpackChunkName: "ltk-recommendations" */ '@/internal-ltk-recommendations/views/Recommendations.vue'),
    meta: { title: 'LTK Recommendations' },
    children: [
      {
        name: 'LtkProfileVectorSearch',
        path: 'profile-vectors',
        beforeEnter: handleAuth,
        component: () =>
          import(
            /* webpackChunkName: "ltk-recommendations" */ '@/internal-ltk-recommendations/views/ProfileVectors.vue'
          ),
        meta: { title: 'Profile Search' },
        children: [
          {
            name: 'LtkProfileVectorManagement',
            path: ':profileId',
            beforeEnter: handleAuth,
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "ltk-recommendations" */ '@/internal-ltk-recommendations/views/ProfileVectorManagement.vue'
              ),
            meta: { title: 'Edit Profile Vectors' },
          },
        ],
      },
    ],
  },
  {
    name: 'LtkSales',
    path: '/ltk/sales/',
    beforeEnter: handleAuth,
    component: () => import(/* webpackChunkName: "cp-sales" */ '@/internal-cp-sales/views/Sales.vue'),
    meta: { title: 'CP - Sales Management' },
    children: [
      {
        name: 'LtkSaleAdd',
        path: 'add',
        beforeEnter: handleAuth,
        props: {
          saleId: 'add',
        },
        component: () => import(/* webpackChunkName: "cp-sales" */ '@/internal-cp-sales/views/SaleManagement.vue'),
        meta: { title: 'Add Sale' },
      },
      {
        name: 'LtkSaleManagement',
        path: ':saleId',
        beforeEnter: handleAuth,
        props: true,
        component: () => import(/* webpackChunkName: "cp-sales" */ '@/internal-cp-sales/views/SaleManagement.vue'),
        meta: { title: 'Edit Sale' },
      },
    ],
  },
  {
    name: 'LtkBubbles',
    path: '/ltk/bubbles/:bubbleId?',
    beforeEnter: handleAuth,
    component: () => import(/* webpackChunkName: "ltk-bubbles" */ '@/internal-ltk-bubbles/views/Bubbles.vue'),
    meta: { title: 'CP - Bubble Management' },
  },
  {
    name: 'InfluencerAppsOld',
    redirect: '/cos/applications',
    path: '/ltk/apps',
  },
  {
    name: 'InfluencersApps',
    path: '/cos/applications',
    beforeEnter: handleAuth,
    component: () =>
      import(/* webpackChunkName: "influencers-apps" */ '@/internal-influencers-apps/views/ListView.vue'),
  },
  {
    name: 'PodManagement',
    path: '/cos/pod-management',
    component: () =>
      import(/* webpackChunkName: "influencers-accounts" */ '@/internal-pod-management/views/Accounts.vue'),
  },
  {
    name: 'Hashtags',
    path: '/hashtags',
    props: (route) => ({
      defaultCountry: route.query.defaultCountry,
    }),
    beforeEnter: handleAuth,
    component: () => import(/* webpackChunkName: "hashtags" */ '@/internal-hashtags/views/Hashtags.vue'),
  },
  {
    name: 'HashtagsAdd',
    path: '/hashtags/add',
    props: (route) => ({
      groupLabel: route.params.groupLabel,
      defaultCountry: route.query.defaultCountry,
    }),
    beforeEnter: handleAuth,
    component: () => import(/* webpackChunkName: "hashtags" */ '@/internal-hashtags/views/HashtagsManage.vue'),
  },
  {
    name: 'HashtagsEdit',
    path: '/hashtags/edit/:groupLabel',
    props: (route) => ({
      groupLabel: route.params.groupLabel,
      defaultCountry: route.query.defaultCountry,
    }),
    beforeEnter: handleAuth,
    component: () => import(/* webpackChunkName: "hashtags" */ '@/internal-hashtags/views/HashtagsManage.vue'),
  },
  {
    name: 'Banners',
    path: '/banners',
    props: (route) => ({
      defaultCountry: route.query.defaultCountry,
    }),
    beforeEnter: handleAuth,
    component: () => import(/* webpackChunkName: "banners" */ '@/internal-banners/views/Banners.vue'),
  },
  {
    name: 'BannersAdd',
    path: '/banners/add',
    props: (route) => ({
      groupLabel: route.params.groupLabel,
      defaultCountry: route.query.defaultCountry,
    }),
    beforeEnter: handleAuth,
    component: () => import(/* webpackChunkName: "banners" */ '@/internal-banners/views/BannersManage.vue'),
  },
  {
    name: 'BannersEdit',
    path: '/banners/edit/:groupLabel',
    props: (route) => ({
      groupLabel: route.params.groupLabel,
      defaultCountry: route.query.defaultCountry,
    }),
    beforeEnter: handleAuth,
    component: () => import(/* webpackChunkName: "banners" */ '@/internal-banners/views/BannersManage.vue'),
  },
  {
    name: 'Bubbles',
    path: '/bubbles',
    props: (route) => ({
      defaultCountry: route.query.defaultCountry,
    }),
    beforeEnter: handleAuth,
    component: () => import(/* webpackChunkName: "bubbles" */ '@/internal-bubbles/views/Bubbles.vue'),
  },
  {
    name: 'BubblesAdd',
    path: '/bubbles/add',
    props: (route) => ({
      groupLabel: route.params.groupLabel,
      defaultCountry: route.query.defaultCountry,
    }),
    beforeEnter: handleAuth,
    component: () => import(/* webpackChunkName: "bubbles" */ '@/internal-bubbles/views/BubblesManage.vue'),
  },
  {
    name: 'BubblesEdit',
    path: '/bubbles/edit/:groupLabel',
    props: (route) => ({
      groupLabel: route.params.groupLabel,
      defaultCountry: route.query.defaultCountry,
    }),
    beforeEnter: handleAuth,
    component: () => import(/* webpackChunkName: "bubbles" */ '@/internal-bubbles/views/BubblesManage.vue'),
  },
  {
    name: 'Advertisers',
    path: '/links/advertisers',
    beforeEnter: handleAuth,
    component: () => import(/* webpackChunkName: "links-advertisers" */ '@/internal-advertisers/views/Advertisers.vue'),
  },
  {
    name: 'Advertiser',
    path: '/links/advertisers/:id',
    beforeEnter: handleAdvertiserAccess,
    component: () =>
      import(/* webpackChunkName: "links-advertisers" */ '@/internal-advertisers/views/AdvertiserConfig.vue'),
  },
  {
    name: 'CatalogQualityControl',
    path: '/catalog/quality-control',
    beforeEnter: handleAuth,
    component: () =>
      import(/* webpackChunkName: "catalog-quality-control" */ '@/internal-catalog/views/QualityControl.vue'),
    meta: { title: 'Catalog Quality Control' },
  },
  {
    name: 'Login',
    path: '/login/callback',
    beforeEnter: AuthService.handleCallback.bind(AuthService),
  },
  {
    name: 'Logout',
    path: '/logout',
    beforeEnter: AuthService.handleLogout.bind(AuthService),
  },
  {
    name: 'nexus',
    path: '/',
    beforeEnter: handleAuth,
    component: Nexus,
  },
  {
    path: '*',
    redirect: '/',
  },
];

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(savedPosition);
        }, 200);
      });
    } else {
      return window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto',
      });
    }
  },
  routes,
});
