<template>
  <div class="regions">
    <h3 class="header-4 mb-2">
      Regions
      <span tabindex="0" class="mt-2 ml-2">
        <rs-icon small class="shrink">info_outline</rs-icon>
        <rs-tip :offset="[0, 16]" placement="right">
          The regions for which deeplinking will actually pay out a commission.
        </rs-tip>
      </span>
    </h3>
    <rs-layout justify-space-between>
      <rs-flex sm9 md6>
        <rs-text-field clearable type="search" v-model="search" label="Search" />
      </rs-flex>
      <rs-flex sm3 md3 align-self-center class="text-xs-right">
        <rs-button @click.prevent="filterBy = !filterBy" size="xs">
          Show {{ filterBy ? 'All' : 'Selected' }}
        </rs-button>
      </rs-flex>
    </rs-layout>
    <dynamic-scroller
      class="regions__content regions__content--overflow"
      key-field="region"
      :items="filteredRegions"
      :min-item-size="74"
    >
      <template #default="{ item, index, active }">
        <dynamic-scroller-item :active="active" :item="item" :size-dependency="[item.pages]" :data-index="index">
          <div class="regions__item">
            <div class="regions__item--start">
              <rs-checkbox
                :label="`${item.name} (${item.symbol})`"
                v-model="item.active"
                dense
                :key="index"
                @change="handleDirtyFields"
              />
            </div>
            <div class="regions__item--end">
              <rs-combobox
                v-model="item.pages"
                label="Homepage"
                :disabled="!item.active || !active"
                dense
                multiple
                small-chips
                deletable-chips
                flat
                :key="index"
                hint="Press Enter to add a new entry"
                @change="handleDirtyFields"
              />
            </div>
          </div>
        </dynamic-scroller-item>
      </template>
    </dynamic-scroller>
    <rs-layout>
      <rs-flex xs12>
        <rs-divider class="my-4" />
        <rs-combobox
          label="Other Homepages"
          v-model.trim="whitelist"
          multiple
          dense
          small-chips
          deletable-chips
          hint="Press Enter to add a new entry"
          @change="handleDirtyFields"
        />
      </rs-flex>
    </rs-layout>
  </div>
</template>

<script>
import { watchEffect, reactive, ref, toRefs, watch, computed } from 'vue';
import RsButton from '../lib/components/RsButton/RsButton.vue';
import { DynamicScrollerItem, DynamicScroller } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import RsTip from '../lib/components/RsTip/RsTip.vue';

export default {
  name: 'AdvertiserRegions',
  components: { RsButton, DynamicScrollerItem, DynamicScroller, RsTip },
  props: {
    regions: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['region-change', 'dirty'],
  setup(props, { emit }) {
    const data = reactive({
      regionsList: [],
      whitelist: [],
      dirty: false,
    });

    // Local Data
    watchEffect(
      () => {
        data.whitelist = props.selected.filter((item) => item.region === null)[0]?.pages;
        data.regionsList = props.regions
          .filter((item) => !!item.name)
          .map((region) => {
            const selected = props.selected.find((item) => item.region === region.abbreviation);
            return {
              id: region.abbreviation,
              region: region.abbreviation,
              symbol: region.symbol,
              name: region.name,
              active: selected ? selected.active : false,
              pages: selected && selected.pages ? selected.pages : [],
            };
          });
      },
      { flush: 'post' },
    );

    // Search
    const search = ref('');

    const onSearch = (obj, value) => {
      const lowercase = obj.name.toLowerCase();
      const result = value === '' || lowercase.indexOf(value ? value.toLowerCase() : '') !== -1;
      return result;
    };

    // Filters
    const filterBy = ref(false);

    const onFilter = (obj, filter) => {
      const isActive = obj.active;
      const hasPages = obj.pages.length > 0;
      const result = isActive || hasPages;
      return obj && filter ? result : true;
    };

    const filteredRegions = computed(() => {
      return data.regionsList
        .map((item) => item)
        .filter((item) => {
          return onSearch(item, search.value) && onFilter(item, filterBy.value);
        });
    });

    const handleDirtyFields = () => {
      data.dirty = true;
      emit('dirty', data.dirty);
    };

    // Output
    const emitter = computed(() => {
      const regionsAndPages = data.regionsList
        .filter((item) => item.active || item.pages.length >= 1)
        .map((item) => {
          return {
            region: item.region,
            active: item.active,
            pages: item.pages,
          };
        });

      const pages = {
        region: null,
        pages: data.whitelist,
        active: true,
      };
      return [pages, ...regionsAndPages];
    });

    watch(
      () => emitter.value,
      () => {
        emit('region-change', emitter.value.length >= 0 ? emitter.value : null);
      },
      { deep: true, immediate: true, flush: 'post' },
    );

    return { ...toRefs(data), emitter, onSearch, onFilter, search, filterBy, filteredRegions, handleDirtyFields };
  },
};
</script>

<style lang="scss">
@use '../scss/breakpoints';
.regions {
  &__item {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    &--start,
    &--end {
      width: 100%;
    }
    &--start {
      max-width: 315px;
    }
    @include breakpoints.down('sm') {
      flex-wrap: wrap;
      &--start {
        max-width: 100%;
      }
    }
  }
  &__content {
    height: 100%;
    &--overflow {
      max-height: 500px;
      overflow-x: hidden;
      overflow-y: auto;
      border: 1px solid var(--bg-3);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.01), 0px 8px 16px rgba(0, 0, 0, 0.06);
      padding: 16px;
      border-radius: 6px;
    }
  }

  // Vuetify Overrides
  .v-input.v-select--chips {
    width: 100% !important;
    .v-chip__content {
      text-transform: inherit !important;
    }
    .v-input__icon {
      display: none;
    }
  }
}
</style>
