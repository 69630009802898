<template>
  <rs-card v-if="hasFetched" data-test-id="qa-campaign-insights-container">
    <rs-layout pa-4 justify-space-between>
      <rs-flex header-h4-sofiapro>Insights for {{ campaign.title }}</rs-flex>
      <rs-icon @click="closeDialog" size="20px">ltkfont-x-close-outline</rs-icon>
    </rs-layout>
    <rs-divider></rs-divider>
    <rs-layout pa-4 mx-5 v-if="!hasReporting" data-test-id="qa-notes-disabled-messsage"
      ><rs-flex>
        Notes are not enabled for this campaign because it is not visible to the Brand's reporting dashboard. Campaigns
        must be less than 26 months old and have at least one approved obligation to be visible.
      </rs-flex></rs-layout
    >
    <rs-layout v-else column pa-4 mx-5 data-test-id="qa-campaign-insights-form">
      <rs-combobox
        label="Note"
        dense
        v-model="selectedNote"
        :items="noteOptions"
        item-text="title"
        :disabled="!hasNotes"
        :loading="loading"
      />
      <rs-text-field :disabled="published" label="Title" v-model="title" :rules="[rules.required]"></rs-text-field>
      <rs-flex py-2 caption-1 style="color: #757575">Note text</rs-flex>
      <insight-input ref="messageInput" :note-text.sync="message" :disabled="published" />
      <rs-flex pt-4>
        <rs-date-picker
          v-if="datePickerOpen"
          single-date
          :date-one.sync="publishDate"
          date-format="MM/dd/yyyy"
          :open.sync="datePickerOpen"
          class="date-picker"
          :min-date="today.toLocaleDateString()"
        />
        <rs-text-field
          :disabled="published"
          @click="toggleModal"
          @click:append="toggleModal"
          label="Publish Date"
          :value="publishDate"
          append-icon="ltkfont-event-calendar-outline"
        ></rs-text-field>
      </rs-flex>
    </rs-layout>
    <rs-layout>
      <rs-layout justify-end px-4 pb-4>
        <rs-btn @click="closeDialog" :block="false" data-test-id="qa-insights-cancel-btn">Cancel</rs-btn>
        <rs-btn
          v-if="!newNote"
          :inverted="published"
          :disabled="published"
          :large="published"
          :block="false"
          @click="deleteNote"
          data-test-id="qa-insights-delete-btn"
          >Delete</rs-btn
        >
        <rs-btn
          :disabled="!allowSave"
          inverted
          :large="newNote"
          :block="false"
          @click="save"
          v-if="showSaveBtn"
          data-test-id="qa-insights-save-note-btn"
          >Save note</rs-btn
        >
      </rs-layout>
    </rs-layout>
  </rs-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import InsightInput from './InsightInput.vue';
export default {
  name: 'CampaignInsights',
  components: {
    InsightInput,
  },
  props: {
    campaign: {
      type: Object,
      default: () => ({}),
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    today: '',
    datePickerOpen: false,
    publishDate: '',
    selectedNote: {
      title: 'Create new note',
    },
    title: '',
    message: '',
    loading: false,
    rules: {
      required: (value) => !!value || 'Field is required',
    },
  }),
  computed: {
    ...mapGetters('reports', ['getNotesByCampaignId', 'accountCampaignsWithReports']),
    notes() {
      return this.getNotesByCampaignId(this.campaign.id || 0)?.data;
    },
    noteOptions() {
      const newNote = [{ title: 'Create new note' }];
      const existingNotes = this.notes || [];
      return newNote.concat(existingNotes);
    },
    hasNotes() {
      return this.noteOptions.length > 1;
    },
    newNote() {
      return !this.selectedNote.id;
    },
    published() {
      const today = new Date(this.today).getTime();
      const publishDate = new Date(this.selectedNote.date_viewable).getTime();
      return !this.newNote && today > publishDate;
    },
    showSaveBtn() {
      return !this.published || this.newNote;
    },
    msgInput() {
      const userInput = this.$refs.messageInput?.msgInput;
      return userInput ? userInput : this.message;
    },
    allowSave() {
      return this.title && this.msgInput;
    },
    hasReporting() {
      return this.accountCampaignsWithReports({
        brandID: this.campaign.brand_id,
      })?.data?.some((campaign) => {
        return campaign.id == this.campaign.id;
      });
    },
    hasFetched() {
      return this.accountCampaignsWithReports({
        brandID: this.campaign.brand_id,
      })?.meta?.hasFetched;
    },
  },
  created() {
    this.today = new Date();
    this.publishDate = this.today.toLocaleDateString();
    if (this.isOpen) {
      this.fetchData();
    }
  },
  methods: {
    ...mapActions('reports', [
      'fetchCampaignNotes',
      'createCampaignNote',
      'updateCampaignNote',
      'deleteCampaignNote',
      'getAccountCampaignsWithReports',
    ]),
    toggleModal() {
      this.datePickerOpen = !this.datePickerOpen;
    },
    savePublishDate(payload) {
      this.datePickerOpen = false;
    },
    closeDialog() {
      this.$emit('close');
    },
    save() {
      if (this.selectedNote.id) {
        this.updateNote(this.msgInput);
      } else {
        this.createNote(this.msgInput);
      }
      this.closeDialog();
    },
    createNote(msg) {
      const today = this.today.toLocaleDateString();
      const date = this.publishDate == today ? new Date() : new Date(this.publishDate);
      let createObj = {
        message: msg,
        title: this.title,
        date_viewable: date.toISOString(),
      };

      this.createCampaignNote({
        campaignId: this.campaign.id,
        payload: createObj,
      });
    },
    updateNote(msg) {
      const today = this.today.toLocaleDateString();
      const date = this.publishDate == today ? new Date() : new Date(this.publishDate);
      let updateObj = {
        id: this.selectedNote.id,
        title: this.title,
        message: msg,
        date_viewable: date.toISOString(),
      };

      this.updateCampaignNote({
        campaignId: this.campaign.id,
        payload: updateObj,
      });
    },
    deleteNote() {
      let deleteObj = {
        id: this.selectedNote.id,
      };

      this.deleteCampaignNote({
        campaignId: this.campaign.id,
        payload: deleteObj,
      });
      this.closeDialog();
    },
    async fetchData() {
      this.loading = true;
      let callsArr = [];
      callsArr.push(this.fetchCampaignNotes(this.campaign.id));
      callsArr.push(
        this.getAccountCampaignsWithReports({
          brandID: this.campaign.brand_id,
        }),
      );
      await Promise.all(callsArr);
      this.loading = false;
    },
  },
  watch: {
    isOpen(newVal, oldVal) {
      if (newVal) {
        this.fetchData();
      }
    },
    selectedNote(newVal) {
      let date = newVal.date_viewable ? new Date(newVal.date_viewable) : this.today;
      this.title = newVal.title == 'Create new note' ? '' : newVal.title;
      this.message = newVal.message;
      this.publishDate = date.toLocaleDateString();
    },
  },
};
</script>

<style lang="sass" scoped>
::v-deep
  div[role="listitem"]:first-of-type
    border-bottom: solid #CCCCCC 1px

.date-picker
  bottom: 326px
</style>
