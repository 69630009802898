<template>
  <rs-menu
    :max-height="$vuetify.breakpoint.smAndUp ? 300 : '100%'"
    :min-width="$vuetify.breakpoint.smAndUp ? 375 : '100%'"
    lazy
    v-model="isOpen"
    :close-on-content-click="false"
    :left="$vuetify.breakpoint.smAndUp"
    :top="$vuetify.breakpoint.xsOnly"
  >
    <rs-tooltip slot="activator" top>
      <slot name="activator" slot="activator">
        <rs-btn fab icon small slot="activator" :block="false" :outline="false" :flat="false">
          <rs-icon>rsfont-add-standard</rs-icon>
        </rs-btn>
      </slot>
      <slot name="default">Add Item</slot>
    </rs-tooltip>
    <rs-card>
      <rs-card-text class="py-0">
        <rs-text-field v-model="searchQuery" :label="`Search ${listName}`" hide-details />
      </rs-card-text>
      <rs-list :expand="isSearching" dense>
        <template v-for="item in filteredItems">
          <rs-list-group :key="item[itemValue]" v-model="item.active" v-if="item.children.length > 0">
            <template #activator>
              <rs-list-tile>
                <rs-list-tile-content>
                  <rs-list-tile-title>{{ item[itemText] }}</rs-list-tile-title>
                </rs-list-tile-content>
              </rs-list-tile>
            </template>
            <template v-for="subItem in item.children">
              <rs-list-tile :key="subItem[itemValue]" @click="handleSelect(subItem)">
                <rs-list-tile-action>
                  <rs-icon>{{ subItem.action }}</rs-icon>
                </rs-list-tile-action>

                <rs-list-tile-content>
                  <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
                  <rs-list-tile-title v-html="genFilteredText(subItem[itemText])" />
                </rs-list-tile-content>
              </rs-list-tile>
            </template>
          </rs-list-group>
        </template>
      </rs-list>
    </rs-card>
  </rs-menu>
</template>

<script>
import { escapeHTML } from 'vuetify/es5/util/helpers';
import { hierarchy } from '../utils/listSorting';

export default {
  props: {
    items: Array,
    usedItems: Array,
    itemValue: {
      type: String,
      default: 'value',
    },
    itemText: {
      type: String,
      default: 'text',
    },
    listName: {
      type: String,
      default: 'Items',
    },
  },
  data() {
    return {
      isOpen: false,
      searchQuery: '',
    };
  },
  computed: {
    isSearching() {
      return Boolean(this.searchQuery);
    },
    hierarchyItems() {
      return hierarchy(this.items);
    },
    filteredItems() {
      const unusedReducer = (list, item) => {
        const clone = JSON.parse(JSON.stringify(item));
        const isParent = clone.children !== undefined;
        const isUsed = this.usedItems.includes(clone.id);
        const isSearchMatched = clone.name.toLowerCase().includes(this.searchQuery.toString().toLocaleLowerCase());

        // Depth first search with children
        if (isParent) {
          clone.children = clone.children.reduce(unusedReducer, []);
        }

        const hasChildren = isParent && clone.children.length > 0;
        // While searching, expand all parents to better show matches
        if (hasChildren && this.searchQuery) {
          clone.active = true;
        }

        /**
         * Item should be added to the list if:
         * - Any of its children also matched
         * - OR
         * - Its value is not in the list of used items
         * - If searching, it is found in the search query
         * - It is not a parent
         */
        if (hasChildren || (!isUsed && isSearchMatched && !isParent)) {
          list.push(clone);
        }

        return list;
      };
      return this.hierarchyItems.reduce(unusedReducer, []);
    },
  },
  methods: {
    resetSearch() {
      this.searchQuery = '';
    },
    handleSelect(item) {
      this.$emit('select-item', item[this.itemValue]);
      this.isOpen = false;
      // Only resetting the search query if the user selects an item
      this.resetSearch();
    },

    // Copied from vuetify's VSelect Component (component variables adjusted to fit use case)
    genFilteredText(text) {
      text = (text || '').toString();

      if (!this.isSearching) {
        return text;
      }

      const { start, middle, end } = this.getMaskedCharacters(text);

      return `${escapeHTML(start)}${this.genHighlight(middle)}${escapeHTML(end)}`;
    },
    genHighlight(text) {
      return `<span class="v-list__tile__mask">${escapeHTML(text)}</span>`;
    },
    getMaskedCharacters(text) {
      const searchInput = (this.searchQuery || '').toString().toLocaleLowerCase();
      const index = text.toLocaleLowerCase().indexOf(searchInput);

      if (index < 0) return { start: '', middle: text, end: '' };

      const start = text.slice(0, index);
      const middle = text.slice(index, index + searchInput.length);
      const end = text.slice(index + searchInput.length);
      return { start, middle, end };
    },
    // End copy pasta
  },
};
</script>
