const FILTERS = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Display name',
    value: 'display',
  },
  {
    label: 'Affiliate ID',
    value: 'affiliateid',
  },
  {
    label: 'Advertiser ID',
    value: 'id',
  },
  {
    label: 'Commission rate',
    value: 'commission',
  },
  {
    label: 'Parent ID',
    value: 'parent_id',
  },
  {
    label: 'Regions',
    value: 'region',
  },
  {
    label: 'Source name',
    value: 'source_description',
  },
];

const NUMERIC_OPERATORS = [
  {
    label: 'Greater than',
    value: '>',
  },
  {
    label: 'Greater than or equal',
    value: '>=',
  },
  {
    label: 'Less than',
    value: '<',
  },
  {
    label: 'Less than or equal',
    value: '<=',
  },
  {
    label: 'Equal to',
    value: '=',
  },
];

export { FILTERS, NUMERIC_OPERATORS };
