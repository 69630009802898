import Vue from 'vue';
import VuePortal from '@linusborg/vue-simple-portal';
import registerPlugins from '@rscollabs/rs-core-library/src/plugins';
import App from './App.vue';
import router from './router';
import store from '@rscollabs/rs-core-library/src/store';
import { createPinia, PiniaVuePlugin } from 'pinia';
import { setErrorCatch } from '@rscollabs/rs-core-library/src/utils/error';
import { storeKey } from '@/composables/vuex';
import { initializeDatadog } from './datadog';
import { formatCurrencyExt } from './utils/helpers';
import { install as VueGoogleMapsInstall } from 'vue2-google-maps';
import * as FullStory from '@fullstory/browser';
import { runwayInstall } from '@rewardstyle/runway-components-vue';
import '@rewardstyle/runway-components-vue/dist/v2.7/style.css';
import '@rewardstyle/runway-components-vue/dist/v2.7/fonts.css';
import { Amplitude } from './amplitude';

// enable full story for qa and prod only
const devEnvs = ['', 'local', 'dev'];
const devMode = devEnvs.includes(window.rewardStyle.ENVIRONMENT);

const pinia = createPinia();
Vue.use(PiniaVuePlugin);

FullStory.init({
  orgId: 'WG0DF',
  devMode: devMode,
});
Vue.prototype.$FullStory = FullStory;

setErrorCatch();

if (rewardStyle.DATADOG_ENABLED) {
  try {
    initializeDatadog(rewardStyle.DATADOG_APPLICATION_ID, rewardStyle.DATADOG_CLIENT_TOKEN, rewardStyle.ENVIRONMENT);
  } catch (error) {
    console.error('There was a problem while trying to initialize datadog', error);
  }
}
Vue.config.errorHandler = function (err, vm, info) {
  Vue.rollbar.error(err, info);
};

Vue.use(
  {
    install: VueGoogleMapsInstall,
  },
  {
    installComponents: true,
    // don't pass in `load` options here to allow for lazy loading
  },
);

Vue.use(VuePortal);
Vue.use(runwayInstall, { theme: 'brand', scheme: 'light' });
registerPlugins(Vue);

store.strict = process.env.NODE_ENV !== 'production';

Vue.config.devtools = window.rewardStyle.devtools;

Vue.filter('currency', function (value) {
  return formatCurrencyExt(value);
});

Vue.prototype.$amplitude = new Amplitude(rewardStyle.AMPLITUDE_KEY);

new Vue({
  router,
  store,
  pinia,
  provide: {
    [storeKey]: store,
  },
  render: (h) => h(App),
}).$mount('#internal-app');
