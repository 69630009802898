<template>
  <rs-form v-model="isValid" @submit.prevent>
    <div class="d-flex justify-space-between align-center mb-3">
      <rs-btn :loading="loading" :disabled="!isValid || loading" @click.stop="createResolution('pending')" class="mr-3"
        >pending
      </rs-btn>
      <rs-btn :loading="loading" :disabled="!isValid || loading" @click.stop="createResolution('approve')" class="mr-3"
        >approve
      </rs-btn>
      <decline-reasons :loading="loading" :disabled="!isValid" @decline="createResolution" />
      <rs-btn
        :loading="loading"
        :disabled="!isValid || loading"
        @click.stop="isCommercialEntity = true"
        v-if="!isCommercialEntity && application.status === 'pending'"
        >commercial entity
      </rs-btn>
    </div>
    <rs-expansion-panel v-model="panel" expand class="influencer-detail-page ma-3">
      <rs-expansion-panel-content class="pt-3">
        <template #header>
          <div class="text-uppercase headline pl-3">about you</div>
        </template>
        <rs-container fluid grid-list-lg>
          <rs-layout row wrap>
            <rs-flex xs1>
              <div class="font-weight-bold">Name:</div>
            </rs-flex>
            <rs-flex xs2>{{ applicant.first_name }} {{ applicant.last_name }}</rs-flex>

            <rs-flex xs1>
              <div class="font-weight-bold">Email:</div>
            </rs-flex>
            <rs-flex xs2>{{ applicant.email }}</rs-flex>

            <rs-flex xs1>
              <div class="font-weight-bold">Country:</div>
            </rs-flex>
            <rs-flex xs2>{{ country.name }}</rs-flex>

            <rs-flex xs1>
              <div class="font-weight-bold">Commercial Entity:</div>
            </rs-flex>
            <rs-flex xs2>{{ applicant.is_commercial_entity }}</rs-flex>
          </rs-layout>
        </rs-container>
      </rs-expansion-panel-content>

      <rs-expansion-panel-content class="pt-3">
        <template #header>
          <div class="text-uppercase headline pl-3">application status</div>
        </template>
        <rs-container fluid grid-list-lg>
          <rs-layout row wrap>
            <rs-flex xs2>
              <div class="font-weight-bold">Referred By ID:</div>
            </rs-flex>
            <rs-flex xs2 v-if="application.referral_id">{{ referral.referrer }}</rs-flex>
            <rs-flex xs2 v-else> -</rs-flex>

            <rs-flex xs2>
              <div class="font-weight-bold">First application date:</div>
            </rs-flex>
            <rs-flex xs2>{{ firstApplication.created_at | moment('MMM DD, YYYY hh:mm') }} </rs-flex>

            <rs-flex xs2>
              <div class="font-weight-bold">Last application date:</div>
            </rs-flex>
            <rs-flex xs2>{{ lastApplication.created_at | moment('MMM DD, YYYY hh:mm') }} </rs-flex>

            <rs-flex xs2>
              <div class="font-weight-bold">Number of applications declined:</div>
            </rs-flex>
            <rs-flex xs2>{{ allDeclinedApplications.length }} out of {{ allApplicationsByApplicant.length }}</rs-flex>

            <rs-flex xs2>
              <div class="font-weight-bold">Declined email sent on:</div>
            </rs-flex>
            <rs-flex xs2>{{ lastDeclinedEmailSentAt }}</rs-flex>

            <rs-flex xs2>
              <div class="font-weight-bold">Declined reason:</div>
            </rs-flex>
            <rs-flex xs2 v-if="lastDeclinedApplication">{{ lastDeclinedApplicationResolution }}</rs-flex>
            <rs-flex xs2 v-else> -</rs-flex>
          </rs-layout>
        </rs-container>
      </rs-expansion-panel-content>

      <rs-expansion-panel-content class="pt-3">
        <template #header>
          <div class="text-uppercase headline pl-3">your content</div>
        </template>
        <rs-container fluid grid-list-lg>
          <rs-layout row wrap align-center>
            <rs-flex xs2>
              <div class="font-weight-bold">Primary Channel:</div>
            </rs-flex>
            <rs-flex xs2>{{ getPlatformNameFor(applicationPlatforms[0].platform_id) }}</rs-flex>
            <rs-flex xs2>
              <div class="font-weight-bold">Primary URL:</div>
            </rs-flex>
            <rs-flex xs2>
              <social-platform-link
                :platform-id="applicationPlatforms[0].platform_id"
                :platform-url="applicationPlatforms[0].url"
                :verification-type="applicationPlatforms[0].verification_status"
                :platforms-map="platformsMap"
              />
            </rs-flex>
            <rs-flex xs2>
              <follower-count-field label="Follower count" v-model="followerCounts[0]"></follower-count-field>
            </rs-flex>
          </rs-layout>
          <rs-layout row wrap align-center v-if="applicationPlatforms.length >= 2">
            <rs-flex xs2>
              <div class="font-weight-bold">Secondary Channel:</div>
            </rs-flex>
            <rs-flex xs2>{{ getPlatformNameFor(applicationPlatforms[1].platform_id) }}</rs-flex>
            <rs-flex xs2>
              <div class="font-weight-bold">Secondary URL:</div>
            </rs-flex>
            <rs-flex xs2>
              <social-platform-link
                :platform-id="applicationPlatforms[1].platform_id"
                :platform-url="applicationPlatforms[1].url"
                :verification-type="applicationPlatforms[1].verification_status"
                :platforms-map="platformsMap"
              />
            </rs-flex>
            <rs-flex xs2>
              <follower-count-field label="Follower count" v-model="followerCounts[1]"></follower-count-field>
            </rs-flex>
          </rs-layout>
          <rs-layout row wrap align-center v-if="applicationPlatforms.length >= 3">
            <rs-flex xs2>
              <div class="font-weight-bold">Additional Channel:</div>
            </rs-flex>
            <rs-flex xs2>{{ getPlatformNameFor(applicationPlatforms[2].platform_id) }}</rs-flex>
            <rs-flex xs2>
              <div class="font-weight-bold">Additional URL:</div>
            </rs-flex>
            <rs-flex xs2>
              <social-platform-link
                :platform-id="applicationPlatforms[2].platform_id"
                :platform-url="applicationPlatforms[2].url"
                :verification-type="applicationPlatforms[2].verification_status"
                :platforms-map="platformsMap"
              />
            </rs-flex>
            <rs-flex xs2>
              <follower-count-field label="Follower count" v-model="followerCounts[2]"></follower-count-field>
            </rs-flex>
          </rs-layout>
        </rs-container>
      </rs-expansion-panel-content>

      <rs-expansion-panel-content class="pt-3" v-if="experience.length > 0">
        <template #header>
          <div class="text-uppercase headline pl-3">experience level</div>
        </template>
        <rs-container fluid grid-list-lg>
          <rs-layout row wrap>
            <rs-flex v-for="exp in experience" :key="exp.id" xs12 md6 lg4>
              <p>
                <span class="font-weight-bold">{{ exp.measurement }}:</span>
                <template v-if="exp.data">
                  <span>
                    {{ exp.data.option_text }}
                  </span>
                </template>
              </p>
            </rs-flex>
          </rs-layout>
        </rs-container>
      </rs-expansion-panel-content>

      <!-- TODO: Remove after moving to v2 /applications endpoint permanently -->
      <rs-expansion-panel-content class="pt-3" v-if="application.extra">
        <template #header>
          <div class="text-uppercase headline pl-3">influencer's goal</div>
        </template>
        <rs-container fluid grid-list-lg>
          <rs-layout row wrap>
            <rs-flex xs2>
              <div class="font-weight-bold">Time Commitment:</div>
            </rs-flex>
            <rs-flex xs2>{{ application.extra.time_commitment }}</rs-flex>

            <rs-flex xs2>
              <div class="font-weight-bold">Goal:</div>
            </rs-flex>
            <rs-flex xs6>{{ application.extra.improvement_goal }}</rs-flex>
          </rs-layout>
        </rs-container>
      </rs-expansion-panel-content>
    </rs-expansion-panel>
  </rs-form>
</template>

<script>
import moment from 'moment';

import DeclineReasons from '../components/DeclineReasons.vue';
import SocialPlatformLink from '@/components/SocialPlatformLink.vue';
import FollowerCountField from '@/internal-influencers-apps/components/FollowerCountField.vue';

import { mapState, mapGetters } from 'vuex';

// auto-decline resolutions not available via the `/resolutions` API call
const AUTO_DECLINE_RESOLUTIONS = {
  'auto-decline-social-following': 'Auto-Decline - Low Social Following',
  'auto-decline-content': 'Auto-Decline - Not Enough Content',
};

const NUMBER_OF_APPLICATION_PLATFORMS = 3;

export default {
  components: {
    DeclineReasons,
    SocialPlatformLink,
    FollowerCountField,
  },
  props: ['id'],
  data: () => ({
    panel: [true, true, true, true],
    loading: false,
    error: '',
    isCommercialEntity: false,
    isValid: true,
    /**
     * List of Follower Counts that matches the Application Platforms order.
     * @type {Array}
     */
    followerCounts: [0, 0, 0],
  }),
  async mounted() {
    await this.$store.dispatch('fetchApplication', this.id);
  },
  methods: {
    async createResolution(resolutionId) {
      try {
        if (!this.isValid) {
          return;
        }

        this.loading = true;
        this.error = '';

        const hasFollowerCountChanges = this.applicationPlatforms
          .slice(0, NUMBER_OF_APPLICATION_PLATFORMS)
          .some((p, i) => this.followerCounts[i] !== (this.getFollowerCountByApplicationPlatformId(p.id) ?? 0));

        if (hasFollowerCountChanges) {
          const socialMetricsData = this.applicationPlatforms
            .slice(0, NUMBER_OF_APPLICATION_PLATFORMS)
            .map((p, i) => [p, this.followerCounts[i]]);

          await this.$store.dispatch('updateSocialMetrics', {
            applicationId: this.id,
            data: socialMetricsData,
          });
        }

        await this.$store.dispatch('createResolution', {
          applicationId: this.id,
          isCommercialEntity: this.isCommercialEntity,
          resolutionId,
        });

        this.$emit('statusUpdated');
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapState({
      applicationsMap: (state) => state['influencer-application'].applicationsMap,
      applicationPlatformsMap: (state) => state['influencer-application'].applicationPlatformsMap,
      socialMetrics: (state) => state['influencer-application'].socialMetrics,
      applicationResolutionsMap: (state) => state['influencer-application'].applicationResolutionsMap,
      resolutionsMap: (state) => state['influencer-application'].resolutionsMap,
      applicantsMap: (state) => state['influencer-application'].applicantsMap,
      platformsMap: (state) => state['influencer-application'].platformsMap,
      countriesMap: (state) => state['influencer-application'].countriesMap,
      referralsMap: (state) => state['influencer-application'].referralsMap,
    }),
    ...mapGetters([
      'getApplicationsByApplicantId',
      'getApplicationResolutionByApplicationId',
      'getFollowerCountByApplicationPlatformId',
      'getExperienceQuestionsFor',
      'getPlatformNameFor',
    ]),
    application() {
      return this.applicationsMap[this.id] ?? {};
    },
    applicant() {
      return this.applicantsMap[this.application?.applicant_id] ?? {};
    },
    applicationPlatforms() {
      const platforms = this.application?.application_platform_ids?.map((id) => this.applicationPlatformsMap[id]) || [];

      return platforms;
    },
    country() {
      return this.countriesMap[this.application?.country_id?.toLowerCase()] ?? {};
    },
    referral() {
      if (this.application?.referral_id === null) {
        return null;
      }

      return this.referralsMap[this.application.referral_id] ?? {};
    },
    allApplicationsByApplicant() {
      return this.getApplicationsByApplicantId(this.applicant?.id) ?? [];
    },
    allDeclinedApplications() {
      return this.allApplicationsByApplicant.filter((application) => application.status === 'declined') ?? [];
    },
    // Returns the first application a user has submitted including this one
    firstApplication() {
      return this.allApplicationsByApplicant[0] ?? {};
    },
    // return the most recent application a user has submitted including this one
    lastApplication() {
      return this.allApplicationsByApplicant[this.allApplicationsByApplicant.length - 1] ?? {};
    },
    lastDeclinedApplication() {
      return this.allDeclinedApplications[this.allDeclinedApplications.length - 1] || null;
    },
    lastDeclinedApplicationResolution() {
      const resolution = this.getApplicationResolutionByApplicationId(this.lastDeclinedApplication?.id);

      if (resolution) {
        if (this.resolutionsMap.hasOwnProperty(resolution.resolution_id)) {
          return this.resolutionsMap[resolution.resolution_id].name;
        } else if (AUTO_DECLINE_RESOLUTIONS.hasOwnProperty(resolution.resolution_id)) {
          return AUTO_DECLINE_RESOLUTIONS[resolution.resolution_id];
        } else {
          return resolution.resolution_id;
        }
      }

      return null;
    },
    hasLastApplicationBeenAutoDeclined() {
      const resolution = this.getApplicationResolutionByApplicationId(this.lastDeclinedApplication?.id);

      return AUTO_DECLINE_RESOLUTIONS.hasOwnProperty(resolution?.resolution_id);
    },
    lastDeclinedEmailSentAt() {
      if (!this.lastDeclinedApplication) {
        return '-';
      }

      let date = moment(this.lastDeclinedApplication.updated_at);

      if (this.hasLastApplicationBeenAutoDeclined) {
        // Add 6 hours to `updated_at` timestamp to reflect (artificial) decline email delay for auto-declined applications
        date = date.add(6, 'h');
      }

      return date.format('MMM DD, YYYY hh:mm');
    },
    experience() {
      const experienceQuestions = this.getExperienceQuestionsFor(this.application?.id);

      if (experienceQuestions.length < 1) return [];

      const measurementsByQuestionId = [
        [1, 'Experience Monetizing'],
        [2, 'Frequency Monetizing'],
        [3, 'Posting Frequency'],
      ];

      const data = measurementsByQuestionId.map(([id, measurement]) => ({
        id,
        measurement,
        data: experienceQuestions.find((q) => q.question_id === id),
      }));

      return data;
    },
  },
  watch: {
    applicationPlatforms(newPlatforms, oldPlatforms) {
      // Update Follower counts. Each count value corresponds to an Application Platform.
      newPlatforms.slice(0, NUMBER_OF_APPLICATION_PLATFORMS).forEach((p, i) => {
        // Default to 0 for missing values
        this.followerCounts[i] = this.getFollowerCountByApplicationPlatformId(p.id) ?? 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.influencer-detail-page .container.fluid {
  max-width: 100% !important;
}
</style>
