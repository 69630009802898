<template>
  <rs-layout column>
    <rs-layout align-center class="mt-2">
      <h4 class="display-2 mt-2 mb-0 font-weight-bold">Retailers</h4>
      <rs-spacer />
      <rs-btn :block="false" @click="addRetailer()">
        <rs-icon color="black" class="font-weight-bold mr-2">ltkfont-plus-outline</rs-icon>
        Sale Retailer
      </rs-btn>
    </rs-layout>
    <rs-data-table :items="retailerDetails" hide-actions :headers="headerRows" item-key="retailer_id">
      <template #items="props">
        <retailer-table-item
          :key="props.index"
          :model-value="props.item"
          @update:modelValue="updateRetailerDetails(props.index, $event)"
          @delete="removeRetailer(props.index)"
        />
      </template>
      <template #no-data>
        <rs-layout align-center justify-center>
          <rs-btn :block="false" @click="addRetailer()">
            <rs-icon color="black" class="font-weight-bold mr-2">ltkfont-plus-outline</rs-icon>
            Add Retailer to Sale
          </rs-btn>
        </rs-layout>
      </template>
      <template #footer>
        <rs-messages :color="validationState" :value="validationTarget" />
      </template>
    </rs-data-table>
  </rs-layout>
</template>

<script>
import { defineComponent, computed } from 'vue';
import validatable from 'vuetify/lib/mixins/validatable';

import useModelWrapper from '@/composables/model-wrapper';
import useStore from '@/composables/vuex';

import RetailerTableItem from './RetailerTableItem.vue';
import { emptySaleRetailerFactory } from '../../store/sales';

export default defineComponent({
  name: 'RetailerTable', // override due to mixin explicitly providing a name
  mixins: [validatable],
  components: {
    RetailerTableItem,
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: Array,
  },
  emits: {
    'update:modelValue': Array,
  },
  setup(props, { emit }) {
    const store = useStore();
    const isLoading = computed(() => store.getters.advertisersFetchState().pending);

    const headerRows = [
      { text: 'Retailer Name (ID) *', sortable: false },
      { text: 'Promo Description *', sortable: false },
      { text: 'Promo Code', sortable: false },
      { text: '', sortable: false },
    ];
    const [retailerDetails] = useModelWrapper(props, emit, 'modelValue', []);

    function addRetailer() {
      this.resetValidation(); // Belongs to validatable mixin
      retailerDetails.value = [...retailerDetails.value, emptySaleRetailerFactory()];
    }

    function updateRetailerDetails(index, newRetailerDetail) {
      this.resetValidation(); // Belongs to validatable mixin
      const newDetails = [...props.modelValue];
      newDetails.splice(index, 1, newRetailerDetail);

      retailerDetails.value = newDetails;
    }

    function removeRetailer(index) {
      this.resetValidation(); // Belongs to validatable mixin
      retailerDetails.value = [
        ...retailerDetails.value.slice(0, index),
        ...retailerDetails.value.slice(index + 1, retailerDetails.value.length),
      ];
    }

    return {
      retailerDetails,
      headerRows,
      isLoading,
      addRetailer,
      updateRetailerDetails,
      removeRetailer,
    };
  },
});
</script>
