<template>
  <rs-text-field
    outline
    v-bind="$attrs"
    v-on="listeners"
    :value="current"
    @focus="unmask"
    @blur="mask"
    :rules="innerRules"
    clearable
    @click:clear="clear"
    :required="required"
    :disabled="disabled"
  ></rs-text-field>
</template>

<script lang="ts">
import { defineComponent, computed, toRef } from 'vue';
import { useFollowerCountField } from '@/internal-influencers-apps/composables/useFollowerCountField';

export default defineComponent({
  name: 'FollowerCountField',
  inheritAttrs: false,
  emits: ['input', 'format'],
  props: {
    /**
     * @description Use with v-model.
     */
    value: {
      type: Number,
      required: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit, attrs: $attrs, listeners: $listeners }) {
    const { count, current, isValidNumbro, unmask, mask, clear } = useFollowerCountField(
      { emit },
      toRef(props, 'value'),
    );

    const listeners = computed(() => {
      // Remove component-specific events and pass along the rest.
      // Remove `input` event to avoid accidentally setting it on `<rs-text-field>` via `v-model` usage
      const { focus, blur, input, ...restOfListeners } = $listeners;
      return restOfListeners;
    });

    const innerRules = computed(() => {
      let rules = [...props.rules, isValidNumbro];
      return rules;
    });

    return {
      listeners,
      innerRules,
      count,
      current,
      unmask,
      mask,
      clear,
    };
  },
});
</script>
