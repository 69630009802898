import clientFactory from '@rscollabs/rs-core-library/src/services/client';

const client = clientFactory('/api/v1/advertiser_control_panel');

export const ADVERTISERS_LIMIT = 10000;

class AdvertiserService {
  async get({ next, limit = ADVERTISERS_LIMIT }) {
    const response = await client.get('/advertisers', {
      params: {
        page: next,
        limit,
      },
    });
    return response.data;
  }
}

export default new AdvertiserService();
