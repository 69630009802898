<template>
  <div class="hashtags-manage__content">
    <div class="hashtags-manage__grid" :class="[modifiers]">
      <div class="hashtags-manage__country">
        <rs-autocomplete
          class="hashtags-manage__country-select"
          v-model="local.selectedCountry"
          :items="validCountries"
          :disabled="!removable"
          label="Country"
          item-text="name"
          item-value="code"
        />
      </div>
      <div class="hashtags-manage__delete">
        <rs-button
          icon-only
          flat
          data-testid="hashtags-manage-delete-btn"
          class="ml-3"
          @click="handleDeleteField()"
          v-if="removable"
        >
          <rs-icon>delete</rs-icon>
        </rs-button>
      </div>
      <div class="hashtags-manage__translation" :class="[classes]" v-if="type === 'hashtag'">
        <rs-text-field
          v-model="local.options.key"
          :label="computedLabel.label"
          :hint="computedLabel.hint"
          persistent-hint
        />
      </div>
      <div class="hashtags-manage__translation" :class="[classes]" v-if="type === 'bubble'">
        <!-- Note the different model -->
        <rs-text-field
          v-model="local.options.title"
          :label="computedLabel.label"
          :hint="computedLabel.hint"
          persistent-hint
        />
      </div>
      <!-- Banner, Bubble,-->
      <div class="hashtags-manage__description" v-if="type === 'bubble'">
        <rs-text-field
          v-model="local.options.subtitle"
          label="Bubble Description"
          hint="None will be used if left blank"
          persistent-hint
        />
      </div>
      <div class="hashtags-manage__hashtag-name" v-if="type === 'banner' || type === 'bubble'">
        <rs-text-field
          v-model="local.feedHashtag"
          label="Hashtag Name"
          :rules="[rules.required]"
          :disabled="!canEdit"
          persistent-hint
          hint="Required"
        />
      </div>
      <div class="hashtags-manage__require-hashtag" v-if="type === 'bubble'">
        <rs-checkbox
          v-model="local.options.requireTag"
          label="Require Hashtag to be shown in feed"
          :disabled="!canEdit"
        />
      </div>
      <!-- Banner(from scratch), Bubble,-->
      <div class="hashtags-manage__feed-items mb-3" v-if="type === 'bubble'">
        <rs-combobox
          v-model="local.options.catalogIds"
          data-testid="hashtags-manage-catalog-ids"
          chips
          small-chips
          multiple
          label="CatalogID"
          hint="Optional"
          persistent-hint
          :disabled="!canEdit"
        />
      </div>
      <div class="hashtags-manage__feed-items mb-3" v-if="type === 'bubble'">
        <rs-autocomplete
          v-model="local.options.retailerIds"
          data-testid="hashtags-manage-retailer-ids"
          chips
          small-chips
          multiple
          :search-input.sync="local.retailerSearch.name"
          :items="[...local.retailers, ...prevRetailers]"
          :disabled="!canEdit"
          item-text="searchKey"
          item-value="id"
          cache-items
          label="RetailerID"
          hint="Optional - Type to search"
          deletable-chips
          persistent-hint
          return-masked-value
        >
          <!-- Selected Items -->
          <template #selection="{ item, selected }">
            <rs-chip small :selected="selected">
              {{ item.name }}
              <rs-tip>{{ item.id }}</rs-tip>
            </rs-chip>
          </template>
          <!-- Menu for selection -->
          <template #item="{ item }">
            <rs-list-tile-content>
              <rs-list-tile-title>{{ item.name }}</rs-list-tile-title>
              <rs-list-tile-sub-title>{{ item.id }}</rs-list-tile-sub-title>
            </rs-list-tile-content>
          </template>
        </rs-autocomplete>
      </div>
      <hashtags-image-upload
        v-if="type === 'bubble' || type === 'banner'"
        :type="type"
        :file="local.file"
        :file-name="local.fileName"
        :url="local.options.url"
        @img-update="handleImageUpdate($event)"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, watch, computed, watchEffect } from 'vue';
import HashtagsImageUpload from '@/internal-hashtags/components/HashtagsImageUpload.vue';
import countries from '@/internal-hashtags/fixtures/countries.json';
import useAdvertiserApi from '@/internal-advertisers/composables/useAdvertiserApi';
import RsButton from '@/internal-advertisers/lib/components/RsButton/RsButton.vue';
import RsTip from '@/internal-advertisers/lib/components/RsTip/RsTip.vue';
import { watchDebounced } from '@vueuse/core';

export default defineComponent({
  name: 'HashtagsCountryFields',
  components: { RsButton, HashtagsImageUpload, RsButton, RsTip },
  props: {
    countries: {
      type: Array,
      default: () => [],
    },
    removable: {
      type: Boolean,
      default: false,
    },
    countryCode: {
      type: String,
      default: '',
    },
    feedHashtag: {
      type: String,
      default: '',
    },
    groupLabel: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: 'hashtag',
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    onEdit: {
      type: Boolean,
      default: true,
    },
    prevRetailers: {
      type: Array,
      default: () => [],
    },
  },
  emits: { change: () => true, deleted: () => true },
  setup(props, { emit }) {
    const { getAdvertisers } = useAdvertiserApi();
    const prevRetailerIds = computed(() => {
      return props.prevRetailers.map((retailer) => retailer.id);
    });
    const local = reactive({
      selectedCountry: props.countryCode,
      options: {
        requireTag: props.options.requireTag,
        key: props.options.key,
        title: props.options.title,
        subtitle: props.options.subtitle,
        catalogIds: props.options.catalogIds,
        retailerIds: prevRetailerIds.value,
        url: props.options.url,
      },
      file: [],
      fileName: null,
      feedHashtag: props.feedHashtag,
      retailers: [],
      retailerSearch: {
        name: '',
      },
    });

    const rules = {
      required: (value) => !!value || 'Required.',
    };

    const classes = computed(() => {
      return {
        [`is--${props.type}`]: props.type,
      };
    });

    const validCountries = computed(() => {
      const currentSelection = countries.filter((country) => country.code === local.selectedCountry);
      if (currentSelection) {
        return [currentSelection[0], ...props.countries];
      } else {
        return prop.countries;
      }
    });

    const computedLabel = computed(() => {
      const captializeType = props.type?.charAt(0).toUpperCase() + props.type.slice(1);
      if (props.type === 'banner') {
        return {
          label: `${captializeType} Title`,
        };
      } else if (props.type === 'bubble') {
        return {
          label: `${captializeType} Title`,
          hint: 'None will be used if left blank',
        };
      } else {
        return {
          label: 'Translation',
          hint: local.options.key
            ? '# will be prepended'
            : props.removable
            ? `# will be prepended, #${props.groupLabel} if left blank`
            : `#${props.groupLabel} if left blank`,
        };
      }
    });

    watchEffect(() => {
      local.selectedCountry = props.countryCode;
      local.options.key = props.options.key;
      local.options.title = props.options.title;
      local.options.subtitle = props.options.subtitle;
      local.options.catalogIds = props.options.catalogIds;
      // Account for uninitialized requireTag on new Bubbles
      local.options.requireTag = props.options.requireTag === true ? true : false;
      local.options.url = props.options.url;
      local.feedHashtag = props.feedHashtag;
    });

    const isDirty = computed(() => {
      return (
        local.selectedCountry !== props.countryCode ||
        local.options.key !== props.options.key ||
        local.options.retailerIds !== prevRetailerIds.value ||
        local.options.catalogIds !== props.options.catalogIds ||
        local.options.requireTag !== props.requireTag ||
        local.options.title !== props.options.title ||
        local.options.subtitle !== props.options.subtitle ||
        local.options.url !== props.options.url ||
        local.feedHashtag !== props.feedHashtag
      );
    });

    function handleImageUpdate(event) {
      local.file = event.file;
      local.fileName = event.fileName;
      local.options.url = event.url;
    }

    watch(
      () => ({
        selectedCountry: local.selectedCountry,
        key: local.options.key,
        feedHashtag: local.feedHashtag,
        retailerIds: local.options.retailerIds,
        catalogIds: local.options.catalogIds,
        title: local.options.title,
        subtitle: local.options.subtitle,
        requireTag: local.options.requireTag,
        url: local.options.url,
      }),
      (newValue) => {
        if (isDirty.value) {
          emit('change', {
            selected_country: newValue.selectedCountry,
            feedHashtag: newValue.feedHashtag,
            options: {
              actions: [
                {
                  feedHashtag: newValue.feedHashtag,
                },
              ],
              key: newValue.key,
              retailerIds: newValue.retailerIds,
              catalogIds: newValue.catalogIds,
              title: newValue.title,
              subtitle: newValue.subtitle,
              requireTag: newValue.requireTag,
              url: newValue.url,
            },
          });
        }
      },
    );

    const modifiers = computed(() => {
      return {
        'is--removable': props.removable,
      };
    });

    function handleDeleteField() {
      emit('deleted');
    }

    const handleAdvertisers = async (filter) => {
      return await getAdvertisers(filter);
    };

    watchDebounced(
      () => local.retailerSearch.name,
      async (next) => {
        const response = await handleAdvertisers({ name: next });
        local.retailers = response.result;
      },
      { debounce: 500, maxWait: 1000 },
    );

    return {
      classes,
      computedLabel,
      isDirty,
      local,
      modifiers,
      rules,
      validCountries,
      handleAdvertisers,
      handleDeleteField,
      handleImageUpdate,
      open,
    };
  },
});
</script>

<style lang="scss" scoped>
.hashtags-manage {
  &__grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 32px;
    position: relative;
  }
  &__content {
    padding: 24px 24px 16px 24px;
    margin-bottom: 24px;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
    &:last-child {
      margin-bottom: 0px;
    }
  }
  &__country {
    grid-column: span 6;
  }
  &__translation {
    display: flex;
    align-items: center;
    grid-column: span 5;
  }
  &__delete {
    grid-row: 1;
    grid-column: 12;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .rs--button {
      margin-top: -23px;
    }
  }
  &__feed-items {
    grid-column: span 12;
  }
  &__hashtag-name,
  &__require-hashtag {
    grid-column: span 6;
    grid-row: 3;
  }
  &__feed-items {
    grid-row: 4;
    grid-column: span 6;
  }
  &__country,
  &__description,
  &__translation {
    grid-column: span 6;
    &.is--hashtag {
      grid-column: span 5;
    }
  }
}
</style>
