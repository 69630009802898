import clientFactory from '@rscollabs/rs-core-library/src/services/client';

const client = clientFactory('/api/v1/ltk-recommendations');

class LtkRecommendationService {
  getCategories() {
    return client.get('/categories');
  }
  getLocations() {
    return client.get('/locations');
  }
  async getInfluencer(ltk_profile_id) {
    try {
      return await client.get(`/influencers/recommendation_profiles?ltk_profile_ids[]=${ltk_profile_id}`);
    } catch (error) {
      if (error.statusCode === 404 && error.data.error_code === 'influencer-recommendation-profile-not-found') {
        return await this.createInfluencer({
          ltk_profile_id,
          features: [
            { type: 'gender', weight: '1.00' },
            { type: 'age', weight: '0.00' },
          ],
        });
      }
      throw error;
    }
  }
  saveInfluencer(id, profile) {
    return client.put(`/influencers/recommendation_profiles/${id}`, profile);
  }
  createInfluencer(profile) {
    return client.post(`/influencers/recommendation_profiles`, profile);
  }
}

export default new LtkRecommendationService();
