<template>
  <div :style="{ width: '100%' }">
    <div class="marketing-manage" v-if="$vuetify.breakpoint.mdAndUp">
      <div class="marketing-manage__wrapper">
        <div class="marketing-manage__header">
          <rs-button icon-only flat rounded size="sm" @click="back">
            <rs-icon>arrow_back</rs-icon>
          </rs-button>
          <h1>{{ pageTitle }}</h1>
        </div>
        <div class="marketing-manage__body">
          <rs-form ref="form" v-model="isFormValid" @submit.prevent="handleSaveUpdates()">
            <hashtags-fields
              v-if="!loading"
              type="banner"
              :group-label="bannerData.group_label"
              :start-date="bannerData.start_date"
              :end-date="bannerData.end_date"
              :editing="isEditing"
              :status="status"
              @change="(e) => onBannerChange(e)"
            />
            <hashtags-country-fields
              v-for="(data, index) in bannerCountries"
              :countries="unselectedCountries"
              :country-code="data.country_code"
              :removable="index >= groupStartingLength"
              :key="index"
              :options="data.options"
              :feed-hashtag="getFeedHashtag(data)"
              @change="(e) => onCountryChange(e, index)"
              @deleted="handleDeleteCountry(index)"
              type="banner"
            />
          </rs-form>
          <div class="marketing-manage__btns">
            <rs-button
              size="sm"
              data-testid="marketing-manage-add-btn"
              @click="handleAddCountry(unselectedCountries[0].code)"
              :disabled="bannerCountries.length === countries.length"
            >
              <rs-icon>add</rs-icon>
              <span>Add another country</span>
            </rs-button>
            <rs-button
              size="sm"
              class="marketing-manage__add-all-btn"
              data-testid="marketing-manage-add-all-btn"
              @click="handleAddAllCountries()"
              :disabled="bannerCountries.length > 1"
            >
              <rs-icon>add</rs-icon>
              <span>Add all countries</span>
            </rs-button>
          </div>
        </div>
        <HashtagsFooter v-model="valid" @cancel="back()" @saved="handleSaveUpdates()" />
      </div>
    </div>
    <hashtags-desktop-notice v-if="$vuetify.breakpoint.smAndDown" />
  </div>
</template>

<script>
import Vue, { ref, computed, defineComponent, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import RsButton from '@/internal-advertisers/lib/components/RsButton/RsButton.vue';
import HashtagsFields from '@/internal-hashtags/components/HashtagsFields.vue';
import HashtagsCountryFields from '@/internal-hashtags/components/HashtagsCountryFields.vue';
import HashtagsFooter from '@/internal-hashtags/components/HashtagsFooter.vue';
import HashtagsDesktopNotice from '@/internal-hashtags/components/HashtagsDesktopNotice.vue';
import countries from '@/internal-hashtags/fixtures/countries.json';
import { addUTCMidnight, getFormattedDates, convertFromUtcToLocal } from '@/internal-hashtags/utils/changeTimeZone';
import { useBannersStore } from '@/stores/banners';
import { storeToRefs } from 'pinia';
import { formatForEdit } from '@/internal-hashtags/utils/formatForEdit';

export default defineComponent({
  name: 'BannersManage',
  components: { RsButton, HashtagsFields, HashtagsCountryFields, HashtagsFooter, HashtagsDesktopNotice },
  props: {
    groupLabel: {
      type: String,
      default: '',
    },
    defaultCountry: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const isEditing = props.groupLabel !== '';

    const bannersStore = useBannersStore();
    const { bannersByGroup } = storeToRefs(bannersStore);

    // Format the dates in 'YYYY-MM-DD' format, with start/end as today/tomorrow
    const { formattedToday, formattedTomorrow } = getFormattedDates();
    const bannerData = ref({
      group_label: props.groupLabel,
      enabled: false,
      pre_staged: false,
      start_date: formattedToday,
      end_date: formattedTomorrow,
      rank_logic: 'Engagement',
      rate_limiting: false,
      styled_posts_only: false,
    });

    const countriesAreValid = computed(() => {
      let valid = true;
      for (const country of bannerCountries.value) {
        if (!country['options']['url']) {
          valid = false;
          break;
        }
      }
      return valid;
    });

    const bannerCountries = ref([]);

    const bannerIsValid = computed(() => {
      return (
        bannerData.value.group_label !== '' &&
        bannerData.value.start_date !== '' &&
        bannerData.value.end_date !== '' &&
        isFormValid.value === true
      );
    });

    const valid = computed(() => {
      return bannerIsValid.value && countriesAreValid.value;
    });

    const groupStartingLength = ref(0);
    const status = ref('disabled');
    const loading = ref(true);

    function setDefaultCountry() {
      const defaultCountry = unselectedCountries.value.filter((country) => country.code === props.defaultCountry)[0];
      handleAddCountry(defaultCountry.code);
    }

    const unselectedCountries = computed(() => {
      const selectedCountryCodes = bannerCountries.value.map((country) => country.country_code);
      const filteredCountries = countries.filter((country) => !selectedCountryCodes.includes(country.code));
      return filteredCountries;
    });

    const pageTitle = computed(() => {
      if (props.groupLabel === '') {
        return 'Add Banners';
      } else {
        return 'Edit Banners';
      }
    });

    const getFeedHashtag = (data) => {
      if (isEditing) {
        // If editing an existing banner we should start with the existing feedHashtag
        return data.options.actions && data.options.actions[0] && data.options.actions[0].feedHashtag
          ? data.options.actions[0].feedHashtag
          : '';
      } else {
        return data.feedHashtag;
      }
    };

    const back = () => router.push(`/banners?defaultCountry=${props.defaultCountry}`);

    const readStatus = (status) => {
      if (status === 'qa') {
        bannerData.value.enabled = true;
        bannerData.value.pre_staged = true;
      } else if (status === 'prod') {
        bannerData.value.enabled = true;
        bannerData.value.pre_staged = false;
      } else if (status === 'disabled') {
        bannerData.value.enabled = false;
        bannerData.value.pre_staged = false;
      }
    };

    const setStatus = () => {
      if (bannerData.value.enabled && bannerData.value.pre_staged) {
        status.value = 'qa';
      } else if (bannerData.value.enabled && !bannerData.value.pre_staged) {
        status.value = 'prod';
      } else if (!bannerData.value.enabled && !bannerData.value.pre_staged) {
        status.value = 'disabled';
      }
    };

    const onBannerChange = (event) => {
      if (!isEditing) {
        bannerData.value.group_label = event.group_label;
      }
      bannerData.value.start_date = event.start_date;
      bannerData.value.end_date = event.end_date;
      readStatus(event.status);
    };

    const onCountryChange = (event, index) => {
      bannerCountries.value[index].country_code = event.selected_country;
      // Because `options` and `actions` are still going to change in the near future and to minimize mutating
      // deeply nested objects, for now we are just saving the entire emitted `option` event and lifting
      // `actions` to the same level so we can use the value during creation/editing.
      Vue.set(bannerCountries.value[index], 'options', event.options);
      Vue.set(bannerCountries.value[index], 'feedHashtag', event.feedHashtag);
    };

    function handleAddAllCountries() {
      unselectedCountries.value.forEach((country) => {
        handleAddCountry(country.code);
      });
    }

    const handleAddCountry = (countryCode) => {
      bannerCountries.value.push({ country_code: countryCode, options: { key: '' } });
    };

    const handleDeleteCountry = (index) => {
      bannerCountries.value.splice(index, 1);
    };

    const buildNewModules = (modulesToBuild) => {
      const startDate = addUTCMidnight(bannerData.value.start_date);
      const endDate = addUTCMidnight(bannerData.value.end_date);
      const modules = [];
      modulesToBuild.forEach((bannerCountry) => {
        const timezoneOffset = countries.find((c) => c.code === bannerCountry.country_code)?.offset || '+00:00';
        const newCountryModule = {
          country_code: bannerCountry.country_code,
          enabled: bannerData.value.enabled,
          end_date: `${endDate}${timezoneOffset}`,
          major_version: 1,
          minor_version: 0,
          notes: 'Created via Operator Tools',
          options: {
            // This field is technically optional but will used 99% of the time.
            key: bannerCountry.options.key ?? '',
            testTag: 'testTag',
            testDescription: 'Description for Unit Test',
            // Note: Even though some fields are left blank they are still required by the backend validator
            title: '',
            url: bannerCountry.options.url,
            actions: [
              {
                // For now the only supported value for type and action_type is `v1_hashtag`
                type: 'v1_hashtag',
                actionType: 'v1_hashtag',
                feedHashtag: bannerCountry.feedHashtag,
              },
            ],
            catalogIds: bannerCountry.options.catalogIds,
            retailerIds: bannerCountry.options.retailerIds,
          },
          options_type: 'v1_banner_image',
          pre_staged: bannerData.value.pre_staged,
          start_date: `${startDate}${timezoneOffset}`,
        };
        modules.push(newCountryModule);
      });
      return modules;
    };

    const form = ref();
    const isFormValid = ref(false);

    const handleSaveUpdates = async () => {
      if (form.value.validate() === false || valid.value === false) {
        return;
      }

      if (isEditing) {
        const modulesToUpdate = bannerCountries.value.slice(0, groupStartingLength.value);
        const modulesToBuild = bannerCountries.value.slice(groupStartingLength.value);
        await editBanners(modulesToUpdate);
        // Check if new countries were added and will need to be created
        if (modulesToBuild.length > 0) {
          await createBanners(modulesToBuild);
        }
      } else {
        await createBanners(bannerCountries.value);
      }
      router.push(`/banners?defaultCountry=${props.defaultCountry}`);
    };

    const editBanners = async (modulesToUpdate) => {
      /*
        When editing we only need to set banner group data before submitting.
          Specific banner data is updated on during user selection.
      */
      const startDate = addUTCMidnight(bannerData.value.start_date);
      const endDate = addUTCMidnight(bannerData.value.end_date);
      modulesToUpdate.forEach((bannerCountry) => {
        // Check if new feedHashtag has been emitted up, if not use old one if country has one
        const oldFeedHashtag =
          bannerCountry.options.actions && bannerCountry.options.actions[0].feedHashtag
            ? bannerCountry.options.actions[0].feedHashtag
            : '';
        const feedHashtag = bannerCountry?.feedHashtag ? bannerCountry.feedHashtag : oldFeedHashtag;

        const timezoneOffset = countries.find((c) => c.code === bannerCountry.country_code)?.offset || '+00:00';
        bannerCountry.start_date = `${startDate}${timezoneOffset}`;
        bannerCountry.end_date = `${endDate}${timezoneOffset}`;
        bannerCountry.enabled = bannerData.value.enabled;
        bannerCountry.pre_staged = bannerData.value.pre_staged;
        /*
          As noted above in onCountryChange, the entire `options` emit is being saved,
            so we manually set deeply nested values here.
        */
        bannerCountry.options.actions = [
          {
            type: 'v1_hashtag',
            actionType: 'v1_hashtag',
            feedHashtag: feedHashtag,
          },
        ];
        bannerCountry.options.testTag = 'banner_test_tag';
        bannerCountry.options.testDescription = `${bannerCountry.title} Test!`;
      });

      // Remove fields not needed/valid for edit
      const formattedModulesToUpdate = formatForEdit(modulesToUpdate);

      // Make POST call to bulk edit endpoint
      try {
        await bannersStore.editMany({ modules: formattedModulesToUpdate });
      } catch (err) {
        console.warn('Error', err);
      }
    };

    const createBanners = async (modulesToBuild) => {
      try {
        const group_label = bannerData.value.group_label;
        const modules = buildNewModules(modulesToBuild);
        await bannersStore.create({ group_label, modules });
      } catch (err) {
        console.warn('Error', err);
      }
    };

    onMounted(async () => {
      /*
        If in Edit View:
          Make initial fetch and they load existing banners data.
      */
      if (isEditing) {
        await bannersStore.getByGroup(props.groupLabel);
        groupStartingLength.value = bannersByGroup.value.length;
        bannerCountries.value = bannersStore.bannersByGroup;
        bannerData.value.pre_staged = bannerCountries.value[0].pre_staged;
        bannerData.value.enabled = bannerCountries.value[0].enabled;
        const localStart = convertFromUtcToLocal(
          bannerCountries.value[0].country_code,
          bannerCountries.value[0].start_date,
        );
        const localEnd = convertFromUtcToLocal(
          bannerCountries.value[0].country_code,
          bannerCountries.value[0].end_date,
        );
        bannerData.value.start_date = localStart.split('T')[0];
        bannerData.value.end_date = localEnd.split('T')[0];
        setStatus();
      } else {
        /*
          If in Create view:
            Hydrate bannerCountries with default country from Banners view
        */
        setDefaultCountry();
      }
      loading.value = false;
    });

    return {
      countries,
      bannerCountries,
      bannerData,
      bannersByGroup,
      form,
      groupStartingLength,
      isEditing,
      isFormValid,
      loading,
      pageTitle,
      status,
      unselectedCountries,
      valid,
      back,
      createBanners,
      getFeedHashtag,
      handleAddAllCountries,
      handleAddCountry,
      handleDeleteCountry,
      handleSaveUpdates,
      onBannerChange,
      onCountryChange,
      readStatus,
      setDefaultCountry,
    };
  },
});
</script>

<style lang="scss">
.marketing-manage {
  width: 100%;
  padding: 0 16px 160px;
  &__wrapper {
    max-width: 1244px;
    margin: 0 auto;
    margin-top: 48px;
    margin-bottom: 108px;
  }
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    & > h1 {
      margin: 0;
      padding: 0;
      line-height: 32px;
      font-size: 24px;
      font-weight: 700;
    }
  }
  &__datepicker {
    height: 370px;
  }
  &__btns {
    padding-top: 24px;
  }
  &__add-all-btn {
    margin-left: 8px;
  }
}
</style>
