<template>
  <!-- eslint-disable-next-line vue/no-mutating-props -->
  <rs-dialog v-model="showDialog" persistent max-width="550px" :fullscreen="$vuetify.breakpoint.xsOnly">
    <rs-card>
      <rs-card-title class="permission__modal-header mb-2 mt-2"> Edit Permissions </rs-card-title>
      <rs-divider />
      <rs-card-text class="mx-0 wrapper">
        <rs-data-table class="permissions-data-table mb-4 pl-2" :headers="headers" :items="tableItems" hide-actions>
          <template slot="items" slot-scope="props">
            <tr>
              <td class="ml-0">{{ props.item.name }}</td>
              <td v-for="index in 3" :key="index">
                <rs-tooltip top v-if="!isLevelSupported(props.item.id, index)">
                  <template #activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <rs-checkbox v-model="props.item.level" :value="index" :disabled="true" />
                    </div>
                  </template>
                  <span>This level is not supported</span>
                </rs-tooltip>
                <rs-checkbox v-else v-model="props.item.level" :value="index" />
              </td></tr
          ></template>
        </rs-data-table>
        <div
          v-if="loading"
          class="d-flex transition-fast-in-fast-out grey darken v-card--reveal display-3 white--text"
          style="height: 100%"
        >
          <rs-progress-circular color="white" class="mb-5" :size="64" indeterminate></rs-progress-circular>
        </div>
      </rs-card-text>
      <rs-flex mt-4>
        <rs-layout wrap justify-center>
          <rs-flex xs4 ma-1>
            <rs-btn font-medium inverted @click="save" :loading="loading"> Save </rs-btn>
          </rs-flex>
          <rs-flex xs4 ma-1>
            <rs-btn font-medium @click="handleDialogCancel()"> Cancel </rs-btn>
          </rs-flex>
        </rs-layout>
      </rs-flex>
    </rs-card>
  </rs-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import PolicyServiceTiers from '@rscollabs/rs-core-library/src/store/data/policyServiceTiers';

export default {
  name: 'EditPermissionModal',
  props: {
    accountId: {
      type: String,
      default: '',
    },
    permissions: {
      type: Object,
      default: () => {
        return {};
      },
    },
    reportingTiers: {
      type: Object,
      default: () => {
        return {
          campaigns_reporting_tier: 0,
          sales_reporting_tier: 0,
          ss_reporting_tier: 0,
        };
      },
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    reportingTiers: {
      handler(val) {
        this.tableItems[0].level = val.campaigns_reporting_tier;
        this.tableItems[1].level = val.sales_reporting_tier;
        this.tableItems[2].level = val.ss_reporting_tier;
      },
      deep: true,
    },
  },
  data: function () {
    return {
      loading: false,
      headers: [
        { text: 'Reporting Type', value: 'name', sortable: false },
        { text: 'Level 1', value: 'level1', sortable: false },
        { text: 'Level 2', value: 'level2', sortable: false },
        { text: 'Level 3', value: 'level3', sortable: false },
      ],
      tableItems: [
        {
          id: 'campaigns_reporting_tier',
          name: 'Campaign Reporting',
          level: this.reportingTiers.campaigns_reporting_tier,
        },
        {
          id: 'sales_reporting_tier',
          name: 'Sales Reporting',
          level: this.reportingTiers.sales_reporting_tier,
        },
        {
          id: 'ss_reporting_tier',
          name: 'Self Service Reporting',
          level: this.reportingTiers.ss_reporting_tier,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('permissions', ['getReportingTiers']),
  },
  methods: {
    ...mapActions('permissions', ['postAccountPermissions', 'revokeAccountPermissions']),
    handleDialogCancel() {
      this.$root.$emit('hidePermissionsDialog');
    },
    isLevelSupported(reportingTier, level) {
      return PolicyServiceTiers[reportingTier].hasOwnProperty(level);
    },
    async save() {
      this.loading = true;
      // map selected values to permissions
      let reportingTiers = {};
      this.tableItems.forEach((x) => {
        reportingTiers[x.id] = parseInt(x.level);
      });

      let newPermissions = [];
      for (const tier in reportingTiers) {
        let level = reportingTiers[tier];

        // add permissions for all previous levels
        for (let idx = 1; idx <= level; idx++) {
          let hasLevel = PolicyServiceTiers[tier].hasOwnProperty(idx);
          let curPermissions = hasLevel ? PolicyServiceTiers[tier][idx] : [];
          newPermissions = newPermissions.concat(curPermissions);
        }
      }
      // first revoke the previous reporting level permissions
      await this.revokePreviousReportingLevels();

      // then grant the new permissions
      let request = newPermissions.map((p) => ({ name: p }));
      await this.postAccountPermissions({ accountId: this.accountId, request });
      this.$root.$emit('hidePermissionsDialog');
      this.$root.$emit('updateAccountPermissions');
      this.loading = false;
    },
    async revokePreviousReportingLevels() {
      let prevReportingTiers = this.getReportingTiers(this.accountId);
      let permissionsToRevoke = [];
      for (const tier in prevReportingTiers) {
        let level = prevReportingTiers[tier];

        // add permissions for all previous levels
        for (let idx = 1; idx <= level; idx++) {
          let hasLevel = PolicyServiceTiers[tier].hasOwnProperty(idx);
          let curPermissions = hasLevel ? PolicyServiceTiers[tier][idx] : [];
          let pNames = curPermissions.map((s) => ({ name: s }));
          permissionsToRevoke = permissionsToRevoke.concat(pNames);
        }
      }
      await this.revokeAccountPermissions({ accountId: this.accountId, request: permissionsToRevoke });
    },
  },
};
</script>

<style lang="sass">
.v-card__title
  font-size: 18px
  font-weight: 500 !important

.edit-modal
  height: 200px

.wrapper
  position: relative

.v-card--reveal
  align-items: center
  justify-content: center
  opacity: .25
  position: absolute
  top: 0
  left: 0
  width: 100%

.subtitle
  font-size: 14px
</style>
