<template>
  <rs-container class="advertiser" grid-list-md my-0>
    <div class="advertiser__header">
      <div class="advertiser__header-group">
        <h1 class="header-2 mb-2">{{ !newAdvertiser ? 'Edit Advertiser' : 'Create New Advertiser' }}</h1>
        <p class="header-1 mt-0 mb-4" v-if="!newAdvertiser">
          {{ advertiserData.name }}
          <rs-chip
            small
            label
            class="mb-2"
            :color="advertiserData.active ? '#26bf7e' : 'white'"
            :text-color="advertiserData.active ? 'white' : ''"
          >
            {{ advertiserData.active ? 'Active' : 'Inactive' }}
          </rs-chip>
          <rs-chip
            small
            label
            class="mb-2"
            v-if="advertiserData.attributes && advertiserData.attributes.median_purchase_leadtime"
          >
            Median Lead Time:&nbsp;
            <strong>{{ convertToDays(advertiserData.attributes.median_purchase_leadtime) }}</strong>
            <rs-tip>
              The amount of time (median) between the time of purchase and when we recieve confirmation.
            </rs-tip>
          </rs-chip>
        </p>
      </div>
      <rs-button @click="state.modal = !state.modal" size="sm" v-if="!newAdvertiser"> Migrate Advertiser </rs-button>
    </div>

    <rs-form ref="advertiserFormRef" v-model="rules.valid">
      <section>
        <rs-layout wrap>
          <rs-flex sm6 xs12>
            <div class="field-combo">
              <rs-text-field
                label="Name"
                v-model.trim="advertiserData.name"
                :rules="[rules.required]"
                @input="touched.name = true"
                persistent-hint
                hint="Required"
              />
              <span tabindex="0" class="mt-2 ml-2">
                <rs-icon small class="shrink">info_outline</rs-icon>
                <rs-tip :offset="[0, 16]"> The name we typically use for the advertiser. </rs-tip>
              </span>
            </div>
          </rs-flex>
          <rs-flex sm6 xs12>
            <div class="field-combo">
              <rs-text-field
                label="Display Name"
                v-model.trim="advertiserData.display"
                @input="touched.display = true"
                persistent-hint
                hint="Required"
                :rules="[rules.required]"
              />
              <span tabindex="0" class="mt-2 ml-2">
                <rs-icon small class="shrink">info_outline</rs-icon>
                <rs-tip :offset="[0, 16]"> This is how the advertiser will display in Link Ninja. </rs-tip>
              </span>
            </div>
          </rs-flex>
        </rs-layout>
        <rs-layout wrap>
          <rs-flex sm12 md6>
            <div class="field-combo">
              <rs-text-field
                label="Commission Rate"
                suffix="%"
                type="number"
                :disabled="advertiserData.commission_freeze"
                :rules="[rules.required, rules.maxPercentage]"
                v-model.trim.number="advertiserData.commission_rate"
                @input="touched.commission_rate = true"
                persistent-hint
                hint="Required"
              />
              <rs-checkbox
                label="Freeze Commission"
                class="ml-2 mt-1"
                @change="touched.commission_freeze = true"
                v-model="advertiserData.commission_freeze"
              />
              <span tabindex="0" class="ml-2">
                <rs-icon small class="shrink">info_outline</rs-icon>
                <rs-tip :offset="[0, 16]">
                  <dl class="advertiser-tip">
                    <dt>Commission Rate:</dt>
                    <dd>
                      Specify the effective commission rate that we receive from the network with regard to this
                      advertiser.
                    </dd>
                    <dt>Freeze Commission:</dt>
                    <dd>Enabling this option forces the advertiser jobs to ignore commission rate changes</dd>
                  </dl>
                </rs-tip>
              </span>
            </div>
          </rs-flex>
          <rs-flex sm6>
            <rs-text-field
              type="number"
              label="Advertiser ID"
              v-model.trim="advertiserData.id"
              @input="touched.id = false"
              disabled
            />
          </rs-flex>
        </rs-layout>
        <rs-divider class="advertiser__divider" />
      </section>
      <section>
        <fields-division-parent
          :parent-value.sync="advertiserData.parent_id"
          :division-value.sync="advertiserData.division"
          :hide-autocomplete="!newAdvertiser"
          :parent-disabled="!newAdvertiser"
        />
        <rs-divider class="advertiser__divider" />
      </section>

      <section>
        <rs-layout wrap>
          <rs-flex sm6>
            <div class="field-combo">
              <rs-autocomplete
                label="Source"
                :items="state.sources"
                item-text="display"
                item-value="id"
                :disabled="!newAdvertiser"
                v-model="advertiserData.source"
                @input="touched.source_description = true"
                persistent-hint
                hint="Required"
                :rules="[rules.required]"
              />
              <span tabindex="0" class="ml-2">
                <rs-icon small class="shrink">info_outline</rs-icon>
                <rs-tip :offset="[0, 16]">
                  The source (also interchangably referred to as "affiliate network") for which this advertiser exists.
                </rs-tip>
              </span>
            </div>
          </rs-flex>
          <rs-flex sm6>
            <div class="field-combo">
              <rs-text-field
                label="Affiliate ID"
                v-model.trim="advertiserData.affiliate_id"
                @input="touched.affiliate_id = true"
                :disabled="!newAdvertiser"
                :rules="[rules.required]"
                persistent-hint
                hint="Required"
              />
              <span tabindex="0" class="ml-2">
                <rs-icon small class="shrink">info_outline</rs-icon>
                <rs-tip :offset="[0, 16]">
                  The internal, constant ID that the source/network uses to identify this advertiser in
                  commission-reporting and/or product feeds.
                </rs-tip>
              </span>
            </div>
          </rs-flex>
          <rs-flex md6 xs12>
            <div class="field-combo">
              <rs-text-field
                label="Deep Link ID"
                :disabled="advertiserData.deeplink_freeze"
                :rules="[rules.required]"
                v-model="advertiserData.deeplink_advertiser"
                @input="touched.deeplink_advertiser = true"
                hint="Required"
                persistent-hint
              />
              <rs-checkbox
                label="Freeze deeplink"
                class="ml-2 mt-1"
                v-model="advertiserData.deeplink_freeze"
                @change="touched.deeplink_freeze = true"
              />
              <span tabindex="0" class="ml-2">
                <rs-icon small class="shrink">info_outline</rs-icon>
                <rs-tip :offset="[0, 16]">
                  <dl class="advertiser-tip">
                    <dt>Deeplink ID:</dt>
                    <dd>The "Deeplink ID" of this advertiser which is used in generating links.</dd>
                    <dt>Freeze Deeplink:</dt>
                    <dd>Prevent the advertiser jobs from making automatic updates to the Deeplink Advertiser field.</dd>
                  </dl>
                </rs-tip>
              </span>
            </div>
          </rs-flex>
          <rs-flex md6 xs12>
            <div class="field-combo">
              <rs-text-field
                label="Cookie Window"
                v-model.number="advertiserData.attributes.cookie_window"
                @input="touched.cookie_window = true"
                persistent-hint
                hint="Days"
              />
            </div>
          </rs-flex>
        </rs-layout>
        <rs-divider class="advertiser__divider" />
      </section>

      <section class="advertiser__regions" v-if="!!regions && advertiserData.regions">
        <advertiser-region
          :regions="regions"
          :selected="advertiserData.regions"
          @region-change="(e) => handleRegionChange(e)"
        />
      </section>

      <section class="advertiser__regions" v-else>
        <rs-progress-circular indeterminate />
      </section>

      <section>
        <h3 class="header-4 mb-2">Additional Options</h3>
        <rs-layout wrap>
          <rs-flex md6>
            <rs-layout wrap>
              <rs-flex md6 xs6>
                <rs-layout align-center>
                  <rs-checkbox
                    label="Active"
                    v-model="advertiserData.active"
                    class="shrink"
                    @change="touched.active = true"
                    dense
                  />
                  <span tabindex="0" class="mt-2 ml-2">
                    <rs-icon small class="shrink">info_outline</rs-icon>
                    <rs-tip :placement="$vuetify.breakpoint.xs ? 'top' : 'right'">
                      Determines whether the advertiser and/or any related products will appear to publishers and be
                      used for international redirects.
                    </rs-tip>
                  </span>
                </rs-layout>
                <rs-layout align-center>
                  <rs-checkbox
                    label="Link Ninja Enabled"
                    v-model="advertiserData.link_ninja_enabled"
                    class="shrink"
                    @change="touched.link_ninja_enabled = true"
                    dense
                  />
                  <span tabindex="0" class="mt-2 ml-2">
                    <rs-icon small class="shrink">info_outline</rs-icon>
                    <rs-tip :offset="[0, 4]" :placement="$vuetify.breakpoint.xs ? 'top' : 'right'">
                      Marks the advertiser as being supported by LinkNinja.
                    </rs-tip>
                  </span>
                </rs-layout>
              </rs-flex>
              <rs-flex md6 xs6>
                <rs-layout align-center>
                  <rs-checkbox
                    label="Mobile Ninja Eligible"
                    v-model="advertiserData.mobile_eligible"
                    class="shrink"
                    @change="touched.mobile_eligible = true"
                    dense
                  />

                  <span tabindex="0" class="mt-2 ml-2">
                    <rs-icon small class="shrink">info_outline</rs-icon>
                    <rs-tip :offset="[0, 4]" :placement="$vuetify.breakpoint.xs ? 'top' : 'right'">
                      Marks the advertiser as being supported by LinkNinja.
                    </rs-tip>
                  </span>
                </rs-layout>
                <rs-layout align-center>
                  <rs-checkbox
                    label="Product Feed"
                    class="shrink"
                    v-model="advertiserData.product_feed"
                    @change="touched.product_feed = true"
                    dense
                  />
                  <span tabindex="0" class="mt-2 ml-2">
                    <rs-icon small class="shrink">info_outline</rs-icon>
                    <rs-tip :offset="[0, 4]" :placement="$vuetify.breakpoint.xs ? 'top' : 'right'">
                      Marks the advertiser as being supported by product feeds.
                    </rs-tip>
                  </span>
                </rs-layout>
              </rs-flex>
              <rs-flex md6 xs6>
                <rs-layout align-center>
                  <rs-checkbox
                    label="In App Tracking"
                    v-model="advertiserData.attributes.in_app_tracking"
                    @change="touched.in_app_tracking = true"
                    class="shrink"
                    dense
                  />
                  <!-- <span tabindex="0" class="mt-2 ml-2">
                    <rs-icon small class="shrink">info_outline</rs-icon>
                    <rs-tip :placement="$vuetify.breakpoint.xs ? 'top' : 'right'">
                      Prevents automatic changes to the Active flag.
                    </rs-tip>
                  </span> -->
                </rs-layout>
                <rs-layout align-center>
                  <rs-checkbox
                    label="Mobile App Shoppable"
                    v-model="advertiserData.attributes.mobile_app_shoppable"
                    @change="touched.mobile_app_shoppable = true"
                    :disabled="!advertiserData.attributes.in_app_tracking"
                    class="shrink"
                    dense
                  />
                  <!-- <span tabindex="0" class="mt-2 ml-2">
                    <rs-icon small class="shrink">info_outline</rs-icon>
                    <rs-tip :placement="$vuetify.breakpoint.xs ? 'top' : 'right'">
                      Prevents automatic changes to the Active flag.
                    </rs-tip>
                  </span> -->
                </rs-layout>
              </rs-flex>
              <rs-flex md6 xs6>
                <rs-layout align-center>
                  <rs-checkbox
                    label="Freeze Active State"
                    v-model="advertiserData.freeze_state"
                    @change="touched.freeze_state = true"
                    class="shrink"
                    dense
                  />
                  <span tabindex="0" class="mt-2 ml-2">
                    <rs-icon small class="shrink">info_outline</rs-icon>
                    <rs-tip :placement="$vuetify.breakpoint.xs ? 'top' : 'right'">
                      Prevents automatic changes to the Active flag.
                    </rs-tip>
                  </span>
                </rs-layout>
              </rs-flex>
            </rs-layout>
          </rs-flex>
          <rs-flex md6 xs12 sm6 align-self-start>
            <rs-textarea label="Notes" v-model.trim="advertiserData.notes" @input="touched.notes = true" />
          </rs-flex>
        </rs-layout>
      </section>
    </rs-form>

    <div class="advertiser__cta">
      <rs-button href="/links/advertisers">Cancel</rs-button>
      <rs-button primary fill @click="onSubmit" :disabled="!(rules.valid && hasChanges)">
        {{ newAdvertiser ? 'Create' : 'Update' }} Advertiser
      </rs-button>
    </div>

    <rs-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="6000" top>
      {{ snackbar.text }}
      <rs-btn flat type="link" @click="snackbar = false"> Close </rs-btn>
    </rs-snackbar>

    <migration-modal
      :initial-data="advertiserData"
      :divisions="state.divisions"
      :sources="state.sources"
      v-model="state.modal"
    />
  </rs-container>
</template>

<script>
import { reactive, toRefs, defineComponent, onMounted, ref, computed, nextTick } from 'vue';
import RsButton from '../lib/components/RsButton/RsButton.vue';
import RsTip from '../lib/components/RsTip/RsTip.vue';
import MigrationModal from '../components/MigrationModal.vue';
import FieldsDivisionParent from '../components/FieldsDivisionParent.vue';
import AdvertiserRegion from '../components/AdvertiserRegion';
import useAdvertiserApi from '../composables/useAdvertiserApi';
import { convertToDays } from '../utils/convert-to-days';
import { useRoute } from 'vue-router/composables';

const touchedFields = {
  name: false,
  display: false,
  divisions: false,
  parent_id: false,
  commission_rate: false,
  commission_freeze: false,
  id: false,
  source_description: false,
  affiliate_id: false,
  deeplink_advertiser: false,
  deeplink_freeze: false,
  regions: false,
  link_ninja_enabled: false,
  active: false,
  mobile_eligible: false,
  product_feed: false,
  freeze_state: false,
  notes: false,
  division: false,
  cookie_window: false,
  mobile_app_shoppable: false,
};

export default defineComponent({
  name: 'AdvertiserConfig',
  components: {
    RsButton,
    RsTip,
    AdvertiserRegion,
    FieldsDivisionParent,
    MigrationModal,
  },
  setup() {
    const route = useRoute();
    const routeParam = route.params.id;

    const advertiserFormRef = ref(null);
    const { getAdvertiser, getRegions, putAdvertiser, postNewAdvertiser, getSources } = useAdvertiserApi(routeParam);

    const newAdvertiser = computed(() => routeParam === 'new');
    const data = reactive({
      advertiserData: {
        division: null,
        parent_id: null,
        regions: [],
        attributes: {},
      },
      regions: [],
    });

    const state = reactive({
      search: '',
      regions: [],
      divisions: [],
      parents: [],
      sources: [],
      modal: false,
    });

    const loading = reactive({
      advertiser: true,
      regions: true,
      divisions: true,
    });

    const temp = reactive({
      regions: null,
    });

    const touched = ref({
      ...touchedFields,
    });

    const hasChanges = computed(() => {
      return Object.values(touched.value).includes(true);
    });

    const rules = reactive({
      valid: true,
      required: (value) => !!value || value === 0 || 'Required',
      maxPercentage: (value) => (parseInt(value) >= 0 && parseInt(value) <= 100) || 'Must be less than 100',
    });

    const validateForm = () => {
      advertiserFormRef.value.validate();
    };

    const clearForm = () => {
      data.advertiserData = {
        parent_id: undefined,
        division: undefined,
        regions: [],
      };
      touched.value = {
        ...touchedFields,
      };
      advertiserFormRef.value.resetValidation();
    };

    const handleRegionChange = (event) => {
      touched.value.regions = !!event;
      temp.regions = event;
    };

    const snackbar = reactive({
      text: '',
      color: '',
      show: false,
    });

    const handleUpdate = async () => {
      await new Promise((r) => setTimeout(r, 500));
      await nextTick();

      const payload = {
        ...data.advertiserData,
        regions: temp.regions,
      };

      putAdvertiser(payload)
        .then(() => {
          snackbar.show = true;
          snackbar.text = data.advertiserData.name + ' has been updated';
          snackbar.color = 'success';
        })
        .catch((error) => {
          snackbar.show = true;
          snackbar.color = 'error';
          snackbar.text = error;
        });
    };

    const handleCreate = async () => {
      await new Promise((r) => setTimeout(r, 500));
      await nextTick();

      const payload = {
        ...data.advertiserData,
        regions: temp.regions,
      };

      postNewAdvertiser(payload)
        .then(() => {
          snackbar.show = true;
          snackbar.text = data.advertiserData.name + ' has been created';
          snackbar.color = 'success';
          clearForm();
        })
        .catch((error) => {
          snackbar.show = true;
          snackbar.color = 'error';
          state.text = 'Unable to create advertiser' + data.advertiserData.name;
        });
    };

    const log = (e) => {
      console.warn(e);
    };

    const onSubmit = async () => {
      if (!newAdvertiser.value) {
        await handleUpdate();
      } else {
        await handleCreate();
      }
    };

    onMounted(() => {
      window.onpopstate = () => {
        location.reload();
      };
      if (!newAdvertiser.value) {
        getAdvertiser(routeParam)
          .then((response) => {
            loading.advertiser = false;
            data.advertiserData = {
              ...response,
              attributes: {
                cookie_window: response?.attributes?.cookie_window,
                in_app_tracking: response?.attributes?.in_app_tracking,
                median_purchase_leadtime: response?.attributes?.median_purchase_leadtime,
                mobile_app_shoppable: response?.attributes?.mobile_app_shoppable,
              },
            };
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        data.advertiserData.active = true;
        loading.advertiser = false;
      }

      getRegions()
        .then((response) => {
          loading.regions = false;
          data.regions = response;
        })
        .catch((err) => {
          console.error(err.data.error.detail);
        });

      getSources()
        .then((response) => {
          loading.divisions = false;
          state.sources = response;
        })
        .catch((err) => {
          console.error(err.data.error.detail);
        });
    });

    return {
      ...toRefs(data),
      state,
      rules,
      advertiserFormRef,
      validateForm,
      handleRegionChange,
      onSubmit,
      snackbar,
      loading,
      touched,
      hasChanges,
      newAdvertiser,
      log,
      clearForm,
      convertToDays,
    };
  },
});
</script>

<style lang="scss">
@use '../scss/breakpoints';
@import '../scss/typography';
@import '../scss/colors';
.advertiser {
  max-width: 1185px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include breakpoints.down('xs') {
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-bottom: 32px;
    }
  }
  &__divider {
    margin: 32px 0;
  }

  &__cta {
    display: flex;
    justify-content: flex-end;
    position: sticky;
    bottom: 0;
    background: var(--bg-1);
    margin: 32px -16px;
    padding: 16px;
    z-index: 4;
    border-top: 1px solid var(--bg-3);
  }
}

.field-combo {
  display: flex;
  align-items: center;
  .v-input {
    &--checkbox {
      flex-grow: 0;
    }
  }
}

.advertiser-tip {
  dt {
    font-weight: 700;
  }
  dd {
    margin-bottom: 8px;
  }
}
</style>
