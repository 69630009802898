<template>
  <rs-dialog v-model="bulkSearchDialog" max-width="750px">
    <template #activator="{ on }">
      <rs-btn :align-self-baseline="$vuetify.breakpoint.sm" font-medium v-on="on" :disabled="disabled"
        >Search By ID</rs-btn
      >
    </template>
    <rs-card>
      <rs-layout row wrap>
        <rs-flex xs12 mx-4>
          <rs-layout row justify-space-between>
            <rs-flex font-weight-medium mt-4 pt-2 mb-3 body-2>Search For Influencer Accounts</rs-flex>

            <rs-icon size="23" @click="closeBulkSearchDialog">rsfont-close_v2</rs-icon>
          </rs-layout>
        </rs-flex>
        <rs-flex
          xs12
          :class="{
            'mb-4': $vuetify.breakpoint.smAndUp,
            'mb-2': $vuetify.breakpoint.xs,
          }"
        >
          <rs-divider></rs-divider>
        </rs-flex>
        <rs-flex xs12 mx-4 mb-0>
          <rs-textarea
            ref="text-area"
            v-model="textAreaData"
            outline
            clearable
            clear-icon="rsfont-delete_v2"
            placeholder="Enter one Account ID per line"
            :rules="inputRules"
          ></rs-textarea>
        </rs-flex>
        <rs-flex xs12 align-self-end ma-4>
          <rs-layout row wrap justify-end>
            <rs-flex
              :class="{
                xs3: $vuetify.breakpoint.smAndUp,
                xs12: $vuetify.breakpoint.xs,
              }"
            >
              <rs-btn font-medium @click="closeBulkSearchDialog">Cancel</rs-btn>
            </rs-flex>
            <rs-flex
              :class="{
                xs3: $vuetify.breakpoint.smAndUp,
                'ml-3': $vuetify.breakpoint.smAndUp,
                xs12: $vuetify.breakpoint.xs,
              }"
            >
              <rs-btn inverted font-medium @click="handleBulkSearchClick"> Search </rs-btn>
            </rs-flex>
          </rs-layout>
        </rs-flex>
      </rs-layout>
    </rs-card>
  </rs-dialog>
</template>

<script>
export default {
  props: ['disabled'],
  data() {
    return {
      bulkSearchDialog: false,
      textAreaData: '',
      inputRules: [
        (v) => !!v || 'Enter one Account ID per line',
        (v) =>
          (v && /^\d+$/.test(this.accountIds.join(''))) ||
          'Account IDs must contain only numbers and newline characters',
      ],
    };
  },
  computed: {
    accountIds() {
      const ids = this.textAreaData ? this.textAreaData.split('\n').filter((id) => id !== '') : [];
      return ids;
    },
  },
  methods: {
    closeBulkSearchDialog() {
      this.bulkSearchDialog = false;
      this.textAreaData = '';
      this.$refs['text-area'].resetValidation();
    },
    handleBulkSearchClick() {
      if (this.$refs['text-area'].validate()) {
        this.$emit('bulk-id-search', this.accountIds);
        this.closeBulkSearchDialog();
      }
    },
  },
};
</script>

<style lang="sass" scoped>
::v-deep .v-input__slot
  border: 1px solid #C0C0C0 !important
</style>
