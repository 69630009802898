import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { LOCAL_STORAGE_KEYS } from '@rscollabs/rs-core-library/src/services/auth0';

const determineIdentityProvider = (subclaim) => {
  if (!subclaim) {
    return;
  }

  const [provider, id] = subclaim.split('|');

  return provider && id
    ? {
        provider,
        id,
      }
    : undefined;
};

function determineRCSClaims(identityProvider) {
  if (!identityProvider) {
    return;
  }

  switch (identityProvider.provider) {
    case 'auth0':
      return ['RS_BRAND_USER'];
    case 'google-apps':
      return ['RS_OPERATOR'];
    default:
      return ['RS_EMPLOYEE'];
  }
}

export const validateClaimsAccess = () => {
  const idToken = Cookies.get(LOCAL_STORAGE_KEYS.AUTH0_CODE);
  const jwt = idToken ? jwtDecode(idToken) : undefined;

  if (!jwt) {
    throw 'JWT not defined';
  }

  const identityProvider = determineIdentityProvider(jwt.sub);
  if (!identityProvider) {
    throw 'Identity Provider is not defined';
  }

  const allowedClaims = ['RS_OPERATOR', 'RS_EMPLOYEE'];
  const claims = determineRCSClaims(identityProvider);

  if (!claims.some((claim) => allowedClaims.indexOf(claim) >= 0)) {
    if (!jwt.email.includes('@rewardstyle')) {
      throw 'Claims mismatch';
    }
  }
};
