<script>
import { defineComponent, computed, ref } from 'vue';
import useKeyboardFocus from '../../composables/useKeyboardFocus';
import uuid from '../../utils/uuid';
export default defineComponent({
  name: 'RsButton',
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: () => 'rp-button-' + uuid(),
    },
    href: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'default',
    },
    dark: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
    full: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: [Boolean, String],
      default: false,
    },
    tab: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
    lowercase: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const buttonRef = ref(null);
    const isFocused = useKeyboardFocus(buttonRef);

    const rootClass = computed(() => {
      return props.tab ? 'rs--tab-button' : 'rs--button';
    });

    const themeSelector = computed(() => {
      if (props.theme === 'default') {
        return props.primary ? 'primary' : 'default';
      } else {
        return props.theme;
      }
    });

    const themeClass = computed(() => {
      return `${rootClass.value}__${themeSelector.value}`;
    });

    const classes = computed(() => {
      return {
        [`is--${props.size}`]: props.size,
        'is--dark': props.dark,
        'is--focused': isFocused.value,
        'is--disabled': props.disabled,
        'is--active': props.active,
        'is--full': props.full,
        'is--rounded': typeof props.rounded === 'boolean' && props.rounded === true,
        'is--rounded--start': props.rounded === 'start',
        'is--rounded--end': props.rounded === 'end',
        'is--icon-only': props.iconOnly,
        'is--lowercase': props.lowercase,
        'is--flat': props.flat,
      };
    });

    const elementTag = computed(() => {
      return props.href ? 'a' : 'button';
    });

    return {
      rootClass,
      elementTag,
      themeClass,
      classes,
      buttonRef,
    };
  },

  // TODO: [VUE3] This will have to be updated to use the new render() api.
  render: function (createElement) {
    return createElement(
      this.elementTag,
      {
        ref: 'buttonRef',
        class: [this.rootClass, this.themeClass, this.classes],
        attrs: {
          id: this.id,
          disabled: this.disabled,
          href: this.href,
          ...this.$attrs,
        },
        on: {
          ...this.$listeners,
        },
      },
      [
        createElement(
          'div',
          {
            class: this.rootClass + '__content',
          },
          [this.$slots.default],
        ),
      ],
    );
  },
});
</script>

<style lang="scss">
@import './button';
@import './tabs';
</style>
