import { onMounted, ref, watch } from 'vue';
import { FILTERS, NUMERIC_OPERATORS } from '../constants';

const useAdvertiserRouterQuery = (query) => {
  const filterBy = ref(null);
  const filterParams = ref('');
  const filterActive = ref(null);
  const filterOperator = ref('');
  const filterRegions = ref([]);
  const page = ref(null);
  const limit = ref(null);

  const filterValues = FILTERS.map((filter) => filter.value);

  watch(
    () => query,
    () => {
      Object.entries({ ...query }).forEach(([key, value]) => {
        if (filterValues.includes(key) && key !== 'commission' && key !== 'region') {
          filterBy.value = FILTERS.find((filter) => filter.value === key);
          filterParams.value = decodeURIComponent(value);
        }
        if (key === 'active') {
          filterActive.value = value;
        }
        if (key === 'page') {
          page.value = Number(value);
        }
        if (key === 'limit') {
          limit.value = Number(value);
        }
        if (key === 'commission') {
          const commissionObj = decodeURIComponent(value).split(',');
          filterBy.value = FILTERS.find((filter) => filter.value === key);
          filterOperator.value = NUMERIC_OPERATORS.find((operator) => operator.value === commissionObj[0]);
          filterParams.value = commissionObj[1];
        }
        if (key === 'region') {
          filterBy.value = FILTERS.find((filter) => filter.value === key);
          filterRegions.value = decodeURIComponent(value).split(',') || [];
        }
      });
    },
    {
      immediate: true,
      flush: 'post',
    },
  );

  return {
    filterBy,
    filterParams,
    filterActive,
    filterOperator,
    filterRegions,
    page,
    limit,
  };
};

export default useAdvertiserRouterQuery;
