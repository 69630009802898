<template>
  <rs-autocomplete
    v-model="select"
    :items="profiles"
    label="LTK Profile Search"
    :loading="isLoading"
    :no-filter="true"
    :search-input.sync="searchQuery"
    :multiple="multiple"
    chips
    hide-no-data
    hide-selected
    auto-select-first
  >
    <template #selection="{ item, selected }">
      <rs-chip :key="item.id" :selected="selected" class="v-chip--select-multi" close @input="select = undefined">
        <rs-avatar><img :src="item.avatar_url" /></rs-avatar>
        {{ item.display_name }}
      </rs-chip>
    </template>
    <template #item="{ item }">
      <rs-list-tile-avatar>
        <img :src="item.avatar_url + '?fm=jpeg&auto=format&w=40&h=40&fit=crop'" />
      </rs-list-tile-avatar>
      <rs-list-tile-content>
        <rs-list-tile-title>{{ item.blog_name }}</rs-list-tile-title>
        <rs-list-tile-sub-title>@{{ item.display_name }}</rs-list-tile-sub-title>
      </rs-list-tile-content>
    </template>
  </rs-autocomplete>
</template>

<script>
import debounce from '../utils/debounce';

export default {
  props: ['value', 'multiple', 'excludeProfiles'],
  data() {
    const searchQuery = this.$route.query.search || null;
    return {
      select: this.value || undefined,
      isLoading: false,
      searchQuery,
      lastSearchQuery: null,
    };
  },
  watch: {
    searchQuery: {
      immediate: true,
      handler: debounce(async function (newValue) {
        if (newValue !== null && newValue !== '' && newValue !== this.lastSearchQuery) {
          await this.search(newValue);
        } else {
          this.addQuery({});
        }
        this.lastSearchQuery = newValue;
      }, 200),
    },
    select(newValue) {
      this.$emit('input', newValue ? newValue.id : null);
    },
  },
  methods: {
    async search(search) {
      this.isLoading = true;
      this.addQuery({ search });
      try {
        await this.$store.dispatch('searchProfiles', search);
        this.isLoading = false;
      } catch (error) {
        this.$root.$emit('onError', error);
      }
    },
    addQuery(params) {
      const keys = Object.keys(params);
      const paramString =
        keys.length > 0
          ? '?' + keys.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&')
          : '';

      if (window.location.search !== paramString) {
        history.pushState({}, null, this.$route.path + paramString);
      }
    },
    getProfile(profileId) {
      return this.$store.state['ltk-profiles'].profiles[profileId] || {};
    },
  },
  computed: {
    profileIds() {
      const { profileIds } = this.$store.state['ltk-profiles'];
      if (this.excludeProfiles !== undefined) {
        return profileIds.filter((id) => !this.excludeProfiles.includes(id));
      }

      return profileIds;
    },
    profiles() {
      const profiles = [];
      if (this.select !== undefined) {
        profiles.push(this.select);
      }

      if (this.searchQuery === '' || this.searchQuery === null) {
        return profiles;
      }
      if (this.isLoading === true) {
        return profiles;
      }
      profiles.push(...this.profileIds.map((profileId) => this.$store.state['ltk-profiles'].profiles[profileId] || {}));

      return profiles;
    },
  },
};
</script>
