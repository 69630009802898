<template>
  <div>
    <p class="match-button-title">Is this a match?</p>
    <div class="match-button-content">
      <rs-button
        :class="['match-button is--approved mb-0', { 'is--selected': isSelected }]"
        full
        rounded
        lowercase
        :active="isApproved"
        @click="onUpdateValue(MATCH_VALUES.YES)"
      >
        <rs-icon class="mr-2" size="16">ltkfont-check-circle-outline</rs-icon>
        Yes
      </rs-button>
      <rs-button
        :class="['match-button is--denied mb-0', { 'is--selected': isSelected }]"
        full
        rounded
        lowercase
        :active="isDenied"
        @click="onUpdateValue(MATCH_VALUES.NO)"
      >
        <rs-icon class="mr-2" size="12">ltkfont-x-close-outline</rs-icon>
        No
      </rs-button>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, toRefs } from 'vue';

import RsButton from '@/internal-advertisers/lib/components/RsButton/RsButton.vue';

import { MATCH_VALUES } from '../constants';
import { isMatchApproved, isMatchDenied } from '../utils/matchStatus';

export default defineComponent({
  name: 'QualityControlMatchButton',
  components: {
    RsButton,
  },
  emits: ['input'],
  props: {
    value: String,
  },
  setup(props, ctx) {
    const { value } = toRefs(props);

    const isApproved = computed(() => isMatchApproved(value.value));
    const isDenied = computed(() => isMatchDenied(value.value));
    const isSelected = computed(() => isApproved.value || isDenied.value);

    const onUpdateValue = (newValue) => {
      ctx.emit('input', newValue);
    };

    return { isApproved, isDenied, isSelected, MATCH_VALUES, onUpdateValue };
  },
});
</script>

<style lang="scss" scoped>
@mixin matchButtonColors($color) {
  color: $color;
  border-color: $color;

  i {
    color: $color;
  }

  &:hover,
  &:focus,
  &.is--active {
    background-color: $color;
    color: white;
    border-color: $color;

    i {
      color: white;
    }
  }

  &.is--selected:not(.is--active) {
    background-color: var(--rs-btn-default-background-default);
    color: #9e9e9e;
    border-color: #9e9e9e;

    i {
      color: #9e9e9e;
    }

    &:hover,
    &:focus {
      background-color: var(--rs-btn-default-background-default);
      color: $color;
      border-color: $color;

      i {
        color: $color;
      }
    }
  }
}

.match-button-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.match-button-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.match-button {
  @include matchButtonColors(#9e9e9e);

  &.is--approved {
    @include matchButtonColors(#4caf50);
  }

  &.is--denied {
    @include matchButtonColors(#aa2222);
  }
}

.match-button::v-deep .rs--button__content {
  padding: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: normal;
}
</style>
