import useStore from '@/composables/vuex';
import { computed, unref } from 'vue';

export const useWorkItem = (workItemId) => {
  const store = useStore();

  const workItem = computed(() => {
    return store.state['catalog-quality-control'].workItemsMap[unref(workItemId)];
  });

  return workItem;
};

export const useWorkItemProgress = (workItemId) => {
  const workItem = useWorkItem(workItemId);

  const matchingProducts = computed(() => {
    if (!workItem.value) {
      return {};
    }
    return workItem.value.matchingProducts;
  });

  const progressCount = computed(() => {
    return Object.keys(matchingProducts.value).filter(
      (matchingProductId) => !!matchingProducts.value[matchingProductId]?.match,
    ).length;
  });

  const totalCount = computed(() => {
    return Object.keys(matchingProducts.value).length;
  });

  return {
    progressCount,
    totalCount,
  };
};

export const usePrimaryProductDetails = (workItemId) => {
  const workItem = useWorkItem(workItemId);

  const productDetails = computed(() => {
    if (!workItem.value) {
      return;
    }
    return workItem.value.primaryProduct;
  });

  return productDetails;
};

export const useMatchingProductDetails = (workItemId, matchingProductId) => {
  const workItem = useWorkItem(workItemId);

  const productDetails = computed(() => {
    if (!workItem.value) {
      return;
    }
    return workItem.value.matchingProducts[unref(matchingProductId)].product;
  });

  return productDetails;
};
