<template>
  <rs-container class="influencer-accounts" fluid>
    <rs-snackbar top right v-model="snack" :timeout="1500" :color="snackColor">
      {{ snackText }}
      <rs-btn flat @click="snack = false">Close</rs-btn>
    </rs-snackbar>
    <rs-card>
      <rs-card-title class="elevation-0">
        <!-- Table Filter  -->
        <rs-layout wrap justify-space-around align-center>
          <rs-flex lg1 md2 xs12>
            <rs-select
              v-model="selectedCountries"
              class="py-0 my-0"
              :items="countries"
              item-text="name"
              item-value="id"
              :disabled="isFilterDisabled"
              label="Countries"
              multiple
              small-chips
              deletable-chips
              chips
              dense
            ></rs-select>
          </rs-flex>
          <rs-flex lg1 md2 xs12>
            <rs-select
              v-model="selectedOffices"
              class="py-0 my-0"
              :menu-props="{ maxHeight: '300' }"
              :items="OFFICES"
              :disabled="isFilterDisabled"
              label="Offices"
              small-chips
              multiple
              chips
              deletable-chips
              dense
            ></rs-select>
          </rs-flex>
          <rs-flex lg1 md2 xs12>
            <rs-select
              v-model="selectedSegments"
              class="py-0 my-0"
              :menu-props="{ maxHeight: '300' }"
              :items="SEGMENTS"
              :disabled="isFilterDisabled"
              label="Segments"
              multiple
              chips
              small-chips
              dense
              deletable-chips
            ></rs-select>
          </rs-flex>
          <rs-flex lg1 md2 xs12>
            <rs-select
              v-model="selectedPods"
              class="py-0 my-0"
              :menu-props="{ maxHeight: '300' }"
              :items="PODS"
              :disabled="isFilterDisabled"
              label="Pods"
              multiple
              chips
              small-chips
              dense
              deletable-chips
            ></rs-select>
          </rs-flex>
          <rs-flex lg3 offset-lg0 md4 offset-md2 xs12>
            <rs-text-field
              height="40"
              class="mb-4"
              :disabled="isFilterDisabled"
              v-model="search"
              append-icon="search"
              placeholder="Enter in Influencer or Company Name"
            ></rs-text-field>
          </rs-flex>
          <rs-flex lg2 offset-lg0 md4 xs12>
            <search-account-dialog @bulk-id-search="handleBulkIDSearch" :disabled="isFilterDisabled" />
          </rs-flex>
        </rs-layout>
      </rs-card-title>
      <!-- Accounts Table -->
      <rs-layout class="table-layout">
        <rs-flex class="table-wrapper">
          <rs-data-table
            v-model="selectedRows"
            :headers="headers"
            :items="accounts"
            :loading="isLoading"
            select-all
            item-key="id"
            hide-details
            :must-sort="true"
            :rows-per-page-items="[5, 10, 25]"
            @update:pagination="onPaginationUpdated"
            :pagination.sync="pagination"
          >
            <template slot="no-data">
              <div v-if="isLoading" class="text-xs-center">Loading...</div>
              <div v-else class="text-xs-center">No accounts found.</div>
            </template>
            <template #headers="props">
              <th>
                <rs-checkbox
                  :input-value="props.all"
                  :indeterminate="props.indeterminate"
                  primary
                  :readonly="editingAccounts.length > 0"
                  hide-details
                  @change="toggleAll"
                ></rs-checkbox>
              </th>
              <th class="text-xs-left" v-for="header in props.headers" :key="header.text">{{ header.text }}</th>
            </template>
            <template #items="props">
              <table-row
                :props="props"
                :selected-rows="selectedRows"
                :editing-accounts="editingAccounts"
                @changeOffice="changeOffice(props.item)"
                @changePod="changePod(props.item)"
                @changeSubPod="changeSubPod(props.item)"
                @changeSubRegion="changeSubRegion(props.item)"
              />
            </template>
            <template #pageText></template>
            <template #no-results>
              <span>Your search for "{{ search }}" found no results.</span>
            </template>
          </rs-data-table>
        </rs-flex>
      </rs-layout>

      <rs-card-actions class="py-4 elevation-0" v-if="isFiltering && editingAccounts.length === 0">
        <rs-spacer></rs-spacer>
        <rs-btn font-medium flat @click="reloadData">Reset all filters</rs-btn>
      </rs-card-actions>

      <rs-card-actions class="py-4 elevation-0" v-if="editingAccounts.length > 0">
        <rs-spacer></rs-spacer>
        <rs-btn inverted font-medium flat @click="dialog = true">Save</rs-btn>
        <rs-btn font-medium flat @click="reloadData">Cancel</rs-btn>
      </rs-card-actions>

      <!-- Confirm Modal -->
      <rs-dialog v-model="dialog" persistent max-width="400">
        <rs-card class="py-2">
          <rs-card-title class="headline">Confirmation</rs-card-title>
          <rs-card-text class="subheading pt-0 mt-0">Are you sure want to save?</rs-card-text>
          <rs-card-actions>
            <rs-spacer></rs-spacer>
            <rs-btn inverted flat @click="confirmSaving">Confirm</rs-btn>
            <rs-btn color="grey darken-2" flat @click="dialog = false">Cancel</rs-btn>
          </rs-card-actions>
        </rs-card>
      </rs-dialog>
    </rs-card>
  </rs-container>
</template>

<script>
import { OFFICES, SEGMENTS, PODS, getSubRegionsFor, getSubPodsFor } from '@/internal-pod-management/utils/attributes';

import TableRow from '../components/TableRow.vue';
import SearchAccountDialog from '../components/SearchAccountDialog.vue';
import appService from '@/internal-pod-management/services/app';
import accountApi from '../services/accounts';
import debounce from '../../internal-ltk-recommendations/utils/debounce';

export default {
  components: { TableRow, SearchAccountDialog },
  data() {
    return {
      OFFICES,
      SEGMENTS,
      PODS,
      isLoading: false,
      hasError: false,
      dialog: false,
      accountIds: [],
      bulkIDSearch: false,
      selectedCountries: [],
      selectedOffices: [],
      selectedSegments: [],
      selectedPods: [],
      snack: false,
      search: '',
      snackColor: '',
      snackText: '',
      selectedRows: [],
      editingAccounts: [],
      headers: [
        {
          text: 'Account ID',
          align: 'left',
          value: 'id',
          sortable: true,
        },
        { text: 'Company', value: 'company' },
        { text: 'Blog', value: 'blog' },
        { text: 'Blog Name', value: 'blog_name' },
        { text: 'Country', value: 'country' },
        { text: 'Office', value: 'office' },
        { text: 'Segment', value: 'segment' },
        { text: 'Pod', value: 'pod' },
        { text: 'Sub-pod', value: 'sub_pod' },
        { text: 'Sub-Region', value: 'sub_region' },
      ],
      countries: [],
      accounts: [],
      limit: 50,
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 5,
      },
    };
  },
  watch: {
    selectedCountries() {
      this.loadDataAndResetPaginationState();
    },
    selectedOffices() {
      this.loadDataAndResetPaginationState();
    },
    selectedSegments() {
      this.loadDataAndResetPaginationState();
    },
    selectedPods() {
      this.loadDataAndResetPaginationState();
    },
    accountIds() {
      if (this.accountIds.length) {
        this.loadDataAndResetPaginationState();
        this.accountIds = [];
      }
    },
    limit() {
      this.loadData();
    },
    search: {
      handler: debounce(async function () {
        this.loadDataAndResetPaginationState();
      }, 1000),
    },
  },
  computed: {
    isFilterDisabled() {
      return this.editingAccounts.length > 0 || this.isLoading;
    },
    isFiltering() {
      return (
        this.selectedCountries.length ||
        this.selectedOffices.length ||
        this.selectedSegments.length ||
        this.selectedPods.length ||
        this.search ||
        this.bulkIDSearch
      );
    },
  },
  async mounted() {
    this.loadData();
  },
  methods: {
    async getCountries() {
      this.countries = await appService.getCountries();
    },
    async getAccounts() {
      const params = {
        'filter[query]': this.search,
        'filter[countries]': this.selectedCountries.toString(),
        'filter[segments]': this.selectedSegments.toString(),
        'filter[pods]': this.selectedPods.toString(),
        'filter[offices]': this.selectedOffices.toString(),
        'filter[account_ids]': this.accountIds.toString(),
        limit: this.limit,
      };
      const accounts = await accountApi.getAccounts(params);
      this.accounts = accounts;
    },
    onPaginationUpdated(pagination) {
      const { page, rowsPerPage } = pagination;
      /* client-side pagination */
      if (page !== 1 && page * rowsPerPage >= this.accounts.length) {
        this.limit += 50;
      }
    },
    async loadData() {
      try {
        this.isLoading = true;
        await Promise.all([this.getCountries(), this.getAccounts()]);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        this.isLoading = false;
      }
    },
    loadDataAndResetPaginationState() {
      if (this.limit == 50) this.loadData();
      else this.limit = 50;
      this.pagination.page = 1;
    },
    selectRow(item) {
      if (!this.selectedRows.some((row) => row.id === item.id) && this.editingAccounts.length === 0)
        this.selectedRows.push(item);
    },
    markAsEditing(item) {
      if (!this.editingAccounts.includes(item.id)) {
        this.editingAccounts = [...this.editingAccounts, item.id];
      }
    },
    changeOffice(item) {
      this.selectRow(item);
      this.markAsEditing(item);

      this.selectedRows.forEach((account) => {
        const office = item.office.office || '';
        const defaultSubRegion = getSubRegionsFor(office)?.[0] ?? '';
        const defaultSubPod = getSubPodsFor(office, defaultSubRegion)?.[0] ?? '';

        this.$set(account.office, 'office', office);
        this.$set(account.office, 'sub_region', defaultSubRegion);
        this.$set(account.office, 'sub_pod', defaultSubPod);
      });
    },
    changePod(item) {
      this.selectRow(item);
      this.markAsEditing(item);
      this.selectedRows.forEach((account) => {
        this.$set(account.office, 'pod', item.office.pod);
      });
    },
    changeSubRegion(item) {
      this.selectRow(item);
      this.markAsEditing(item);
      this.selectedRows.forEach((account) => {
        this.$set(account.office, 'sub_region', item.office.sub_region);
      });
    },
    changeSubPod(item) {
      this.selectRow(item);
      this.markAsEditing(item);
      this.selectedRows.forEach((account) => {
        this.$set(account.office, 'sub_pod', item.office.sub_pod);
      });
    },
    reloadData() {
      window.location.reload();
    },
    toggleAll() {
      if (this.editingAccounts.length > 0) return;
      if (this.selectedRows.length === this.accounts.length) {
        this.selectedRows = [];
      } else {
        this.selectedRows = this.accounts.slice();
      }
    },
    resetStates() {
      this.selectedRows = [];
      this.editingAccounts = [];
      this.dialog = false;
    },

    handleBulkIDSearch(accountIds) {
      this.bulkIDSearch = true;
      this.accountIds = accountIds;
    },
    async confirmSaving() {
      /* update all editing accounts */
      try {
        const promises = this.selectedRows.map(async (account) => {
          const { id, office } = account;
          const { pod, sub_pod, sub_region } = office;
          return await accountApi.updateAccount(id, { office: office.office, pod, sub_pod, sub_region });
        });

        await Promise.all(promises);

        /* reset all stuffs */
        this.resetStates();
        this.snack = true;
        this.snackColor = 'green darken-1';
        this.snackText = 'Saved successfully';
      } catch (error) {
        this.snack = true;
        this.snackText = 'Error! Please try again';
        this.snackColor = 'orange';
        this.resetStates();
        this.loadData();
      }
    },
  },
};
</script>

<style lang="sass">
@media only screen and (min-width: 1904px)
  .container
    min-width: 1680px
    max-width: fit-content !important
.influencer-accounts
  width: 100%
  width: -moz-available
  width: -webkit-fill-available

th
  white-space: nowrap

td
  white-space: nowrap

.table-layout
  max-height: 65vh

.table-wrapper
  overflow: auto

.v-select__selections input
  display: none

.v-select__selection--comma
  width: 100%
  text-overflow: unset
  margin: 0 5px

.v-snack .v-snack__wrapper
  width: auto

.v-btn
  @media (min-width: 960px)
    max-width: 200px
    margin-top: 0px
  @media (max-width: 960px)
    margin-top: 0px
</style>
