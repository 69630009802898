import clientFactory from '@rscollabs/rs-core-library/src/services/client';

const client = clientFactory('/api/ltk/v1/product-matching-qc');

class CatalogQualityControlService {
  get({ group, operatorId, pageIndex, pageSize, completed = false }) {
    let url = '/entries';
    if (group === 'C&D') {
      url = '/cd' + url;
    }
    return client.get(url, {
      headers: {
        'X-Operator-Id': operatorId,
      },
      params: { pageIndex, pageSize, completed },
    });
  }

  getOne(id, { group, operatorId }) {
    let url = '/entries';
    if (group === 'C&D') {
      url = '/cd' + url;
    }
    return client.get(`${url}/${id}`, {
      headers: {
        'X-Operator-Id': operatorId,
      },
    });
  }

  getOperators({ group }) {
    let url = '/operators';
    if (group === 'C&D') {
      url = '/cd' + url;
    }
    return client.get(url);
  }

  getOperatorsStat({ group, from, to }) {
    let url = '/stat';
    if (group === 'C&D') {
      url = '/cd' + url;
    }
    return client.get(`${url}?from=${from}&to=${to}`);
  }

  getUnassignedCount({ group }) {
    let url = '/entries-ops/unassigned-count';
    if (group === 'C&D') {
      url = '/cd' + url;
    }
    return client.get(url);
  }

  putSave(id, { group, completed, matchingProducts, operatorId }) {
    let url = '/entries';
    if (group === 'C&D') {
      url = '/cd' + url;
    }
    return client.put(
      `${url}/${id}`,
      { completed, matchingProducts },
      {
        headers: {
          'X-Operator-Id': operatorId,
        },
      },
    );
  }

  postTransfer({ group, operatorId }) {
    let url = '/entries-ops/transfer';
    if (group === 'C&D') {
      url = '/cd' + url;
    }
    return client.post(
      url,
      {},
      {
        headers: {
          'X-Operator-Id': operatorId,
        },
      },
    );
  }

  postAcquire({ group, operatorId }) {
    let url = '/entries-ops/acquire';
    if (group === 'C&D') {
      url = '/cd' + url;
    }
    return client.post(
      url,
      {},
      {
        headers: {
          'X-Operator-Id': operatorId,
        },
      },
    );
  }

  postReleaseAll({ group, operatorId }) {
    let url = '/entries-ops/releaseAll';
    if (group === 'C&D') {
      url = '/cd' + url;
    }
    return client.post(
      url,
      {},
      {
        headers: {
          'X-Operator-Id': operatorId,
        },
      },
    );
  }
}

export default new CatalogQualityControlService();
