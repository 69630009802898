<template>
  <rs-container>
    <rs-flex class="header">
      <h2 class="display-3 mb-2 mt-0">Collaborations</h2>
    </rs-flex>
    <rs-card>
      <rs-card-title class="card-header">
        <h3 class="display-2 font-weight-medium ma-0">Casting Lists</h3>
      </rs-card-title>

      <rs-card-text class="card-body">
        <rs-data-table :loading="loading" :headers="headers" :items="listData" item-key="name" hide-actions>
          <template #items="props">
            <tr class="list-row" data-test-id="qa-list-item" @click="handleListClick(props.item.name)">
              <td>{{ props.item.title }}</td>
              <td>{{ props.item.description }}</td>
            </tr>
          </template>
        </rs-data-table>
      </rs-card-text>
    </rs-card>
  </rs-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { listIdsDev, listIdsProd, listDetails } from '../utils/lists';
import amplitudeMixins from '@/mixins/amplitude';
const agencyAllowListFlag = 'agency-allowed-list';

export default {
  name: 'Casting',
  mixins: [amplitudeMixins],
  computed: {
    ...mapGetters('casting', ['getProductCategories']),
    listIds() {
      let ids = rewardStyle.ENVIRONMENT === 'prod' ? listIdsProd : listIdsDev;
      let allowListId = 24601;
      if (this.agencyAllowListFlagEnabled && !ids.includes(allowListId)) {
        ids.push(allowListId);
      }
      return ids;
    },
    listData() {
      const listDataObj = (this.getProductCategories()?.value || []).reduce((acc, { id, name }) => {
        acc[id] = { name, ...listDetails[name] };
        return acc;
      }, {});
      return this.listIds.map((id) => listDataObj[id]).filter(Boolean);
    },
    agencyAllowListFlagEnabled() {
      return this.checkAmplitudeFlag(agencyAllowListFlag);
    },
  },
  async mounted() {
    try {
      await this.getCastingListsV3(this.listIds);
      this.loading = false;
    } catch (e) {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions('casting', ['getCastingListsV3']),
    handleListClick(listName) {
      this.$router.push({ path: `/collabs/casting/${listName}` });
    },
  },
  data() {
    return {
      headers: [
        { text: 'List', sortable: false, value: 'name' },
        { text: 'Description', sortable: false, value: 'description' },
      ],
      loading: true,
    };
  },
};
</script>
<style lang="sass" scoped>
::v-deep .v-datatable tbody tr
  border-bottom: none
.container
  padding: 0 24px 48px 24px !important
.header
  margin: 48px 0 24px 0
.breadcrumb-text
  color: #757575
  font-weight: 700
  font-size: 14px
  line-height: 170%
  letter-spacing: -0.005em
  cursor: pointer
.card-header
  padding: 32px 24px
  border-bottom: 1px solid #ebebeb
.card-body
  padding: 0 24px
.list-row
  cursor: pointer
</style>
