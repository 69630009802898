import clientFactory from '@rscollabs/rs-core-library/src/services/client';

const ISO8601_DATE_LENGTH = 10; // YYYY-MM-DD

const client = clientFactory('/api/v1/ltk/sales');

class LtkSalesService {
  get() {
    // Currently only allow looking up present and future sales, not historical ones
    const today = new Date().toISOString().slice(0, ISO8601_DATE_LENGTH);

    return client.get('', { params: { live_on_or_after: today } });
  }

  post(sale) {
    return client.post('', sale);
  }

  put(sale) {
    return client.put(sale.id, sale);
  }

  delete(saleId) {
    return client.delete(saleId);
  }
}

export default new LtkSalesService();
