/**
 * Form Validation compatible with vuetify's validator library. This library should export factories
 * for creating validator functions that accept an optional error message as it's last parameter.
 * Factory functions can accept any number of arguments to scope a validator function to a particular
 * use case, for example string length, or comparing independent fields match.
 *
 * @see https://vuetifyjs.com/en/components/inputs/#rules
 */

/**
 * Error Code Enum
 */
export const errorCodeRequired = 'required';
export const errorCodeMinLength = 'min_length';
export const errorCodeExactLength = 'exact_length';
export const errorCodeMaxLength = 'max_length';
export const errorCodeMatchFormat = 'match_format';

/**
 * Required validator ensures that a value is a truthy value. For strings, whitespace will also be trimmed.
 *
 * @param {string} errorCode custom error code to be returned by the validator
 * @returns a validator function
 */
export function makeRequired(errorCode = errorCodeRequired) {
  return function required(v) {
    return (v && `${v}`.trim().length > 0) || errorCode;
  };
}

/**
 * makeMinLength is a factory validator.
 * Creates a validator that ensures that a value is at least the length provided.
 *
 * @param {number} n length of value to compare against
 * @param {string} errorCode custom error code to be returned by the validator
 * @returns a validator function scoped to the provided length
 */
export function makeMinLength(n, errorCode = errorCodeMinLength) {
  return function minLength(v) {
    return (v !== null && v !== undefined && v.length >= n) || errorCode;
  };
}

/**
 * makeExactLength is a factory validator.
 * Creates a validator that ensures that a value is exactly the length provided.
 *
 * @param {number} n length of value to compare against
 * @param {string} errorCode custom error code to be returned by the validator
 * @returns a validator function scoped to the provided length
 */
export function makeExactLength(n, errorCode = errorCodeExactLength) {
  return function exactLength(v) {
    return (v !== null && v !== undefined && v.length === n) || errorCode;
  };
}

/**
 * makeMaxLength is a factory validator.
 * Creates a validator that ensures that a value is no more than the max length provided.
 *
 * @param {number} n length of value to compare against
 * @param {string} errorCode custom error code to be returned by the validator
 * @returns a validator function scoped to the provided length
 */
export function makeMaxLength(n, errorCode = errorCodeMaxLength) {
  return function maxLength(v) {
    return (v !== null && v !== undefined && v.length <= n) || errorCode;
  };
}

/**
 * makeMatchFormat is a factory validator
 * Creates a validator that ensures that a value matches the provided regex pattern.
 *
 * @param {RegExp} format a Regular Expression to be used to validate the string
 * @param {string} errorCode custom error code to be returned by the validator
 * @returns a validator function scoped to the provided regular expression
 */
export function makeMatchFormat(format, errorCode = errorCodeMatchFormat) {
  return function matchFormat(v) {
    return format.exec(v) !== null || errorCode;
  };
}
