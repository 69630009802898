<template>
  <rs-container fluid id="ltk_profile_vector_management">
    <rs-card>
      <rs-card-text>
        <rs-layout row wrap fill-height justify-center>
          <rs-flex sm3 id="profile_card">
            <ltk-profile :profile-id="profileId" />
            <rs-switch v-model="isEnabled" :false-value="false" :true-value="true" label="Enable Recommendations" />
            <rs-switch
              v-model="isTracking"
              :false-value="false"
              :true-value="true"
              label="Enable Interaction Tracking"
            />
            <rs-text-field
              v-model="age"
              type="number"
              :min="minAge"
              :max="maxAge"
              @blur="handleAgeChange(age)"
              label="Desired Audience Age"
            />
            <p class="headline mt-2 mb-0">Compare with LTK Profile</p>
            <ltk-profile-search v-model="searchProfile" :exclude-profiles="[profileId]" />
          </rs-flex>
          <rs-flex sm9>
            <rs-layout justify-end>
              <ltk-feature-vector-chart-faq />
            </rs-layout>
            <rs-layout align-center justify-center fill-height column v-if="hasError">
              <rs-icon size="70" color="error">warning</rs-icon>
              <p v-if="errorText">{{ errorText }}</p>
              <rs-btn @click="loadData" :block="false" :outline="false" :flat="false" color="error">Retry</rs-btn>
            </rs-layout>
            <rs-layout align-center justify-center fill-height v-else-if="isLoading">
              <rs-progress-circular :size="70" :width="7" indeterminate />
            </rs-layout>
            <rs-layout class="ml-3" column v-else id="chart_container">
              <ltk-feature-vector-chart
                :chart-data="chartData"
                :styles="styles"
                @label-click="removeFeature"
                @data-label-click="moveInput"
                @chart-updated="updateFeatures"
              />
              <profile-vector-input
                v-if="isInputVisible"
                :value="inputValue"
                :x="inputX"
                :y="inputY"
                @input="handleInputChange"
                @hide="isInputVisible = false"
              />
              <rs-layout justify-end>
                <hierarchy-list
                  :items="Object.values(categories)"
                  :used-items="usedCategories"
                  list-name="Categories"
                  :item-text="'name'"
                  :item-value="'id'"
                  @select-item="addFeature({ type: 'category', id: $event })"
                >
                  <rs-btn fab icon small color="cyan" slot="activator" :block="false" :outline="false" :flat="false">
                    <rs-icon>rsfont-grid-layout</rs-icon>
                  </rs-btn>
                  <span>Add Category</span>
                </hierarchy-list>
                <hierarchy-list
                  :items="Object.values(locations)"
                  :used-items="usedLocations"
                  list-name="Locations"
                  :item-text="'name'"
                  :item-value="'id'"
                  @select-item="addFeature({ type: 'location', id: $event })"
                >
                  <rs-btn
                    fab
                    icon
                    small
                    color="pink darken-1"
                    slot="activator"
                    :block="false"
                    :outline="false"
                    :flat="false"
                  >
                    <rs-icon color="white">rsfont-location</rs-icon>
                  </rs-btn>
                  <span>Add Location</span>
                </hierarchy-list>
                <rs-tooltip top>
                  <rs-btn
                    fab
                    icon
                    small
                    color="green"
                    :block="false"
                    :outline="false"
                    :flat="false"
                    :loading="isSaving"
                    @click="saveProfile()"
                    slot="activator"
                  >
                    <rs-icon color="white">save</rs-icon>
                  </rs-btn>
                  <span>Save</span>
                </rs-tooltip>
              </rs-layout>
            </rs-layout>
          </rs-flex>
        </rs-layout>
      </rs-card-text>
    </rs-card>
    <rs-dialog v-model="showConfirmLeave">
      <rs-card>
        <rs-card-title class="headline text-xs-center" primary-title>
          Are you sure you want to leave this page?
        </rs-card-title>
        <rs-card-text>
          <p>
            Hey there! It looks like you're in the middle of adjusting this profile and you haven't saved all of your
            changes.
          </p>
        </rs-card-text>
        <rs-divider />
        <rs-card-actions>
          <rs-spacer />
          <rs-btn :block="false" @click="confirmLeave()">leave</rs-btn>
          <rs-btn color="primary" inverted :block="false" @click="showConfirmLeave = false">stay</rs-btn>
        </rs-card-actions>
      </rs-card>
    </rs-dialog>
  </rs-container>
</template>

<script>
import LtkProfile from '../components/LtkProfile.vue';
import LtkFeatureVectorChart from '../components/LtkFeatureVectorChart.vue';
import LtkFeatureVectorChartFaq from '../components/LtkFeatureVectorChartFaq.vue';
import LtkProfileSearch from '../components/LtkProfileSearch.vue';
import ProfileVectorInput from '../components/ProfileVectorInput.vue';
import HierarchyList from '../components/HierarchyList.vue';
import recommendationService from '../services/ltk-recommendation-service';
import { calculateFeatureColor } from '../utils/colors';
import { capitalizeWords, recursiveLabel } from '../utils/strings';

const MIN_AGE = 1;
const MAX_AGE = 100;
const featureFinder = (feature) => (item) => item.id === feature.id && item.type === feature.type;

export default {
  components: {
    LtkProfile,
    LtkFeatureVectorChart,
    LtkProfileSearch,
    ProfileVectorInput,
    HierarchyList,
    LtkFeatureVectorChartFaq,
  },
  props: {
    profileId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchProfile: null,
      compareProfile: null,
      isLoading: false,
      isSaving: false,
      hasError: false,
      errorText: null,

      isInputVisible: false,
      inputX: 0,
      inputY: 0,
      inputValue: '0.00',
      inputFeature: 0,

      minAge: MIN_AGE,
      maxAge: MAX_AGE,

      hasChanges: false,
      confirmLeave: () => {},
      showConfirmLeave: false,
    };
  },
  async mounted() {
    await this.loadData();
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  computed: {
    flatData() {
      let data = [];
      if (this.recommendationFeatures !== undefined) {
        data = this.recommendationFeatures.reduce(this.flatDataReducer(0), data);
      }
      if (this.compareProfile !== null) {
        data = this.compareFeatures.reduce(this.flatDataReducer(1), data);
      }

      return data;
    },
    chartData() {
      let data = {
        labels: [],
        datasets: [
          { label: this.profileName, data: [], backgroundColor: [] },
          { label: '', data: [], dragData: false },
        ],
      };
      this.flatData.reduce((data, feature) => {
        data.labels.push(feature.name);

        data.datasets[0].data.push(feature.data[0] || 0);
        data.datasets[0].backgroundColor.push(calculateFeatureColor(feature.data[0] || 0));

        if (this.compareFeatures !== null) {
          data.datasets[1].data.push(feature.data[1] || 0);
        }

        return data;
      }, data);

      if (this.compareProfile === null) {
        data.datasets.pop();
      } else {
        data.datasets[1].label = this.$store.state['ltk-profiles'].profiles[this.searchProfile].display_name || '';
      }

      return data;
    },
    recommendationProfile() {
      return this.$store.state['ltk-recommendations'].recommendationProfile || {};
    },
    recommendationFeatures() {
      return this.recommendationProfile.features;
    },
    compareFeatures() {
      return (this.compareProfile || {}).features || [];
    },

    categories() {
      return this.$store.state['ltk-recommendations'].categories;
    },
    usedCategories() {
      return this.flatData.filter((feature) => feature.type === 'category').map((category) => category.id);
    },

    locations() {
      return this.$store.state['ltk-recommendations'].locations;
    },
    usedLocations() {
      return this.flatData.filter((feature) => feature.type === 'location').map((location) => location.id);
    },

    profileName() {
      return (this.$store.state['ltk-profiles'].profiles[this.profileId] || {}).display_name || '';
    },
    styles() {
      return {
        position: 'relative',
        height: `${this.height}px`,
      };
    },
    height() {
      return this.chartData.labels.length * 40 * 2 + 62;
    },
    isEnabled: {
      get() {
        return !this.recommendationProfile.excluded || false;
      },
      set(value) {
        this.hasChanges = true;
        this.$store.commit('UPDATE_RECOMMENDATION_PROFILE_EXCLUDE', !value);
      },
    },
    isTracking: {
      get() {
        return this.recommendationProfile.tracked || false;
      },
      set(value) {
        this.hasChanges = true;
        this.$store.commit('UPDATE_RECOMMENDATION_PROFILE_TRACKING', value);
      },
    },
    age: {
      get() {
        return this.recommendationProfile.age || 0;
      },
      set(value) {
        this.hasChanges = true;
        this.handleAgeChange(value);
      },
    },
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      this.hasError = false;
      try {
        await Promise.all([
          this.$store.dispatch('getProfiles', { id: this.profileId }),
          this.$store.dispatch('getInfluencerRecommendationProfile', this.profileId),
          this.$store.dispatch('getCategories'),
          this.$store.dispatch('getLocations'),
        ]);
        this.isLoading = false;
      } catch (error) {
        this.$root.$emit('onError', error);
        this.hasError = true;
      }
    },
    async saveProfile() {
      this.isSaving = true;
      try {
        await this.$store.dispatch('saveInfluencerRecommendationProfile');
        this.hasChanges = false;
      } catch (error) {
        this.$root.$emit('onError', error);
      }
      this.isSaving = false;
    },
    // Callback for clicking on the activate
    addFeature({ type, id }) {
      this.hasChanges = true;
      this.$store.commit('ADD_RECOMMENDATION_PROFILE_FEATURE', { type, id });
    },
    // Callback for clicking the remove button
    removeFeature(element) {
      this.hasChanges = true;
      const feature = this.flatData[element.dataIndex];
      this.$store.commit('REMOVE_RECOMMENDATION_PROFILE_FEATURE', feature);
    },
    // Callback for dragging data bars
    updateFeatures({ index, value }) {
      const feature = this.flatData[index];
      const profileFeature = this.recommendationProfile.features.find(featureFinder(feature));
      const hasFeature = profileFeature !== undefined;

      if (!hasFeature || feature.weight !== profileFeature.weight) {
        this.hasChanges = true;
      }

      if (!hasFeature) {
        const { type, id } = feature;
        this.$store.commit('ADD_RECOMMENDATION_PROFILE_FEATURE', { type, id, weight: value.toString() });
        return;
      }

      const updatedFeature = JSON.parse(JSON.stringify(profileFeature));
      updatedFeature.weight = value.toString();
      this.$store.commit('UPDATE_RECOMMENDATION_PROFILE_FEATURE', updatedFeature);
    },
    getFeatureName(feature) {
      let labelName = feature.type;
      switch (feature.type) {
        case 'category':
          labelName = recursiveLabel(this.categories, feature.id);
          break;
        case 'location':
          labelName = recursiveLabel(this.locations, feature.id);
          break;
        default:
          labelName = feature.type;
      }

      return capitalizeWords(labelName);
    },
    flatDataReducer(index) {
      return (map, feature) => {
        let featureParent;
        switch (feature.type) {
          case 'category':
            featureParent = (this.categories[feature.id] || { parent: null }).parent;
            break;
          case 'location':
            featureParent = (this.locations[feature.id] || { parent: null }).parent;
            break;
          default:
            // Either age/gender or an unexpected type.
            featureParent = null;
        }

        // ignore root level tiered data, defined be not having a defined parent
        if (featureParent === undefined) {
          return map;
        }

        const labelName = this.getFeatureName(feature);
        let featureIndex = map.findIndex(featureFinder(feature));

        if (featureIndex === -1) {
          featureIndex = map.length;
          map.push({
            name: labelName,
            type: feature.type,
            id: feature.id,
            data: [],
          });
        }

        map[featureIndex].data[index] = feature.weight;

        return map;
      };
    },
    moveInput({ dataIndex, x, y }) {
      this.inputX = x;
      this.inputY = y;
      this.inputFeature = dataIndex;
      this.inputValue = Number(this.flatData[dataIndex].data[0] || 0).toFixed(2);
      this.isInputVisible = true;
    },
    handleInputChange(value) {
      const index = this.inputFeature;
      this.updateFeatures({ index, value });
    },
    handleAgeChange(value) {
      let age = Number(value);
      if (age < MIN_AGE) {
        age = MIN_AGE;
      } else if (age > MAX_AGE) {
        age = MAX_AGE;
      }

      this.$store.commit('UPDATE_RECOMMENDATION_PROFILE_AGE', age);
    },
    handleBeforeUnload(event) {
      if (this.hasChanges === true) {
        event.preventDefault();
        event.returnValue = '';
      }

      delete event['returnValue'];
    },
  },
  watch: {
    searchProfile(profileId) {
      if (profileId === null) {
        this.$set(this, 'compareProfile', null);
        return;
      }
      this.isLoading = true;
      recommendationService
        .getInfluencer(profileId)
        .then((data) => {
          const features = (data.data.recommendation_profiles || [])[0] || {};
          this.$set(this, 'compareProfile', features);
        })
        .catch((error) => {
          this.$root.$emit('onError', error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasChanges) {
      this.confirmLeave = next;
      this.showConfirmLeave = true;
      return;
    }

    next();
  },
};
</script>

<style lang="sass" scoped>
// remove some padding from the outer container
#ltk_profile_vector_management
  padding-right: 0 !important
  padding-left: 0 !important

#chart_container
  position: relative
</style>
