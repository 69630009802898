<template>
  <div :style="{ width: '100%' }">
    <div class="campaign-list" v-if="$vuetify.breakpoint.mdAndUp">
      <campaign-header
        :header-text="campaignTitle"
        :is-selected="selectedRows.length > 0"
        @delete="toggleDeleteConfirmation"
        @add="onAdd"
        @change:search="onSearchChanged"
      />
      <!-- Content -->
      <div class="campaign-list__body">
        <rs-autocomplete
          class="campaign-list__country-select"
          v-model="selectedCountry"
          :items="countries"
          label="Country"
          item-text="name"
          item-value="code"
        />
        <sortable-data-table
          ref="bannerTableRef"
          :headers="headers"
          :items="bannersTable"
          :search="search"
          :loading="loading"
          :draggable="false"
          @selected="handleSelectedRows"
          @edit="handleEditClick"
        />
      </div>
    </div>
    <hashtags-desktop-notice v-if="$vuetify.breakpoint.smAndDown" />
    <rs-modal v-model="deleteConfirmation" size="sm">
      <rs-modal-content>
        <h3 class="header-3a my-3">Confirm Delete</h3>
        <p class="header-4">Are you sure you want to delete these items? You cannot undo this action.</p>
        <div class="delete-modal__cta">
          <rs-button @click="toggleDeleteConfirmation" secondary>Cancel</rs-button>
          <rs-button @click="onDelete" primary>Delete</rs-button>
        </div>
      </rs-modal-content>
    </rs-modal>
  </div>
</template>

<script>
import { defineComponent, computed, ref, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import RsButton from '@/internal-advertisers/lib/components/RsButton/RsButton.vue';
import RsModal from '@/internal-advertisers/lib/components/RsModal/RsModal.vue';
import RsModalContent from '@/internal-advertisers/lib/components/RsModal/RsModalContent.vue';
import HashtagsDesktopNotice from '@/internal-hashtags/components/HashtagsDesktopNotice.vue';
import SortableDataTable from '@/internal-hashtags/components/SortableDataTable.vue';
import CampaignHeader from '@/internal-hashtags/components/CampaignHeader.vue';
import { removeTime } from '@/internal-hashtags/utils/changeTimeZone';
import { useBannersStore } from '@/stores/banners';
import { storeToRefs } from 'pinia';
import countries from '@/internal-hashtags/fixtures/countries.json';
import { convertFromUtcToLocal } from '@/internal-hashtags/utils/changeTimeZone';

export default defineComponent({
  name: 'Banners',
  components: {
    RsButton,
    SortableDataTable,
    CampaignHeader,
    HashtagsDesktopNotice,
    RsModal,
    RsModalContent,
  },
  props: {
    defaultCountry: {
      type: String,
      default: 'us',
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();

    const loading = ref(true);
    const bannerTableRef = ref(null);
    const campaignTitle = 'Banner Management';
    const deleteConfirmation = ref(false);
    const selectedRows = ref([]);
    const bannersStore = useBannersStore();
    const { bannersByCountry } = storeToRefs(bannersStore);

    const bannersTable = computed(() => {
      return bannersByCountry.value.map((banner) => {
        return {
          id: banner.id,
          group_label: banner.group_label,
          start_date: removeTime(convertFromUtcToLocal(selectedCountry.value, banner.start_date)),
          end_date: removeTime(convertFromUtcToLocal(selectedCountry.value, banner.end_date)),
          status: displayStatus(banner.pre_staged, banner.enabled),
        };
      });
    });

    const headers = [
      { text: 'Group', value: 'group_label', sortable: false },
      { text: 'Start Date', value: 'start_date', sortable: true },
      { text: 'End Date', value: 'end_date', sortable: true },
      { text: 'Status', value: 'status', sortable: true },
    ];

    const search = ref('');
    const selectedCountry = ref(props.defaultCountry);

    const displayStatus = (prestaged, enabled) => {
      if (!enabled) {
        return 'Disabled';
      }
      if (prestaged) {
        return 'QA';
      }
      return 'Live in app';
    };

    const handleEditClick = ($event) => {
      const selectedBanner = bannersStore.getById($event);
      router.push(`/banners/edit/${selectedBanner.value.group_label}?defaultCountry=${selectedCountry.value}`);
    };

    const handleSelectedRows = ($event) => {
      selectedRows.value = $event;
    };

    const onAdd = () => {
      router.push(`/banners/add?defaultCountry=${selectedCountry.value}`);
    };

    const onDelete = async () => {
      const selectedRowIds = selectedRows.value.map((row) => row.id);
      await bannersStore.deleteMany(selectedRowIds);
      bannersStore.getByCountry(selectedCountry.value);
      toggleDeleteConfirmation();
    };

    const onSearchChanged = ($event) => {
      search.value = $event;
    };

    const toggleDeleteConfirmation = () => {
      deleteConfirmation.value = !deleteConfirmation.value;
    };

    onMounted(async () => {
      if (route.query && route.query.defaultCountry === '') {
        selectedCountry.value = 'us';
      }
      await bannersStore.getByCountry(selectedCountry.value);
      loading.value = false;
    });

    watch(
      () => selectedCountry.value,
      (next) => {
        if (next) {
          bannersStore.getByCountry(next);
        }
      },
    );

    return {
      bannersTable,
      bannerTableRef,
      campaignTitle,
      countries,
      deleteConfirmation,
      headers,
      loading,
      search,
      selectedCountry,
      selectedRows,
      handleEditClick,
      handleSelectedRows,
      onAdd,
      onDelete,
      onSearchChanged,
      toggleDeleteConfirmation,
    };
  },
});
</script>

<style lang="scss">
@use '../../internal-advertisers/scss/typography.scss';
.campaign-list {
  width: 100%;
  margin: 0 auto;
  min-height: calc(100vh - 48px);
  &__search {
    max-width: 376px;
    width: 100%;
    margin-right: 16px;
  }
  &__body {
    max-width: 1260px;
    margin: 0 auto 108px;
    padding: 0 16px;
  }
}

.campaign-footer {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  &__content {
    max-width: 1260px;
    margin: 0 auto;
    padding: 24px 16px;
    border-top: 1px solid #efefef;
    display: flex;
    justify-content: flex-end;
    opacity: 1;
    transform: translate3D(0, 0, 0);
  }
}
.slide-in-enter-active,
.slide-in-leave-active {
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.slide-in-enter {
  opacity: 1;
  transform: translate3D(0, 100%, 0);
}
.slide-in-leave-to {
  opacity: 0;
  transform: translate3D(0, 100%, 0);
}
.delete-modal {
  &__cta {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
