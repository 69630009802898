<template>
  <tr class="table-row">
    <td>
      <div class="d-flex my-4 align-center">
        <rs-btn
          class="v-btn--small expand-btn mr-3"
          @click="onRowExpanded"
          :block="false"
          borderless
          fab
          :aria-label="isRowExpanded ? 'Collapse row' : 'Expand row'"
        >
          <rs-icon :class="['expand-icon', { 'expand-icon__expanded': isRowExpanded }]" small>
            ltkfont-down-chevron-outline
          </rs-icon>
        </rs-btn>
        <rs-img class="table-data-image" :src="productImageUrl" height="135" width="135" aspect-ratio="1" contain />
      </div>
    </td>
    <td>
      <div class="py-4 fill-height">
        <div class="table-data-name">
          <div class="table-data-name-text">{{ productName }}</div>
          <a class="table-data-link mt-auto" :href="productUrl" target="_blank" @click.stop>
            <span class="table-data-link-text mr-1">Product URL</span>
            <rs-icon color="#0469e0" size="20">ltkfont-link-in-new-window-outline</rs-icon>
          </a>
        </div>
      </div>
    </td>
    <td>
      <div class="my-4">
        <span class="table-data-text">{{ brandName }}</span>
      </div>
    </td>
    <td>
      <div class="my-4">
        <span class="table-data-text">{{ retailerName }}</span>
      </div>
    </td>
    <td>
      <div class="my-4">
        <span class="table-data-text">{{ productDescription }}</span>
      </div>
    </td>
    <td>
      <div class="my-4">
        <span class="table-data-text">{{ productDetails }}</span>
      </div>
    </td>
    <td>
      <div class="my-4">
        <span class="table-data-text">{{ productPrice }}</span>
      </div>
    </td>
    <td>
      <div class="my-4">
        <div>{{ numberOfMatchingCandidates }} potential matches</div>
        <rs-btn
          class="save-button mt-4"
          inverted
          :disabled="isSaveButtonDisabled"
          :loading="isSaveButtonLoading"
          @click.stop="onMarkCompleted"
        >
          <span v-if="isCompletedTab"> Update </span>
          <span v-else> Mark as complete </span>
        </rs-btn>
      </div>
    </td>
  </tr>
</template>

<script>
import { computed, defineComponent, onMounted } from 'vue';
import useStore from '@/composables/vuex';

import { formatCurrencyExt } from '@/utils/helpers';

import { WORK_ITEM_STATUS } from '../constants';
import { usePrimaryProductDetails, useWorkItemProgress } from '../composables/work-items';

export default defineComponent({
  emits: ['onRowExpanded', 'onCompleteWorkItem'],
  props: {
    item: Object,
    index: Number,
    selected: Boolean,
    expanded: Boolean,
  },
  setup(props, { emit }) {
    const store = useStore();
    const workItemId = computed(() => props.item.id);
    const primaryProduct = usePrimaryProductDetails(workItemId);
    const { progressCount, totalCount } = useWorkItemProgress(workItemId);

    const isCompletedTab = computed(() => store.getters.currentSelectedTab === WORK_ITEM_STATUS.COMPLETE);
    const isRowExpanded = computed(() => props.expanded);
    const isSaveButtonLoading = computed(() => store.getters.isSavingWorkItem(workItemId.value));
    const isSaveButtonDisabled = computed(() => {
      if (isCompletedTab.value) {
        return !store.getters.isWorkItemPendingSave(workItemId.value);
      }
      return progressCount.value < totalCount.value;
    });

    const numberOfMatchingCandidates = computed(() => {
      return `${totalCount.value}`;
    });
    const productImageUrl = computed(() => {
      const imageUrls = primaryProduct.value.imageUrls;
      if (imageUrls.length > 0) {
        return imageUrls[0];
      }
      return '';
    });
    const productName = computed(() => primaryProduct.value.title);
    const productUrl = computed(() => primaryProduct.value.productUrl);
    const brandName = computed(() => primaryProduct.value.brand);
    const retailerName = computed(() => primaryProduct.value.retailer);
    const productDescription = computed(() => primaryProduct.value.description);
    const productDetails = computed(() => primaryProduct.value.details);
    const productPrice = computed(() => formatCurrencyExt(primaryProduct.value.price));

    const onRowExpanded = () => {
      emit('onRowExpanded');
    };

    const onMarkCompleted = () => {
      emit('onCompleteWorkItem', workItemId.value);
    };

    return {
      isCompletedTab,
      isRowExpanded,
      isSaveButtonLoading,
      isSaveButtonDisabled,
      numberOfMatchingCandidates,
      productImageUrl,
      productName,
      productUrl,
      brandName,
      retailerName,
      productDescription,
      productDetails,
      productPrice,
      onRowExpanded,
      onMarkCompleted,
    };
  },
});
</script>

<style lang="scss" scoped>
.table-row {
  background: #ffffff;
  border: 1px solid #ebebeb;
  vertical-align: top;

  &:hover,
  &:focus {
    background: #ffffff !important;
  }
}

.table-data {
  &-name {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &-text,
  &-name-text {
    -moz-line-clamp: 6;
    -webkit-line-clamp: 6;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    display: -moz-box;
    display: -webkit-box;
    overflow: hidden;
    text-wrap: wrap;
  }

  &-name-text {
    -moz-line-clamp: 5;
    -webkit-line-clamp: 5;
    font-weight: 700;
  }

  &-link {
    display: flex;
    align-items: center;
    text-decoration: none;

    &-text {
      color: #0469e0;
      text-decoration: underline #0469e0;
    }
  }

  &-image {
    height: 135px;
    width: 135px;
    border-radius: 6px;
    border: 1px solid #ccc;
  }
}

.expand-btn {
  margin: 0;
  padding: 16px;
  height: 24px;
  width: 24px;
}

.expand-icon {
  height: 24px;
  width: 24px;
  transition: transform 300ms ease-in-out;

  &__expanded {
    transform: rotate(180deg);
  }
}

.save-button {
  min-width: 260px;
}
</style>
