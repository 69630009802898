<template>
  <!-- eslint-disable-next-line vue/no-mutating-props -->
  <rs-dialog v-model="showDialog" persistent max-width="550px" :fullscreen="$vuetify.breakpoint.xsOnly">
    <rs-card>
      <rs-card-title class="accounts__modal-header justify-center">
        <h3 class="headline font-weight-medium ma-0">{{ title }}</h3>
      </rs-card-title>

      <rs-divider />

      <rs-form ref="form" class="dialog__form py-5 px-6" @submit.prevent="onSubmit" data-test-id="dialog-user-form">
        <Loader v-if="loading" data-test-id="dialog-user-loader" />
        <rs-card-text class="pa-0">
          <!-- eslint-disable vue/no-mutating-props -->
          <rs-text-field
            v-model="user.first_name"
            label="First Name*"
            :rules="[(v) => !!v || 'First Name is required']"
            data-test-id="dialog-user-first-name-text-field"
            required
          ></rs-text-field>
          <rs-text-field
            v-model="user.last_name"
            label="Last Name*"
            :rules="[(v) => !!v || 'Last Name is required']"
            required
            data-test-id="dialog-last-name-text-field"
          ></rs-text-field>
          <rs-select
            label="Language"
            v-model="user.preferred_locale"
            :items="availableLanguages"
            :rules="[(v) => availableLanguages.some((l) => l.value === v) || 'Language is required']"
            append-icon="ltkfont-down-chevron-outline"
          />
          <rs-text-field
            v-model="user.email"
            label="Email*"
            :rules="[(v) => !!v || 'E-mail is required', (v) => /.+@.+/.test(v) || 'E-mail must be valid']"
            required
            data-test-id="dialog-user-email-text-field"
            :error-messages="emailDuplicateMessage"
          ></rs-text-field>
          <rs-text-field
            v-model="user.phone"
            label="Phone*"
            :rules="[(v) => !!v || 'Phone is required']"
            required
            data-test-id="dialog-user-phone-text-field"
          ></rs-text-field>
          <rs-text-field
            v-model="user.title"
            label="Title*"
            :rules="[(v) => !!v || 'Title is required']"
            required
            data-test-id="dialog-user-title-text-field"
          ></rs-text-field>
          <rs-text-field
            v-model="user.hubspot_owner_id"
            label="Hubspot Owner ID"
            data-test-id="dialog-user-hubspot-owner-id-text-field"
          ></rs-text-field>
          <!-- eslint-enable vue/no-mutating-props -->
        </rs-card-text>
        <rs-card-actions class="pa-0 my-3">
          <rs-btn inverted type="submit" data-test-id="dialog-user-save-button">Save</rs-btn>
          <rs-btn @click="onClose" data-test-id="dialog-user-cancel-button">Cancel</rs-btn>
        </rs-card-actions>
      </rs-form>
    </rs-card>
  </rs-dialog>
</template>
<script>
// component imports
import Loader from './Loader';

// mixin imports
import loaderMixin from '@rscollabs/rs-core-library/src/mixins/rs-loader';

export default {
  name: 'DialogUsers',
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    accountId: {
      type: String,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Loader,
  },
  mixins: [loaderMixin],
  data() {
    return {
      emailDuplicateMessage: [],
      languageOptions: [
        { text: 'English (US)', value: 'en-US' },
        { text: 'English (UK)', value: 'en-GB' },
        { text: 'Deutsch', value: 'de-DE' },
        { text: 'Español (Spain)', value: 'es-ES' },
        { text: 'Français (France)', value: 'fr-FR' },
        { text: 'Italiano', value: 'it-IT' },
        { text: 'Português (Brasil)', value: 'pt-BR' },
        { text: '한국어 (Korea)', value: 'ko-KR' },
        { text: '简体中文 (Simplified Chinese)', value: 'zh-Hans' },
        { text: '繁體中文 (Traditional Chinese)', value: 'zh-Hant' },
      ],
    };
  },
  methods: {
    async onSubmit() {
      if (!this.$refs.form.validate()) return;

      this.resetFormErrors();

      const payload = {
        ...this.user,
        hubspot_owner_id: this.user.hubspot_owner_id || null,
      };

      const submitFunc = this.user.id
        ? this.$identity.editUser.bind(this, this.accountId, this.user.id, payload)
        : this.$identity.addUser.bind(this, this.accountId, payload);

      const userResponse = await this.loader(submitFunc, [], (err) => {
        let e = err.response ? err.response.data : err;
        if (e.status === '400') {
          if (e.error_code === 'duplicate') this.emailDuplicateMessage = [e.title];
        } else {
          this.$root.$emit('onError', e);
        }
      });

      if (userResponse && userResponse.status === 200) {
        this.onClose();
      }
    },
    onClose() {
      this.$emit('close');
      this.resetFormErrors();
    },
    resetFormErrors() {
      this.emailDuplicateMessage = [];
      this.$refs.form.resetValidation();
    },
  },
  computed: {
    title() {
      return this.user.id ? 'Edit User' : 'Add User';
    },
    languageFeatureEnabled() {
      return {
        'en-US': true,
        'en-GB': true,
        'de-DE': true,
        'es-ES': true,
        'fr-FR': true,
        'it-IT': true,
        'pt-BR': this.apptimize.isFeatureFlagEnabled('locale_pt-BR'),
        'ko-KR': this.apptimize.isFeatureFlagEnabled('locale_ko-KR'),
        'zh-Hans': this.apptimize.isFeatureFlagEnabled('locale_zh-Hans'),
        'zh-Hant': this.apptimize.isFeatureFlagEnabled('locale_zh-Hant'),
      };
    },
    availableLanguages() {
      return this.languageOptions.filter((item) => {
        return this.languageFeatureEnabled[item.value];
      });
    },
  },
};
</script>

<style lang="sass"></style>
