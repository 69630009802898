<template>
  <rs-navigation-drawer app clipped :value="value" @input="$listeners.input" :disable-resize-watcher="true">
    <rs-list dense nav class="py-0">
      <rs-list-tile two-line class="py-4">
        <rs-list-tile-avatar>
          <img class="avatar" :src="user.avatarUrl" v-if="user.avatarUrl" />
          <rs-icon x-large v-else>rsfont-placeholder-avatar</rs-icon>
        </rs-list-tile-avatar>
        <rs-list-tile-content>
          <rs-list-tile-title>{{ user.name }}</rs-list-tile-title>
          <rs-list-tile-sub-title>Role</rs-list-tile-sub-title>
        </rs-list-tile-content>
      </rs-list-tile>

      <rs-divider />
    </rs-list>

    <rs-list class="py-0">
      <template v-for="group in groupTree">
        <rs-list-group :key="group.name" v-model="group.active" no-action :group="group.namespace" v-if="!group.hidden">
          <rs-list-tile slot="activator">
            <rs-list-tile-content>
              <rs-list-tile-title>{{ group.name }}</rs-list-tile-title>
            </rs-list-tile-content>
          </rs-list-tile>

          <rs-list-tile v-for="link in group.links" :key="link.name" :to="link.to" :href="link.href">
            <rs-list-tile-content>{{ link.name }}</rs-list-tile-content>
            <rs-list-tile-action v-if="link.href">
              <rs-icon small>rsfont-launch</rs-icon>
            </rs-list-tile-action>
          </rs-list-tile>
        </rs-list-group>
      </template>
    </rs-list>

    <div class="pa-2">
      <rs-btn inverted :to="{ name: 'Logout' }"> <rs-icon small class="px-2">rsfont-logout</rs-icon> Logout </rs-btn>
    </div>
    <div class="caption text-xs-center">build: {{ version }}</div>
  </rs-navigation-drawer>
</template>

<script>
import AuthService from '@rscollabs/rs-core-library/src/services/auth';

const { VUE_APP_VERSION } = process.env;
export const groupTree = [
  {
    name: 'Quick Links',
    namespace: '/',
    links: [
      {
        href: window.rewardStyle.LEGACY_HOME_PAGE,
        name: 'rewardStyle.com',
      },
      {
        href: window.rewardStyle.LTK_SPA_URL + '/featured',
        name: 'LIKEtoKNOW.it',
      },
      {
        href: window.rewardStyle.SIGIL_URL + '/admin/',
        name: 'Sigil Admin',
      },
    ],
  },
  {
    name: 'Collaborations',
    namespace: 'collabs',
    links: [
      {
        to: '/collabs/accounts',
        name: 'Accounts',
      },
      {
        to: '/collabs/posts',
        name: 'Posts',
      },
      {
        to: '/collabs/campaigns',
        name: 'Campaigns',
      },
      {
        to: '/collabs/casting',
        name: 'Casting Lists',
      },
    ],
  },
  {
    name: 'LTK',
    namespace: 'ltk',
    links: [
      {
        to: '/ltk/sales',
        name: 'LTK Sale Management',
      },
      {
        to: '/ltk/recommendations',
        name: 'LTK Recommendations',
      },
    ],
  },
  {
    name: 'COS',
    namespace: 'cos',
    links: [
      {
        to: '/cos/applications',
        name: 'Influencers Applications',
      },
      {
        to: '/cos/pod-management',
        name: 'Influencers Accounts',
      },
    ],
  },
  {
    name: 'REV',
    namespace: 'rev',
    links: [
      {
        to: '/links/advertisers',
        name: 'Advertisers',
      },
    ],
  },
  {
    name: 'Catalog',
    namespace: 'catalog',
    links: [
      {
        to: '/catalog/quality-control',
        name: 'Quality Control',
      },
    ],
  },
  {
    name: 'Campaign',
    namespace: 'campaign',
    links: [
      {
        to: '/banners',
        name: 'Banners',
      },
      {
        to: '/hashtags',
        name: 'Hashtags',
      },
      {
        to: '/bubbles',
        name: 'Bubbles',
      },
    ],
  },
  {
    name: 'Consumer Feeds',
    namespace: 'curation',
    links: [
      {
        to: '/activity/curation',
        name: 'Interest Training',
      },
    ],
  },
];
export default {
  name: 'RsSideNav',
  props: {
    value: Boolean,
    default: () => {},
  },
  data() {
    const identity = AuthService.getTokenIdObj();

    return {
      version: VUE_APP_VERSION || 'development',
      groupTree,
      user: {
        avatarUrl: identity.picture,
        name: identity.name,
      },
    };
  },
};
</script>
