<template>
  <rs-layout :justify-end="$vuetify.breakpoint.smAndUp">
    <rs-dialog v-model="dialog" persistent max-width="750px">
      <template #activator="{ on }">
        <rs-icon v-show="$vuetify.breakpoint.smAndDown" v-on="on">rsfont-import-influencers</rs-icon>
        <rs-btn
          v-show="$vuetify.breakpoint.mdAndUp"
          small
          flat
          :block="false"
          v-on="on"
          data-test-id="qa-import-influencers-btn"
        >
          <rs-icon>rsfont-import-influencers</rs-icon>
          <div class="mx-2">Import Influencers</div>
        </rs-btn>
      </template>
      <rs-card>
        <rs-layout row wrap>
          <rs-flex xs12 mx-4>
            <rs-layout row justify-space-between>
              <rs-flex font-weight-medium mt-4 pt-2 mb-3 body-2 data-test-id="qa-import-modal-header"
                >Import Influencers</rs-flex
              >
              <rs-icon size="23" data-test-id="qa-import-modal-close-btn" @click="handleCloseBtnClick()"
                >rsfont-close_v2</rs-icon
              >
            </rs-layout>
          </rs-flex>
          <rs-flex
            v-show="!successFullImportsCount && !failedImportsCount && !bulkImportFail"
            font-weight-medium
            mx-4
            mb-4
            body-1
            data-test-id="qa-influencer-count"
            >{{ accountIds.length }} {{ accountIds.length === 1 ? 'Influencer' : 'Influencers' }}</rs-flex
          >
          <rs-flex
            v-show="successFullImportsCount && successFullImportsCount !== 'isFetching'"
            xs10
            font-weight-medium
            mx-4
            mb-4
            body-1
          >
            <rs-icon color="#1B8B49" size="20" class="img-icon" data-test-id="qa-success-checkmark-icon"
              >rsfont-checkmark</rs-icon
            >

            {{ successFullImportsCount }}
            {{ successFullImportsCount === 1 ? 'Influencer has' : 'Influencers have' }}
            successfully been imported
          </rs-flex>

          <rs-flex
            v-show="(failedImportsCount && failedImportsCount !== 'isFetching') || bulkImportFail"
            xs10
            font-weight-medium
            mx-4
            mb-4
            body-1
          >
            <rs-icon color="#B50404" size="19" class="img-icon">rsfont-close_v2</rs-icon>
            {{ failedImportsCount }}
            {{ failedImportsCount === 1 ? 'Influencer has' : 'Influencers have' }}
            failed to import
          </rs-flex>
          <rs-flex
            xs12
            :class="{
              'mb-4': $vuetify.breakpoint.smAndUp,
              'mb-2': $vuetify.breakpoint.xs,
            }"
          >
            <rs-divider></rs-divider>
          </rs-flex>
          <rs-flex xs12 mx-4 mb-0>
            <rs-textarea
              ref="text-area"
              v-model="textAreaData"
              data-test-id="qa-id-input-field"
              clearable
              clear-icon="rsfont-delete_v2"
              placeholder="Enter one Influencer ID per line"
              hint="Enter one Influencer ID per line"
              outline
              persistent-hint
              :rules="inputRules"
              @input="onTextAreaChange()"
            ></rs-textarea>
          </rs-flex>
          <rs-flex xs12 align-self-end ma-4>
            <rs-layout row wrap justify-end>
              <rs-flex
                :class="{
                  xs3: $vuetify.breakpoint.smAndUp,
                  xs12: $vuetify.breakpoint.xs,
                }"
              >
                <rs-btn data-test-id="qa-cancel-import-btn" font-medium @click="handleCloseBtnClick()">Cancel</rs-btn>
              </rs-flex>
              <rs-flex
                :class="{
                  xs3: $vuetify.breakpoint.smAndUp,
                  'ml-3': $vuetify.breakpoint.smAndUp,
                  xs12: $vuetify.breakpoint.xs,
                }"
              >
                <rs-btn
                  data-test-id="bulk-import-button"
                  inverted
                  font-medium
                  :loading="successFullImportsCount === 'isFetching'"
                  @click="handleImportClick()"
                >
                  import
                </rs-btn>
              </rs-flex>
            </rs-layout>
          </rs-flex>
        </rs-layout>
      </rs-card>
    </rs-dialog>
  </rs-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import loaderMixin from '@rscollabs/rs-core-library/src/mixins/rs-loader';

export default {
  name: 'ImportInfluencerModal',
  mixins: [loaderMixin],
  props: {
    campaignId: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
    brandId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      textAreaData: '',
      bulkImportFail: false,
      inputRules: [
        (v) =>
          (v && /^\d+$/.test(this.accountIds.join(''))) ||
          'Influencer IDs must contain only numbers and newline characters',
      ],
    };
  },
  computed: {
    ...mapGetters('collabs', ['getFailedCollaborationAccountIds', 'getSucceededCollaborationAccountIds']),
    ...mapGetters('accounts', ['brandReportinginfluencers']),

    accountIds() {
      const ids = this.textAreaData ? this.textAreaData.split('\n').filter((id) => id !== '') : [];
      return ids;
    },

    existingAccountIds() {
      const influencers = this.brandReportinginfluencers(this.brandId) || {};
      if (influencers.brand_influencers && influencers.brand_influencers.length) {
        return influencers.brand_influencers;
      }

      return [];
    },

    brandInfluencers() {
      const influencerData = this.brandReportinginfluencers(this.brandId) || {};
      return influencerData;
    },

    successFullImportsCount() {
      const { succeededCollaborationAccountIds = {}, meta } =
        this.getSucceededCollaborationAccountIds(this.campaignId) || {};
      if (
        succeededCollaborationAccountIds.accounts &&
        succeededCollaborationAccountIds.accounts.length &&
        meta &&
        meta.hasFetched
      ) {
        return succeededCollaborationAccountIds.accounts.length;
      } else if (meta && meta.isFetching) {
        return 'isFetching';
      }
      return null;
    },

    failedImportsCount() {
      const { failedCollaborationAccountIds = {}, meta } = this.getFailedCollaborationAccountIds(this.campaignId) || {};
      if (
        failedCollaborationAccountIds.accounts &&
        failedCollaborationAccountIds.accounts.length &&
        meta &&
        meta.hasFetched
      ) {
        return failedCollaborationAccountIds.accounts.length;
      } else if (meta && meta.isFetching) {
        return 'isFetching';
      } else if (this.bulkImportFail) {
        return this.accountIds.length;
      }
      return null;
    },

    hasError() {
      return this.$refs['text-area'].hasError;
    },
  },
  watch: {
    dialog(newVal) {
      if (newVal === false) {
        this.resetInput();
      } else {
        this.getBrandReportingInfluencers(this.brandId);
      }
    },
    async textAreaData(newVal, oldVal) {
      if (newVal && newVal.length) {
        await this.$nextTick();
        this.textAreaData = newVal.replace(/[^\r\n0-9]/g, '').replace(/[\r\n]+/g, '\n');
      }
    },

    existingAccountIds(newVal, oldVal) {
      if (Array.isArray(newVal) && newVal.length) {
        this.textAreaData = this.formatTextAreaData(newVal);
      }
    },
  },
  methods: {
    ...mapActions('collabs', ['CreateBulkCollaborations', 'deleteExisitngCollaborationsInState']),
    ...mapActions('accounts', ['getBrandReportingInfluencers', 'editBrandReportingInfluencers']),
    handleCloseBtnClick() {
      this.bulkImportFail = false;
      this.dialog = false;
    },
    handleImportClick() {
      this.bulkImportFail = false;
      this.editBrandReportingInfluencers({
        accountId: this.brandId,
        brand_influencers: this.accountIds,
      })
        .catch((e) => {
          this.bulkImportFail = true;
        })
        .then(() => {
          this.handleCloseBtnClick();
        });

      this.CreateBulkCollaborations({
        campaignId: this.campaignId,
        accountIds,
      }).catch((e) => {
        this.bulkImportFail = true;
      });
    },
    formatTextAreaData(dataArray) {
      return dataArray.join('\n');
    },
    onTextAreaChange() {
      this.bulkImportFail = false;
    },
    resetInput() {
      this.textAreaData = '';
      this.$refs['text-area'].resetValidation();
    },
  },
};
</script>

<style lang="sass" scoped>
.v-card__title
  padding: 24px 24px 24px 0px
  font-size: 18px

::v-deep .v-input__slot
  border: 1px solid #C0C0C0 !important

::v-deep .rsfont-delete_v2
  font-size: 23px !important

::v-deep textarea
  line-height: 150% !important


.img-icon
   margin-right: 10px
</style>
