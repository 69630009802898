<template>
  <rs-container fluid grid-list-lg class="influencer-list-page">
    <!-- FILTERS-->
    <rs-layout row wrap align-center justify-center>
      <rs-flex xs2>
        <territory-preset :preset="territoryName" @select-territory="onSelectTerritory" />
      </rs-flex>
      <rs-flex xs2>
        <rs-autocomplete
          @blur="onCountryUpdated"
          :items="countries"
          chips
          multiple
          v-model="selectedCountryIds"
          item-text="name"
          item-value="id"
          no-data-text="No countries found"
          label="Countries"
          hide-details
          small-chips
        >
          <template #selection="{ item, index }">
            <rs-chip small v-if="index === 0 && territoryName">{{ territoryName }} Territory</rs-chip>
            <rs-chip small v-else-if="index < 3 && !territoryName">{{ item.name }}</rs-chip>
            <span v-else-if="index === 3 && !territoryName">+{{ selectedCountryIds.length - 3 }} more</span>
          </template>
        </rs-autocomplete>
      </rs-flex>
      <rs-flex xs2>
        <rs-autocomplete
          @blur="getApplicationsFromApi"
          :items="statuses"
          chips
          multiple
          v-model="selectedStatuses"
          item-text="name"
          item-value="status"
          no-data-text="No status found"
          label="Statuses"
          hide-details
          small-chips
        >
        </rs-autocomplete>
      </rs-flex>
      <rs-flex xs2>
        <rs-autocomplete
          @blur="onOperatorChange"
          :items="assignees"
          chips
          v-model="selectedAssignees"
          label="Assignees"
          hide-details
          clearable
          small-chips
        >
        </rs-autocomplete>
      </rs-flex>
      <rs-flex xs4>
        <rs-text-field
          label="Search..."
          hide-details
          v-model="searchQuery"
          @blur="getApplicationsFromApi"
          @keypress.enter="$event.target.blur()"
        ></rs-text-field>
      </rs-flex>
    </rs-layout>

    <rs-layout row wrap align-center justify-center>
      <rs-flex xs3>
        <rs-btn :loading="loading" :disabled="!selectedRows.length || loading" @click="createBulkResolutions('pending')"
          >mark applications as pending</rs-btn
        >
      </rs-flex>

      <rs-flex xs9></rs-flex>
    </rs-layout>

    <!--TABLE-->
    <rs-layout row wrap align-center justify-center>
      <rs-flex xs12>
        <rs-data-table
          v-model="selectedRows"
          :headers="headers"
          :items="applications"
          :pagination.sync="pagination"
          :loading="loading"
          :total-items="meta.total"
          :rows-per-page-items="recordsPerPage"
          :must-sort="true"
          :select-all="true"
          hide-details
        >
          <template slot="no-data">
            <div v-if="loading" class="text-xs-center">Loading...</div>
            <div v-else class="text-xs-center">No applications found.</div>
          </template>
          <template #items="props">
            <!-- eslint-disable-next-line vue/valid-v-bind-sync -->
            <application-table-row :props.sync="props" :id="props.item.id" @click="props.expanded = !props.expanded" />
          </template>
          <template #expand="{ item }">
            <detail-view :id="item.id" @statusUpdated="getApplicationsFromApi(false, false, true)" />
          </template>
        </rs-data-table>
      </rs-flex>
    </rs-layout>

    <!-- Copy + paste ErrorMessage component because it doesn't work with the error boundary implementation -->
    <rs-snackbar v-model="hasError" color="red" top>
      <rs-container class="my-0 pt-0 pb-0">
        <rs-layout justify-space-between align-center :column="$vuetify.breakpoint.smAndDown">
          <span class="white--text text-xs-center">
            <rs-icon :small="$vuetify.breakpoint.smAndDown" color="white" class="mr-2">error_outline</rs-icon>
            An error has occured. Please try again. If the issue persists please submit a
            <a href="https://rsbrands.zendesk.com/hc/en-us" target="_blank" class="white--text">support ticket.</a>
          </span>

          <rs-btn @click="hasError = false" color="white" borderless class="headline ml-4">
            <rs-icon small color="white">clear</rs-icon>
            <span class="title white--text">dismiss notification</span>
          </rs-btn>
        </rs-layout>
      </rs-container>
    </rs-snackbar>
  </rs-container>
</template>

<script>
import applicationStore from '../store/index';

import TerritoryPreset, { territoryMap } from '../components/TerritoryPreset';
import DetailView from './DetailView';
import ApplicationTableRow from '../components/ApplicationTableRow';

import { mapState } from 'vuex';

export default {
  components: { TerritoryPreset, DetailView, ApplicationTableRow },
  errorCaptured(err, vm, info) {
    console.error(`
=== Error Details ===
Name: ${err.name}
Message: ${err.message}
Stack: ${err.stack}
Component: ${vm?.$options?.name || 'Anonymous'}
Hook: ${info}
==================`);

    this.hasError = true;
    this.loading = false;

    return false;
  },
  data: () => {
    const countries = localStorage.getItem('USER_COUNTRIES');
    let selectedCountryIds = [];
    if (countries) {
      selectedCountryIds = JSON.parse(countries);
    }

    const assignees = localStorage.getItem('OPERATORS');
    let selectedAssignees = [];
    if (assignees) {
      selectedAssignees = JSON.parse(assignees);
    }

    return {
      //prefill options
      assignees: [
        { text: 'All', value: '' },
        { text: 'Mine', value: 'me' },
      ],

      //filters
      searchQuery: '',

      //v-model
      selectedRows: [],
      selectedCountryIds,
      selectedStatuses: ['new', 'pending'],
      selectedAssignees,

      //data table
      headers: [
        {
          text: 'Date Received',
          value: 'created_at',
        },
        { text: 'Name', value: 'applicant_name', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Primary Channel', value: 'platform_id', sortable: false },
        { text: 'Primary Channel URL', value: 'url', sortable: false },
        { text: 'Largest Following', value: 'largest_follower_count' },
        { text: 'Country', value: 'country_id' },
        { text: 'Review Status', value: 'status' },
        { text: 'Modified At', value: 'updated_at' },
      ],
      loading: true,
      hasError: false,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        sortBy: 'created_at',
        totalItems: 0,
      },
      recordsPerPage: [25],
    };
  },

  created() {
    this.$store.registerModule('influencer-application', applicationStore);
  },

  beforeDestroy() {
    this.$store.unregisterModule('influencer-application', applicationStore);
  },

  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let isNext = false;
        let isPrevious = false;

        if (newValue.page > oldValue.page) {
          isNext = true;
        } else if (newValue.page < oldValue.page) {
          isPrevious = true;
        }

        const hasChangedSortColumn = newValue.sortBy !== oldValue.sortBy;
        const hasChangedSortOrder = newValue.descending !== oldValue.descending;
        if (hasChangedSortColumn || hasChangedSortOrder) {
          isNext = false;
          isPrevious = false;
        }

        await this.getApplicationsFromApi(isNext, isPrevious, false);
      },
      deep: true,
    },
  },

  async mounted() {
    this.loading = true;

    await Promise.all([
      this.$store.dispatch('loadCountries'),
      this.$store.dispatch('loadQuestions'),
      this.$store.dispatch('loadPlatforms'),
      this.$store.dispatch('loadResolutions'),
    ]);

    this.loading = false;
  },

  methods: {
    async getApplicationsFromApi(isNext, isPrevious, isCurrent) {
      this.loading = true;
      await this.$store.dispatch('fetchApplicationPage', {
        countryIds: this.selectedCountryIds,
        statuses: this.selectedStatuses,
        query: this.searchQuery,
        assignees: this.selectedAssignees,
        isNext,
        isPrevious,
        isCurrent,
        sortBy: this.pagination.sortBy,
        descending: this.pagination.descending,
      });

      // Prevent leaky selections from previous pages
      this.selectedRows.splice(0, this.selectedRows.length);
      this.loading = false;
    },

    onSelectTerritory(territory) {
      const countryIds = territoryMap[territory];
      this.selectedCountryIds.splice(0, this.selectedCountryIds.length, ...countryIds);
      this.onCountryUpdated();
    },

    onCountryUpdated() {
      if (this.selectedCountryIds && this.selectedCountryIds.length) {
        localStorage.setItem('USER_COUNTRIES', JSON.stringify(this.selectedCountryIds));
      } else {
        localStorage.removeItem('USER_COUNTRIES');
      }

      this.getApplicationsFromApi();
    },

    onOperatorChange() {
      if (this.selectedAssignees && this.selectedAssignees.length) {
        localStorage.setItem('OPERATORS', JSON.stringify(this.selectedAssignees));
      } else {
        localStorage.removeItem('OPERATORS');
      }

      this.getApplicationsFromApi();
    },

    async createBulkResolutions(resolutionId) {
      const params = this.selectedRows.map(({ id }) => ({
        application_id: id,
        resolution_id: resolutionId,
      }));
      this.loading = true;
      await this.$store.dispatch('createBulkResolutions', params);
      this.getApplicationsFromApi();
      this.loading = false;
    },
  },
  computed: {
    statuses() {
      return ['new', 'pending', 'approved', 'declined'];
    },

    countries() {
      const selectedList = [];
      const rest = this.$store.state['influencer-application'].countries.filter((el) => {
        if (this.selectedCountryIds.includes(el.id)) {
          selectedList.push(el);
        } else {
          return el;
        }
      });
      return selectedList.concat(rest);
    },
    ...mapState({
      applicationsMap: (state) => state['influencer-application'].applicationsMap,
      applicationIds: (state) => state['influencer-application'].applicationIds,
      meta: (state) => state['influencer-application'].applicationMeta,
    }),
    // needs to be in object format for proper data table iteration and keying
    applications() {
      return this.applicationIds.map((id) => ({ id }));
    },

    territoryName() {
      return Object.keys(territoryMap).find((territory) => {
        if (territoryMap[territory].length !== this.selectedCountryIds.length) {
          return false;
        }

        for (let x = this.selectedCountryIds.length - 1; x >= 0; x--) {
          if (territoryMap[territory].includes(this.selectedCountryIds[x]) === false) {
            return false;
          }
        }

        return true;
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.influencer-list-page.container.fluid
  max-width: 100% !important

.v-datatable__actions__select
  display: none
</style>
