import { getClient } from '@/utils/client';
import { FOLLOWER_COUNT_PLATFORM_DATA_TYPES } from '@/internal-influencers-apps/utils/followerCount';

/**
 * URL paths for different API endpoints
 */
const BASE_PATH_INFLUENCER_APPLICATION_V1 = '/api/v1/influencer_application';
const BASE_PATH_RS_V1 = '/api/rs/v1/';

const clients = {
  influencerApplication: {
    v1: getClient({
      host: window.rewardStyle.TYK_GATEWAY_HOST,
      basePath: BASE_PATH_INFLUENCER_APPLICATION_V1,
    }),
  },
  rs: {
    v1: getClient({
      host: window.rewardStyle.API_GATEWAY,
      basePath: BASE_PATH_RS_V1,
    }),
  },
};

class InfluencerApplicationsService {
  async getCountries() {
    const response = await clients.influencerApplication.v1.get(`/countries/`);
    return response.data.countries;
  }

  async getQuestions() {
    const response = await clients.rs.v1.get('/applications/questions');
    return response.data.questions;
  }

  async getPlatforms() {
    const response = await clients.influencerApplication.v1.get(`/platforms`);
    return response.data.platforms;
  }

  async getResolutions() {
    const response = await clients.influencerApplication.v1.get('/resolutions');
    return response.data.resolutions;
  }

  async getApplicationList({
    countryIds,
    statuses,
    query,
    assignees,
    next,
    previous,
    sortBy = 'created_at',
    sortOrder = 'desc',
    limit = 25,
  }) {
    const params = {
      country_ids: countryIds,
      statuses,
      sort: sortBy,
      sort_order: sortOrder,
      limit,
      includes: ['applicants', 'application_platforms', 'referrals'],
    };

    if (query) {
      params.query = query;
    }

    if (assignees) {
      params.assignee = assignees;
    }

    if (next) {
      params.next = next;
    } else if (previous) {
      params.previous = previous;
    }

    const response = await clients.influencerApplication.v1.get('/applications', { params });
    return response.data;
  }

  async getApplicationDetail(ids) {
    const params = {
      ids: ids,
      includes: ['applicants', 'application_platforms', 'referrals', 'application_resolutions', 'social_metrics'],
    };
    const response = await clients.influencerApplication.v1.get('/applications/', { params });
    return response.data;
  }

  /**
   * API call for updating the Social Metrics Follower Count for an Application.
   * @param {string} applicationId
   * @param {[Object, number][]} data
   */
  async updateSocialMetrics(applicationId, data) {
    await clients.influencerApplication.v1.post(`social_metrics`, {
      application_id: applicationId,
      social_metrics_data: data.map(([applicationPlatform, followerCount]) => ({
        application_platform_id: applicationPlatform.id,
        platform_data_type_id: FOLLOWER_COUNT_PLATFORM_DATA_TYPES[applicationPlatform.platform_id],
        data_value: followerCount,
        data_source: 'manual-entry',
      })),
    });
  }

  async createResolution(applicationId, resolutionId, isCommercialEntity) {
    await clients.influencerApplication.v1.post(`applications/${applicationId}/resolutions`, {
      resolution_id: resolutionId,
      is_commercial_entity: isCommercialEntity,
    });
  }

  async createBulkResolutions(params) {
    await clients.influencerApplication.v1.post(`application_resolutions`, params);
  }

  async getDeclinedReasons() {
    const response = await clients.influencerApplication.v1.get(`/resolutions?statuses=declined`);
    return response.data.resolutions;
  }
}

export const influencerApplicationsService = new InfluencerApplicationsService();
