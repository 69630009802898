export default {
  methods: {
    async amplitudeInit(accountId, roles = []) {
      if (!this.$amplitude.userIdIsSet()) {
        this.$amplitude.setUserId(accountId);
      }
      if (!this.$amplitude.hasUserProperties('roles')) {
        this.$amplitude.setUserProperties({
          roles: roles.map((role) => role.name),
        });
      }
    },
    amplitudeLogEvent(event, eventProperties) {
      this.$amplitude.logEvent(event, eventProperties);
    },
    checkAmplitudeFlag(flagName) {
      const variant = this.getAmplitudeVariant(flagName);
      return this.isAmplitudeFFOn(variant);
    },
    isAmplitudeFFOn(amplitudeExperiment) {
      return amplitudeExperiment?.value === 'on';
    },
    getAmplitudeVariant(flagName) {
      return this.amplitude?.variant(flagName);
    },
  },
};
