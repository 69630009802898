import clientFactory from '@rscollabs/rs-core-library/src/services/client';

const client = clientFactory('/api/v1/influencer_application');

class AppService {
  async getCountries() {
    const response = await client.get(`/countries/`);
    return response.data.countries;
  }
}

export default new AppService();
