<template>
  <portal ref="targetRef" :selector="portalTo" append :disabled="portalDisabled">
    <transition name="tooltip">
      <div ref="contentRef" class="rs--tooltip" v-if="shouldRender">
        <div :class="['rs--tooltip__content', themeClass]">
          <slot />
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
import { computed, defineComponent } from 'vue';
import usePopper from '../../composables/usePopper';

export default defineComponent({
  name: 'RsTip',
  emits: ['update:active', 'opened', 'closed'],
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: '',
    },
    // FUTURE: Update options obj with editable settings.
    delay: {
      type: [String, Number],
      default: 100,
    },
    placement: {
      type: String,
      default: 'top',
    },
    autoOpen: {
      type: Boolean,
      default: true,
    },
    offset: {
      type: Array,
      default: () => [0, 8],
    },
    portalTo: {
      type: String,
      default: '#app',
    },
    focusable: {
      type: Boolean,
      default: true,
    },
    portalDisabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const themeClass = computed(() => `is--${props.theme}`);
    const { shouldRender, targetRef, contentRef } = usePopper(props, emit);

    return {
      targetRef,
      contentRef,
      themeClass,
      shouldRender,
    };
  },
});
</script>

<style lang="scss">
.rs--tooltip {
  min-height: 32px;
  transition: opacity 0.5s;
  user-select: none;
  pointer-events: none;
  position: relative;
  font-family: var(--font-family);
  z-index: 200;
  &__content {
    max-width: 300px;
    min-width: 40px;
    min-height: 24px;
    line-height: 18px;
    padding: 10px 15px;
    letter-spacing: 0.04px;
    border-radius: 6px;
    background-color: #000000;
    color: #fff;
    font-size: 14px;
    pointer-events: none;
    text-transform: none;
    white-space: wrap;
    opacity: 1;
    font-weight: 500;
    position: relative;
    transition: opacity 0.2s 0.1s, top 0.3s ease-in-out;
    z-index: 1000;
    & > p:last-child {
      margin-bottom: 0;
    }
  }
  &.is--light {
    .rs--tooltip__content {
      background-color: #fff;
      color: #000;
    }
  }
}

.tooltip-enter-active,
.tooltip-leave-active {
  .rs--tooltip__content {
    transition: opacity 0.2s ease-in, top 0.2s ease-in-out;
    top: 0;
    opacity: 0;
  }
}

.tooltip-enter {
  .rs--tooltip__content {
    top: 16px;
    opacity: 0;
  }
}

.tooltip-enter-to {
  .rs--tooltip__content {
    opacity: 1;
    top: 0;
  }
}

.tooltip-leave-to {
  .rs--tooltip__content {
    opacity: 0;
    top: -16px;
  }
}
</style>
