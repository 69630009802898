<template>
  <rs-card width="375px">
    <rs-card-title class="pb-0">
      <h4 class="display-1 ma-0">Preview</h4>
    </rs-card-title>
    <rs-card-text>
      <!-- Hero Image -->
      <rs-sheet class="d-flex v-sheet-75 overflow-hidden rounded" color="grey lighten-3">
        <rs-chip label class="white--text ma-0" color="#C45500" disabled>
          <rs-icon class="mr-2" size="15" color="#FFF">ltkfont-tag-fill</rs-icon>
          <span class="label-sofiapro-semibold">
            {{ badgeText }}
          </span>
        </rs-chip>
      </rs-sheet>

      <!-- Products -->
      <rs-layout class="mt-2">
        <rs-flex xs3 class="overflow-hidden rounded">
          <rs-badge overlap color="#C45500">
            <template #badge><rs-icon size="10" color="#FFF">ltkfont-tag-fill</rs-icon></template>
            <rs-sheet class="v-sheet-100" color="grey lighten-3 rounded" />
          </rs-badge>
        </rs-flex>
        <rs-flex xs3 class="ml-2" v-for="i in 3" :key="i">
          <rs-sheet class="v-sheet-100" color="grey lighten-3 rounded" />
        </rs-flex>
      </rs-layout>

      <!-- Product Card -->
      <rs-layout
        column
        class="mt-2 overflow-hidden rounded"
        v-for="retailer in modelValue.retailer_details"
        :key="retailer.retailer_id"
      >
        <rs-divider :style="{ 'border-color': '#C45500' }" />
        <div class="promo-container pa-4">
          <div class="promo-description">
            <span class="button-xsmall-sofiapro text-uppercase mb-1">{{ promoTitle }}</span>
            <span
              :class="[
                'text-truncate-lines-3',
                retailer.promo_code && retailer.promo_description.length > 45
                  ? 'caption2-sofiapro'
                  : 'caption1-sofiapro',
              ]"
            >
              {{ retailer.promo_description }}
            </span>
          </div>
          <div v-if="retailer.promo_code" class="promo-button">
            <rs-btn class="v-btn--x-small" :outline="false" :flat="false" depressed inverted> copy promo code </rs-btn>
          </div>
        </div>
      </rs-layout>
    </rs-card-text>
  </rs-card>
</template>

<script>
import { defineComponent, computed } from 'vue';

export default defineComponent({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: Object,
  },
  setup(props, _context) {
    const badgeText = computed(() => props.modelValue.badge_text);

    const promoTitle = computed(() => props.modelValue.promo_title);
    const promoDescription = computed(
      () => props.modelValue.retailer_details?.[props.retailerIndex]?.promo_description,
    );

    return {
      badgeText,
      promoTitle,
      promoDescription,
    };
  },
});
</script>

<style lang="scss" scoped>
.v-sheet-100 {
  padding-bottom: 100%;
}
.v-sheet-75 {
  padding-bottom: 75%;
}

.v-badge {
  width: 100%;

  &::v-deep &__badge {
    height: 16px;
    width: 16px;
    left: 0;
    top: 0;
    border-radius: 6px 0;
  }
}

.v-chip {
  position: absolute;
  top: 0;
  left: -4px;
  border-radius: 6px 0 3px;
  transform: perspective(10px) rotateX(-2deg);

  &::v-deep .v-chip__content {
    height: 24px;
    transform: perspective(10px) rotateX(2deg);
  }
}

.v-divider {
  border-width: 2px;
}

.rounded {
  border-radius: 6px;
}

.v-btn--x-small {
  padding: 8px 16px;
  margin: 0;

  &[inverted]:before {
    background: #c45500;
  }

  &[inverted]:not(.v-btn--disabled):hover:before {
    background: rgba(196, 85, 0, 0.8);
  }

  &::v-deep .v-btn__content {
    letter-spacing: 0;
    font-size: 10px;
  }
}

.promo {
  &-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.2rem;
  }
  &-description {
    flex: 1;
  }
  &-button {
    flex: 1;
  }
}

.text-truncate-lines-3 {
  // Cannot utilize autoprefixer for line-clamp as it does not have a standardized root property
  -moz-line-clamp: 3;
  -webkit-line-clamp: 3;
  // Cannot utilize autoprefixer for box-orient as it does not have a standardized root property
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  // Cannot utilize autoprefixer for display:box as display:flex is not compatible with line-clamp
  display: -moz-box;
  display: -webkit-box;

  overflow: hidden;
  text-wrap: wrap;
  word-break: break-word;
}
</style>
