<template>
  <rs-data-table
    class="table"
    :headers="headers"
    :items="operatorsStat"
    :rows-per-page-items="[25]"
    :hide-actions="true"
  >
    <template slot="items" slot-scope="props">
      <!-- Sub-rows for each date -->
      <tr v-for="(dateStat, index) in props.item.dates" :key="`${props.item.operatorId}-${dateStat.date}`">
        <td>
          <template v-if="index === 0">
            {{ props.item.displayName }}
          </template>
        </td>
        <td>{{ dateStat.date }}</td>
        <td>{{ dateStat.completedItems }}</td>
        <td>{{ dateStat.completedProducts }}</td>
        <td>{{ dateStat.completedMatches }}</td>
        <td>{{ dateStat.completedMatchesYes }}</td>
        <td>{{ dateStat.completedMatchesNo }}</td>
      </tr>
    </template>
  </rs-data-table>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import useStore from '@/composables/vuex';

export default defineComponent({
  setup(_props, _context) {
    const store = useStore();

    const headers = ref([
      { text: 'Operator', value: 'operator', sortable: false },
      { text: 'Date', value: 'date', sortable: false, width: '15%' },
      { text: 'Completed Items', value: 'completedItems', sortable: false, width: '15%' },
      { text: 'Completed Products', value: 'completedProducts', sortable: false, width: '15%' },
      { text: 'Completed Matches', value: 'completedMatches', sortable: false, width: '15%' },
      { text: 'Completed Matches Yes', value: 'completedMatchesYes', sortable: false, width: '15%' },
      { text: 'Completed Matches No', value: 'price', sortable: false, width: '15%' },
    ]);

    const operatorsStat = computed(() => {
      const selectedOperatorFilter = store.getters.selectedOperatorStatFilter;
      if (selectedOperatorFilter) {
        const operatorStat = store.getters.operatorsStat.find(
          (element) => element.operatorId === selectedOperatorFilter.id,
        );
        return operatorStat ? [operatorStat] : [];
      }
      return store.getters.operatorsStat;
    });

    return {
      headers,
      operatorsStat,
    };
  },
});
</script>

<style lang="scss" scoped>
.table {
  &::v-deep .v-table {
    background: none;

    & tbody tr {
      border: 1px solid #ebebeb;
    }
  }

  &::v-deep .v-datatable {
    & thead tr {
      border-bottom: none;
    }
  }
}
</style>
