<template>
  <portal :selector="portalTo" prepend :disabled="portalDisabled">
    <keep-alive>
      <transition name="modal" @after-enter="dispatchEvents('enter')" @after-leave="dispatchEvents('leave')">
        <div name="modal" class="rs--modal" aria-modal="true" v-if="active">
          <rs-focus-trap
            :target="modalRef"
            v-model="trapActive"
            :set-return-focus="lastFocusedElement"
            :outside-click="clickOutsideToClose"
          >
            <div :class="['rs--modal__wrapper', classes]" @keydown.esc="onEsc">
              <div class="rs--modal__container" ref="modalRef">
                <slot />
              </div>
            </div>
            <!-- overlay here -->
          </rs-focus-trap>
          <rs-overlay
            fixed
            :class="backdropClass"
            :active="active"
            :blur="backdropBlur"
            @click="onOutsideClick"
            v-if="backdrop && active"
          />
        </div>
      </transition>
    </keep-alive>
  </portal>
</template>

<script>
import { defineComponent, nextTick, ref, onMounted, computed, watchEffect } from 'vue';
import RsFocusTrap from '../RsFocusTrap/RsFocusTrap.vue';
import uuid from '../../utils/uuid';
import RsOverlay from './RsOverlay.vue';

export default defineComponent({
  components: { RsFocusTrap, RsOverlay },
  name: 'RsModal',
  model: {
    prop: 'active',
    event: 'update:active',
  },
  props: {
    id: {
      type: String,
      default: 'modal-' + uuid(),
    },
    portalTo: {
      type: String,
      default: '#app',
    },
    portalDisabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    backdrop: {
      type: Boolean,
      default: true,
    },
    backdropClass: {
      type: String,
      default: 'rs--dialog__overlay',
    },
    backdropBlur: {
      type: Boolean,
      default: false,
    },
    clickOutsideToClose: {
      type: Boolean,
      default: true,
    },
    closeOnEsc: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'md',
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    aside: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'right',
    },
  },
  setup(props, { emit }) {
    const modalRef = ref(null);
    const trapActive = ref(false);
    const lastFocusedElement = ref(null);
    const classes = computed(() => {
      const sizeClass = `is--${props.size}`;
      const asidePlacement = `is--aside--${props.placement}`;
      return {
        'is--fullscreen': props.fullscreen,
        'is--aside': props.aside,
        [asidePlacement]: props.aside && props.placement,
        [sizeClass]: true,
      };
    });

    const setFocus = () => {
      window.setTimeout(() => {
        if (modalRef.value) {
          modalRef.value.setAttribute('tabindex', '-1');
          modalRef.value.focus();
        }
      }, 0);
    };

    watchEffect(
      async () => {
        if (props.active) {
          lastFocusedElement.value = document.activeElement;
          emit('update:active', true);
          document.body.classList.add('rs--modal--open');
          document.body.style.top = `-${window.scrollY}px`;
          document.body.style.position = 'fixed';
          document.body.style.left = '0';
          document.body.style.right = '0';
          setFocus();
        } else {
          const scrollY = document.body.style.top;
          document.body.classList.remove('rs--modal--open');
          document.body.removeAttribute('style');
          window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
      },
      { flush: 'post' },
    );

    const onClose = () => {
      emit('update:active', false);
      trapActive.value = false;
    };

    const onOutsideClick = () => {
      if (props.clickOutsideToClose) {
        onClose();
      }
      emit('outside-click');
    };

    const onEsc = () => {
      if (props.closeOnEsc) {
        onClose();
      }
    };

    const dispatchEvents = async (state) => {
      await nextTick();
      window.dispatchEvent(new Event('resize'));
      if (state === 'enter') {
        emit('opened');
        trapActive.value = true;
      }
      if (state === 'leave') {
        emit('closed');
      }
    };

    onMounted(() => {
      setFocus();
    });

    return {
      classes,
      modalRef,
      trapActive,
      onOutsideClick,
      onEsc,
      dispatchEvents,
      lastFocusedElement,
    };
  },
});
</script>

<style lang="scss">
@use '../../../scss/breakpoints';
.rs--modal {
  font-family: var(--font-family);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(0, 0);
  color: var(--text);
  &__container {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    height: 100%;
    min-width: 400px;
    &:focus {
      outline: none;
    }
    @include breakpoints.down('sm') {
      height: 100%;
      top: 0;
    }
  }
  &__wrapper {
    margin: auto;
    flex-flow: column;
    flex-direction: row;
    overflow: hidden;
    position: fixed;
    z-index: 100;
    border-radius: 2px;
    backface-visibility: hidden;
    pointer-events: auto;
    transform-origin: center center;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    will-change: opacity, transform, left, top;
    transform: translate3D(0, 0, 0);
    max-width: 100%;
    max-height: 80%;
    &.is {
      &--sm {
        width: 100%;
        max-width: 400px;
        height: auto;
        min-width: 400px;
      }
      &--md {
        @include breakpoints.up('md') {
          max-height: 60%;
        }
        width: 100%;
        max-width: 600px;
        min-width: 400px;
      }
      &--lg {
        width: 100%;
        max-width: 900px;
        min-width: 400px;
      }
      &--fullscreen {
        height: 100%;
        width: 100%;
        @include breakpoints.down('xs') {
          margin: 16px;
          min-height: 100%;
        }
        @include breakpoints.down('sm') {
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 0;
          transform: none;
          box-shadow: none;
        }
      }
      &--aside {
        top: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
        height: 100%;
        min-height: 100%;
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
        &--left {
          right: auto;
          left: 0;
          transform: translate3D(0%, 0, 0);
        }
        &--right {
          left: auto;
        }
        @include breakpoints.down('xs') {
          min-width: 100%;
          transform: none;
          opacity: 1;
        }
        &.is--md {
          width: 40%;
        }
      }
    }
  }
}
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  & > .rs--overlay {
    will-change: opacity;
    transition: opacity 0.2s 0.1s ease-out;
  }
}

.modal-enter {
  .rs--modal__wrapper {
    transform: translate3D(0, 30px, 0);
  }
}
.modal-leave-to {
  .rs--modal__wrapper {
    transform: translate3D(0, -30px, 0);
  }
}
.modal-enter,
.modal-leave-to {
  .rs--overlay {
    opacity: 0;
  }
  .rs--modal__wrapper {
    opacity: 0;
    &.is--fullscreen {
      @include breakpoints.down('sm') {
        transform: translate3D(0, 100%, 0);
      }
    }
    &.is--aside {
      &--left {
        transform: translate3D(-100%, 0, 0);
      }
      &--right {
        transform: translate3D(100%, 0, 0);
      }
    }
  }
}
</style>
