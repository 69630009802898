import { INCOMPLETE_PAGINATION_STORAGE_KEY } from '../constants';
import { COMPLETE_PAGINATION_STORAGE_KEY } from '../constants';

export const setCompleteWorkItemPaginationStorage = (pagination) => {
  sessionStorage.setItem(COMPLETE_PAGINATION_STORAGE_KEY, JSON.stringify(pagination));
};

export const getCompleteWorkItemPaginationStorage = () => {
  return JSON.parse(sessionStorage.getItem(COMPLETE_PAGINATION_STORAGE_KEY) || '{}');
};

export const setIncompleteWorkItemPaginationStorage = (pagination) => {
  sessionStorage.setItem(INCOMPLETE_PAGINATION_STORAGE_KEY, JSON.stringify(pagination));
};

export const getIncompleteWorkItemPaginationStorage = () => {
  return JSON.parse(sessionStorage.getItem(INCOMPLETE_PAGINATION_STORAGE_KEY) || '{}');
};
