<template>
  <rs-layout>
    <rs-flex xs12>
      <rs-card v-if="$route.name === 'LtkProfileVectorSearch'">
        <rs-card-text class="pt-0">
          <ltk-profile-search class="mt-3" @input="activeProfile($event)" />
        </rs-card-text>
      </rs-card>

      <router-view />
    </rs-flex>
  </rs-layout>
</template>

<script>
import LtkProfileSearch from '../components/LtkProfileSearch.vue';

export default {
  components: { LtkProfileSearch },
  methods: {
    activeProfile(profileId) {
      this.$router.push({ path: `${this.$route.path}/${profileId}` });
    },
  },
};
</script>
