<template>
  <rs-container class="posts">
    <rs-layout>
      <h2 class="display-3">{{ $route.meta.title }}</h2>
    </rs-layout>

    <rs-card>
      <rs-card-title class="pa-5">
        <h3 class="display-2 font-weight-medium ma-0">Posts</h3>
      </rs-card-title>

      <rs-card-text class="pt-0">
        <rs-layout xs12 px-5 row justify-space-between>
          <rs-flex mx-1 xs7>
            <rs-text-field
              label="Search"
              append-icon="search"
              placeholder="Search by submitted post URL..."
              v-model="searchQuery"
              @keyup.enter.native="fetchPostsByQuery"
              @click:append="fetchPostsByQuery"
            ></rs-text-field>
          </rs-flex>
          <rs-flex xs4 mx-1>
            <rs-select
              class="font-weight-light"
              v-model="status.selected"
              prepend-icon="rsfont-filter_v2"
              :items="status.options"
              label="Status"
              @change="fetchPostsByQuery"
              clearable
              outlined
            ></rs-select>
          </rs-flex>
        </rs-layout>
        <rs-data-table
          class="posts-data-table mb-4"
          :loading="isLoading"
          :headers="headers"
          :items="posts"
          :total-items="totalRows"
          :must-sort="true"
          :rows-per-page-items="[5, 10, 25, 50]"
          :pagination.sync="pagination"
          @update:pagination="fetchPostsByQuery"
        >
          <template slot="items" slot-scope="props">
            <td>
              <router-link
                style="text-decoration: none"
                :to="{
                  name: 'EditPostDialog',
                  params: {
                    postId: props.item.id,
                  },
                }"
                :data-test-id="`posts-view-post-management-link-${props.index}`"
              >
                <rs-btn borderless fab small :block="false" class="ma-0">
                  <rs-icon size="25">rsfont-edit_v2</rs-icon>
                </rs-btn>
              </router-link>
            </td>
            <td>{{ props.item.influencer_id }}</td>
            <td>{{ props.item.channel | properCase }}</td>
            <td>{{ props.item.submitted_url || '--' }}</td>
            <td>{{ props.item.rstyle_links ? props.item.rstyle_links.length : 0 }}</td>
            <td>
              <div class="links">{{ props.item.rstyle_links ? props.item.rstyle_links.join(', ') : '--' }}</div>
            </td>
            <td>{{ props.item.status || 'No Status' }}</td>
          </template>
        </rs-data-table>
      </rs-card-text>
    </rs-card>

    <!-- Page Modals -->
    <div v-if="showModal" class="modal-route">
      <div class="modal-content">
        <router-view @close="fetchPostsByQuery(query)"></router-view>
      </div>
    </div>
  </rs-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'Posts',
  data() {
    return {
      showModal: false,
      headers: [
        { text: 'Edit', sortable: false },
        { text: 'Influencer ID', value: 'influencer_id' },
        { text: 'Channel', value: 'channel', sortable: false },
        { text: 'Submitted URL', value: 'submitted_url', sortable: false },
        { text: '# of Links', value: 'count', sortable: false },
        { text: 'Links', value: 'links', sortable: false },
        { text: 'Status', value: 'status' },
      ],
      pagination: {
        sortBy: 'influencer_id',
        descending: false,
        page: 1,
        rowsPerPage: 50,
      },
      status: {
        options: ['OK', 'NO_STATUS', 'BROKEN'],
        selected: '',
      },
      searchQuery: '',
    };
  },
  computed: {
    ...mapGetters('posts', ['getPostsByQuery']),
    ...mapState('posts', {
      postsMeta: 'postServiceMeta',
    }),
    isLoading() {
      const { meta } = this.getPostsByQuery(this.query);
      return meta.isFetching;
    },
    filter() {
      return this.status.selected
        ? {
            status: this.status.selected,
          }
        : {};
    },
    posts() {
      const { posts } = this.getPostsByQuery(this.query);
      return posts;
    },
    totalRows() {
      return this.postsMeta.value ? this.postsMeta.value.total_rows : 0;
    },
    query() {
      let p = this.pagination;
      const dir = p.descending ? 'desc' : 'asc';
      const submittedUrlFilterQuery = this.searchQuery
        ? {
            submitted_url: encodeURI(this.searchQuery),
          }
        : {};
      p = {
        page_number: p.page,
        page_size: p.rowsPerPage,
        order_by: `${p.sortBy}:${dir}`,
      };

      return this.serialize({
        ...p,
        ...submittedUrlFilterQuery,
        ...this.filter,
      });
    },
  },
  async mounted() {
    await this.fetchPostsByQuery(this.query);
  },
  methods: {
    ...mapActions('posts', {
      fetchPost: 'getPost',
      fetchPosts: 'getPosts',
    }),
    serialize(obj) {
      var str = [];
      for (var p in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, p)) {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
      }
      return str.join('&');
    },
    async fetchPostsByQuery() {
      if (this.query.includes('submitted_url')) {
        await this.fetchPosts(this.query);
      }
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler: function (newVal) {
        this.showModal = newVal.meta && newVal.meta.showModal;
      },
    },
  },
  filters: {
    properCase: function (value) {
      if (!value) return '--';
      value = value.toString().toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
};
</script>
<style lang="sass">
@media only screen and (min-width: 1904px)
  .container
    min-width: 1680px
    max-width: fit-content !important
.posts
  height: 100%
  width: 100%
  width: -moz-available
  width: -webkit-fill-available
.row-link
  cursor: pointer
td
  max-width: 250px
  text-overflow: ellipsis
  overflow-wrap: break-word
td:nth-child(2)
  white-space: nowrap
  max-width: 350px
.links
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  max-width: max-content !important
</style>
