import Vue from 'vue';
import advertisersService, { ADVERTISERS_LIMIT } from '../../services/advertisers';

function emptyFetchStateFactory() {
  return {
    pending: false,
    error: null,
    timestamp: 0,
  };
}

function fetchStateDecorator(fn) {
  return async function wrappedAction(store, ...rest) {
    const fetchState = {
      pending: true,
      error: null,
      timestamp: 0,
    };

    store.commit('SET_ADVERTISERS_FETCH_STATE', fetchState);

    try {
      const result = fn.call(this, store, ...rest);
      if (result instanceof Promise) {
        await result;
      }
    } catch (error) {
      fetchState.error = error;
    }

    fetchState.timestamp = Date.now();
    fetchState.pending = false;
    store.commit('SET_ADVERTISERS_FETCH_STATE', fetchState);
  };
}

export default {
  state: {
    // [ id ] list of advertisers by id
    advertiserIds: [],
    // { id: Advertiser } Mapping
    advertiserMap: {},
    // String indicating the next pagination url
    pagination: {
      next: 1,
      retrieved: ADVERTISERS_LIMIT,
      total: -1,
    },
    fetchState: emptyFetchStateFactory(),
  },
  getters: {
    advertisersFetchState(state) {
      return () => state.fetchState;
    },
  },
  actions: {
    async fetchAdvertiserPage({ commit, state }) {
      const response = await advertisersService.get({
        next: state.pagination.next,
        limit: ADVERTISERS_LIMIT,
      });

      const data = response.data.advertisers.reduce(
        (acc, advertiser) => {
          if (advertiser.active === true && advertiser.name) {
            acc.ids.push(advertiser.id);
          }
          acc.advertiserMap[advertiser.id] = advertiser;
          return acc;
        },
        { ids: [], advertiserMap: {} },
      );

      data.pagination = response.data.pagination;

      commit('SET_ADVERTISER_PAGE', data);
    },

    async fetchAllAdvertisers(store) {
      if (store.state.fetchState.pending === true || store.state.pagination.total !== -1) {
        // Already Fetched, or currently fetching
        return;
      }

      await fetchStateDecorator(async function ({ dispatch, state }) {
        while (state.pagination.retrieved === ADVERTISERS_LIMIT) {
          await dispatch('fetchAdvertiserPage');
        }
      })(store);
    },
  },
  mutations: {
    SET_ADVERTISERS_FETCH_STATE(state, fetchState) {
      Vue.set(state, 'fetchState', Object.assign({}, fetchState));
    },

    SET_ADVERTISER_PAGE(state, { ids, advertiserMap, pagination }) {
      Vue.set(state, 'advertiserMap', Object.assign({}, state.advertiserMap, advertiserMap));
      state.advertiserIds.push(...ids);
      state.pagination = pagination;
    },
  },
};
