const INTERESTS = [
  { text: 'Active and Fitness', value: 'active_and_fitness' },
  { text: 'Beauty', value: 'beauty' },
  { text: 'Family and Parenting', value: 'family_and_parenting' },
  { text: 'Fashion and Clothing', value: 'fashion_and_clothing' },
  { text: 'Food and Cooking', value: 'food_and_cooking' },
  { text: 'Health & Wellness', value: 'health_and_wellness' },
  { text: 'Holiday Celebrations', value: 'holiday_celebrations' },
  { text: 'Home and Decor', value: 'home_and_decor' },
  { text: 'Pets', value: 'pets' },
  { text: 'Travel and Adventure', value: 'travel_and_adventure' },
  { text: 'Budget Home', value: 'budget_home' },
  { text: 'Budget-friendly Beauty', value: 'budget-friendly_beauty' },
  { text: 'Budget-friendly Fashion', value: 'budget-friendly_fashion' },
  { text: 'Casual & Leisure Fashion', value: 'casual_and_leisure_fashion' },
  { text: 'DIY', value: 'diy' },
  { text: 'Designer-Inspired Fashion', value: 'designer-inspired_fashion' },
  { text: 'Gym Wear', value: 'gym_wear' },
  { text: 'Hair Care', value: 'hair_care' },
  { text: 'Luxury Fashion', value: 'luxury_fashion' },
  { text: 'Makeup', value: 'makeup' },
  { text: 'Maternity Fashion', value: 'maternity_fashion' },
  { text: 'Mom', value: 'mom' },
  { text: 'Organization', value: 'organization' },
  { text: 'Plus Size Fashion', value: 'plus_size_fashion' },
  { text: 'Pregnancy & Bump', value: 'pregnancy_and_bump' },
  { text: 'Running', value: 'running' },
  { text: 'Skincare', value: 'skincare' },
  { text: 'Sneakerhead Culture', value: 'sneakerhead_culture' },
  { text: 'Streetwear Fashion', value: 'streetwear_fashion' },
  { text: 'Tennis & Pickleball', value: 'tennis_and_pickleball' },
  { text: 'Travel Fashion', value: 'travel_fashion' },
  { text: 'Western Fashion', value: 'western_fashion' },
  { text: 'Workout', value: 'workout' },
  { text: 'Workwear Fashion', value: 'workwear_fashion' },
];

const getInterest = (interest) => {
  return INTERESTS.find((i) => i.value === interest).text;
};

export { INTERESTS, getInterest };
