<template>
  <div class="quality-control-match-image-container">
    <rs-img
      class="quality-control-match-image-img"
      :src="src"
      height="135"
      width="135"
      aspect-ratio="1"
      contain
      @click.stop="onCompareWorkItem"
    />
    <div class="quality-control-match-image-overlay">
      <div class="quality-control-match-image-icon">
        <rs-icon color="white">ltkfont-visible-show-outline</rs-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['click'],
  props: {
    src: String,
  },
  setup(_, ctx) {
    const onCompareWorkItem = () => {
      ctx.emit('click');
    };

    return { onCompareWorkItem };
  },
});
</script>

<style lang="scss" scoped>
.quality-control-match-image {
  &-container {
    cursor: pointer;
    position: relative;

    &:hover .quality-control-match-image {
      &-img {
        opacity: 0.5;
      }
      &-overlay {
        opacity: 1;
      }
    }
  }

  &-img {
    height: 135px;
    width: 135px;
    border-radius: 6px;
    border: 1px solid #ccc;
    transition: opacity 300ms ease-in-out;
  }

  &-overlay {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }

  &-icon {
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
  }
}
</style>
