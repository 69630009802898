<template>
  <rs-card>
    <rs-loader v-if="isLoading" />

    <rs-card-title>
      <h3 class="ma-0" v-if="saleId === 'add'">Add Sale</h3>
      <h3 class="ma-0" v-else>Edit Sale</h3>
      <rs-spacer />
      <sale-status :status="status" />
      <rs-btn small fab icon :block="false" :outline="false" :to="{ name: 'LtkSales' }">
        <rs-icon>ltkfont-x-close-outline</rs-icon>
      </rs-btn>
    </rs-card-title>

    <rs-card-text>
      <rs-layout>
        <rs-flex class="pr-2">
          <sale-form v-model="sale" ref="form" />
        </rs-flex>
        <rs-flex class="pl-2" shrink>
          <sale-preview v-model="sale" />
        </rs-flex>
      </rs-layout>
    </rs-card-text>

    <rs-card-actions>
      <rs-spacer />
      <rs-btn
        :flat="false"
        :outline="false"
        color="blue"
        :block="false"
        @click="handleDraftClick()"
        :disabled="isLoading"
        :loading="isLoading"
      >
        <rs-icon class="mr-1">ltkfont-edit-outline_1</rs-icon>
        Save As Draft
      </rs-btn>
      <rs-btn
        :flat="false"
        :outline="false"
        :block="false"
        color="rs-success"
        @click="handlePublishClick()"
        :disabled="isLoading"
        :loading="isLoading"
      >
        <rs-icon class="mr-1">ltkfont-send-message-outline</rs-icon>
        Publish
      </rs-btn>
    </rs-card-actions>

    <sale-publish-modal v-model="isPublishConfirmOpen" @confirm="onPublishConfirm()" />
    <sale-publish-live-modal v-model="isPublishLiveConfirmOpen" @confirm="onPublishConfirm()" />
    <rs-snackbar color="red" top v-model="hasError">{{ errorText }}</rs-snackbar>
  </rs-card>
</template>

<script>
import { defineComponent, computed, ref, watch, onMounted } from 'vue';

import useStore from '@/composables/vuex';
import { useRouter } from 'vue-router/composables';
import RsLoader from '@/internal-accounts/components/Loader.vue';

import SaleForm from '../components/form/SaleForm.vue';
import SaleStatus from '../components/SaleStatus.vue';
import SalePreview from '../components/SalePreview.vue';
import SalePublishModal from '../components/modals/SalePublishModal.vue';
import SalePublishLiveModal from '../components/modals/SalePublishLiveModal.vue';

export default defineComponent({
  components: {
    RsLoader,
    SaleForm,
    SaleStatus,
    SalePreview,
    SalePublishModal,
    SalePublishLiveModal,
  },
  props: {
    saleId: String,
  },
  setup(props, _context) {
    const store = useStore();
    const router = useRouter();

    onMounted(async () => {
      await store.dispatch('fetchAllAdvertisers');
    });

    const isNewSale = computed(() => props.saleId === 'add');
    const status = computed(() => store.getters.saleStatus(props.saleId));

    const form = ref(null);
    const sale = ref({});
    watch(
      () => store.state['ltk-sales'].saleMap[props.saleId],
      () => {
        // Deep clone a JSON object
        sale.value = JSON.parse(JSON.stringify(store.state['ltk-sales'].saleMap[props.saleId]) ?? '{}');
      },
      { immediate: true },
    );
    watch(
      () => isNewSale.value === true && Object.keys(store.state['ltk-sales'].saleMap),
      (newValue, oldValue) => {
        if (isNewSale.value === false) {
          return;
        }

        const newIds = newValue.filter((id) => !oldValue.includes(id));
        // No new IDs were added since last fetch, do nothing
        if (newIds.length === 0) {
          return;
        }

        /**
         * Given that a retailer cannot be in overlapping sales, we will look for the same sale range,
         * and same retailers to locate the new sale without the id.
         */
        const matchingSale = newIds
          .map((id) => store.state['ltk-sales'].saleMap[id])
          .find((newSale) => {
            const compareProps = ['start_date', 'end_date'];
            const mismatchIndex = compareProps.findIndex((prop) => sale.value[prop] !== newSale[prop]);

            // properties were different
            if (mismatchIndex !== -1) {
              return false;
            }

            // deep check sale retailers
            const newSaleRetailers = newSale.retailer_details.map(({ retailer_id }) => retailer_id);
            const mismatchRetailerIndex = sale.value.retailer_details.findIndex(
              ({ retailer_id }) => !newSaleRetailers.includes(retailer_id),
            );

            // retailers were different
            if (mismatchRetailerIndex !== -1) {
              return false;
            }

            return true;
          });

        if (matchingSale === undefined) {
          console.warn('Unable to lookup a matching sale. Returning to the list page');
          return router.push({ name: 'LtkSales' });
        }

        router.push({ name: 'LtkSaleManagement', params: { saleId: matchingSale.id } });
      },
    );

    const fetchState = computed(() => store.getters.salesFetchState(props.saleId));
    const isLoading = computed(() => store.getters.salesFetchState('list').pending || fetchState.value.pending);
    const hasError = computed({
      get: () => fetchState.value.error !== null,
      set: () =>
        store.commit('SET_SALES_FETCH_STATE', {
          id: props.saleId,
          fetchState: Object.assign({}, fetchState.value, { error: null }),
        }),
    });
    const errorText = computed(
      () => fetchState.value.error?.data?.developer_message || fetchState.value.error?.data || fetchState.value.error,
    );

    function validateForm() {
      return form.value?.$refs?.form?.validate();
    }

    async function handleDraftClick() {
      if (!validateForm()) {
        return;
      }

      const actionName = isNewSale.value === true ? 'createSale' : 'updateSale';
      await store.dispatch(actionName, Object.assign({}, sale.value, { status: 'draft' }));
    }

    const isPublishConfirmOpen = ref(false);
    const isPublishLiveConfirmOpen = ref(false);
    function handlePublishClick() {
      if (!validateForm()) {
        return;
      }

      const isNewSale = sale.value.status !== 'live' && sale.value.status !== 'published';
      const isSalePublished = sale.value.status === 'published';
      const originalStartDate = store.state['ltk-sales'].saleMap[props.saleId]?.start_date;
      const isStartDateModified = originalStartDate !== undefined && sale.value.start_date !== originalStartDate;

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const startDate = new Date(`${sale.value.start_date}T00:00:00`);
      const isStartDateTodayOrBefore = startDate <= today;

      /**
       * Show "immediately live" popup if sale start date is on or before today and either
       * - it is a new sale (not already published/live)
       * - an existing sale that has its start date modified (don't show this popup if currently editing a live sale)
       */
      if (isStartDateTodayOrBefore && (isNewSale || (isSalePublished && isStartDateModified))) {
        isPublishLiveConfirmOpen.value = true;
      } else {
        isPublishConfirmOpen.value = true;
      }
    }

    async function onPublishConfirm() {
      const actionName = isNewSale.value === true ? 'createSale' : 'updateSale';
      await store.dispatch(actionName, Object.assign({}, sale.value, { status: 'published' }));
    }

    return {
      form,
      sale,
      status,
      isLoading,
      hasError,
      errorText,
      isPublishConfirmOpen,
      isPublishLiveConfirmOpen,
      handleDraftClick,
      handlePublishClick,
      onPublishConfirm,
    };
  },
});
</script>
