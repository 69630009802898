/**
 * Map of Follower Count "Data Types" for our CIQ Social Metrics: https://rewardstyle.atlassian.net/l/cp/Fq1RSW21
 *
 * Each Social Metric `platform_data_type_id` value is mapped to an Application Platform `platform_id` key.
 */
export const FOLLOWER_COUNT_PLATFORM_DATA_TYPES = {
  instagram: 1,
  facebook: 3,
  tiktok: 4,
  twitter: 6,
  youtube: 8,
  pinterest: 10,
  red: 12,
  blog: 13,
  other: 14,
  plurk: 15,
  weibo: 16,
  douban: 17,
  douyin: 18,
  lemon8: 19,
  wechat: 20,
  bilibili: 21,
  telegram: 22,
  'naver-blog': 23,
  'naver-cafe': 24,
  'kakao-story': 25,
};

/**
 * Formats a Follower Count using `compact` intl notation.
 * @param {number} count
 * @returns {string}
 */
export const formatFollowerCount = (count) => {
  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(count);
};
