<template>
  <transition name="slide-in">
    <div class="campaign-footer">
      <div class="campaign-footer__content">
        <rs-button @click="handleClose()" class="campaign-footer__cancel" data-testid="campaign-footer-cancel"
          >Cancel</rs-button
        >
        <rs-button :disabled="!modelValue" @click="handleSave()" data-testid="campaign-footer-save">
          Save Changes
        </rs-button>
      </div>
    </div>
  </transition>
</template>

<script>
import { defineComponent } from 'vue';
import { useVModel } from '@vueuse/core';
import RsButton from '@/internal-advertisers/lib/components/RsButton/RsButton.vue';

export default defineComponent({
  name: 'HashtagsFooter',
  components: { RsButton },
  emit: { 'update:modelValue': () => true, saved: () => true },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    function handleClose() {
      emit('cancel');
    }

    function handleSave() {
      emit('saved');
    }

    return { handleClose, handleSave };
  },
});
</script>

<style lang="scss">
.campaign-footer {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  &__content {
    max-width: 1260px;
    margin: 0 auto;
    padding: 24px 16px;
    border-top: 1px solid #efefef;
    display: flex;
    justify-content: flex-end;
    opacity: 1;
    transform: translate3D(0, 0, 0);
    & > button {
      width: 226px;
    }
  }
  &__cancel {
    margin-right: 16px;
  }
}
.slide-in-enter-active,
.slide-in-leave-active {
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}
.slide-in-enter {
  opacity: 1;
  transform: translate3D(0, 100%, 0);
}
.slide-in-leave-to {
  opacity: 0;
  transform: translate3D(0, 100%, 0);
}
</style>
