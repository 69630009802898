<template>
  <rs-card class="" style="background-color: #f0f0f0; border-radius: 10px" :rounded="true" :bordered="false">
    <rs-card-title class="pa-2">
      <p class="px-3 pt-3 pb-0 mb-0 font-weight-black">Pricing breakdown</p>
    </rs-card-title>
    <rs-card-text class="px-2 pb-2 pt-2">
      <div>
        <div grid-list-md class="mx-3 mb-3">
          <rs-layout row wrap>
            <rs-flex xs6 class="pt-0">
              <p class="text-sm-left caption mb-0">MSRP</p>
            </rs-flex>
            <rs-flex xs6>
              <p class="text-xs-right body-1 mb-0">{{ msrpPriceDollars | currency }}</p>
            </rs-flex>
          </rs-layout>
          <rs-layout row wrap mb-2>
            <rs-flex xs6 pb-0>
              <p class="text-sm-left caption mb-0">Discount</p>
            </rs-flex>
            <rs-flex xs6 pb-0>
              <p class="text-xs-right body-1 mb-0" data-test-id="discount-field">
                <span v-if="isDiscountValid">{{ discount | currency }}</span>
                <span v-else>--</span>
              </p>
            </rs-flex>
          </rs-layout>
          <rs-divider color="#000000" />
          <rs-layout row wrap mt-2>
            <rs-flex xs6 pt-0>
              <p class="text-sm-left caption mb-0">Total subscription pricing</p>
            </rs-flex>
            <rs-flex xs6 pt-0>
              <p class="text-xs-right body-1 mb-0">{{ subscriptionPriceDollars | currency }}</p>
            </rs-flex>
          </rs-layout>
          <rs-layout row wrap>
            <rs-flex xs12>
              <p class="text-xs-right mb-0 caption">*taxes and fees not included</p>
            </rs-flex>
          </rs-layout>
        </div>
      </div>
    </rs-card-text>
  </rs-card>
</template>

<script>
export default {
  name: 'PriceBreakdownTable',
  props: {
    msrpPriceDollars: {
      type: Number,
      default: 0,
    },
    subscriptionPriceDollars: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    discount() {
      return (this.msrpPriceDollars - this.subscriptionPriceDollars) * -1;
    },
    isDiscountValid() {
      return this.discount < 0;
    },
  },
};
</script>
