<template>
  <rs-dialog :value="isOpen" max-width="650px" @input="handleClose()" persistent>
    <rs-card width="100%">
      <rs-layout column>
        <rs-flex xs12 mx-4>
          <rs-layout row justify-space-between>
            <rs-flex display-2 my-4 data-test-id="qa-delete-creator-header">
              Remove {{ accountName }} from following lists:
            </rs-flex>
            <rs-icon size="23" @click="handleClose()"> rsfont-close_v2 </rs-icon>
          </rs-layout>
        </rs-flex>
        <rs-divider />
        <rs-flex v-if="!isLoading" column px-4>
          <rs-layout
            v-for="list in accountLists"
            :key="list.name"
            class="py-4 justify-space-between list-item"
            data-test-id="qa-creator-list-item"
          >
            <span class="body-2 font-weight-bold">{{ listDetails[list.name].title }}</span>
            <rs-checkbox v-model="selections[list.name]" dense hide-details class="shrink" />
          </rs-layout>
        </rs-flex>
        <rs-flex xs12 ma-4>
          <rs-layout row wrap justify-end>
            <rs-flex
              :class="{
                xs3: $vuetify.breakpoint.smAndUp,
                xs12: $vuetify.breakpoint.xs,
              }"
            >
              <rs-btn class="ma-0" @click="handleClose()">Cancel</rs-btn>
            </rs-flex>
            <rs-flex
              :class="{
                xs3: $vuetify.breakpoint.smAndUp,
                xs12: $vuetify.breakpoint.xs,
                'ml-3': $vuetify.breakpoint.smAndUp,
                'mt-3': $vuetify.breakpoint.xs,
              }"
            >
              <rs-btn
                class="ma-0"
                data-test-id="qa-delete-btn"
                inverted
                :disabled="isAllDeselected"
                :loading="isRemoving"
                @click="handleDelete()"
              >
                Remove
              </rs-btn>
            </rs-flex>
          </rs-layout>
        </rs-flex>
      </rs-layout>
    </rs-card>
  </rs-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { listIdsDev, listIdsProd, listDetails } from '../utils/lists';
import amplitudeMixins from '@/mixins/amplitude';
const agencyAllowListFlag = 'agency-allowed-list';

export default {
  name: 'DeleteCreatorModal',
  mixins: [amplitudeMixins],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    listName: {
      type: String,
      required: true,
    },
    accountId: {
      type: String,
    },
    accountName: {
      type: String,
    },
  },
  computed: {
    ...mapGetters('casting', ['accountLists']),
    listDetails() {
      return listDetails;
    },
    listIds() {
      let ids = rewardStyle.ENVIRONMENT === 'prod' ? listIdsProd : listIdsDev;
      let allowListId = 24601;
      if (this.agencyAllowListFlagEnabled && !ids.includes(allowListId)) {
        ids.push(allowListId);
      }
      return ids;
    },
    isAllDeselected() {
      return Object.values(this.selections).every((val) => !val);
    },
    agencyAllowListFlagEnabled() {
      return this.checkAmplitudeFlag(agencyAllowListFlag);
    },
    isLiquidMarketplaceDefault() {
      return this.listName === 'liquid-marketplace-default';
    },
  },
  data() {
    return {
      isLoading: false,
      isRemoving: false,
      selections: {},
    };
  },
  async mounted() {
    try {
      this.isLoading = true;
      await this.getAccountLists({ accountId: this.accountId, listIds: this.listIds });
      this.selections = this.accountLists.reduce((acc, list) => {
        acc[list.name] = true;
        return acc;
      }, {});
      this.isLoading = false;
    } catch (e) {
      this.$root.$emit('onError', e);
    }
  },
  methods: {
    ...mapActions('casting', ['getAccountLists', 'deleteAccountFromList']),
    ...mapActions('roles', ['deleteAccountRole']),
    handleClose() {
      this.$emit('close');
    },
    async handleDelete() {
      this.isRemoving = true;
      try {
        if (this.isLiquidMarketplaceDefault) {
          await this.deleteAccountRole({
            accountId: this.accountId,
            role: [
              {
                name: 'creator-marketplace-mvp',
              },
            ],
          });
        }

        const promises = [];
        Object.entries(this.selections).forEach(([listName, isSelected]) => {
          if (isSelected) {
            promises.push(
              this.deleteAccountFromList({
                listName,
                accountId: this.accountId,
                isCurrentList: listName === this.listName,
              }),
            );
          }
        });
        await Promise.all(promises);
        this.isRemoving = false;
        this.$emit('close');
      } catch (e) {
        this.isRemoving = false;
        this.$emit('close');
        this.$root.$emit('onError', {
          data: {
            error: 'Creator was not deleted',
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list-item {
  border-bottom: 1px solid #ebebeb;
}

::v-deep .v-input {
  display: block;
  margin: 0;
  padding: 0;
}

::v-deep .v-input--selection-controls__input {
  zoom: 0.8;
  margin: 0;
}
</style>
