<template>
  <rs-layout class="unassigned-count" row>
    <span class="unassigned-count-segment"
      >Working queue length: <b>{{ unassignedData.count }}</b> (last calculated {{ unassignedData.updatedAt }})</span
    >

    <span class="unassigned-count-segment"
      >Full work days: {{ unassignedData.leftDays }}. Estimated empty by <b>{{ unassignedData.emptyBy }}</b></span
    >
  </rs-layout>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import useStore from '@/composables/vuex';

export default defineComponent({
  setup(_props, _context) {
    const store = useStore();

    const unassignedData = computed(() => {
      const data = store.getters.unassignedCount;
      if (Object.keys(data).length === 0) {
        return {
          count: 0,
          leftDays: 0,
          updatedAt: '',
          emptyBy: '',
        };
      }
      // Calculate the number of working days left: divide the total count by the average of the last week (5 working days)
      const leftDays = Math.floor(data.count / (data.totalLastWeek / 5));
      // Calculate the date when the queue will be empty
      let workingDaysAdded = 0;
      let futureDate = new Date();
      while (workingDaysAdded < leftDays) {
        futureDate.setDate(futureDate.getDate() + 1);
        // Check if it's a weekday (Monday to Friday)
        if (futureDate.getDay() !== 0 && futureDate.getDay() !== 6) {
          workingDaysAdded++;
        }
      }
      // Create options for formatting the date
      let options = {
        timeZone: 'America/Chicago',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      const emptyBy = futureDate.toLocaleDateString('en-US', options);
      return {
        count: data.count.toLocaleString('en-US'),
        leftDays: leftDays,
        updatedAt: data.updatedAt,
        emptyBy: emptyBy,
      };
    });
    return {
      unassignedData,
    };
  },
});
</script>

<style lang="scss" scoped>
.unassigned-count {
  padding: 10px;
  background: whitesmoke;
  margin-top: 1px;
}

.unassigned-count-segment {
  margin-right: 10px;
}
</style>
