/**
 * This file is necessary to rewrite the imports for translations.json. The source plugin reaches
 * way too far outside it's scope and assumes an unsafe directory structure of where the locales
 * folder would be. A better improvement would be to import `@/translations.json`, as that is
 * a controllable path rather than outside of source code directory.
 */
import GetTextPlugin from 'vue-gettext';
import translations from '@/translation.json';
import Auth from '@rscollabs/rs-core-library/src/services/auth';

const defaultLanguage = (() => {
  let { language } = Auth.getTokenIdObj();
  language = language === 'zh_TW' ? 'zh-Hant' : language;
  language = language === 'zh_CN' ? 'zh-Hans' : language;
  return language || 'en_US';
})();
const availableLanguages = {
  en_US: 'English',
  fr_FR: 'French - Français',
  de_DE: 'German - Deutsch',
  'zh-Hans': 'Simplified Chinese - 中文(简体)',
  'zh-Hant': 'Traditional Chinese - 中文(香港)',
  ru_RU: 'Russian - Pусский',
  es_ES: 'Spanish - Español',
  it_IT: 'Italian - Italia',
  pt_BR: 'Portuguese - Português',
  sv_SE: 'Swedish - Svenska',
  ja_JP: 'Japanese - 日本語',
  nl_NL: 'Dutch - Nederlands',
  da_DK: 'Danish - Dansk',
  ko_KR: 'Korean - 한국어',
  nb_NO: 'Norwegian - Bokmål',
};

export default (Vue) => {
  Vue.use(GetTextPlugin, {
    translations,
    availableLanguages,
    defaultLanguage,
    silent: process.env.NODE_ENV !== 'development',
  });
};
