<template>
  <rs-dialog
    :value="isOpen"
    max-width="650px"
    @input="(v) => v || handleClose()"
    data-test-id="qa-list-name-accounts-modal"
    persistent
  >
    <rs-card data-test-id="qa-offer-warning-modal" width="100%">
      <rs-layout column fill-height>
        <rs-flex shrink>
          <rs-layout ma-4 justify-end>
            <rs-flex align-self-center display-2>Add Creators to {{ listDetails[listName].title }}</rs-flex>

            <rs-flex shrink text-xs-right>
              <rs-icon color="#000000" size="30" @click="handleClose"> close </rs-icon>
            </rs-flex>
          </rs-layout>
        </rs-flex>

        <rs-divider />

        <rs-flex v-if="failedListNameAccounts.length" class="mx-4 mt-4">
          <rs-layout class="pt-2 px-3 creator-id-container column">
            <rs-flex class="subheading account-id-message__error">
              {{ failedListNameAccounts.length }} Creator IDs have failed to import
            </rs-flex>
            <rs-flex class="subheading text-truncate_2">
              <span>{{ displayFailedAccountIds }} </span>
            </rs-flex>
            <rs-layout align-center mb-2>
              <rs-flex class="xs3">
                <rs-btn @click="handleCopy" class="copy-btn">COPY ID LIST</rs-btn>
              </rs-flex>
              <rs-flex mx-3 v-if="isCopied" class="account-id-message__success subheading"
                >Copied to clipboard!</rs-flex
              >
            </rs-layout>
          </rs-layout>
        </rs-flex>

        <rs-textarea
          height="200"
          outline
          full-width
          placeholder="Enter one Account ID per line"
          row-height="18"
          hint="Enter one Account ID per line"
          class="mx-4 mt-4 text-area"
          v-model="textAreaData"
          :rules="inputRules"
        />

        <rs-flex
          d-flex
          :class="{
            'ma-4': $vuetify.breakpoint.xs,
          }"
        >
          <rs-layout
            justify-end
            :class="{
              column: $vuetify.breakpoint.xs,
              'mt-2': true,
            }"
          >
            <rs-flex
              xs3
              :class="{
                'mr-3': $vuetify.breakpoint.smAndUp,
              }"
            >
              <rs-btn small class="ma-0 mb-4" flat @click="handleClose"> Cancel </rs-btn>
            </rs-flex>
            <rs-flex
              xs3
              :class="{
                'mr-4': $vuetify.breakpoint.smAndUp,
              }"
            >
              <rs-btn
                class="ma-0 mb-4"
                @click="submitAccountIDs"
                inverted
                data-test-id="qa-submit-account_ids"
                :disabled="!textAreaData"
              >
                Submit
              </rs-btn>
            </rs-flex>
          </rs-layout>
        </rs-flex>
      </rs-layout>
    </rs-card>
  </rs-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { listDetails } from '../utils/lists';

export default {
  name: 'AddCreatorModal',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    listName: {
      type: String,
      required: true,
    },
    failedListNameAccounts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      textAreaData: '',
      inputRules: [
        (v) =>
          (v && /^\d+$/.test(this.accountIds.join(''))) ||
          'Account IDs must contain only numbers and newline characters',
      ],
      isCopied: false,
    };
  },
  computed: {
    accountIds() {
      const ids = this.textAreaData ? this.textAreaData.split('\n').filter((id) => id !== '') : [];
      return ids;
    },
    listDetails() {
      return listDetails;
    },
    displayFailedAccountIds() {
      const results = this.failedListNameAccounts.join(', ');
      return results.replace(/,\s*$/, '');
    },
    isLiquidMarketplaceDefault() {
      return this.listName === 'liquid-marketplace-default';
    },
  },
  methods: {
    ...mapActions('casting', ['addAccountsListName']),
    ...mapActions('roles', ['addAccountRole']),
    async submitAccountIDs() {
      if (this.isLiquidMarketplaceDefault) {
        await Promise.all(
          this.accountIds.map(async (id) => {
            await this.addAccountRole({
              accountId: id,
              role: [
                {
                  name: 'creator-marketplace-mvp',
                },
              ],
            });
          }),
        );
      }
      await this.addAccountsListName({
        listName: this.listName,
        accountIds: this.accountIds,
      });
      if (this.failedListNameAccounts.length) {
        this.textAreaData = '';
      } else {
        this.handleClose();
      }
    },
    handleClose() {
      this.$emit('close');
    },
    handleCopy() {
      const results = this.failedListNameAccounts.reduce((acc, curr) => {
        acc += `${curr}\n`;
        return acc;
      }, '');
      navigator.clipboard.writeText(results);
      this.isCopied = true;
    },
  },
  watch: {
    async textAreaData(newVal, oldVal) {
      if (newVal && newVal.length) {
        await this.$nextTick();
        this.textAreaData = newVal.replace(/[^\r\n0-9]/g, '').replace(/[\r\n]+/g, '\n');
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.creator-id-container
  background-color: #FDE9E8

.account-id-message
  &__success
    color: #1B8659

  &__error
    color: #FE636D

.text-truncate_2
  overflow: hidden
  text-overflow: ellipsis
  display: -webkit-box
  -webkit-box-orient: vertical
  -webkit-line-clamp: 2
  white-space: normal

.copy-btn
  height: 12px
  font-size: 12px
  font-weight: 700

::v-deep .v-text-field__details
  align-self: flex-start !important
  padding: 0 !important
</style>
