<template>
  <div class="action-bar">
    <div class="action-bar--start">
      <div class="action-bar__search-by">
        <rs-select
          label="Search by"
          :items="filters"
          item-text="label"
          item-value="value"
          return-object
          v-model="search.filter"
          @change="onClear"
        />
      </div>
      <div class="action-bar__search">
        <rs-menu offset-y min-width="200" v-if="search.filter.value === 'commission'">
          <template #activator="{ on }">
            <rs-button class="action-bar__operator-menu" icon-only size="sm" color="secondary" v-on="on">
              <span>{{ search.operator.value }}</span>
              <rs-icon>keyboard_arrow_down</rs-icon>
              <rs-tip>{{ search.operator.label }}</rs-tip>
            </rs-button>
          </template>
          <rs-list class="filters">
            <rs-radio-group label="Status" v-model="search.operator">
              <rs-radio
                :label="`${operator.label} (${operator.value})`"
                :value="operator"
                v-for="operator in numericOperators"
                :key="operator.value"
              />
            </rs-radio-group>
          </rs-list>
        </rs-menu>
        <rs-text-field
          class="action-bar__search-field"
          :label="`Search by ${search.filter.label}`"
          type="search"
          clearable
          autofocus
          v-model="search.param"
          @click:clear="onClear"
          @keydown.enter.prevent="() => commitQuery()"
          v-if="search.filter.value !== 'region'"
        />
        <rs-autocomplete
          @change="() => commitQuery()"
          class="action-bar__region-field"
          v-model="search.regions"
          :search-input.sync="state.regionsSearchInput"
          :return-object="false"
          :items="regions"
          autofocus
          auto-select-first
          item-text="display"
          item-value="abbreviation"
          label="Search regions"
          dense
          multiple
          small-chips
          deletable-chips
          flat
          v-else
        />
      </div>
    </div>
    <div class="action-bar--end">
      <rs-menu offset-y min-width="200">
        <template #activator="{ on }">
          <rs-button class="action-bar__filter" size="sm" color="secondary" v-on="on">
            <rs-icon>filter_list</rs-icon>
            <span>{{ computedActiveLabel }}</span>
            <rs-icon>keyboard_arrow_down</rs-icon>
          </rs-button>
        </template>
        <rs-list class="filters">
          <rs-radio-group label="Status" v-model="search.active" @change="commitQuery()">
            <rs-radio label="None" value="null" />
            <rs-radio label="Active" value="true" />
            <rs-radio label="Inactive" value="false" />
          </rs-radio-group>
        </rs-list>
      </rs-menu>
      <rs-button class="action-bar__submit" size="sm" @click="() => commitQuery()" primary> Search </rs-button>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, computed, onMounted, watch } from 'vue';
import RsButton from '../lib/components/RsButton/RsButton.vue';
import RsTip from '../lib/components/RsTip/RsTip.vue';
import useAdvertiserRouterQuery from '../composables/useAdvertiserRouterQuery';
import { FILTERS, NUMERIC_OPERATORS } from '../constants';
export default defineComponent({
  name: 'AdvertiserActionBar',
  components: { RsButton, RsTip },
  props: {
    regions: {
      type: Array,
      required: true,
    },
    init: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const filters = FILTERS;
    const numericOperators = NUMERIC_OPERATORS;
    const { filterBy, filterParams, filterActive, filterRegions, filterOperator } = useAdvertiserRouterQuery(
      props.init,
    );
    const state = reactive({
      hasError: false,
      errorMessage: '',
      regionsSearchInput: '',
    });

    const search = reactive({
      filter: filterBy.value || filters[0],
      operator: filterOperator.value || numericOperators[0],
      param: filterParams.value || '',
      regions: filterRegions.value,
      active: filterActive.value,
    });

    const activeParam = computed(() => {
      const activeFilter = search.active !== null ? encodeURIComponent(search.active) : null;
      return activeFilter;
    });
    const searchParams = computed(() => {
      const searchFilter = encodedSearch.value ? { [`${search.filter.value}`]: encodedSearch.value } : {};
      const result = {
        ...searchFilter,
        active: activeParam.value,
      };
      return result;
    });

    const computedActiveLabel = computed(() => {
      if (search.active === 'null') {
        return 'None';
      }
      if (search.active === 'true') {
        return 'Active';
      }
      if (search.active === 'false') {
        return 'Inactive';
      }
      return undefined;
    });

    const onClear = () => {
      search.param = '';
      search.regions.value = [];
      if (state.regionsSearchInput !== '') {
        state.regionsSearchInput = '';
        commitQuery();
      }
    };

    const encodedSearch = computed(() => {
      if ((!search.param && !search.regions) || search.param === null) {
        return '';
      }

      if (search.filter.value === 'region') {
        return search.regions.map((region) => encodeURIComponent(region)).join(',');
      }

      if (search.filter.value === 'commission' && search.param) {
        return encodeURIComponent(search.operator.value + ',' + search.param);
      }

      return encodeURIComponent(search.param);
    });

    const commitQuery = () => {
      state.regionsSearchInput = '';
      emit('query', searchParams.value);
    };

    watch(
      () => search.active,
      (next) => {
        if (next) {
          localStorage.setItem('rs-acp-active', next);
        }
      },
    );

    onMounted(() => {
      const storedActiveState = localStorage.getItem('rs-acp-active');
      search.active = storedActiveState;
      commitQuery();
    });

    return {
      search,
      state,
      onClear,
      filters,
      numericOperators,
      searchParams,
      commitQuery,
      computedActiveLabel,
    };
  },
});
</script>

<style lang="scss">
@use '../scss/colors';
@use '../scss/breakpoints';
.action-bar {
  position: sticky;
  top: 48px;
  left: 0;
  background: var(--bg-1);
  z-index: 4;
  margin: 0 -16px 28px;
  border-bottom: 1px solid var(--bg-3);
  display: flex;
  min-width: 100%;
  padding-bottom: 8px;

  @include breakpoints.down('sm') {
    flex-wrap: wrap;
    margin-bottom: 16px;
    padding-bottom: 8px;
  }
  &--start {
    padding: 8px 0 0 16px;
    width: 100%;
    @include breakpoints.down('xs') {
      flex-wrap: wrap;
    }
    & > div:not(:last-child) {
      padding-right: 8px;
    }
  }
  &--end {
    padding: 8px 16px 0 0;
    flex-shrink: 0;
    @include breakpoints.down('sm') {
      width: 100%;
      disply: flex;
      padding: 8px 16px;
    }
  }
  &--start,
  &--end {
    display: flex;
    align-items: center;
    @include breakpoints.down('xs') {
      width: 100%;
      padding: 8px 16px;
    }
  }
  &__search-by {
    @include breakpoints.down('xs') {
      width: 50%;
    }
  }
  &__search {
    width: 100%;
    display: flex;
    align-items: center;
    @include breakpoints.down('xs') {
      width: 100%;
    }
    & > div {
      margin-right: 6px;
    }
  }
  &__operator-field {
    flex-grow: 1;
    flex-shrink: 2;
  }
  &__search-field,
  &__region-field {
    flex-shrink: 2;
    width: 100%;
  }
  @include breakpoints.down('xs') {
    padding-bottom: 12px;
    position: static;
  }
  @include breakpoints.down('sm') {
    &__filter {
      display: flex;
      width: 33%;
    }
    &__submit {
      display: flex;
      width: 66%;
    }
  }
}

.filters {
  .v-input--radio-group,
  .v-input--selection-controls {
    padding: 8px 16px 0;
    margin-top: 0;
  }
}
</style>
