<template>
  <tr :class="['expanded-table-row', { 'expanded-table-row-denied': isDeniedMatchStatus }]">
    <td>
      <div class="expanded-table-row-image-container d-flex my-4 align-center">
        <quality-control-match-image :src="productImageUrl" @click="onOpenCompareModal" />
      </div>
    </td>
    <td>
      <div class="py-4 fill-height">
        <div class="expanded-table-data-name">
          <div class="expanded-table-data-name-text">{{ productName }}</div>
          <a class="expanded-table-data-link mt-auto" :href="productUrl" target="_blank" @click.stop>
            <span class="expanded-table-data-link-text mr-1">Product URL</span>
            <rs-icon :color="isDeniedMatchStatus ? '#222222' : '#0469e0'" size="20">
              ltkfont-link-in-new-window-outline
            </rs-icon>
          </a>
        </div>
      </div>
    </td>
    <td>
      <div class="my-4">
        <span class="expanded-table-data-text">{{ brandName }}</span>
      </div>
    </td>
    <td>
      <div class="my-4">
        <span class="expanded-table-data-text">{{ retailerName }}</span>
      </div>
    </td>
    <td>
      <div class="my-4">
        <span class="expanded-table-data-text">{{ productDescription }}</span>
      </div>
    </td>
    <td>
      <div class="my-4">
        <span class="expanded-table-data-text">{{ productDetails }}</span>
      </div>
    </td>
    <td>
      <div class="my-4">
        <span class="expanded-table-data-text">{{ productPrice }}</span>
      </div>
    </td>
    <td>
      <div class="my-4">
        <quality-control-match-button :value="matchStatus" @input="updateMatchStatus" />
        <quality-control-match-notes :value="matchNotes" @input="updateMatchNotes" />
      </div>
    </td>
  </tr>
</template>

<script>
import { computed, defineComponent, ref, toRefs } from 'vue';
import useStore from '@/composables/vuex';

import { formatCurrencyExt } from '@/utils/helpers';

import QualityControlMatchButton from './QualityControlMatchButton.vue';
import QualityControlMatchImage from './QualityControlMatchImage.vue';
import QualityControlMatchNotes from './QualityControlMatchNotes.vue';

import { useMatchingProductDetails, useWorkItem } from '../composables/work-items';
import { isMatchDenied } from '../utils/matchStatus';

export default defineComponent({
  components: {
    QualityControlMatchButton,
    QualityControlMatchImage,
    QualityControlMatchNotes,
  },
  props: {
    workItemId: String,
    productId: String,
  },
  emits: ['onOpenCompareModal'],
  setup(props, { emit }) {
    const store = useStore();

    const showCompareModal = ref(false);
    const { workItemId, productId } = toRefs(props);
    const workItem = useWorkItem(workItemId);
    const matchingProduct = useMatchingProductDetails(workItemId, productId);

    const productImageUrl = computed(() => {
      const imageUrls = matchingProduct.value.imageUrls;
      if (imageUrls.length > 0) {
        return imageUrls[0];
      }
      return '';
    });
    const productName = computed(() => matchingProduct.value.title);
    const productUrl = computed(() => matchingProduct.value.productUrl);
    const brandName = computed(() => matchingProduct.value.brand);
    const retailerName = computed(() => matchingProduct.value.retailer);
    const productDescription = computed(() => matchingProduct.value.description);
    const productDetails = computed(() => matchingProduct.value.details);
    const productPrice = computed(() => formatCurrencyExt(matchingProduct.value.price));

    const matchStatus = computed(() => {
      if (!workItem.value) {
        return '';
      }
      return workItem.value.matchingProducts[productId.value].match;
    });
    const matchNotes = computed(() => {
      if (!workItem.value) {
        return '';
      }
      return workItem.value.matchingProducts[productId.value].notes;
    });
    const isDeniedMatchStatus = computed(() => isMatchDenied(matchStatus.value));

    const updateMatchStatus = (value) => {
      store.dispatch('updateMatchingProduct', {
        id: workItemId.value,
        productId: productId.value,
        match: value,
        notes: matchNotes.value,
      });
    };

    const updateMatchNotes = (value) => {
      store.dispatch('updateMatchingProduct', {
        id: workItemId.value,
        productId: productId.value,
        match: matchStatus.value,
        notes: value,
      });
    };

    const onOpenCompareModal = () => {
      emit('onOpenCompareModal');
    };

    return {
      showCompareModal,
      matchStatus,
      matchNotes,
      productImageUrl,
      productName,
      productUrl,
      brandName,
      retailerName,
      productDescription,
      productDetails,
      productPrice,
      isDeniedMatchStatus,
      updateMatchStatus,
      updateMatchNotes,
      onOpenCompareModal,
    };
  },
});
</script>

<style lang="scss" scoped>
.expanded-table-row {
  vertical-align: top;
  border: none;

  &:hover,
  &:focus {
    background: none !important;
  }

  &-image-container {
    margin-left: 48px;
  }

  &:not(:last-of-type) > td {
    border-bottom: 1px solid #ebebeb;
  }

  &-denied .expanded-table-data {
    &-text,
    &-name-text,
    &-image,
    &-link {
      opacity: 0.5;
    }

    &-link-text {
      color: unset;
      text-decoration: underline;
    }
  }
}

.expanded-table-data {
  &-name {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &-text,
  &-name-text {
    -moz-line-clamp: 6;
    -webkit-line-clamp: 6;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    display: -moz-box;
    display: -webkit-box;
    overflow: hidden;
    text-wrap: wrap;
  }

  &-name-text {
    -moz-line-clamp: 5;
    -webkit-line-clamp: 5;
    font-weight: 700;
  }

  &-link {
    display: flex;
    align-items: center;
    text-decoration: none;

    &-text {
      color: #0469e0;
      text-decoration: underline #0469e0;
    }
  }
}
</style>
