<template>
  <rs-card>
    <rs-card-title class="mx-4 mt-4">
      <p class="display-1 mb-0">Review plan:</p>
    </rs-card-title>
    <rs-card-text>
      <rs-card
        class="mx-4 mb-5"
        style="background-color: #f0f0f0; border-radius: 10px"
        :rounded="true"
        :bordered="false"
      >
        <rs-card-title class="pa-0">
          <div class="px-4 pt-4 pb-4 mb-0">
            <ManageRenewalModalRenewalText
              :account-name="account.name"
              :subscription-type="subscriptionType"
              :subscription-renewal-date="subscriptionRenewalDate"
              :subscription-interval-price-text="subscriptionIntervalPriceText"
            />
          </div>
        </rs-card-title>
        <rs-card-text class="px-2 pb-2 pt-0">
          <div>
            <div grid-list-md class="pt-0 px-3 pb-3">
              <rs-layout row wrap class="pb-3">
                <rs-flex xs6>
                  <p class="text-xs-left title font-weight-bold mb-0">Subscription type</p>
                </rs-flex>
                <rs-flex xs6>
                  <p class="text-xs-right body-1 mb-0">{{ subscriptionType }}</p>
                </rs-flex>
              </rs-layout>
              <rs-divider />
              <rs-layout row wrap class="py-3">
                <rs-flex xs6>
                  <p class="text-xs-left title font-weight-bold mb-0">Monthly offer limit</p>
                </rs-flex>
                <rs-flex xs6>
                  <p class="text-xs-right body-1 mb-0">{{ monthlyOfferLimit }}</p>
                </rs-flex>
              </rs-layout>
              <rs-divider />
              <rs-layout row wrap class="py-3">
                <rs-flex xs6>
                  <p class="text-xs-left title font-weight-bold mb-0">Yearly spend limit</p>
                </rs-flex>
                <rs-flex xs6>
                  <p class="text-xs-right body-1 mb-0">{{ yearlySpendLimit | currency }}</p>
                </rs-flex>
              </rs-layout>
              <rs-divider />
              <rs-layout row wrap class="pt-3">
                <rs-flex xs6 class="pb-0">
                  <p class="text-xs-left title font-weight-bold mb-0">Subscription pricing</p>
                </rs-flex>
                <rs-flex xs6 class="pb-0">
                  <p class="text-xs-right body-1 mb-0">{{ subscriptionIntervalPriceText }}</p>
                </rs-flex>
              </rs-layout>
              <rs-layout row wrap class="pb-3">
                <rs-flex xs12 class="pt-0">
                  <p class="text-xs-right mb-0 pt-0 caption" style="color: #525252">*taxes and fees not included</p>
                </rs-flex>
              </rs-layout>
              <rs-divider />
              <rs-layout row wrap class="pt-3">
                <rs-flex xs6>
                  <p class="text-xs-left title font-weight-bold mb-0">Renewal date</p>
                </rs-flex>
                <rs-flex xs6>
                  <p class="text-xs-right body-1 mb-0 pt-1">{{ subscriptionRenewalDate }}</p>
                </rs-flex>
              </rs-layout>
            </div>
          </div>
        </rs-card-text>
      </rs-card>
    </rs-card-text>
    <rs-card-actions class="ma-4">
      <rs-btn
        inverted
        class="mx-2 mb-2"
        :disabled="submitting"
        data-test-id="submit-button"
        @click="
          () => {
            $emit('submitSubscriptionPrice');
          }
        "
      >
        Confirm
      </rs-btn>
      <rs-btn
        class="mx-2 mb-2"
        @click="
          () => {
            $emit('editRenewal');
          }
        "
        >Edit</rs-btn
      >
    </rs-card-actions>
  </rs-card>
</template>

<script>
import ManageRenewalModalRenewalText from './ManageRenewalModalRenewalText.vue';

export default {
  name: 'ManageRenewalModalReviewPlan',
  components: {
    ManageRenewalModalRenewalText,
  },
  props: {
    account: {
      type: Object,
      default: () => {},
    },
    subscriptionType: {
      type: String,
      default: '',
    },
    subscriptionRenewalDate: {
      type: String,
      default: '',
    },
    submitting: {
      type: Boolean,
      default: false,
    },
    subscriptionIntervalPriceText: {
      type: String,
      default: '',
    },
  },
  data() {
    // We are ok with hardcoding these values for now.
    return {
      yearlySpendLimits: {
        Connect: 25000,
        ConnectPro: 100000,
      },
      monthlyOfferLimits: {
        Connect: 10,
        ConnectPro: 50,
      },
    };
  },
  computed: {
    serializedSubscriptionType() {
      return this.subscriptionType.replace(/\s/g, '');
    },
    yearlySpendLimit() {
      return this.yearlySpendLimits[this.serializedSubscriptionType];
    },
    monthlyOfferLimit() {
      return this.monthlyOfferLimits[this.serializedSubscriptionType];
    },
  },
};
</script>
