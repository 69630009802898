<script>
import { watchEffect, onUnmounted, defineComponent } from 'vue';
import { createFocusTrap } from 'focus-trap';

export default defineComponent({
  name: 'RsFocusTrap',
  emits: ['activate', 'deactivate', 'update:active'],
  model: {
    prop: 'active',
    event: 'update:active',
  },
  props: {
    target: {
      type: [HTMLElement, String],
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    escDeactivates: {
      type: Boolean,
      default: true,
    },
    outsideClick: {
      type: Boolean,
      default: false,
    },
    returnFocus: {
      type: Boolean,
      default: true,
    },
    setReturnFocus: {
      type: [HTMLElement, SVGElement, String, Function],
      default: undefined,
    },
    preventScroll: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    let trap = null;

    watchEffect(
      () => {
        if (props.target instanceof HTMLElement) {
          trap = createFocusTrap(props.target, {
            returnFocusOnDeactivate: props.returnFocus,
            clickOutsideDeactivates: props.outsideClick,
            setReturnFocus: props.setReturnFocus,
            escDeactivates: props.escDeactivates,
            preventScroll: props.preventScroll,
            onActivate: () => {
              emit('update:active', true);
              emit('activate', true);
            },
            onDeactivate: () => {
              emit('update:active', false);
              emit('activate', false);
              emit('deactivate', true);
            },
          });
          if (props.active || props.modelValue) {
            trap.activate();
          } else {
            trap.deactivate();
          }
        }
      },
      { flush: 'pre' },
    );

    onUnmounted(() => {
      trap.deactivate();
    });
  },
  render() {
    return this.$slots.default;
  },
});
</script>
