<template>
  <div v-if="profile">
    <rs-avatar size="100%" :tile="true">
      <img :src="`${profile.avatar_url}?w=500`" />
    </rs-avatar>
    <h3 class="headline my-1">{{ profile.full_name }}</h3>
    <h4 class="title my-1">{{ profile.display_name }}</h4>
    <rs-tooltip top>
      <rs-btn class="mx-0" :href="ltkUrl" target="_blank" icon :block="false" :outline="false" slot="activator">
        <rs-icon>rsfont-ltk</rs-icon>
      </rs-btn>
      <span>LTK Profile</span>
    </rs-tooltip>
    <rs-tooltip top>
      <rs-btn class="mx-0" :href="instagramUrl" target="_blank" icon :block="false" :outline="false" slot="activator">
        <rs-icon>rsfont-instagram</rs-icon>
      </rs-btn>
      <span>Instagram Profile</span>
    </rs-tooltip>
    <rs-tooltip top>
      <rs-btn class="mx-0" :href="blogUrl" target="_blank" icon :block="false" :outline="false" slot="activator">
        <rs-icon>rsfont-blog</rs-icon>
      </rs-btn>
      <span>Blog</span>
    </rs-tooltip>
  </div>
</template>

<script>
const { LTK_SPA_URL } = window.rewardStyle;
export default {
  props: { profileId: String },
  watch: {
    profileId: {
      immediate: true,
      async handler() {
        try {
          await this.$store.dispatch('getProfiles', { id: this.profileId });
        } catch (error) {
          this.$root.$emit('onError', error);
        }
      },
    },
  },
  computed: {
    profile() {
      return this.$store.state['ltk-profiles'].profiles[this.profileId];
    },
    ltkUrl() {
      return `${LTK_SPA_URL}/${this.profile.display_name}`;
    },
    instagramUrl() {
      return `https://www.instagram.com/${this.profile.instagram_name}`;
    },
    blogUrl() {
      if (this.profile.blog_url.indexOf('http') !== 0) {
        return 'http://' + this.profile.blog_url;
      }
      return this.profile.blog_url;
    },
  },
};
</script>
