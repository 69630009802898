import { computed, ref } from 'vue';

export const useExpandedWorkItems = (pagination) => {
  // An object that stores the indexes of the expanded work items
  const workItemIndexes = ref({ 0: true });

  const paginationPage = computed(() => pagination.value.page);
  const paginationRowsPerPage = computed(() => pagination.value.rowsPerPage);

  const onToggleWorkItem = (workItemIndex) => {
    const page = paginationPage.value;
    const rowsPerPage = paginationRowsPerPage.value;
    // Calculate the index of the work item in the current page
    const offset = (page - 1) * rowsPerPage;
    const index = offset + workItemIndex;

    // Toggle the expanded status of the work item
    const isExpanded = !!workItemIndexes.value[index];
    workItemIndexes.value = {
      ...workItemIndexes.value,
      [index]: !isExpanded,
    };
  };

  const expandedWorkItemIndexes = computed(() => {
    const currentWorkItemIndexes = {};
    const page = paginationPage.value;
    const rowsPerPage = paginationRowsPerPage.value;
    // Calculate the index of the work item in the current page
    const offset = (page - 1) * rowsPerPage;
    // Get the expanded status of the work items in the current page
    for (let i = 0; i < rowsPerPage; i++) {
      currentWorkItemIndexes[i] = !!workItemIndexes.value[offset + i];
    }
    return currentWorkItemIndexes;
  });

  return {
    expandedWorkItemIndexes,
    onToggleWorkItem,
  };
};
