export const formatCurrency = (amount) => {
  return new Intl.NumberFormat().format(amount);
};

export const formatCurrencyExt = (price, includeCurrencySymbol = true, currency = 'USD', locale = 'en-US') => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const formatted = formatter.format(price);
  return includeCurrencySymbol ? formatted : formatted.slice(1);
};

export const generateMask = (amount, symbol = '') => {
  let mask = formatCurrency(amount)
    .toString()
    .split('')
    .map((item) => {
      return item === ',' ? ',' : '#';
    });
  // push some more numbers at the end so that next numbers can be entered/pasted.
  mask.push('###########');
  return `${symbol}${mask.join('')}`;
};

export const getImageLink = ({ url, width, height, useDefaultDpr = true, crop = true }) => {
  const newUrl = new URL(url);
  const params = newUrl.searchParams;

  params.set('auto', 'format');
  params.set('fm', 'jpg');

  if (width) {
    params.set('w', width);
  }

  if (height) {
    params.set('h', height);
  }

  if (crop) {
    params.set('fit', 'crop');
  }

  params.set('q', '80');

  const maxPixelRatio = 3;
  const defaultPixelRatio = 1;
  const devicePixelRatio = window.devicePixelRatio || defaultPixelRatio;
  const dpr = devicePixelRatio > maxPixelRatio ? maxPixelRatio : devicePixelRatio;
  if (useDefaultDpr) {
    params.set('dpr', dpr);
  }

  newUrl.search = params.toString();
  return newUrl.toString();
};
