<template>
  <rs-autocomplete
    :items="retailerItems"
    v-model="retailerId"
    v-bind="$attrs"
    :loading="isLoading"
    :return-object="false"
    dense
    append-icon=""
  />
</template>

<script>
import { defineComponent, computed } from 'vue';

import useStore from '@/composables/vuex';

import useModelWrapper from '@/composables/model-wrapper';

export default defineComponent({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: {
    'update:modelValue': String,
  },
  setup(props, { emit }) {
    const store = useStore();
    const [retailerId] = useModelWrapper(props, emit, 'modelValue', '');
    const isLoading = computed(() => store.getters.advertisersFetchState().pending);

    const retailerItems = computed(() =>
      store.state['ltk-advertisers'].advertiserIds
        .map((id) => store.state['ltk-advertisers'].advertiserMap[id])
        .map(({ id, name, display }) => ({ value: `${id}`, text: `${display} - (${id}) [${name}]` })),
    );

    return { retailerId, isLoading, retailerItems };
  },
});
</script>
