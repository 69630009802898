import Vue from 'vue';
import profileService from '../../services/ltk-profile-service';

export default {
  state: {
    profileIds: [],
    profiles: {},
  },
  getters: {
    getProfileByDisplayName(state) {
      return (displayName) =>
        Object.values(state.profiles).filter(({ display_name }) => display_name === displayName)[0];
    },
  },
  actions: {
    /**
     * This could also be improved with some debounce logic where many single calls get batched up
     * into a bulk request (id1 + id2 === ids[id1, id2]). This would allow for the client to be more
     * frivolous with fetching single data and therefore make the most optimized call to the service.
     * For now, it's much easier to just eagerly request bulk profiles when we know we fetch bulk data.
     */
    async getProfiles(context, { id = '', ids = [], displayNames = [] }) {
      // Prefer single ID over batches
      if (id !== '' && context.state.profiles[id] !== undefined) {
        return;
      }

      const missingIds = ids.filter((id) => context.state.profiles[id] === undefined);
      const missingDisplayNames = displayNames.filter(
        (displayname) => context.getters.getProfileByDisplayName(displayname) === undefined,
      );

      // Everything already exists in the context, no need to hit the service
      if (id === '' && missingIds.length === 0 && missingDisplayNames.length === 0) {
        return;
      }

      const response = await profileService.get({ id, ids: missingIds, displayNames: missingDisplayNames });
      if (id !== '') {
        return context.commit('SET_PROFILE', response.data.profile);
      }

      const profiles = response.data.profiles.reduce((profileMap, profile) => {
        profileMap[profile.id] = profile;
        return profileMap;
      }, {});
      context.commit('SET_PROFILES', profiles);
    },
    async searchProfiles({ commit, dispatch }, query) {
      const response = await profileService.search(query);
      const ids = response.data.profile_ids;

      /**
       * Load the store with profiles before loading with profile IDs. This will help prevent render
       * errors where the associated profile doesn't yet exist in the store and might cause a bunch of
       * single fetches to the service.
       */
      await dispatch('getProfiles', { ids });

      commit('SET_PROFILE_IDS', ids);
    },
  },
  mutations: {
    SET_PROFILE_IDS(state, profileIds = []) {
      state.profileIds.splice(0, state.profileIds.length, ...profileIds);
    },
    SET_PROFILE(state, profile) {
      Vue.set(state.profiles, profile.id, profile);
    },
    SET_PROFILES(state, profiles) {
      Vue.set(state, 'profiles', Object.assign({}, state.profiles, profiles));
    },
  },
};
