<template>
  <div class="container">
    <div class="social-platform-link" v-if="isValidPlatformUrl">
      <a :href="platformToUrl" target="_blank" :class="linkClasses" data-testid="platform-link">{{ platformUrl }}</a>
      <rs-icon v-if="isVerified" data-testid="verification-check"> rsfont-check-filled_v2 </rs-icon>
    </div>
    <span v-else>
      {{ platformUrl }}
    </span>
  </div>
</template>

<script>
import { isUrl } from '@/utils/is-url';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'SocialPlatformLink',
  props: {
    platformId: {
      type: String,
      required: true,
    },
    platformUrl: {
      type: String,
      required: true,
    },
    platformsMap: {
      type: Object,
      required: true,
    },
    verificationType: {
      type: String,
      required: false,
      default: 'manual',
    },
  },
  setup(props) {
    /**
     * This function detects if the user has provided a value that is not a full URL
     * but contains the platform domain, for example 'instagram.com'.
     *
     * @param {string} userValue
     * @param {string} platformPageTemplate
     * @return {string|boolean} Platform URL with schema attached to make it a nice clean URL
     */
    const isValuePlatformUrl = (userValue, platformPageTemplate) => {
      // Strip schema and www, then split from slash to get username off
      let platformTemplateWithoutSchemaPrefix = platformPageTemplate.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '');
      // Strip any schema or www prefix from value just to be safe
      userValue = userValue.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '');
      const userValueAsUrl = 'https://' + userValue;

      // Platform templates without a domain (blog, other)
      if (!platformTemplateWithoutSchemaPrefix.includes('/')) {
        return isUrl(userValueAsUrl) ? userValueAsUrl : false;
      }

      // Assume we are dealing with something that has a domain
      const platformDomain = platformTemplateWithoutSchemaPrefix.split('/');

      if (userValue.includes(platformDomain[0])) {
        return userValueAsUrl;
      }

      return false;
    };

    const isVerified = computed(() => {
      if (props.verificationType.toLowerCase() === 'verified') {
        return true;
      } else {
        return false;
      }
    });

    const linkClasses = computed(() => {
      return { 'is-verified': isVerified.value };
    });

    const platformToUrl = computed(() => {
      const platformUrl = props.platformUrl;
      const platformsMap = props.platformsMap;
      const platformId = props.platformId;

      // Just return the value if it's a URL
      if (isUrl(platformUrl)) {
        return platformUrl;
      }

      let urlOrUsername = platformUrl.trim();
      const platformData = platformsMap[platformId];

      if (platformData) {
        const { homepage, page_template } = platformData;

        // Check if they provided a value without a schema and compare it to template
        const isPlatformUrl = isValuePlatformUrl(urlOrUsername, homepage + page_template);

        if (isPlatformUrl) {
          return isPlatformUrl;
        }

        // Remove at symbol from handle
        if (urlOrUsername.startsWith('@')) {
          urlOrUsername = urlOrUsername.slice(1);
        }

        // Remove spaces from the username
        urlOrUsername = urlOrUsername.replace(/\s/g, '').toLowerCase();
        return homepage + page_template.replace('%s', urlOrUsername);
      } else {
        // Do some valid checking?
      }

      return urlOrUsername;
    });

    const isValidPlatformUrl = computed(() => isUrl(platformToUrl.value));

    return {
      platformToUrl,
      isValidPlatformUrl,
      isVerified,
      linkClasses,
    };
  },
});
</script>

<style lang="scss" scoped>
.container {
  overflow-wrap: break-word;
}
.social-platform-link {
  .is-verified {
    color: #5d7a79;
  }
  .theme--light.v-icon {
    color: #5d7a79;
    font-size: 16px;
    padding-left: 2px;
  }
}
</style>
