import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

export const initializeDatadog = (applicationId, clientToken, env) => {
  if (applicationId !== '' && clientToken !== '') {
    datadogRum.init({
      applicationId,
      clientToken,
      site: 'datadoghq.com',
      service: `rs-internal-ui-[${env}]`,
      sampleRate: 100,
      sessionReplaySampleRate: 100,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      env,
      allowedTracingOrigins: [/https:\/\/.*tyk-gateway.*\.(rscollaborationsdev|rewardstyle|qashopltk|shopltk)\.com/],
    });
  }
  datadogRum.startSessionReplayRecording();

  datadogLogs.init({
    clientToken,
    site: 'datadoghq.com',
    service: `rs-internal-ui-[${env}]`,
    env,
    sessionSampleRate: 100,
  });
};
