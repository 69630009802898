import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import usePsApi from '@/services/usePsApi.js';

const emptyBubbleFactory = () => ({
  enabled: true,
  country_code: 'us',
  start_date: '',
  end_date: '',
  page: 'v1_bubbles',
  group_label: '',
  feature_flag: null,
  major_version: 1,
  minor_version: 0,
  options_type: 'v1_bubble',
  options: {
    key: '',
    url: '',
    type: 2,
    testTag: 'testTag',
    testDescription: 'Test Description',
    title: '',
  },
  notes: 'Created via Operator Tools',
});

export const useBubblesStore = defineStore('Bubbles Store', () => {
  const bubblesByCountry = ref([]);
  const bubblesByGroup = ref([]);
  const presentationService = usePsApi();

  const getByCountry = async (country) => {
    try {
      const bubbleResponse = await presentationService.getByCountry('v1_bubbles', country);
      bubblesByCountry.value = [];
      bubbleResponse.forEach((bubble) => {
        bubblesByCountry.value.push(bubble);
      });
    } catch (e) {
      console.warn('ERROR: ', e);
    }
  };

  const getByGroup = async (name) => {
    try {
      const bubbleResponse = await presentationService.getByGroup('v1_bubbles', name);
      bubblesByGroup.value = [];
      bubbleResponse.forEach((bubble) => {
        bubblesByGroup.value.push(bubble);
      });
    } catch (e) {
      console.warn('ERROR: ', e);
    }
  };

  const getById = (id, type = 'country') => {
    return computed(() => {
      let bubble;
      if (type === 'country') {
        bubble = bubblesByCountry.value.filter((bubble) => bubble.id === id)[0];
      } else if (type === 'group') {
        bubble = bubblesByGroup.value.filter((bubble) => bubble.id === id)[0];
      }
      return bubble ?? emptyBubbleFactory();
    });
  };

  const create = async ({ group_label, modules }) => {
    try {
      const page = 'v1_bubbles';
      const response = await presentationService.createMany({ group_label, modules, page });
      return response.length;
    } catch (e) {
      console.warn('ERROR: ', e);
    }
  };

  const deleteMany = async (modules) => {
    try {
      const response = await presentationService.deleteMany(modules);
      return response;
    } catch (e) {
      console.warn('ERROR: ', e);
    }
  };

  const editOne = async (update) => {
    try {
      const res = await presentationService.editOne(update);
      return res;
    } catch (e) {
      console.warn('error', e);
    }
  };

  const editMany = async (modules) => {
    try {
      const response = await presentationService.editMany(modules);
      return response;
    } catch (e) {
      console.warn('ERROR: ', e);
    }
  };

  return {
    bubblesByCountry,
    bubblesByGroup,
    create,
    deleteMany,
    editOne,
    editMany,
    getByCountry,
    getByGroup,
    getById,
  };
});
