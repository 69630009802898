<template>
  <transition name="overlay">
    <div :class="['rs--overlay', overlayClasses]" @click="onClick" v-if="active" />
  </transition>
</template>

<script>
import { computed, defineComponent } from 'vue';
export default defineComponent({
  name: 'RsOverlay',
  props: {
    active: Boolean,
    fixed: Boolean,
    blur: Boolean,
    transparent: Boolean,
  },
  emits: ['click'],
  setup(props, { emit }) {
    const overlayClasses = computed(() => {
      return {
        'is--fixed': props.fixed,
        'is--blur': props.blur,
        'is--transparent': props.transparent,
      };
    });

    const onClick = () => {
      emit('click');
    };

    return {
      overlayClasses,
      onClick,
    };
  },
});
</script>

<style lang="scss">
.rs--overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.4);
  transition-property: opacity;
  will-change: opacity;
  opacity: 1;
  transition: 0.3s 0s opacity ease-in-out;
  body > &,
  &.is--fixed {
    position: fixed;
  }
  &.is--blur {
    backdrop-filter: blur(16px);
  }
  &.is--transparent {
    opacity: 0;
  }
}
.overlay-enter {
  transition: 0.1s 0.4s opacity ease-in-out;
}
.overlay-leave {
  transition: 0.1s 0 opacity ease-in-out;
}
.overlay-enter,
.overlay-leave {
  opacity: 0;
}
</style>
