<template>
  <rs-container data-test-id="qa-list-name-accounts">
    <rs-layout><rs-flex class="display-3 font-weight-large">Collaborations</rs-flex></rs-layout>

    <rs-layout>
      <rs-flex mt-2 mb-4>
        <rs-breadcrumbs :items="breadcrumbs" data-test-id="qa-view-offers-breadcrumbs">
          <template #divider>
            <rs-icon slot="divider" size="8"> rsfont-arrow-right </rs-icon>
          </template>
        </rs-breadcrumbs>
      </rs-flex>
    </rs-layout>

    <rs-card>
      <rs-card-text class="pa-0">
        <rs-layout row justify-space-between>
          <rs-flex class="display-2 card-header"> {{ listDetails[listName].title }} </rs-flex>
        </rs-layout>

        <rs-layout
          row
          justify-space-between
          :class="{
            column: $vuetify.breakpoint.xs,
          }"
          mb-3
        >
          <rs-flex mt-1 mx-4 xs4 md4 lg4>
            <rs-text-field hide-details placeholder="Search" v-model="searchTerm" />
          </rs-flex>
          <rs-flex
            xs4
            mx-4
            md3
            lg2
            :class="{
              'mb-3': $vuetify.breakpoint.xs,
            }"
            v-if="isAddDeleteEnabled"
          >
            <rs-btn @click="isAddModalOpen = true"> Add Creator </rs-btn>
          </rs-flex>
        </rs-layout>

        <rs-data-table class="card-table" :headers="headers" :items="searchListNameAccounts[0]" :loading="loading">
          <template #items="props">
            <tr data-test-id="qa-list-item">
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.id }}</td>
              <td class="text-xs-right px-2" v-if="isAddDeleteEnabled">
                <rs-btn class="pa-0" flat icon :block="false" @click="handleDeleteItem(props.item.id, props.item.name)">
                  <rs-icon @click="handleDeleteItem(props.item.id)">ltkfont-delete-outline</rs-icon>
                </rs-btn>
              </td>
            </tr>
          </template>
        </rs-data-table>
      </rs-card-text>
    </rs-card>
    <add-creator-modal
      v-if="isAddModalOpen"
      :is-open="isAddModalOpen"
      :list-name="listName"
      @close="onClose"
      :failed-list-name-accounts="searchListNameAccounts[1]"
    />
    <delete-creator-modal
      v-if="isDeleteModalOpen"
      :is-open="isDeleteModalOpen"
      :list-name="listName"
      :account-id="selectedAccountID"
      :account-name="selectedAccountName"
      @close="isDeleteModalOpen = false"
    />
  </rs-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AddCreatorModal from '../components/AddCreatorModal.vue';
import { listDetails } from '../utils/lists';
import DeleteCreatorModal from '../components/DeleteCreatorModal.vue';

export default {
  name: 'CastingDetail',
  components: {
    AddCreatorModal,
    DeleteCreatorModal,
  },
  props: {
    listName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: 'Creator', sortable: false, value: 'creator', width: '500px' },
        { text: 'Account ID', sortable: false, value: 'accountID', width: '900px' },
      ],
      breadcrumbs: [
        {
          text: 'Casting Lists',
          disabled: false,
          href: '/collabs/casting',
        },
        {
          text: listDetails[this.listName].title,
          disabled: true,
        },
      ],
      loading: true,
      isAddModalOpen: false,
      isDeleteModalOpen: false,
      selectedAccountID: null,
      selectedAccountName: '',
      searchTerm: '',
    };
  },
  async mounted() {
    if (this.isAddDeleteEnabled) {
      this.headers.push({ text: 'Actions', sortable: false, align: 'right', width: '100px' });
    }
    try {
      await this.fetchListNameAccounts(this.listName);
      this.loading = false;
    } catch (e) {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions('casting', ['fetchListNameAccounts']),
    handleDeleteItem(id, name) {
      this.selectedAccountID = id;
      this.selectedAccountName = name;
      this.isDeleteModalOpen = true;
    },
    onClose() {
      if (this.searchListNameAccounts.length >= 2) {
        this.searchListNameAccounts[1] = [];
      }
      this.isAddModalOpen = false;
    },
  },
  computed: {
    ...mapGetters('casting', {
      listNameAccounts: 'getListNameAccounts',
    }),
    listDetails() {
      return listDetails;
    },
    isAddDeleteEnabled() {
      return this.apptimize?.isFeatureFlagEnabled('show_add_delete_list_management');
    },
    searchListNameAccounts() {
      if (this.searchTerm.length < 3) {
        return [this.listNameAccounts.success, this.listNameAccounts.failed];
      }
      const results = this.listNameAccounts.success.reduce((found, curr) => {
        let numOfMatch = 0;
        for (let prop in curr) {
          if (curr[prop].toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1) {
            numOfMatch++;
          }
        }
        if (numOfMatch >= 1) {
          found.push(curr);
        }
        return found;
      }, []);
      return [results, this.listNameAccounts.failed];
    },
  },
};
</script>

<style lang="sass" scoped>
.card-header
  margin: 32px 24px 8px 24px

.card-table
  padding: 0px 24px
  border-top: 1px solid #ebebeb
</style>
