<template>
  <rs-card>
    <rs-layout column>
      <rs-flex pa-4>
        <rs-layout
          row
          :class="{
            'align-start': $vuetify.breakpoint.xs,
            'align-center': $vuetify.breakpoint.smAndUp,
          }"
        >
          <rs-icon class="warning-icon" :size="$vuetify.breakpoint.smAndUp ? '20px' : '18px'" color="#B50404">{{
            warningIcon
          }}</rs-icon>
          <rs-card-title>
            {{ headerText }}
          </rs-card-title>
          <rs-flex text-xs-right>
            <rs-icon
              :size="$vuetify.breakpoint.smAndUp ? '24px' : '20px'"
              @click="$emit('confirmation-dialog-cancel')"
              >{{ closeIcon }}</rs-icon
            >
          </rs-flex>
        </rs-layout>
      </rs-flex>
      <rs-divider></rs-divider>
      <rs-flex pa-4>
        <rs-card-text>
          {{ bodyText1 }}
        </rs-card-text>
        <rs-card-text v-if="bodyText2">
          {{ bodyText2 }}
        </rs-card-text>
      </rs-flex>
      <rs-divider></rs-divider>
      <rs-flex pa-4>
        <rs-card-actions>
          <rs-layout row wrap>
            <rs-spacer></rs-spacer>
            <rs-flex xs12 sm3 order-xs2 order-sm1>
              <rs-btn font-medium @click="$emit('confirmation-dialog-cancel')">
                {{ cancelButtonText }}
              </rs-btn>
            </rs-flex>
            <rs-flex xs12 sm3 order-xs1 order-sm2 :ml-4="$vuetify.breakpoint.smAndUp">
              <rs-btn
                font-medium
                inverted
                :disabled="confirmButtonDisabled"
                :loading="confirmButtonLoading"
                @click="$emit('confirmation-dialog-confirm')"
              >
                <rs-icon v-if="confirmButtonSuccess" class="font-medium">rsfont-check-line_v2</rs-icon>
                {{ !confirmButtonSuccess && visible ? confirmButtonText : '' }}
              </rs-btn>
            </rs-flex>
          </rs-layout>
        </rs-card-actions>
      </rs-flex>
    </rs-layout>
  </rs-card>
</template>

<script>
export default {
  name: 'ConfirmationDialogContent',
  props: {
    headerText: {
      type: String,
      required: true,
    },
    bodyText1: {
      type: String,
      required: true,
    },
    bodyText2: {
      type: String,
      default: () => '',
    },
    confirmButtonDisabled: {
      type: Boolean,
      default: () => false,
    },
    confirmButtonLoading: {
      type: Boolean,
      default: () => false,
    },
    confirmButtonSuccess: {
      type: Boolean,
      default: () => false,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    confirmButtonText: {
      type: String,
      required: true,
    },
    cancelButtonText: {
      type: String,
      required: true,
    },
    warningIcon: {
      type: String,
      default: () => 'rsfont-alert-line_v2',
    },
    closeIcon: {
      type: String,
      default: () => 'rsfont-close_v2',
    },
  },
};
</script>

<style lang="sass" scoped>
.v-card__title
  font-size: 20px
  font-weight: 500
  padding: 0px
  @media (max-width: 600px)
    font-size: 16px
.warning-icon
  padding-right: 10px
.v-card__text
  padding: 0px
  font-size: 14px
  line-height: 30px
.v-card__actions
  padding: 0px
.v-btn
  @media (min-width: 960px)
    max-width: 175px
    margin-top: 0px
  @media (max-width: 960px)
    margin-top: 16px
</style>
