<template>
  <rs-dialog value="true" persistent max-width="650px" :fullscreen="$vuetify.breakpoint.xsOnly">
    <rs-card>
      <rs-card-title class="ma-2">
        <h3 class="headline my-0">Manage renewal</h3>
        <rs-spacer />
        <rs-btn borderless icon :block="false" class="close-delete-dialog-btn pa-0 ma-0">
          <rs-icon
            color="#808080"
            @click="
              () => {
                $emit('close');
              }
            "
            >rsfont-close_v2</rs-icon
          >
        </rs-btn>
      </rs-card-title>
      <rs-divider />
      <ManageRenewalModalPriceForm
        v-if="!showConfirmModal"
        :account="account"
        :available-subscription-types="availableSubscriptionTypes"
        :subscription-type="subscriptionType"
        :currency="currency"
        :msrp-price-dollars="msrpPriceDollars"
        :subscription-price-dollars="subscriptionPriceDollars"
        :subscription-billing-interval="billingInterval"
        :subscription-interval-price-text="subscriptionIntervalPriceText"
        :subscription-renewal-date="subscriptionRenewalDate"
        :next-price-dollars="nextPriceDollars"
        :subscription-price-error="createPreferenceError"
        @subscriptionPriceUpdated="updateSubscriptionPrice"
        @openReviewPriceModal="showConfirmModal = true"
        @close="$emit('close')"
      />
      <ManageRenewalModalReviewPlan
        v-if="showConfirmModal"
        :account="account"
        :subscription-type="subscriptionType"
        :subscription-interval-price-text="subscriptionIntervalPriceText"
        :subscription-renewal-date="subscriptionRenewalDate"
        :submitting="createPreferenceIsLoading"
        @close="$emit('close')"
        @editRenewal="showConfirmModal = false"
        @submitSubscriptionPrice="saveSubscriptionPrice"
      />
    </rs-card>
  </rs-dialog>
</template>

<script>
import { formatCurrencyExt } from '@/utils/helpers';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import ManageRenewalModalPriceForm from './ManageRenewalModalPriceForm.vue';
import ManageRenewalModalReviewPlan from './ManageRenewalModalReviewPlan.vue';

export default {
  name: 'ManageRenewalModal',
  components: {
    ManageRenewalModalPriceForm,
    ManageRenewalModalReviewPlan,
  },
  props: {
    account: {
      type: Object,
      default: () => {},
    },
    billingInterval: {
      type: String,
      default: '',
    },
    subscriptionType: {
      type: String,
      default: '',
    },
    subscriptionRenewalDate: {
      type: String,
      default: '',
    },
    nextPriceDollars: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      subscriptionPriceDollars: 0,
      showConfirmModal: false,
    };
  },
  async mounted() {
    await this.fetchSubscriptions(this.account.id);
    this.subscriptionPriceDollars = this.nextPriceDollars;
  },
  computed: {
    ...mapGetters('billing', [
      'getCurrentPlan',
      'getSubscription',
      'getCreatePreferenceIsLoading',
      'getCreatePreferenceError',
    ]),
    availableSubscriptionTypes() {
      return this.getSubscription.filter((product) => product.price !== null && product.display_product);
    },
    currentProduct() {
      const product = this.availableSubscriptionTypes.find((s) => {
        return this.getCurrentPlan()?.product?.platform_role_id === s.platform_role_id;
      });
      if (!product) throw new Error('product not found');
      return product;
    },
    currency() {
      return this.currentProduct.price.currency === 'usd' ? '$' : '';
    },
    msrpPriceDollars() {
      return Math.ceil(this.currentProduct.price.annual_retail_price_cents / 100);
    },
    subscriptionIntervalPriceText() {
      const intervalPrice = formatCurrencyExt(this.getIntervalPriceForAnnualPrice(this.subscriptionPriceDollars));
      switch (this.billingInterval) {
        case 'Quarter':
          return `${intervalPrice} per quarter`;
        case 'Bi-Annual':
          return `${intervalPrice} every six months`;
        default:
          return `${intervalPrice} per year`;
      }
    },
    createPreferenceIsLoading() {
      return this.getCreatePreferenceIsLoading;
    },
    createPreferenceError() {
      const rawError = this.getCreatePreferenceError;
      if (!rawError) return null;
      const { min, max } = rawError;
      if (!min || !max) return 'An error occurred. Please try a different value.';
      const minLimitUSD = formatCurrencyExt(Math.round(min / 100), true, 'usd');
      const maxLimitUSD = formatCurrencyExt(Math.round(max / 100), true, 'usd');
      return `Amount must be between ${minLimitUSD} and ${maxLimitUSD}`;
    },
  },
  watch: {
    createPreferenceIsLoading(isLoading) {
      if (isLoading) return;
      if (this.createPreferenceError !== null) {
        this.showConfirmModal = false;
      } else {
        this.$emit('close');
      }
    },
  },
  methods: {
    ...mapActions('billing', ['fetchSubscriptions', 'createUserPreference']),
    getAnnualPriceForInterval(price) {
      switch (this.billingInterval) {
        case 'Quarter':
          return price * 4;
        case 'Bi-Annual':
          return price * 2;
        default:
          return price;
      }
    },
    getIntervalPriceForAnnualPrice(price) {
      switch (this.billingInterval) {
        case 'Quarter':
          return Math.ceil(price / 4);
        case 'Bi-Annual':
          return Math.ceil(price / 2);
        default:
          return price;
      }
    },
    saveSubscriptionPrice() {
      const annual_price_cents = this.subscriptionPriceDollars * 100;
      const type = 'custom_pricing';
      const payload = {
        account_id: this.account.id,
        type,
        value: { [this.currentProduct.id]: { annual_price_cents } },
      };

      this.createUserPreference(payload);
    },
    updateSubscriptionPrice(priceDollars) {
      const parsedPrice = parseInt(priceDollars, 10);
      this.subscriptionPriceDollars = isNaN(parsedPrice) ? 0 : parsedPrice;
    },
  },
};
</script>
