<template>
  <rs-dialog content-class="quality-control-compare-modal-dialog" lazy :value="active" @input="onInput">
    <div class="quality-control-compare-modal-container">
      <div class="quality-control-compare-modal-header">
        <rs-button class="quality-control-compare-modal-close-btn" size="sm" icon-only @click="onClose">
          <rs-icon :size="16" color="black">ltkfont-x-outline</rs-icon>
        </rs-button>
      </div>
      <template v-if="active">
        <div class="quality-control-compare-modal-actions">
          <div class="quality-control-compare-modal-actions-pagination">
            <rs-button
              class="quality-control-compare-modal-actions-pagination-button ma-0"
              :disabled="!previousProduct"
              size="sm"
              @click="onPrevious"
            >
              <rs-icon :size="16" color="black">ltkfont-left-chevron-outline</rs-icon>
              <span class="quality-control-compare-modal-actions-pagination-text ml-2">Previous</span>
            </rs-button>
            <rs-button
              class="quality-control-compare-modal-actions-pagination-button ma-0"
              :disabled="!nextProduct"
              size="sm"
              @click="onNext"
            >
              <span class="quality-control-compare-modal-actions-pagination-text mr-2">Next</span>
              <rs-icon :size="16" color="black">ltkfont-right-chevron-outline</rs-icon>
            </rs-button>
          </div>
          <div class="quality-control-compare-modal-actions-container">
            <div class="align-self-end">
              <div class="mb-3">Product {{ completionStatus }}</div>
              <rs-button
                class="quality-control-compare-modal-actions-complete-button"
                primary
                :disabled="isSaveButtonDisabled"
                :loading="isSaveButtonLoading"
                @click.stop="onMarkCompleted"
              >
                <span> Mark as complete </span>
              </rs-button>
            </div>
            <div class="qquality-control-compare-modal-actions-container-match">
              <quality-control-compare-modal-product-notes
                :match-notes="matchNotes"
                :match-status="matchStatus"
                :product-id="matchingProduct.id"
                :work-item-id="workItemId"
              />
            </div>
          </div>
        </div>
        <div class="quality-control-compare-modal-content">
          <quality-control-compare-product type="source" :product="primaryProduct" />
          <quality-control-compare-product :product="matchingProduct" :work-item-id="workItemId" />
        </div>
      </template>
    </div>
  </rs-dialog>
</template>

<script>
import { computed, defineComponent, toRefs } from 'vue';
import useStore from '@/composables/vuex';

import RsButton from '@/internal-advertisers/lib/components/RsButton/RsButton.vue';

import { WORK_ITEM_STATUS } from '../constants';
import {
  useMatchingProductDetails,
  usePrimaryProductDetails,
  useWorkItem,
  useWorkItemProgress,
} from '../composables/work-items';

import QualityControlCompareProduct from './QualityControlCompareProduct.vue';
import QualityControlCompareModalProductNotes from './QualityControlCompareModalProductNotes.vue';

export default defineComponent({
  components: {
    RsButton,
    QualityControlCompareProduct,
    QualityControlCompareModalProductNotes,
  },
  emits: ['onClose', 'onChangeProductIndex', 'onCompleteWorkItem'],
  props: {
    workItemId: String,
    productId: String,
    active: Boolean,
  },
  setup(props, { emit }) {
    const store = useStore();

    const { workItemId, productId } = toRefs(props);

    const workItem = useWorkItem(workItemId);
    const primaryProduct = usePrimaryProductDetails(workItemId);
    const matchingProduct = useMatchingProductDetails(workItemId, productId);
    const { progressCount, totalCount } = useWorkItemProgress(workItemId);

    const isCompletedTab = computed(() => store.getters.currentSelectedTab === WORK_ITEM_STATUS.COMPLETE);
    const currentIndex = computed(() => {
      if (!workItem.value) {
        return -1;
      }
      return Object.keys(workItem.value.matchingProducts).findIndex((id) => id === productId.value);
    });
    const previousProduct = computed(() => {
      if (currentIndex.value < 0) {
        return;
      }
      const previousProductId = Object.keys(workItem.value.matchingProducts)[currentIndex.value - 1];
      if (!previousProductId) {
        return;
      }
      return workItem.value.matchingProducts[previousProductId]?.product;
    });
    const nextProduct = computed(() => {
      if (currentIndex.value < 0) {
        return;
      }
      const nextProductId = Object.keys(workItem.value.matchingProducts)[currentIndex.value + 1];
      if (!nextProductId) {
        return;
      }
      return workItem.value.matchingProducts[nextProductId]?.product;
    });
    const matchStatus = computed(() => {
      if (!workItem.value) {
        return '';
      }
      return workItem.value.matchingProducts[productId.value]?.match || '';
    });
    const matchNotes = computed(() => {
      if (!workItem.value) {
        return '';
      }
      return workItem.value.matchingProducts[productId.value]?.notes || '';
    });
    const completionStatus = computed(() => {
      return `${currentIndex.value + 1}/${totalCount.value}`;
    });
    const isSaveButtonLoading = computed(() => store.getters.isSavingWorkItem(workItemId.value));
    const isSaveButtonDisabled = computed(() => {
      if (isCompletedTab.value) {
        return !store.getters.isWorkItemPendingSave(workItemId.value);
      }
      return progressCount.value < totalCount.value;
    });

    const onClose = () => {
      emit('onClose', false);
    };
    const onInput = (newValue) => {
      if (!newValue) {
        onClose();
      }
    };
    const onPrevious = () => {
      if (previousProduct.value) {
        emit('onChangeProductIndex', currentIndex.value - 1);
      }
    };
    const onNext = () => {
      if (nextProduct.value) {
        emit('onChangeProductIndex', currentIndex.value + 1);
      }
    };
    const onMarkCompleted = () => {
      emit('onCompleteWorkItem', workItemId.value);
    };

    return {
      primaryProduct,
      matchingProduct,
      previousProduct,
      nextProduct,
      matchStatus,
      matchNotes,
      completionStatus,
      isSaveButtonLoading,
      isSaveButtonDisabled,
      onClose,
      onInput,
      onPrevious,
      onNext,
      onMarkCompleted,
    };
  },
});
</script>

<style lang="scss" scoped>
.quality-control-compare-modal {
  &-container {
    background: #ffffff;
    border-radius: 24px;
    overflow: hidden;
    position: relative;
  }

  &-close-btn {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 64px;
    padding: 0 0;

    &-progress {
      display: flex;
      align-items: center;
      padding: 0 0;
    }
  }

  &-actions {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    &-pagination {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 24px;
      margin-bottom: 4px;

      &-button {
        border: none;
      }

      &-text {
        text-transform: none;
        letter-spacing: -0.005em !important;
        font-weight: 400;
        line-height: 24px;
        font-size: 12px;
      }
    }

    &-complete-button {
      width: 280px;
      max-width: 100%;
    }

    &-container {
      flex-grow: 1;
      display: flex;
      gap: 16px;
      margin: 0 24px;
      padding: 24px;
      border: 1px solid #f0f0f0;
      background: #fafafa;
      border-radius: 12px;

      > div {
        flex: 1;
      }

      &-match {
        width: 200px;
        font-size: 12px;

        .v-select__selections {
          font-size: 12px;
        }
      }
    }
  }

  &-content {
    display: flex;
    padding: 0 24px 16px;
    gap: 16px;
  }
}
</style>

<style lang="scss">
.quality-control-compare-modal-dialog.v-dialog {
  border-radius: 24px;
  width: 100%;

  @media only screen and (min-width: 960px) {
    width: 872px;
  }

  @media only screen and (min-width: 1904px) {
    width: 1264px;
  }
}
</style>
