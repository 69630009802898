<template>
  <rs-form ref="form" @submit.prevent>
    <rs-container>
      <rs-layout row wrap align-center>
        <rs-flex xs6>
          <rs-text-field
            label="Sale Name *"
            required
            :value="sale.promo_title"
            :rules="promoTitleRules"
            @input="updateField('promo_title', $event)"
          />
        </rs-flex>
        <rs-flex xs3>
          <rs-autocomplete
            :value="sale.country_code"
            :items="updatedCountries"
            label="Country (Optional)"
            item-text="label"
            item-value="code"
            @input="updateField('country_code', $event)"
          />
        </rs-flex>
        <rs-flex xs3>
          <rs-autocomplete
            :value="sale.time_zone"
            :items="timeZones"
            :required="isTimeZoneRequired"
            :label="`Time Zone${isTimeZoneRequired ? ' *' : ' (Optional)'}`"
            :rules="timeZoneRules"
            @input="updateField('time_zone', $event)"
          />
        </rs-flex>
        <rs-flex xs6>
          <rs-text-field
            label="Post Badge Text *"
            required
            :value="sale.badge_text"
            :rules="badgeTextRules"
            :maxlength="badgeTextMaxLength"
            @input="updateField('badge_text', $event)"
          />
        </rs-flex>
        <rs-flex xs6>
          <date-range-input
            :start-date="sale.start_date"
            :end-date="sale.end_date"
            @update:startDate="updateField('start_date', $event)"
            @update:endDate="updateField('end_date', $event)"
          />
        </rs-flex>
      </rs-layout>
    </rs-container>

    <retailer-table
      :model-value="modelValue.retailer_details"
      :value="modelValue.retailer_details"
      @update:modelValue="updateField('retailer_details', $event)"
      :rules="retailerDetailsRules"
    />
  </rs-form>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';

import useModelWrapper from '@/composables/model-wrapper';
import { makeRequired, makeMinLength, makeMaxLength } from '@/utils/form-validation';
import moment from 'moment-timezone';

import RetailerTable from './RetailerTable.vue';
import DateRangeInput from './DateRangeInput.vue';
import countries from '@/internal-hashtags/fixtures/countries.json';

const saleTitleMaxLength = 256;
const badgeTextMaxLength = 25;

export default defineComponent({
  components: {
    RetailerTable,
    DateRangeInput,
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: Object,
  },
  setup(props, { emit }) {
    const form = ref(null);
    const [sale] = useModelWrapper(props, emit);

    function updateField(fieldName, newValue) {
      const hasCountryCodeChanged =
        fieldName === 'country_code' && sale.value.country_code && sale.value.country_code !== newValue;
      const newSale = Object.assign({}, sale.value, {
        [fieldName]: newValue,
        // Reset the time_zone field when the country_code field changes
        ...(hasCountryCodeChanged && { time_zone: undefined }),
      });

      sale.value = newSale;
    }

    const promoTitleRules = [
      makeRequired('Sale name is required'),
      makeMaxLength(saleTitleMaxLength, `Sale Name must not be more than ${saleTitleMaxLength} characters`),
    ];
    const badgeTextRules = [
      makeRequired('Badge text is required'),
      makeMaxLength(badgeTextMaxLength, `Badge Text must not be more than ${badgeTextMaxLength} characters`),
    ];

    const retailerDetailsRules = [
      () => makeMinLength(1, 'At least 1 retailer must be in the sale')(props.modelValue.retailer_details),
    ];

    const isTimeZoneRequired = computed(() => sale.value.country_code && sale.value.country_code !== 'us');

    const timeZones = computed(() => {
      if (!sale.value.country_code) {
        return moment.tz.zonesForCountry('us');
      }
      return moment.tz.zonesForCountry(sale.value.country_code);
    });

    const timeZoneRules = computed(() => {
      if (isTimeZoneRequired.value) {
        return [
          makeRequired('Time Zone is required'),
          (value) => timeZones.value.includes(value) || 'Time Zone is required',
        ];
      }
      return [];
    });

    /*
      Business logic has the UK country code as "UK" instead of "GB"
        but for backend validation we need the actual 2 letter country code.
    */
    const updatedCountries = computed(() => {
      const parsedCountries = JSON.parse(JSON.stringify(countries));
      const ukIndex = parsedCountries.findIndex((country) => country.code === 'uk');
      if (ukIndex !== -1) {
        parsedCountries[ukIndex].code = 'gb';
      }
      return parsedCountries;
    });

    return {
      updatedCountries,
      timeZones,
      sale,
      form,
      saleTitleMaxLength,
      badgeTextMaxLength,
      promoTitleRules,
      badgeTextRules,
      timeZoneRules,
      isTimeZoneRequired,
      updateField,
      retailerDetailsRules,
    };
  },
});
</script>
