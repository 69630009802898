<template>
  <div class="rs--modal__header">
    <div class="rs--modal__header-content" v-if="title || subtitle">
      <h3 class="rs--modal__title header-3" v-if="title">
        {{ title }}
      </h3>
      <p class="rs--modal__subtitle header-5" v-if="subtitle">
        {{ subtitle }}
      </p>
    </div>
    <rs-icon @click="onClick" tabindex="0"> close </rs-icon>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RsModalHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const onClick = () => {
      emit('close');
    };
    return { onClick };
  },
});
</script>

<style lang="scss">
@import '../../../scss/colors';
.rs--modal {
  &__header {
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
    background-color: var(--bg);
    border-radius: 3px 3px 0 0;
    flex-shrink: 0;
    border-bottom: 1px solid var(--divider);
  }
  &__title {
    font-size: 20px;
    line-height: 24px;
    margin: 0;
    font-weight: 500;
    padding: 0;
  }
  &__subtitle {
    font-size: 16px;
    color: var(--text-2);
    margin: 0;
    padding: 0;
    font-weight: 300;
  }
}
</style>
