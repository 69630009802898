// Simple alphabetical sorting
export const alphabetical = (a, b) => (a.name > b.name ? 1 : -1);

/**
 * Complicated Hierarchy Sorting.
 *
 * - Root level elements have no parent ID property
 * - Children of the root will have a parent ID equal to another ID in the list
 * - Each level will be sorted alphabetically
 *
 * interface HierarchyItem {
 *   id: String;
 *   name: String;
 *   parent?: String;
 *   children?: HierarchyItem[];
 * }
 *
 * @returns HierarchyItem[]
 */
export const hierarchy = (flatList) => {
  const listClone = JSON.parse(JSON.stringify(flatList));
  // mutate our clone so that references to items all go into a flat list of children references.
  listClone.forEach((item, index, list) => {
    // Root items have no action
    if (item.parent === undefined) {
      return;
    }

    const parent = list.find(({ id }) => id === item.parent);
    if (parent === undefined) {
      return;
    }

    // Create children list if it doesn't currently exist
    if (parent.children === undefined) {
      parent.children = [];
    }
    // Add children to the parent
    parent.children.push(item);
  });
  // Sort the children alphabetically
  listClone.forEach((item) => {
    if (item.children === undefined) {
      return;
    }
    item.children.sort(alphabetical);
  });

  return listClone.filter(({ parent }) => parent === undefined).sort(alphabetical);
};

// Reducer function to convert a list of items with an id property to a map
export const listToMap = (map, item) => {
  map[item.id] = item;
  return map;
};
