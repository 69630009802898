<template>
  <rs-container>
    <Loader v-if="loading" />
    <rs-alert v-if="requiresProfile" :value="requiresProfile" type="warning" color="#FEE7C2" class="black--text">
      <b>Warning:</b> This brand does not have a brand profile. You may create a default profile by clicking "Create
      Default Profile".
    </rs-alert>
    <!-- Page header -->
    <rs-flex mt-5 mb-3 column>
      <h2 class="display-3 ma-0">{{ account.name }}&nbsp;</h2>
      <rs-breadcrumbs class="px-0" :items="breadcrumbs" divider=">">
        <rs-breadcrumbs-item slot="item" slot-scope="{ item }" :to="{ name: item.name }">{{
          item.meta.title
        }}</rs-breadcrumbs-item>
      </rs-breadcrumbs>
    </rs-flex>
    <rs-btn
      v-if="requiresProfile"
      class="create-profile-btn ml-0 mb-3"
      :block="false"
      :outline="false"
      :flat="false"
      large
      small
      inverted
      @click="createDefaultBrandProfile"
      data-test-id="create-brand-profile-button"
    >
      <b>Create Default Profile</b>
    </rs-btn>

    <!-- About section -->
    <rs-card class="mb-4">
      <rs-card-title class="pa-4">
        <h3 class="headline font-weight-medium ma-0">
          <rs-icon class="mr-2">business</rs-icon>
          Company information
        </h3>
        <rs-spacer />
        <rs-btn
          small
          flat
          :block="false"
          :disabled="requiresProfile"
          @click="showEditAccountModal = true"
          data-test-id="account-management-view-edit-account-button"
        >
          <rs-icon size="19">rsfont-edit_v2</rs-icon>
          <div class="mx-2">Edit Company Information</div>
        </rs-btn>
      </rs-card-title>
      <rs-divider />
      <rs-card-text class="py-0">
        <rs-list two-line class="layout row wrap">
          <rs-flex xs6 px-4 mb-2>
            <rs-list-tile>
              <rs-list-tile-action>
                <rs-icon>business</rs-icon>
              </rs-list-tile-action>
              <rs-list-tile-content>
                <rs-list-tile-sub-title>Brand name</rs-list-tile-sub-title>
                <rs-list-tile-title>{{ account.name }}</rs-list-tile-title>
              </rs-list-tile-content>
            </rs-list-tile>
            <rs-divider></rs-divider>
          </rs-flex>

          <rs-flex xs6 px-4 mb-2>
            <rs-list-tile>
              <rs-list-tile-action>
                <rs-icon class="material-icons-outlined">rsfont-location</rs-icon>
              </rs-list-tile-action>
              <rs-list-tile-content>
                <rs-list-tile-sub-title>Location</rs-list-tile-sub-title>
                <rs-list-tile-title v-if="locationString">{{ locationString }}</rs-list-tile-title>
              </rs-list-tile-content>
            </rs-list-tile>
            <rs-divider></rs-divider>
          </rs-flex>

          <rs-flex xs6 px-4 mb-2>
            <rs-list-tile>
              <rs-list-tile-action>
                <rs-icon>language</rs-icon>
              </rs-list-tile-action>
              <rs-list-tile-content>
                <rs-list-tile-sub-title>Language</rs-list-tile-sub-title>
                <rs-list-tile-title>{{ getLanguage(account.preferred_locale) }}</rs-list-tile-title>
              </rs-list-tile-content>
            </rs-list-tile>
            <rs-divider></rs-divider>
          </rs-flex>

          <rs-flex xs6 px-4 mb-2>
            <rs-list-tile>
              <rs-list-tile-action>
                <rs-icon>rsfont-link_v2</rs-icon>
              </rs-list-tile-action>
              <rs-list-tile-content>
                <rs-list-tile-sub-title>Website</rs-list-tile-sub-title>
                <rs-list-tile-title>{{ profile.website_url }}</rs-list-tile-title>
              </rs-list-tile-content>
            </rs-list-tile>
            <rs-divider></rs-divider>
          </rs-flex>

          <rs-flex xs6 px-4 mb-2>
            <rs-list-tile>
              <rs-list-tile-action>
                <rs-icon>desktop_mac</rs-icon>
              </rs-list-tile-action>
              <rs-list-tile-content>
                <rs-list-tile-sub-title>Account ID</rs-list-tile-sub-title>
                <rs-list-tile-title>{{ account.id }}</rs-list-tile-title>
              </rs-list-tile-content>
            </rs-list-tile>
            <rs-divider></rs-divider>
          </rs-flex>

          <rs-flex xs6 px-4 mb-2>
            <rs-list-tile>
              <rs-list-tile-action>
                <rs-icon>desktop_mac</rs-icon>
              </rs-list-tile-action>
              <rs-list-tile-content>
                <rs-list-tile-sub-title>Hubspot company ID</rs-list-tile-sub-title>
                <rs-list-tile-title>{{ account.hubspot_company_id || '--' }}</rs-list-tile-title>
              </rs-list-tile-content>
            </rs-list-tile>
            <rs-divider></rs-divider>
          </rs-flex>

          <rs-flex xs6 px-4 mb-2>
            <rs-list-tile>
              <rs-list-tile-action>
                <rs-icon>desktop_mac</rs-icon>
              </rs-list-tile-action>
              <rs-list-tile-content>
                <rs-list-tile-sub-title>Netsuite ID</rs-list-tile-sub-title>
                <rs-list-tile-title>{{ account.netsuite_entity_id || '--' }}</rs-list-tile-title>
              </rs-list-tile-content>
            </rs-list-tile>
            <rs-divider></rs-divider>
          </rs-flex>

          <rs-flex xs6 px-4 mb-2>
            <rs-list-tile>
              <rs-list-tile-action>
                <rs-icon class="material-icons-outlined">rsfont-user-profile</rs-icon>
              </rs-list-tile-action>
              <rs-list-tile-content>
                <rs-list-tile-sub-title>Account type</rs-list-tile-sub-title>
                <rs-list-tile-title>{{ formattedAccountType }}</rs-list-tile-title>
              </rs-list-tile-content>
            </rs-list-tile>
            <rs-divider></rs-divider>
          </rs-flex>

          <rs-flex xs6 px-4 mb-2>
            <rs-list-tile>
              <rs-list-tile-action>
                <rs-icon class="material-icons-outlined">rsfont-check-line_v2</rs-icon>
              </rs-list-tile-action>
              <rs-list-tile-content>
                <rs-list-tile-sub-title>Account state</rs-list-tile-sub-title>
                <rs-list-tile-title style="text-transform: capitalize">{{ account.state }}</rs-list-tile-title>
              </rs-list-tile-content>
            </rs-list-tile>
            <rs-divider></rs-divider>
          </rs-flex>

          <rs-flex xs6 px-4 mb-2>
            <rs-list-tile>
              <rs-list-tile-action>
                <rs-icon>desktop_mac</rs-icon>
              </rs-list-tile-action>
              <rs-list-tile-content>
                <rs-list-tile-sub-title>Account created</rs-list-tile-sub-title>
                <rs-list-tile-title>{{ formattedAccountCreationDate }}</rs-list-tile-title>
              </rs-list-tile-content>
            </rs-list-tile>
            <rs-divider></rs-divider>
          </rs-flex>

          <rs-flex xs6 px-4 mb-2>
            <rs-list-tile>
              <rs-list-tile-action>
                <rs-icon class="material-icons-outlined">rsfont-user-profile</rs-icon>
              </rs-list-tile-action>
              <rs-list-tile-content>
                <rs-list-tile-sub-title>Account role</rs-list-tile-sub-title>
                <rs-list-tile-title>{{ dataLoading ? 'Loading...' : formattedAccountRole }}</rs-list-tile-title>
              </rs-list-tile-content>
            </rs-list-tile>
            <rs-divider></rs-divider>
          </rs-flex>

          <rs-flex x6 px-4 mb-2 v-if="advertisers.length && advertisers[0] !== ''">
            <rs-list-tile class="advertiser-tile">
              <rs-list-tile-content>
                <rs-list-tile-sub-title>Advertisers</rs-list-tile-sub-title>
                <rs-flex column>
                  <rs-list-tile-title v-for="(item, index) in advertisers" :key="item.display_name">{{
                    item.id + ' - ' + item.display_name + (index !== advertisers.length - 1 ? ', ' : '')
                  }}</rs-list-tile-title>
                </rs-flex>
              </rs-list-tile-content>
            </rs-list-tile>
          </rs-flex>
        </rs-list>
      </rs-card-text>
    </rs-card>

    <!-- Subscription and Billing -->
    <error-boundary>
      <template #error>
        <!-- content for the error slot -->
        <error-fallback-card title="Subscription and Billing" icon="rsfont-dollar-amount_v2" />
      </template>
      <account-billing
        :account.sync="account"
        :permissions="permissions"
        :profile="profile"
        :roles="roles"
        :account-id="accountId"
      />
    </error-boundary>

    <rs-card v-if="showPermissionsCard" class="mb-5 elevation-0">
      <rs-card-title class="pa-4">
        <rs-flex>
          <h3 class="headline font-weight-medium ma-0">
            <rs-icon class="mr-2">rsfont-lock_v2</rs-icon>
            Permissions
          </h3>
        </rs-flex>

        <rs-spacer />
        <rs-btn
          small
          flat
          :block="false"
          :disabled="requiresProfile"
          @click="showEditPermissionsDialog = true"
          data-test-id="account-users-add-account-button"
        >
          <rs-icon size="19" class="add-btn">rsfont-edit_v2</rs-icon>
          <div class="mx-2">Edit Permissions</div>
        </rs-btn>
      </rs-card-title>

      <rs-divider />

      <rs-data-table class="permissions-data-table mb-4 pl-2" hide-headers :items="tableItems" hide-actions>
        <template slot="items" slot-scope="props">
          <tr>
            <td class="reporting-data">{{ props.item.name }}</td>
            <td>{{ props.item.level }}</td>
            <td>
              <ImportInfluencerModal
                v-show="props.item.level == 'Level 3' && props.item.name == 'Sales Reporting'"
                :campaign-id="'1234'"
                :brand-id="accountId"
                :type="props.item.name"
              />
            </td>
            <td>
              <rs-flex xs4 ma-1></rs-flex>
            </td>
            <td class="pl-2" style="text-align: right">
              <rs-btn borderless fab small :block="false" class="ma-0">
                <rs-icon @click="showEditPermissionsDialog = true" size="25">rsfont-edit_v2</rs-icon>
              </rs-btn>
            </td>
          </tr>
        </template>
      </rs-data-table>
    </rs-card>

    <!-- Acouunt Credits -->
    <AccountCredits :account-id="accountId" v-if="displayAccountCredits" />

    <!-- Users Table -->
    <account-users
      @open-delete-user-dialog="openDeleteUserDialog"
      :account-id="accountId"
      :user-deleted="userDeleted"
      :disabled="requiresProfile"
    />

    <!-- Page Modals -->
    <dialog-account
      title="Edit Account"
      :account-role.sync="accountRole"
      :account.sync="account"
      :account-id="accountId"
      :advertisers.sync="advertiserIds"
      :default-account-type.sync="account.type"
      :default-account-state.sync="account.state"
      :profile.sync="profile"
      :location-string="locationString"
      v-if="showEditAccountModal"
      @close="
        () => {
          showEditAccountModal = false;
          load();
        }
      "
    />
    <rs-dialog v-model="deleteUserDialog" max-width="751px" persistent>
      <ConfirmationDialogContent
        :header-text="'Delete User'"
        :body-text1="`Are you sure you want to delete ${userToBeDeletedEmail}?`"
        :body-text2="'This action cannot be undone.'"
        :confirm-button-text="'Delete'"
        :cancel-button-text="'Cancel'"
        :confirm-button-loading="deleteUserLoading"
        :confirm-button-disabled="loading"
        :confirm-button-success="userDeleted"
        :visible="deleteUserDialog"
        @confirmation-dialog-cancel="resetDeleteUserState()"
        @confirmation-dialog-confirm="deleteUser()"
      />
    </rs-dialog>

    <EditPermissionsModal
      :account-id="accountId"
      :permissions="permissions"
      :reporting-tiers="reportingTiers"
      :show-dialog="showEditPermissionsDialog"
    />
  </rs-container>
</template>
<script>
// Components
import AccountBilling from '../components/AccountBilling.vue';
import AccountCredits from '../components/AccountCredits.vue';
import AccountUsers from '../components/AccountUsers';
import DialogAccount from '../components/DialogAccount';
import EditPermissionsModal from '../components/EditPermissionsModal';
import ErrorFallbackCard from '../components/ErrorFallbackCard.vue';
import ConfirmationDialogContent from '@/components/ConfirmationDialogContent';
import ImportInfluencerModal from '../components/ImportInfluencerModal';
import Loader from '../components/Loader';

import { mapActions, mapGetters } from 'vuex';
import { get } from 'lodash';
import loaderMixin from '@rscollabs/rs-core-library/src/mixins/rs-loader';
import initClient from '@rscollabs/rs-core-library/src/services/client';
import accountTypeIcon from '../assets/account_type_icon.svg';
import { getRoleDisplayName } from '../util/roles.js';

const client = initClient();
const vn = 'api/v1';

export default {
  name: 'AccountManagement',
  props: ['accountId'],
  components: {
    AccountUsers,
    AccountBilling,
    AccountCredits,
    ConfirmationDialogContent,
    DialogAccount,
    EditPermissionsModal,
    ErrorFallbackCard,
    ImportInfluencerModal,
    Loader,
  },
  mixins: [loaderMixin],
  data() {
    return {
      account: {},
      accountTypeIcon,
      advertiserIds: [''],
      advertisers: [''],
      currentRole: {},
      deleteUserDialog: false,
      deleteUserLoading: false,
      editInfluencerDialog: false,
      influencerDialogType: '',
      profile: {},
      reportingDetails: {},
      reportingTiers: {},
      requiresProfile: false,
      showEditAccountModal: false,
      showEditPermissionsDialog: false,
      userDeleted: false,
      userToBeDeleted: null,
      dataLoading: true,
      languageOptions: [
        { text: 'English (US)', value: 'en-US' },
        { text: 'Deutsch', value: 'de-DE' },
        { text: 'Español (Spain)', value: 'es-ES' },
        { text: 'Français (France)', value: 'fr-FR' },
        { text: 'Italiano', value: 'it-IT' },
        { text: 'Português (Brasil)', value: 'pt-BR' },
        { text: '한국어 (Korea)', value: 'ko-KR' },
        { text: '简体中文 (Simplified Chinese)', value: 'zh-Hans' },
        { text: '繁體中文 (Traditional Chinese)', value: 'zh-Hant' },
      ],
    };
  },
  async created() {
    await this.load();
    this.currentRole = this.roles && this.roles.length > 0 ? this.roles.at(-1) : { name: '' };
    this.$root.$on('updateAccountPermissions', () => {
      this.fetchAccountPermissions(this.accountId);
    });
    this.$root.$on('hidePermissionsDialog', () => {
      this.showEditPermissionsDialog = false;
    });
  },

  beforeDestroy() {
    this.$root.$off('hidePermissionsDialog');
    this.$root.$off('updateAccountPermissions');
  },
  watch: {
    permissions: {
      handler(val) {
        let tiers = this.permissions ? this.getReportingTiers(this.accountId) : {};
        this.reportingTiers = tiers ? tiers : {};
        this.reportingDetails = this.reportingTiers;
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('permissions', [
      'getAccountPermissions',
      'getAccountRoles',
      'getReportingTiers',
      'getRoles',
      'getRole',
    ]),
    ...mapGetters('accounts', ['accountMSSA']),
    accountRole: {
      get: function () {
        let role = this.currentRole ? this.allRoles.find((x) => x.name == this.currentRole.name) : '';
        return role ? role : { name: '', label: '' };
      },
      set: async function (role) {
        await this.fetchAccountRoles(this.accountId);
        let currentRole = this.allRoles.find((x) => x.name == role);
        this.currentRole = currentRole;
        return currentRole;
      },
    },
    preferences() {
      return this.getCurrentUserPreferencesByType('billing_interval');
    },
    allRoles() {
      const { roles } = this.getRoles();
      let result = [];
      for (let roleId in roles) {
        let role = this.getRole(roleId);
        result.push({
          text: getRoleDisplayName(role.name),
          value: role.name,
          ...role,
        });
      }
      result.push({ text: '-- No Role --', value: '' });
      result.sort((a, b) => {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
      return result;
    },
    breadcrumbs() {
      const { routes = [] } = this.$router.options;
      const accountsRoute = routes.find(({ name }) => name === 'Accounts');
      return [accountsRoute, this.$route];
    },
    formattedAccountType() {
      return this.account.type
        ? this.account.type
            .replace(/(\B)[^ ]*/g, (match) => match.toLowerCase())
            .replace(/^[^ ]/g, (match) => match.toUpperCase())
        : '';
    },
    formattedAccountRole() {
      if (typeof this.accountRole === 'string' || this.accountRole instanceof String) {
        return this.accountRole;
      } else if (this.accountRole.text) {
        return this.accountRole.text;
      } else {
        return getRoleDisplayName(this.accountRole?.name);
      }
    },
    formattedAccountCreationDate() {
      const creationDate = new Date(Date.parse(this.account.date_created));
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: 'true',
        timeZoneName: 'shortGeneric',
      };
      return creationDate.toLocaleDateString(undefined, options);
    },
    permissions() {
      return this.getAccountPermissions(this.accountId);
    },
    roles() {
      const { roles } = this.getAccountRoles(this.accountId);
      return roles;
    },
    showPermissionsCard() {
      return this.currentRole && this.currentRole.name === 'managed';
    },
    tableItems() {
      const details = this.reportingDetails;
      return [
        {
          name: 'Campaign Reporting',
          level: `Level ${details.campaigns_reporting_tier}`,
          disabled: this.requiresProfile,
        },
        {
          name: 'Sales Reporting',
          level: `Level ${details.sales_reporting_tier}`,
          disabled: this.requiresProfile,
        },
        {
          name: 'Self Service Reporting',
          level: `Level ${details.ss_reporting_tier}`,
          disabled: this.requiresProfile,
        },
      ];
    },
    userToBeDeletedEmail() {
      return get(this.userToBeDeleted, 'email');
    },
    creditManagementFeatureEnabled() {
      return this.apptimize.isFeatureFlagEnabled('credits_management');
    },
    displayAccountCredits() {
      return this.creditManagementFeatureEnabled && this.brandHasPermissions('create-campaigns');
    },
    locationString() {
      const locationLoaded = this.profile.headquarters_location;
      if (locationLoaded && locationLoaded.hasOwnProperty('city')) {
        return Object.values(locationLoaded)
          .filter((locationProperty) => !!locationProperty)
          .join(', ');
      }
      return '';
    },
  },
  methods: {
    ...mapActions('users', {
      deleteAccountUser: 'deleteUser',
    }),
    ...mapActions('permissions', {
      fetchAccountPermissions: 'getAccountPermissions',
      fetchAccountRoles: 'getAccountRoles',
      fetchRoles: 'getRoles',
    }),
    brandHasRoleOf(roles) {
      return this.roles?.some((r) => roles?.indexOf(r.name) >= 0);
    },
    brandHasPermissions(permission) {
      return this.permissions?.permissions?.some((p) => p.name === permission);
    },

    async createDefaultBrandProfile() {
      const defaultProfile = {};
      try {
        await this.loader(this.$identity.addProfile, [this.accountId, defaultProfile]);
        // on success, reload the page
        window.location.reload();
      } catch (error) {
        console.error(`Failed to create default brand profile for account ${this.accountId}`);
      }
    },
    async getAdvertisers(id) {
      return this.loader(() => client.get(`${vn}/brands/${id}/advertisers`));
    },
    async getAccountDetails() {
      const { data } = await this.loader(this.$identity.getAccount, [this.accountId]);
      this.account = data.account;
    },
    async getProfileDetails() {
      try {
        // get account profiles
        const response = await this.$identity.getProfiles([this.accountId]);
        const profile = response.data.profiles[0];

        if (profile) {
          // get details for first profile
          this.profile = profile;
        } else {
          console.error('No Brand Profile returned. Response: ', response);
        }
      } catch (e) {
        this.profile = {};
        this.requiresProfile = true;
      }
    },
    async load() {
      this.dataLoading = true;
      await this.getAccountDetails().then(async () => {
        this.loadAdvertisers();
      });
      await this.getProfileDetails();
      await this.fetchAccountRoles(this.accountId);
      await this.fetchAccountPermissions(this.accountId);
      await this.fetchRoles();
      this.dataLoading = false;
    },
    loadAdvertisers() {
      this.getAdvertisers(this.accountId).then((response) => {
        if (response.data) {
          if (response.data.data.brand_advertisers.advertisers) {
            this.advertisers = response.data.data.brand_advertisers.advertisers;
          }
          if (response.data.data.brand_advertisers.advertiser_ids) {
            this.advertiserIds = response.data.data.brand_advertisers.advertiser_ids;
          }
        }
      });
    },
    openDeleteUserDialog(user) {
      this.userToBeDeleted = user;
      this.deleteUserDialog = true;
    },
    resetDeleteUserState() {
      this.deleteUserDialog = false;
      this.deleteUserLoading = false;
      this.userToBeDeleted = null;
      this.userDeleted = false;
    },
    async deleteUser() {
      try {
        this.deleteUserLoading = true;
        await this.deleteAccountUser(this.userToBeDeleted.id);
        this.deleteUserLoading = false;
        this.userDeleted = true;
        setTimeout(() => {
          this.resetDeleteUserState();
        }, 1000);
      } catch (error) {
        console.error(error);
        this.resetDeleteUserState();
      }
    },
    getLanguage(lang) {
      const selectedLanguage = this.languageOptions.find((l) => l.value === lang);
      return selectedLanguage?.text || 'English';
    },
  },
};
</script>
<style lang="sass">
.rsfont-edit, .rsfont-add
  height: 20px !important
  padding: 0px !important

.advertiser-tile .v-list__tile
  height: auto

.reporting-data
  width: 25%

button.v-btn[disabled]
  background-color: transparent !important
  color: lightgrey
  filter: invert(1)
  opacity: 0.6

.create-profile-btn
  background-color: #96c0a7 !important
  font-weight: bold !important

.banner
    background-color: #F2DEDF
    color: red
    font-weight: 600

.reporting__state
  display: flex
  align-items: stretch
  &__error, &__empty, &__loader
    width: 100%
    display: flex
    align-items: center
    justify-content: center

  &__error, &__empty
    &__default
      &__header
        display: flex
        justify-content: center

      &__content
        span
          display: block
          text-align: center
        .subheading
          color: #757575
</style>
