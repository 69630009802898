<template>
  <div class="hashtags-notice__container">
    <h2>{{ titleText }}</h2>
    <p>{{ contentText }}</p>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'HashtagsDesktopNotice',
  props: {
    type: {
      type: String,
      default: 'Hashtags',
    },
  },
  setup(props) {
    const titleText = 'Oops!';
    const contentText = computed(() => `${props.type} management is only available on desktop.`);

    return { titleText, contentText };
  },
});
</script>

<style lang="scss">
.hashtags-notice__container {
  padding: 200px 24px;
  text-align: center;
  & h2 {
    margin: 0;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  }
  & p {
    font-size: 14px;
    line-height: 20px;
    padding-top: 8px;
    margin: 0;
  }
}
</style>
