<template>
  <div class="parent-division">
    <div class="parent-division__header">
      <h3 class="header-4 mt-0 mb-0">Parents / Divisions</h3>
      <rs-menu offset-y min-width="200">
        <template #activator="{ on }">
          <rs-button class="action-bar__operator-menu" size="sm" color="secondary" v-on="on" @click.prevent>
            <rs-icon class="ml-0">more_vert</rs-icon>
            <span>Options</span>
          </rs-button>
        </template>
        <rs-list class="parent-division__options">
          <rs-checkbox dense label="Parent by Division" class="mt-0" v-model="autofillParent" />
          <rs-checkbox dense label="Show Divisions by Parent" class="mt-0" v-model="filterDivisions" />
        </rs-list>
      </rs-menu>
    </div>
    <rs-layout wrap>
      <rs-flex sm12 md6>
        <div class="field-combo">
          <rs-autocomplete
            label="Parent ID"
            :items="parents"
            item-text="display_name"
            item-value="id"
            @change="setParent"
            @input="setParent"
            @click:clear="setParent"
            :search-input.sync="parentSearch"
            clearable
            :value="parent"
            return-object
          >
            <template #prepend-item>
              <div class="menu-options" @click="onAddParent">
                <div class="menu-options__item">
                  <rs-icon>add</rs-icon> <strong class="text-2 pl-2">Add Parent</strong>
                </div>
              </div>
            </template>
            <template #item="data">
              <rs-list-tile-content v-bind="data.attrs" :input-value="data.selected">
                <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
                <rs-list-tile-title v-html="`${data.item.display_name}`" />
              </rs-list-tile-content>
            </template>
          </rs-autocomplete>
        </div>
      </rs-flex>
      <rs-flex sm12 md6>
        <div class="field-combo">
          <rs-autocomplete
            label="Division"
            :items="divisions"
            item-text="display_name"
            item-value="id"
            @change="setDivision"
            @input="setDivision"
            @click:clear="setDivision"
            :search-input.sync="divisionSearch"
            clearable
            :value="division"
            return-object
          >
            <template #prepend-item>
              <div class="menu-options" @click="onAddDivision">
                <div class="menu-options__item">
                  <rs-icon>add</rs-icon> <strong class="text-2 pl-2">Manage Divisions</strong>
                </div>
              </div>
            </template>
            <template #item="data">
              <rs-list-tile-content v-bind="data.attrs" :input-value="data.selected">
                <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
                <rs-list-tile-title v-html="`${data.item.display_name}`" />
              </rs-list-tile-content>
            </template>
          </rs-autocomplete>
          <span tabindex="0" class="mt-2 ml-2">
            <rs-icon small class="shrink">info_outline</rs-icon>
            <rs-tip :offset="[0, 16]">
              Division exists so that new programs can be migrated from old programs, usually because an advertiser
              decides to change affiliates.
            </rs-tip>
          </span>
        </div>
      </rs-flex>
      <parents-modal v-model="addParent" :parents="parents" @saved="onAdded" />
      <divisions-modal
        v-model="addDivision"
        :parents="parents"
        :parent-id="parent"
        @changed="onDivisionChanged"
        @closed="onCloseDivisionModal"
      />
    </rs-layout>
  </div>
</template>

<script>
import { defineComponent, toRefs, reactive, watchEffect, watch, computed, onBeforeMount } from 'vue';
import DivisionsModal from './DivisionsModal.vue';
import ParentsModal from './ParentsModal.vue';
import RsTip from '../lib/components/RsTip/RsTip.vue';
import RsButton from '../lib/components/RsButton/RsButton.vue';
import useDivisionApi from '../composables/useDivisionApi';

export default defineComponent({
  name: 'FieldsDivisionParent',
  props: {
    parentValue: {
      type: Number,
      default: null,
    },
    divisionValue: {
      type: Number,
      default: null,
    },
  },
  emits: ['update:parentValue', 'update:divisionValue'],
  components: { RsTip, DivisionsModal, RsButton, ParentsModal, DivisionsModal },
  setup(props, { emit }) {
    const { getParents, getDivisions, divisions, parents } = useDivisionApi();

    const state = reactive({
      parents: [],
      divisions: [],
      autofillParent: true,
      filterDivisions: true,
      addParent: false,
      addDivision: false,
      parentSearch: '',
      divisionSearch: '',
      parent: null,
      division: null,
    });

    watchEffect(() => {
      if (props.divisionValue) {
        state.division = props.divisionValue;
      } else {
        state.parent = null;
      }
      if (props.parentValue) {
        state.parent = props.parentValue;
      } else {
        state.division = null;
      }
    });

    const setParent = (event) => {
      if (event && event.id) {
        emit('update:parent-value', event.id);
        state.parent = event.id;
      }
    };

    const setDivision = (event) => {
      if (event) {
        emit('update:division-value', event.id);
        state.division = event.id;
        if (state.autofillParent) {
          emit('update:parent-value', event.retailer_id);
          state.parent = event.retailer_id;
        }
      }
    };

    const onAddDivision = () => {
      state.addDivision = true;
    };

    const onAddParent = () => {
      state.addParent = true;
    };

    const onAdded = async (res) => {
      if (res.parent.id) {
        await getParents();
        state.parents = parents;
        state.parent = res.parent.id;
        emit('update:parent-value', res.parent.id);
      }
    };

    const onDivisionChanged = async (res) => {
      if (res.divisions?.length > 0) {
        await getDivisions(computedFilter.value);
        state.divisions = divisions.value;
        state.division = res.division[0].id;
        emit('update:division-value', res.division[0].id);
      }
    };
    const onCloseDivisionModal = async () => {
      await getDivisions(computedFilter.value);
      state.divisions = divisions.value;
    };

    const computedFilter = computed(() => {
      if (state.filterDivisions && state.parent) {
        return state.parent;
      }
      return null;
    });

    watch(
      () => computedFilter.value,
      async () => {
        await getDivisions(computedFilter.value);
        state.divisions = divisions.value;
      },
      {
        immediate: true,
      },
    );

    onBeforeMount(async () => {
      await getParents();
      state.parents = parents;
    });

    return {
      ...toRefs(state),
      setParent,
      setDivision,
      onAddDivision,
      onCloseDivisionModal,
      onAddParent,
      onAdded,
      onDivisionChanged,
      computedFilter,
    };
  },
});
</script>

<style lang="scss">
.parent-division {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 100%;
    h3 {
      width: 100%;
    }
  }
  &__options {
    padding: 16px 16px 8px;
  }
}
.menu-options {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  &__item {
    font-weight: 500;
    padding: 0 8px;
    height: 48px;
    background-color: #fff;
    display: flex;
    align-items: center;
    &:hover {
      background-color: #f5f5f5;
    }
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #efefef;
  }
}
.field-combo {
  display: flex;
  align-items: center;
  .v-input {
    &--checkbox {
      flex-grow: 0;
    }
  }
}
</style>
