<template>
  <div class="rs--modal__footer" :style="[alignmentStyle]">
    <slot />
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'RsModalFooter',
  props: {
    align: {
      type: String,
      default: 'center',
    },
    justify: {
      type: String,
      default: 'flex-end',
    },
    direction: {
      type: String,
      default: 'initial',
    },
  },
  setup(props) {
    const alignmentStyle = computed(() => {
      return {
        alignItems: props.align,
        justifyContent: props.justify,
        flexDirection: props.direction,
      };
    });

    return { alignmentStyle };
  },
});
</script>

<style lang="scss">
@use '../../../scss/breakpoints';

.rs--modal {
  &__footer {
    display: flex;
    align-content: center;
    width: 100%;
    justify-content: flex-end;
    flex-shrink: 0;
    background-color: var(--bg);
    border-radius: 0 0 3px 3px;
    padding: 8px 16px;
    border-radius: 0 0 3px 3px;
    position: sticky;
    bottom: 0;
    left: 0;
    @include breakpoints.down('sm') {
      padding: 8px 8px calc(16px + ios-safe-area(bottom));
    }
  }
}
.is--aside {
  .rs--modal {
    &__footer {
      padding-bottom: 32px;
    }
  }
}
</style>
