<script>
import { HorizontalBar, mixins } from 'vue-chartjs';
import 'chartjs-plugin-dragdata';
import 'chartjs-plugin-datalabels';

const TimesCharacter = '\u00D7';
const getContextValue = (context) => context.dataset.data[context.dataIndex];
const slightThreshold = 0.1;
const isSlightNegative = (value) => value < 0 && value > -slightThreshold;
const isSlightPositive = (value) => value >= 0 && value < slightThreshold;

const PROTECTED_VECTORS = 1;

export default {
  extends: HorizontalBar,
  mixins: [mixins.reactiveProp],
  props: ['chart-options'],
  data() {
    return {
      isHoveringBar: false,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                suggestedMin: -1,
                suggestedMax: 1,
              },
              minBarLength: 5,
            },
          ],
          yAxes: [
            {
              categoryPercentage: 0.9,
              barPercentage: 0.9,
              ticks: {
                callback(value) {
                  return value.split('//').map((item) => item.trim());
                },
              },
            },
          ],
        },
        tooltips: { enabled: false },
        dragData: true,
        dragDataRound: 2,
        onDrag: this.onDragHandler,
        onHover: this.onHoverHandler,
        plugins: {
          datalabels: {
            labels: {
              // Using to add an X to allow someone to remove a bar from the chart
              title: {
                formatter: () => TimesCharacter,
                color: this.$vuetify.theme.error,
                font: { family: 'Montserrat', size: 20, weight: 'bold', lineHeight: '0.5' },
                /**
                 * Shows the X only for removable datasets. The first dataset should be the
                 * dataset to manipulate, and therefore anything in a later dataset should be ignored.
                 * We also don't allow the removal of the `gender` and `age` features, which are guaranteed
                 * to be the first two features.
                 */
                display(context) {
                  if (context.dataIndex < PROTECTED_VECTORS) {
                    return false;
                  }

                  return context.datasetIndex === 0 ? true : false;
                },
                // align and anchor are used for positioning the x on the opposite side of the axis as the data
                align: (context) => (getContextValue(context) >= 0 ? 'left' : 'right'),
                anchor: (context) => (getContextValue(context) >= 0 ? 'start' : 'end'),
                listeners: {
                  click: (context) => {
                    this.$emit('label-click', context);
                  },
                  enter: (context) => {
                    context.chart.canvas.style.cursor = 'pointer';
                  },
                  leave: (context) => {
                    context.chart.canvas.style.cursor = 'default';
                  },
                },
              },
              // Using as a replacement for the floating tooltip
              value: {
                formatter: (value) => Number(value).toFixed(2),
                // align and anchor are used for positioning the value inside the bar as much as possible
                // but not overlapping with the x
                align(context) {
                  const value = getContextValue(context);
                  if (isSlightNegative(value)) {
                    return 'left';
                  }
                  if (isSlightPositive(value)) {
                    return 'right';
                  }
                  return 'center';
                },
                anchor(context) {
                  const value = getContextValue(context);
                  if (isSlightNegative(value)) {
                    return 'end';
                  }
                  if (isSlightPositive(value)) {
                    return 'start';
                  }
                  return 'center';
                },
                listeners: {
                  click: ({ datasetIndex, dataIndex, chart }) => {
                    if (datasetIndex > 0) {
                      return;
                    }
                    const { x, y } = chart.$datalabels._hovered.$layout._box._rect;
                    this.$emit('data-label-click', { dataIndex, x, y });
                  },
                  enter: (context) => {
                    if (context.datasetIndex > 0) {
                      return;
                    }
                    context.chart.canvas.style.cursor = 'pointer';
                  },
                  leave: (context) => {
                    if (context.datasetIndex > 0) {
                      return;
                    }
                    context.chart.canvas.style.cursor = 'default';
                  },
                },
              },
            },
          },
        },
      },
    };
  },
  mounted() {
    this.options = Object.assign(this.options, this.chartOptions);
    this.renderChart(this.chartData, this.options);
  },
  methods: {
    onDragHandler(event, datasetIndex, index, value) {
      this.$emit('chart-updated', { index, value });
    },
    /**
     * This function is triggered on mouse move and interferes with other mouse events.
     * Tracking and removing only when necessary to avoid interfering with hoverability of
     * label annotations
     */
    onHoverHandler(event) {
      const [element] = this.$data._chart.getElementAtEvent(event);

      if (element === undefined && this.isHoveringBar === true) {
        event.target.style.cursor = 'default';
        this.isHoveringBar = false;
      }

      if (element !== undefined && element._datasetIndex === 0) {
        event.target.style.cursor = 'ew-resize';
        this.isHoveringBar = true;
      }
    },
  },
};
</script>
