// Returns a decorated function that debounces the passed function
export default function debounce(callback, timeout = 200) {
  // create a scoped timer
  let timer = null;
  // debounce wrapped function with appropriate context bindings
  return function value(...args) {
    // Clear previous timeout and start over
    if (timer !== null) {
      window.clearTimeout(timer);
      timer = null;
    }

    // rebind the new debounce function and arguments
    timer = window.setTimeout(() => {
      callback.apply(this, args);
      timer = null;
    }, timeout);
  };
}
