<template>
  <rs-dialog :value="true" persistent max-width="650px" :fullscreen="$vuetify.breakpoint.xsOnly" v-if="account">
    <rs-card>
      <rs-card-title class="accounts__modal-header justify-center">
        <h3 class="headline font-weight-medium ma-0">{{ title }}</h3>
      </rs-card-title>
      <rs-divider />
      <rs-form
        ref="form"
        style="position: relative"
        class="dialog__form pa-5"
        @submit.prevent="onSubmit"
        data-test-id="dialog-account-add-edit-form"
      >
        <loader v-if="loading" data-test-id="dialog-account-loader" />
        <rs-card-text class="pa-0">
          <div v-if="accEditErrorMsg !== ''" class="pb-4 error-msg">{{ accEditErrorMsg }}</div>
          <rs-text-field
            v-model="editingAccount.name"
            label="Company"
            :rules="[(v) => !!v || 'Company is required']"
            required
            data-test-id="dialog-account-name-text-field"
          ></rs-text-field>
          <rs-select
            class="dialog-account__custom-select"
            append-icon="rsfont-arrow-down_v2"
            label="Account Type"
            :items="formattedPotentialAccountTypes"
            :value="defaultAccountType || undefined"
            :rules="[(v) => !!v || 'Account Type is required']"
            @input="handleAccountTypeSelection"
            data-test-id="dialog-account-type-select"
          />
          <rs-select
            class="dialog-account__custom-select"
            append-icon="rsfont-arrow-down_v2"
            label="Account State"
            :items="potentialAccountStates"
            :value="accountState || undefined"
            @input="handleAccountStateSelection"
            data-test-id="dialog-account-state-select"
          />
          <rs-select
            v-if="isAccManagement"
            class="dialog-account__custom-select"
            append-icon="rsfont-arrow-down_v2"
            label="Account role"
            :disabled="toggleSubscriptionRole"
            v-model="currentRole"
            :items="accountRoles"
            data-test-id="dialog-account-role-select"
          />
          <rs-select
            label="Language"
            :items="availableLanguages"
            v-model="editingAccount.preferred_locale"
            :rules="[(v) => availableLanguages.some((l) => l.value === v) || 'Language is required']"
            append-icon="ltkfont-down-chevron-outline"
            data-test-id="dialog-language-select"
          />
          <rs-text-field
            v-model="editingProfile.website_url"
            label="Website"
            :rules="[(v) => !!v || 'Website is required', (v) => isURL(v) || 'URL is not valid']"
            required
            data-test-id="dialog-website-name-text-field"
          ></rs-text-field>
          <rs-autocomplete
            v-model="location.selected"
            :search-input.sync="location.input"
            :items="location.predictions"
            item-text="description"
            label="Location"
            :rules="[() => !!location.selected || 'Location is required']"
            prepend-inner-icon="rsfont-location"
            data-test-id="qa-basic-info-slot-location-text-field"
            append-icon="ltkfont-down-chevron-outline"
            hide-no-data
            auto-select-first
            @focus="location.loaded = false"
          />
          <template #header>
            <div class="v-label--active theme--light">Additional Info</div>
          </template>
          <div>
            <rs-text-field
              v-model="editingAccount.id"
              v-if="account.id"
              label="Account ID"
              disabled
              data-test-id="dialog-account-id-text-field"
            ></rs-text-field>
            <div v-if="isAccManagement">
              <rs-layout row xs12 v-for="(item, index) in newAdvertisers" :key="item.id">
                <rs-flex row :class="index !== 0 ? 'xs8' : 'xs12'">
                  <rs-text-field v-model="item.value" label="Advertiser ID"></rs-text-field>
                </rs-flex>
                <rs-flex v-if="index !== 0" row xs4 pt-2 ml-4>
                  <rs-btn @click="removeAdvertiser(index)" data-test-id="dialog-account-advertiser-delete-button"
                    >Delete</rs-btn
                  >
                </rs-flex>
              </rs-layout>
              <rs-btn @click="addNewAdvertiser()" data-test-id="dialog-account-advertiser-button"
                >Add Advertiser</rs-btn
              >
            </div>
            <rs-text-field
              v-model="editingAccount.hubspot_company_id"
              label="Hubspot Company ID"
              data-test-id="dialog-account-hubspot-id-text-field"
              :rules="[(v) => isNumericString(v) || 'You have entered a non-numeric value.']"
            ></rs-text-field>
            <rs-text-field
              v-model="editingAccount.netsuite_entity_id"
              label="Netsuite ID"
              data-test-id="dialog-account-netsuite-id-text-field"
            ></rs-text-field>
          </div>
        </rs-card-text>
        <rs-card-actions class="pa-0 my-3">
          <rs-btn inverted type="submit" data-test-id="dialog-account-save-button">Save</rs-btn>
          <rs-btn
            @click="
              () => {
                accEditErrorMsg = '';
                $emit('close');
              }
            "
            data-test-id="dialog-account-cancel-button"
            >Cancel</rs-btn
          >
        </rs-card-actions>
      </rs-form>
    </rs-card>
  </rs-dialog>
</template>

<script>
import { get } from 'lodash';
import Loader from './Loader';
import loaderMixin from '@rscollabs/rs-core-library/src/mixins/rs-loader';
import initClient from '@rscollabs/rs-core-library/src/services/client';
import { mapActions, mapGetters } from 'vuex';
import { getRoleDisplayName } from '../util/roles.js';

const client = initClient();
const vn = 'api/v1';

export default {
  name: 'AddAccount',
  props: {
    account: {
      type: Object,
      default: () => ({}),
    },
    accountRole: {
      type: Object,
      default: () => {},
    },
    accountId: {
      type: String,
      default: () => '',
    },
    advertisers: {
      type: Array,
      default: () => [''],
    },
    defaultAccountType: {
      type: String,
      default: () => '',
    },
    defaultAccountState: {
      type: String,
      default: () => 'Pending',
    },
    profile: {
      type: Object,
      default: () => ({}),
    },
    title: String,
    locationString: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      accEditErrorMsg: '',
      chosenAccountType: '',
      chosenAccountState: '',
      newAdvertisers: [],
      potentialAccountTypes: [],
      currentRole: '',
      location: {
        loaded: false,
        input: '',
        selected: '',
        predictions: [],
      },
      gPlaces: null,
      selectedLanguage: '',
      loading: false,
      languageOptions: [
        { text: 'English (US)', value: 'en-US' },
        { text: 'Deutsch', value: 'de-DE' },
        { text: 'Español (Spain)', value: 'es-ES' },
        { text: 'Français (France)', value: 'fr-FR' },
        { text: 'Italiano', value: 'it-IT' },
        { text: 'Português (Brasil)', value: 'pt-BR' },
        { text: '한국어 (Korea)', value: 'ko-KR' },
        { text: '简体中文 (Simplified Chinese)', value: 'zh-Hans' },
        { text: '繁體中文 (Traditional Chinese)', value: 'zh-Hant' },
      ],
    };
  },
  computed: {
    ...mapGetters('permissions', ['getRoles']),
    languageFeatureEnabled() {
      return {
        'en-US': true,
        'de-DE': true,
        'es-ES': true,
        'fr-FR': true,
        'it-IT': true,
        'pt-BR': this.apptimize.isFeatureFlagEnabled('locale_pt-BR'),
        'ko-KR': this.apptimize.isFeatureFlagEnabled('locale_ko-KR'),
        'zh-Hans': this.apptimize.isFeatureFlagEnabled('locale_zh-Hans'),
        'zh-Hant': this.apptimize.isFeatureFlagEnabled('locale_zh-Hant'),
      };
    },
    availableLanguages() {
      return this.languageOptions.filter((item) => {
        return this.languageFeatureEnabled[item.value];
      });
    },
    allRoles() {
      const { roles } = this.getRoles();
      const result = [];
      for (const id in roles) {
        result.push({
          text: getRoleDisplayName(roles[id].name),
          value: roles[id].name,
        });
      }
      result.push({ text: '-- No Role --', value: '' });
      result.sort((a, b) => a.text.localeCompare(b.text));
      return result;
    },
    accountRoles() {
      return this.allRoles;
    },
    formattedPotentialAccountTypes() {
      return this.potentialAccountTypes
        .map((at) => at.type)
        .map((at) => {
          return {
            text: at
              .replace(/(\B)[^ ]*/g, (match) => match.toLowerCase())
              .replace(/^[^ ]/g, (match) => match.toUpperCase()),
            value: at,
          };
        });
    },
    potentialAccountStates() {
      return ['Active', 'Pending', 'Integrating', 'Suspended'];
    },
    accountState() {
      return this.defaultAccountState[0].toUpperCase() + this.defaultAccountState.substring(1);
    },
    editingAccount() {
      return this.account;
    },
    editingProfile() {
      return this.profile;
    },
    isAccManagement() {
      return this.$router.currentRoute.name === 'AccountManagement';
    },
    toggleSubscriptionRole() {
      const currentSelectedRole = this.accountRole?.value;
      const conditionArray = ['connect', 'connect-scale', 'connect-pro'];
      return conditionArray.includes(currentSelectedRole);
    },
  },
  created() {
    this.currentRole = this.accountRole?.name;
  },
  watch: {
    'location.input': function (val) {
      if (!val || this.location.loaded) return;
      this.gPlaces.getPlacePredictions({ input: val, types: ['(cities)'] }, this.getLocationPredictions);
    },
    'location.selected': function (val) {
      if (!val || this.location.loaded) return;
      const selectedObj = this.location?.predictions?.filter((el) => el.description === val)[0];
      let selSplitted = {};
      // location object creation
      selectedObj?.terms?.forEach((term, index) => {
        if (index === 0) {
          selSplitted.city = term.value;
        } else if (index === selectedObj.terms.length - 1) {
          selSplitted.country = term.value;
        } else {
          if (index === 1) {
            selSplitted.region = term.value;
          } else {
            selSplitted.region += ', ' + term.value;
          }
        }
      });
      /* eslint-disable-next-line vue/no-mutating-props */
      this.profile.headquarters_location = selSplitted;
    },
  },
  async mounted() {
    this.gPlaces = new window.google.maps.places.AutocompleteService();
    if (this.locationString) {
      this.location.selected = this.locationString;
      this.location.predictions.push({
        description: this.locationString,
      });
      this.location.loaded = true;
    }
    await this.load();
    if (!this.advertisers.length) {
      this.newAdvertisers.push({ value: '' });
    } else {
      for (let i = 0; i < this.advertisers.length; i++) {
        this.newAdvertisers.push({ id: Math.random() * 100, value: this.advertisers[i] });
      }
    }
    await this.getAccountTypes();
  },
  components: {
    Loader,
  },
  mixins: [loaderMixin],
  methods: {
    ...mapActions('permissions', {
      fetchAccountRoles: 'getAccountRoles',
      fetchRoles: 'getRoles',
      replaceAccountRoles: 'replaceAccountRoles',
      revokeAccountRoles: 'revokeAccountRoles',
    }),

    getLocationPredictions(predictions, status) {
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        return;
      }
      this.location.predictions = predictions;
    },

    async load() {
      if (this.accountId !== '') {
        await this.fetchAccountRoles(this.accountId);
      }
      await this.fetchRoles();
    },
    async updateRole() {
      const name = this.currentRole;
      if (name !== '') {
        await this.replaceAccountRoles({ accountId: this.account.id, request: [{ name }] });
        this.$emit('update:accountRole', name);
      } else {
        await this.revokeAccountRoles({ accountId: this.account.id, request: [{ name: this.currentRole }] });
        this.$emit('update:accountRole', '');
      }
    },
    addNewAdvertiser() {
      this.newAdvertisers.push({ id: Math.random() * 100, value: '' });
    },
    async putAdvertisers() {
      let newAdvArr = [];
      for (let i = 0; i < this.newAdvertisers.length; i++) {
        newAdvArr.push(this.newAdvertisers[i].value.toString());
      }
      let uniqueAdv = [...new Set(newAdvArr)];
      if (uniqueAdv[0] === '') {
        uniqueAdv = [];
      }
      const data = {
        data: {
          advertiser_ids: uniqueAdv,
        },
      };
      return this.loader(
        () => {
          return client.put(`${vn}/brands/${this.accountId}/advertisers`, data);
        },
        [],
        (err) => {
          let e = err.response ? err.response.data : err;
          if (e.statusCode === 409) {
            if (e.data.error.type === 'InvalidAdvertiserIDError') {
              this.accEditErrorMsg = e.data.error.detail;
            }
          } else if (e.statusCode === 404) {
            if (e.data.error.type === 'AdvertiserIDNotFoundError') {
              this.accEditErrorMsg = e.data.error.detail;
            }
          } else {
            this.$root.$emit('onError', e);
          }
          return false;
        },
      );
    },
    removeAdvertiser(index) {
      this.newAdvertisers.splice(index, 1);
    },
    async onSubmit() {
      this.accEditErrorMsg = '';

      // validate form
      if (!this.$refs.form.validate()) return;

      // submit and merge account details
      try {
        const resp = await this.submitAccount();
        const { account, profile } = resp;

        await this.updateRole();

        this.$emit('update:account', {
          ...this.account,
          ...account,
        });
        this.$emit('update:profile', {
          ...this.profile,
          ...profile,
        });

        if (!this.isAccManagement) {
          this.$emit('close');
          this.$router.push({
            name: 'AccountManagement',
            params: { accountId: account.id },
          });
        } else {
          if (this.newAdvertisers[0] === '') {
            this.newAdvertisers = [];
            this.$emit('close');
          } else {
            this.putAdvertisers().then((resp) => {
              if (resp) {
                this.$emit('close');
              }
            });
          }
        }
      } catch (err) {
        // error has already been handled in prev function callbacks
        return;
      }
    },
    async editAccountProfile() {
      let additionalParams;
      if (this.chosenAccountType) {
        additionalParams = { type: this.chosenAccountType };
      }
      if (this.chosenAccountState) {
        additionalParams = { state: this.chosenAccountState.toLowerCase() };
      }
      this.loading = true;
      try {
        const { data } = await this.$identity.putAccount(this.accountId, {
          ...this.account,
          ...additionalParams,
        });
        const account = data.account || {};
        const { data: profile } = await this.$identity.editProfile(this.profile.id, this.profile, this.accountId);
        return { account, profile };
      } catch (err) {
        let e = err.data ? err.data.error : err;
        if (e.indexOf('cannot create new account with an existing unique field') !== -1) {
          this.$root.$emit('onError', {
            data: {
              error: 'The Hubspot Company ID or Netsuite ID you have provided is already assigned to another company. ',
            },
          });
        }
      }
      this.loading = false;
    },
    addAccountProfile() {
      return this.loader(
        async () => {
          const { data } = await this.$identity.addAccount(this.account, this.profile);
          return {
            account: data.account,
            profile: data.account.brand_profile,
          };
        },
        [],
        (err) => {
          let e = err.data ? err.data.error : err;
          if (e.indexOf('cannot create new account with an existing unique field') !== -1) {
            this.$root.$emit('onError', {
              data: {
                error:
                  'The Hubspot Company ID or Netsuite ID you have provided is already assigned to another company. ',
              },
            });
          } else {
            this.$root.$emit('onError', e);
          }
        },
      );
    },
    submitAccount() {
      return this.account.id ? this.editAccountProfile() : this.addAccountProfile();
    },
    async getAccountTypes() {
      const accountTypes = await this.$identity.getAccountTypes();
      this.potentialAccountTypes = get(accountTypes, 'data.account_types', []);
    },
    handleAccountTypeSelection(input) {
      this.chosenAccountType = input;
    },
    handleAccountStateSelection(input) {
      this.chosenAccountState = input;
    },
    isURL(value) {
      const re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
      return re.test(value);
    },
    isNumericString(value) {
      if (!value || value.length === 0) {
        return true;
      }
      return /^\d+$/.test(value);
    },
  },
};
</script>

<style lang="sass" scoped>
.error-msg
  color: red

.v-expansion-panel:before
  box-shadow: none !important

.v-expansion-panel
  ::v-deep .v-expansion-panel__header
    margin: 0 !important
    padding: 0 !important
  ::v-deep .v-expansion-panel__header__icon .v-icon
    height: 24px
    width: 24px
    font-size: 16px
    font-weight: bold

.v-select-list
  ::v-deep .theme--light.v-list .v-list__tile--link
    &:hover
      background-color: #808080
  ::v-deep .v-list__tile__title
    &:hover
      color: white !important
      height: 35px
      width: 100%
      font-family: Montserrat !important
      font-size: 14px !important
      line-height: 35px !important

::v-deep .v-list.v-list--dense.theme--light
  padding-top: 0
  padding-bottom: 0

::v-deep .v-select
  &__selections
    padding-top: 0 !important

::v-deep .v-input__append-inner
  i
    height: 13px
    width: 13px
    overflow: hidden
</style>
