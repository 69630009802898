import amplitude from 'amplitude-js';
export class Amplitude {
  constructor(secretKey) {
    this.amplitude = amplitude;
    this.amplitude.getInstance().init(secretKey);
    this.userId;
    this.userProperties = {};
  }
  setUserId(userId) {
    if (this.userId) return;
    this.amplitude.getInstance().setUserId(userId);
    this.userId = userId;
  }
  userIdIsSet() {
    return !!this.userId;
  }
  setUserProperties(props) {
    const entries = Object.entries(props);
    if (!entries.length) return;

    entries.forEach((entrie) => {
      const [key, value] = entrie;
      this.amplitude.getInstance().identify(new this.amplitude.Identify().set(key, value));
      this.userProperties[key] = value;
    });
  }
  hasUserProperties(userPropertiesKey) {
    return this.userProperties.hasOwnProperty(userPropertiesKey);
  }
  logEvent(event, eventProperties) {
    this.amplitude.getInstance().logEvent(event, eventProperties);
  }
}
