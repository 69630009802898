<template>
  <p class="body-1 mb-0">
    {{ accountName }}'s {{ subscriptionType }} plan will be renewed on {{ subscriptionRenewalDate }} at a rate of
    {{ subscriptionIntervalPriceText }} (not including taxes and fees).
  </p>
</template>

<script>
export default {
  name: 'ManageRenewalModalRenewalText',
  props: {
    accountName: {
      type: String,
      default: '',
    },
    subscriptionType: {
      type: String,
      default: '',
    },
    subscriptionRenewalDate: {
      type: String,
      default: '',
    },
    subscriptionIntervalPriceText: {
      type: String,
      default: '',
    },
  },
};
</script>
