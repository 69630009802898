<template>
  <rs-app>
    <rsHeader @nav-open="navDrawer = !navDrawer" />
    <rsSideNav v-model="navDrawer" />
    <rs-content>
      <ErrorMessage />
      <router-view />
    </rs-content>
  </rs-app>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import rsHeader from '@/components/rsHeader.vue';
import rsSideNav from '@/components/rsSideNav.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import { ID_TOKEN_NAME, GOOGLE_APPS_EMAIL_DOMAIN } from '@/authorization';
import Apptimize from '@apptimize/apptimize-web-sdk';
import { Experiment } from '@amplitude/experiment-js-client';
import { load as VueGoogleMapsLoad } from 'vue2-google-maps';
import amplitudeMixins from '@/mixins/amplitude';

const amplitudeDeploymentKey = window.rewardStyle?.AMPLITUDE_DEPLOYMENT_KEY;

export default {
  name: 'App',
  components: { rsHeader, rsSideNav, ErrorMessage },
  mixins: [amplitudeMixins],
  data() {
    return {
      // Starting with side nav closed always
      navDrawer: false,
      apptimizeInitialized: false,
      apptimizeError: false,
      userInfo: {},
    };
  },
  beforeCreate() {
    try {
      // lazily load google maps
      if (!window.rsGoogleMapsInitialized) {
        VueGoogleMapsLoad({
          key: window.rewardStyle.GOOGLE_API_KEY,
          libraries: 'places', // This is required if you use the Autocomplete plugin
        });
        window.rsGoogleMapsInitialized = true;
      }
    } catch (error) {
      window.rsGoogleMapsInitialized = false;
    }
  },
  async mounted() {
    const idToken = Cookies.get(ID_TOKEN_NAME);
    if (idToken !== undefined) {
      this.requestAccessUser = false;
      this.userInfo = await this.fetchUserInfo();
      this.initializeFullStory();
      const jwt = jwtDecode(idToken);
      try {
        this.initializeAmplitude(this.userInfo.user.id);
        this.initializeApptimize(window.rewardStyle.APPTIMIZE_KEY, jwt);
        this.setGlobalApptimize();
      } catch (error) {
        this.disableAllFeatureFlags();
        this.apptimizeError = true;
        console.error('Apptimize failed to initialize properly', error);
      }
      // Safeguard in case apptimize hangs and doesn't throw an error
      setTimeout(
        function () {
          if (!this.apptimizeInitialized) {
            this.disableAllFeatureFlags();
            this.apptimizeError = true;
            console.error('Apptimize took more than 3 seconds to initialize.  Apptimize features will be disabled.');
          }
        }.bind(this),
        3000,
      );
    }
  },
  computed: {
    apptimizeReady() {
      return this.apptimizeInitialized && Vue.prototype.apptimize && !this.apptimizeError;
    },
  },
  methods: {
    ...mapActions('users', {
      fetchUser: 'getUser',
      fetchUserInfo: 'getUserInfo',
    }),
    initializeApptimize(key = '', jwt) {
      Apptimize.setOnApptimizeInitializedCallback(this.onApptimizeInitialized);
      Apptimize.setCustomerUserId(jwt.email);
      Apptimize.setPilotTargetingId(jwt.email);
      Apptimize.setup(key, { log_level: 'LOG_LEVEL_NONE' });
    },
    async initializeAmplitude(accountId, roles = []) {
      if (!amplitudeDeploymentKey) return;
      const experiment = Experiment.initializeWithAmplitudeAnalytics(amplitudeDeploymentKey);
      Vue.prototype.amplitude = experiment;

      await this.amplitudeInit(accountId, roles);
      await experiment.start();
    },
    async initializeFullStory() {
      try {
        window.FS.identify(this.userInfo?.user?.id, {
          email: this.userInfo?.user?.email,
          accountId: this.userInfo?.account?.id,
          uid: this.userInfo?.user?.id,
          accountType: this.userInfo?.account?.type,
          displayName: this.userInfo?.user?.email,
          accountName: this.userInfo?.account?.name,
          isEmployee: true,
        });
      } catch (err) {
        console.error(`Error initializing FullStory: ${err.message}`);
      }
    },
    setGlobalApptimize() {
      Vue.prototype.apptimize = Apptimize;
      this.apptimizeError = false;
    },
    onApptimizeInitialized() {
      Apptimize.updateApptimizeMetadataOnce();
      Apptimize.track('Apptimize Initialized');
      this.apptimizeInitialized = true;
    },
    disableAllFeatureFlags() {
      let disableAllApptimizeFeatures = {
        isFeatureFlagEnabled: () => false,
      };
      Vue.prototype.apptimize = disableAllApptimizeFeatures;
    },
  },
};
</script>
<style lang="sass">
h1, h2, h3, h4, h5, h6, h7, h
  font-family: 'SofiaPro', sans-serif !important
.display-3, .display-2, .headline
  font-family: 'SofiaPro', sans-serif !important
.container
  max-width: 1440px
.contentContainer
  height: 100%
.v-content__wrap
  height: 100%
  flex: 1 0 auto
  display: flex
.dialog__form
  position: relative
.v-list__tile__sub-title
  font-size: 12px
</style>
