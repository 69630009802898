<template>
  <dl :class="['field-metric', classes]">
    <dt class="field-metric__label" :style="[alignment]">
      <span v-if="label">
        {{ label }}
      </span>
      <slot name="label" />
    </dt>
    <dd class="field-metric__value" :style="[styles]">
      <span v-if="!hasSlot('value')">
        {{ value ? value : '--' }}
      </span>
      <slot name="value" />
    </dd>
  </dl>
</template>

<script>
import { computed } from 'vue';
export default {
  name: 'FieldMetric',
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    inline: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: null,
    },
  },
  setup(props, { slots }) {
    const hasSlot = (name) => !!slots[name];

    const alignment = computed(() => {
      return {
        'justify-content': props.align,
      };
    });

    const styles = computed(() => {
      return {
        ...alignment.value,
        color: props.color,
        width: props.width ? `${props.width}px` : false,
      };
    });

    const classes = computed(() => {
      return {
        'is--inline': props.inline,
      };
    });

    return { hasSlot, styles, alignment, classes };
  },
};
</script>

<style lang="scss">
@use '../scss/breakpoints';
@use '../scss/typography';

.field-metric {
  padding: 0;
  @include breakpoints.down('sm') {
    &:not(.is--inline) {
      width: 100%;
    }
  }
  &:last-child {
    margin-right: 0;
  }

  &__label {
    padding-top: 8px;
    margin-bottom: 4px;
    white-space: nowrap;
    color: var(--text-2);
    @extend .text-2;
    text-align: left;
    @include breakpoints.up('md') {
      width: 100%;
    }
  }

  &__value {
    margin: 0;
    padding-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    @extend .text-1;
  }
  .v-chip__content {
    text-transform: none !important;
  }
  &.is--inline {
    display: flex;
    align-items: center;
    align-self: center;
    min-height: 32px;
    .field-metric__label {
      width: auto;
      margin: 0;
      padding: 0;
      width: inherit;
      padding-right: 16px;
    }
    .field-metric__value {
      padding: 0;
      margin: 0;
      flex-grow: 1;
      min-width: 100%;
    }
  }
}
</style>
