<template>
  <div :id="id" :class="['rs--button-group', classes]">
    <slot />
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import uuid from '../../utils/uuid';
export default defineComponent({
  name: 'RsButtonGroup',
  props: {
    id: {
      type: String,
      default: () => 'rs-button-group' + uuid(),
    },
    stacked: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    grouped: {
      type: Boolean,
      default: true,
    },
    tabs: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const classes = computed(() => {
      return {
        'is--stacked': props.stacked,
        'is--full': props.full,
        'is--grouped': props.grouped,
        'is--tabs': props.tabs,
      };
    });
    return {
      classes,
    };
  },
});
</script>

<style lang="scss">
.rs--button-group {
  vertical-align: middle;
  display: inline-flex;
  overflow: visible;
  margin-right: 16px;
  position: relative;
  &:last-child {
    margin-right: 0;
  }
  &.is--grouped {
    & > .rs--button {
      margin: 0;
      border-radius: 0;
      border-width: 1px 0px 1px 1px;
      &:first-child:not(:only-child) {
        border-radius: 6px 0 0 6px;
        border-width: 1px 0px 1px 1px;
      }
      &:last-child:not(:only-child) {
        border-radius: 0 6px 6px 0;
        border-width: 1px;
      }
      &:only-child {
        border-radius: 6px;
        border-width: 1px;
      }
    }
    &.is--stacked {
      flex-direction: column;
      .rs--button {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        border-radius: 1px 1px 0 1px;
        border-width: 1px 1px 0px 1px;
        &:only-child {
          border-radius: 6px;
          border-width: 1px;
        }
        &:first-child:not(:only-child) {
          border-radius: 6px 6px 0 0;
          border-width: 1px 1px 0px 1px;
        }
        &:last-child:not(:only-child) {
          border-radius: 0 0 6px 6px;
          border-width: 1px;
        }
      }
    }
  }
  &.is--full {
    width: 100%;
    display: flex;
  }
  &.is--tabs {
    &:after {
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      bottom: 0px;
      height: 2px;
      background-color: var(--bg-2);
      z-index: 0;
    }
  }
}
</style>
