import Vue from 'vue';
import recommendationService from '../../services/ltk-recommendation-service';
import { hierarchy, listToMap } from '../../utils/listSorting';

const DEFAULT_AGE = 26;
const featureFinder =
  ({ type, id }) =>
  (feature) =>
    feature.type === type && feature.id === id;
const listParentRollup = (state, type) => (list) => {
  const feature = {
    id: list.id,
    type,
    weight: 0,
  };

  if (list.children.length === 0) {
    return feature;
  }

  const BETA = 1 / list.children.length;
  const weight = list.children.reduce((value, child) => {
    const profileFeature = (state.recommendationProfile.features || []).find(featureFinder({ type, id: child.id }));

    if (profileFeature === undefined) {
      return value;
    }

    return value + Number(profileFeature.weight) * BETA;
  }, 0);

  feature.weight = weight.toFixed(2);

  return feature;
};
const parentCategoryValues = (state) => {
  const categories = hierarchy(Object.values(state.categories));
  return categories.map(listParentRollup(state, 'category'));
};
const parentLocationValues = (state) => {
  const locations = hierarchy(Object.values(state.locations));
  return locations.map(listParentRollup(state, 'location'));
};

export default {
  state: {
    categories: {},
    locations: {},
    recommendationProfile: {},
  },
  getters: {
    parentCategoryValues,
    parentLocationValues,
  },
  actions: {
    async getCategories({ commit, state }) {
      if (Object.values(state.categories).length !== 0) {
        return;
      }

      const response = await recommendationService.getCategories();
      const categories = response.data.ltk_categories.reduce(listToMap, {});

      commit('SET_CATEGORIES', categories);
    },
    async getLocations({ commit, state }) {
      if (Object.values(state.locations).length !== 0) {
        return;
      }

      const response = await recommendationService.getLocations();
      const locations = response.data.ltk_locations.reduce(listToMap, {});

      commit('SET_LOCATIONS', locations);
    },
    async getInfluencerRecommendationProfile({ commit }, profileId) {
      const { data } = await recommendationService.getInfluencer(profileId);
      const profile = data.recommendation_profiles ? data.recommendation_profiles[0] : data.recommendation_profile;
      profile.age = profile.age || DEFAULT_AGE;

      commit('SET_RECOMMENDATION_PROFILE', profile);
    },
    async saveInfluencerRecommendationProfile({ commit, state, getters }) {
      const profile = Object.assign({}, state.recommendationProfile);
      // Remove parent categories and locations as these are automatically calculated
      profile.features = profile.features.filter((feature) => {
        if (feature.type === 'category') {
          return state.categories[feature.id].parent !== undefined;
        }
        if (feature.type === 'location') {
          return state.locations[feature.id].parent !== undefined;
        }

        return true;
      });
      // Add Auto calculated values for parent category values
      profile.features.push(...getters.parentCategoryValues, ...getters.parentLocationValues);

      // Remove zero value weighted features for non-protected feature types
      profile.features = profile.features.filter(
        (feature) => ['gender', 'age'].includes(feature.type) || Number(feature.weight) !== 0,
      );

      if (profile.id === null || profile.id === undefined) {
        const response = await recommendationService.createInfluencer(profile);
        commit('SET_RECOMMENDATION_PROFILE', response.data.recommendation_profile);
        return;
      }
      const response = await recommendationService.saveInfluencer(profile.id, profile);
      commit('SET_RECOMMENDATION_PROFILE', response.data.recommendation_profile);
    },
  },
  mutations: {
    SET_CATEGORIES(state, categories) {
      Vue.set(state, 'categories', categories);
    },
    SET_LOCATIONS(state, locations) {
      Vue.set(state, 'locations', locations);
    },
    SET_RECOMMENDATION_PROFILE(state, profile) {
      Vue.set(state, 'recommendationProfile', profile);
    },
    ADD_RECOMMENDATION_PROFILE_FEATURE(state, { type, id, weight = '0' }) {
      state.recommendationProfile.features.push({ type, id, weight });
    },
    REMOVE_RECOMMENDATION_PROFILE_FEATURE(state, { type, id }) {
      const index = state.recommendationProfile.features.findIndex(featureFinder({ type, id }));
      state.recommendationProfile.features.splice(index, 1);
    },
    UPDATE_RECOMMENDATION_PROFILE_FEATURE(state, { type, id, weight }) {
      const index = state.recommendationProfile.features.findIndex(featureFinder({ type, id }));
      state.recommendationProfile.features[index].weight = weight;
    },
    UPDATE_RECOMMENDATION_PROFILE_EXCLUDE(state, isExcluded) {
      state.recommendationProfile.excluded = isExcluded;
    },
    UPDATE_RECOMMENDATION_PROFILE_TRACKING(state, isTracking) {
      state.recommendationProfile.tracked = isTracking;
    },
    UPDATE_RECOMMENDATION_PROFILE_AGE(state, age) {
      state.recommendationProfile.age = age;
    },
  },
};
