var render = function render(){var _vm=this,_c=_vm._self._c;return _c('rs-card',{staticClass:"mb-5"},[_c('rs-card-title',{staticClass:"pa-4"},[_c('h3',{staticClass:"headline font-weight-medium ma-0"},[_c('rs-icon',{staticClass:"mr-2",attrs:{"size":25}},[_vm._v("account_box")]),_vm._v(" System Users ")],1),_c('rs-spacer'),_c('rs-btn',{attrs:{"small":"","flat":"","block":false,"disabled":_vm.disabled,"data-test-id":"account-users-add-account-button"},on:{"click":function($event){_vm.showUserModal = true}}},[_c('rs-icon',{staticClass:"add-btn",attrs:{"size":"19"}},[_vm._v("add")]),_c('div',{staticClass:"mx-2"},[_vm._v("Add System User")])],1)],1),_c('rs-divider'),_c('rs-data-table',{staticClass:"accounts-data-table mb-4 px-2",attrs:{"headers":_vm.tableHeaders,"items":_vm.users,"must-sort":true,"rows-per-page-items":[5, 10, 25],"total-items":_vm.usersMeta.total_rows || 0,"pagination":_vm.pagination},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',{class:{ 'table-row--pending': props.item.status == 'pending' }},[_c('td',[_vm._v(_vm._s(props.item.first_name || '--'))]),_c('td',[_vm._v(_vm._s(props.item.last_name || '--'))]),_c('td',[_vm._v(_vm._s(props.item.email || '--'))]),_c('td',[_vm._v(_vm._s(props.item.title || '--'))]),_c('td',[_vm._v(_vm._s(props.item.hubspot_owner_id || '--'))]),_c('td',[_vm._v(_vm._s(props.item.phone || '--'))]),_c('td',[_vm._v(_vm._s(props.item.status || '--'))]),_c('td',{staticClass:"layout justify-end align-center"},[_c('rs-btn',{staticClass:"ma-0",attrs:{"borderless":"","fab":"","small":"","block":false,"data-test-id":`account-users-edit-account-button-${props.index}`},on:{"click":() => {
                _vm.selectedUser = props.item;
                _vm.showUserModal = true;
              }}},[_c('rs-icon',{attrs:{"size":"25"}},[_vm._v("rsfont-edit_v2")])],1),_c('rs-btn',{staticClass:"ma-0",attrs:{"borderless":"","fab":"","small":"","block":false,"data-test-id":`account-users-delete-account-button-${props.index}`},on:{"click":function($event){return _vm.$emit('open-delete-user-dialog', props.item)}}},[_c('rs-icon',{attrs:{"size":"25"}},[_vm._v("rsfont-delete_v2")])],1)],1)])]}}])},[_c('template',{slot:"no-data"},[_c('rs-layout',{staticClass:"no-users-data",attrs:{"justify-center":"","align-center":""}},[_c('rs-icon',{staticClass:"mr-2"},[_vm._v("people")]),_vm._v(" No system users yet ")],1)],1)],2),_c('DialogUser',{attrs:{"show-dialog":_vm.showUserModal,"user":_vm.selectedUser,"account-id":_vm.accountId,"data-test-id":"account-users-account-add-edit-dialog"},on:{"update:user":function($event){_vm.selectedUser=$event},"close":() => {
        _vm.showUserModal = false;
        _vm.selectedUser = {};
        _vm.getUsers();
      }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }