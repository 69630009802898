<template>
  <rs-container class="quality-control-container" fluid fill-height>
    <rs-layout column>
      <h2 class="display-3">
        <rs-layout>
          {{ $route.meta.title }}
          <rs-layout row align-center justify-end>
            <rs-spacer></rs-spacer>
            <div class="controls-wrapper">
              <div class="is-rari-wrapper">
                <rs-select
                  v-model="currentGroup"
                  class="groups"
                  :items="groups"
                  label="RARI/C&D"
                  outline
                  dense
                  hide-details
                  single-line
                ></rs-select>
              </div>
            </div>
          </rs-layout>
        </rs-layout>
      </h2>
      <rs-flex>
        <rs-layout row align-center justify-end>
          <rs-spacer></rs-spacer>
          <div class="controls-wrapper">
            <div class="operator-name-wrapper">
              <rs-select
                v-model="selectedOperator"
                class="operator-name"
                :items="operators"
                label="Select an operator"
                outline
                dense
                hide-details
                single-line
              ></rs-select>
            </div>
            <div class="release-wrapper" v-if="isAdmin">
              <rs-btn class="release-button" @click="onReleaseAllWorkItems" :disabled="incompleteCount === 0">
                Release All Products
              </rs-btn>
            </div>
          </div>
        </rs-layout>
        <rs-tabs :value="selectedTab" @change="onTabChange" slider-color="#222222">
          <rs-tab>
            <span class="tab-text">Incomplete ({{ incompleteCount }})</span>
          </rs-tab>
          <rs-tab>
            <span class="tab-text">Complete</span>
          </rs-tab>
          <rs-tab>
            <span class="tab-text">Operator statistics </span>
          </rs-tab>
        </rs-tabs>
        <quality-control-table key="incompleted" table-name="incompleted" v-if="selectedTab === 0" />
        <quality-control-table key="completed" table-name="completed" v-if="selectedTab === 1" />
        <rs-layout column v-if="selectedTab === 2">
          <unassigned-count-text />
          <quality-control-bar />
          <rs-layout class="operators-stat-fitlers">
            <quality-control-date-picker />
            <rs-flex offset-xs3 v-if="isAdmin">
              <rs-select
                v-model="selectedOperatorStatFilter"
                :items="selectOperators"
                label="All operators (click to choose specific)"
                dense
                hide-details
                single-line
                clearable
              ></rs-select>
            </rs-flex>
          </rs-layout>
          <quality-control-stat-table />
        </rs-layout>
      </rs-flex>
    </rs-layout>
  </rs-container>
</template>

<script>
import { defineComponent, onBeforeMount, onBeforeUnmount, computed } from 'vue';

import useStore from '@/composables/vuex';

import QualityControlTable from '../components/QualityControlTable.vue';
import QualityControlStatTable from '../components/QualityControlStatTable.vue';
import QualityControlBar from '../components/QualityControlBar.vue';
import QualityControlDatePicker from '../components/QualityControlDatePicker.vue';
import qualityControlStore from '../store/quality-control';
import UnassignedCountText from '../components/UnassignedCountText.vue';

export default defineComponent({
  components: {
    QualityControlTable,
    QualityControlStatTable,
    QualityControlBar,
    QualityControlDatePicker,
    UnassignedCountText,
  },
  setup(_props, _context) {
    const groups = ['RARI', 'C&D'];
    const store = useStore();
    store.registerModule('catalog-quality-control', qualityControlStore);

    const isAdmin = computed({
      get: () => {
        const { isAdmin } = store.getters.currentOperator || {};
        return isAdmin;
      },
    });

    const selectedOperator = computed({
      get: () => {
        const { id, displayName, isAdmin } = store.getters.currentOperator || {};
        return {
          text: `${isAdmin ? 'Admin' : 'Operator'} - ${displayName}`,
          value: id,
        };
      },
      set: (operator) => {
        store.dispatch('updateOperator', operator);
      },
    });

    const currentGroup = computed({
      get: () => {
        // const isRARI = store.getters.currentGroup;
        return store.getters.currentGroup;
      },
      set: (group) => {
        store.dispatch('updateGroup', group);
      },
    });

    const operators = computed(() => {
      return store.getters.operators
        .map(({ id, displayName, isAdmin }) => ({
          text: `${isAdmin ? 'Admin' : 'Operator'} - ${displayName}`,
          value: id,
        }))
        .sort((a, b) => {
          // Sort first by isAdmin (Operators first, then Admins)
          if (a.text.includes('Operator') && b.text.includes('Admin')) return -1;
          if (a.text.includes('Admin') && b.text.includes('Operator')) return 1;
          // If isAdmin is the same, sort by displayName
          return a.text.localeCompare(b.text);
        });
    });
    const selectOperators = computed(() => {
      return store.getters.operators
        .filter((item) => !item.isAdmin)
        .map(({ id, displayName, isAdmin }) => ({
          text: `Operator - ${displayName}`,
          value: id,
        }));
    });
    const selectedTab = computed(() => store.getters.currentSelectedTab);
    const incompleteCount = computed(() => store.getters.totalIncompleteWorkItemsCount);

    const onReleaseAllWorkItems = () => {
      store.dispatch('releaseAllWorkItems');
    };

    onBeforeMount(() => {
      store.dispatch('fetchOperators');
    });

    onBeforeUnmount(() => {
      store.unregisterModule('catalog-quality-control');
    });

    const onTabChange = (value) => {
      store.dispatch('updateSelectedTab', value);
    };

    const selectedOperatorStatFilter = computed({
      get: () => {
        const { id, displayName, isAdmin } = store.getters.selectedOperatorStatFilter || {};
        return {
          text: displayName,
          value: id,
        };
      },
      set: (operator) => {
        store.dispatch('updateSelectedOperatorStatFilter', operator);
      },
    });

    return {
      groups,
      currentGroup,
      operators,
      isAdmin,
      selectedOperator,
      selectedTab,
      incompleteCount,
      onTabChange,
      onReleaseAllWorkItems,
      selectedOperatorStatFilter,
      selectOperators,
    };
  },
});
</script>

<style lang="scss" scoped>
.quality-control-container.container.fluid {
  @media only screen and (min-width: 1904px) {
    max-width: 100% !important;
  }
}

.operator-name {
  &::v-deep .v-input__control > .v-input__slot {
    border-radius: 6px;
    border: 1px solid #9e9e9e;

    &:hover {
      border-width: 1px !important;
    }
  }
}

.controls-wrapper {
  display: flex;
  flex-direction: column;
  width: 318px;
}

.operator-name-wrapper,
.release-wrapper {
  width: 100%;
}

.release-wrapper > button.release-button:not(:disabled) {
  // override other !important
  background-color: #2a616a !important;
  color: white !important;
}

.tab-text {
  color: #222222;
  font-size: 12px;
  font-weight: 700;
}

.operators-stat-fitlers {
  margin-top: 3rem; /* Adjust the spacing as needed */
}
</style>
