<template>
  <rs-chip label :color="color">{{ status }}</rs-chip>
</template>

<script>
import { defineComponent, computed } from 'vue';

export default defineComponent({
  props: {
    status: {
      type: String,
      default: 'draft',
    },
  },
  setup(props, _context) {
    const color = computed(() => {
      switch (props.status) {
        case 'live':
          return 'rs-success';
        case 'published':
          return 'rs-information';
        case 'draft':
          return 'blue';
        default:
          return undefined;
      }
    });

    return { color };
  },
});
</script>
