<template>
  <div class="hashtags-manage__content">
    <div class="hashtags-manage__grid">
      <div class="hashtags-manage__name">
        <rs-text-field
          :disabled="editing"
          :label="fieldLabels.groupLabel"
          v-model="local.groupLabel"
          :rules="[rules.required, rules.noSpecialChar]"
          persistent-hint
          :hint="fieldLabels.hint"
        />
      </div>
      <div class="hashtags-manage__status">
        <rs-radio-group v-model="local.status" row class="justify-space-between" :disabled="!canEdit">
          <rs-radio label="Live in App" value="prod" />
          <rs-radio label="QA" value="qa" />
          <rs-radio label="Disabled" value="disabled" />
        </rs-radio-group>
      </div>
      <div class="hashtags-manage__title" v-if="type === 'bubble'">
        <rs-text-field
          label="Title"
          v-model="local.title"
          :rules="[rules.required]"
          persistent-hint
          :hint="fieldLabels.hint"
        />
      </div>
      <div class="hashtags-manage__title-empty" v-if="type === 'bubble'"></div>
      <div class="hashtags-manage__description" v-if="type === 'bubble'">
        <rs-text-field
          label="Description"
          v-model="local.subtitle"
          :rules="[rules.required]"
          persistent-hint
          :hint="fieldLabels.hint"
        />
      </div>
      <div class="hashtags-manage__title-settings" v-if="type === 'bubble'">
        <rs-radio-group row class="justify-space-between pt-0 mt-0">
          <rs-checkbox v-model="local.shouldUseDarkTitle" label="Dark Title" class="mr-4 mt-2" :disabled="!canEdit" />
          <rs-checkbox v-model="local.shouldHideOverlay" label="Hide Title" class="mr-4 mt-2" :disabled="!canEdit" />
        </rs-radio-group>
      </div>
      <div class="hashtags-manage__start-date">
        <rs-menu v-model="local.startDateMenu" :close-on-content-click="false" full-width max-width="305">
          <template #activator="{ on }">
            <rs-text-field
              v-model="local.startDate"
              clearable
              label="Start Date (YYYY-MM-DD)"
              v-on="on"
              persistent-hint
              hint="Dates are within local country's timezone"
            />
          </template>
          <div class="hashtags-manage__datepicker">
            <rs-date-picker
              single-date
              :date-one.sync="local.startDate"
              date-format="yyyy-MM-dd"
              :open.sync="local.startDateMenu"
              class="date-picker"
            />
          </div>
        </rs-menu>
      </div>
      <div class="hashtags-manage__end-date">
        <rs-menu v-model="local.endDateMenu" :close-on-content-click="false" full-width max-width="305">
          <template #activator="{ on }">
            <rs-text-field v-model="local.endDate" clearable label="End Date (YYYY-MM-DD)" v-on="on" persistent-hint />
          </template>
          <div class="hashtags-manage__datepicker">
            <rs-date-picker
              single-date
              :date-one.sync="local.endDate"
              date-format="yyyy-MM-dd"
              :open.sync="local.endDateMenu"
              class="date-picker"
            />
          </div>
        </rs-menu>
      </div>
      <hashtags-image-upload
        v-if="type === 'bubble'"
        :type="type"
        :file="local.file"
        :file-name="local.fileName"
        :url="local.url"
        @img-update="handleImageUpdate($event)"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, watch, computed } from 'vue';
import { removeTime } from '@/internal-hashtags/utils/changeTimeZone';
import HashtagsImageUpload from '@/internal-hashtags/components/HashtagsImageUpload.vue';

export default defineComponent({
  name: 'HashtagsFields',
  emits: { change: () => true },
  components: { HashtagsImageUpload },
  props: {
    type: {
      type: String,
      default: 'hashtag',
    },
    groupLabel: {
      type: String,
      default: '',
    },
    startDate: {
      type: String,
      default: '',
    },
    endDate: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    imageUrl: {
      type: String,
      default: () => null,
    },
    shouldUseDarkTitle: {
      type: Boolean,
      default: false,
    },
    shouldHideOverlay: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: 'qa',
      validator: function (value) {
        return ['qa', 'disabled', 'prod'].includes(value);
      },
    },
    editing: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const local = reactive({
      title: props.title,
      groupLabel: props.groupLabel,
      startDate: removeTime(props.startDate),
      startDateMenu: false,
      endDate: removeTime(props.endDate),
      endDateMenu: false,
      status: props.status,
      shouldUseDarkTitle: props.shouldUseDarkTitle,
      shouldHideOverlay: props.shouldHideOverlay,
      subtitle: props.subtitle,
      file: [],
      fileName: null,
      url: props.imageUrl,
    });

    const rules = {
      required: (value) => !!value || 'Required.',
      noSpecialChar: (value) => {
        const regex = new RegExp('^[a-zA-Z0-9_]+$');
        return regex.test(value) || 'No special char. allowed.';
      },
    };

    const fieldLabels = computed(() => {
      const captializeType = props.type?.charAt(0).toUpperCase() + props.type.slice(1);
      return {
        groupLabel: `${captializeType} Group Label`,
        hint: 'Required',
      };
    });

    const isDirty = computed(() => {
      return (
        local.title !== props.title ||
        local.groupLabel !== props.groupLabel ||
        local.startDate !== props.startDate ||
        local.endDate !== props.endDate ||
        local.status !== props.status ||
        local.subtitle !== props.subtitle ||
        local.shouldUseDarkTitle !== props.shouldUseDarkTitle ||
        local.shouldHideOverlay !== props.shouldHideOverlay ||
        local.url !== props.imageUrl
      );
    });

    const makeEmitObject = (fields) => {
      const sharedFields = {
        group_label: fields.groupLabel.split(' ').join('_'),
        start_date: fields.startDate,
        end_date: fields.endDate,
        status: fields.status,
      };

      const bubbleFields = {
        title: fields.title,
        subtitle: fields.subtitle,
        shouldUseDarkTitle: fields.shouldUseDarkTitle,
        shouldHideOverlay: fields.shouldHideOverlay,
        url: fields.url,
      };

      if (props.type === 'hashtag' || props.type === 'banner') {
        return sharedFields;
      }

      if (props.type === 'bubble') {
        return { ...sharedFields, ...bubbleFields };
      }
    };

    function handleImageUpdate(event) {
      local.file = event.file;
      local.fileName = event.fileName;
      local.url = event.url;
    }

    watch(
      () => ({
        groupLabel: props.groupLabel,
        startDate: props.startDate,
        endDate: props.endDate,
        status: props.status,
        shouldUseDarkTitle: props.shouldUseDarkTitle,
        shouldHideOverlay: props.shouldHideOverlay,
        subtitle: props.subtitle,
        title: props.title,
        url: props.imageUrl,
      }),
      (newValue) => {
        local.groupLabel = newValue.groupLabel;
        local.startDate = removeTime(newValue.startDate);
        local.endDate = removeTime(newValue.endDate);
        local.shouldHideOverlay = newValue.shouldHideOverlay;
        local.shouldUseDarkTitle = newValue.shouldUseDarkTitle;
        local.subtitle = newValue.subtitle;
        local.title = newValue.title;
        local.status = newValue.status;
        local.url = newValue.url;
      },
      {
        immediate: true,
      },
    );

    watch(
      () => local,
      (newValue) => {
        if (isDirty.value) {
          const payload = makeEmitObject(newValue);
          emit('change', {
            ...payload,
          });
        }
      },
      {
        deep: true,
      },
    );

    return {
      fieldLabels,
      local,
      rules,
      handleImageUpdate,
    };
  },
});
</script>

<style lang="scss" scoped>
.hashtags-manage {
  &__grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 32px;
    position: relative;
  }
  &__content {
    padding: 24px 24px 16px 24px;
    margin-bottom: 24px;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
    &:last-child {
      margin-bottom: 0px;
    }
  }
  &__name,
  &__status,
  &__title,
  &__title-empty,
  &__start-date,
  &__description,
  &__title-settings,
  &__end-date {
    grid-column: span 6;
  }
  &__datepicker {
    height: 370px;
    & > .datepicker {
      date-picker {
        width: 305px;
      }
    }
  }
}
</style>
