<template>
  <rs-data-table v-model="selectedRows" :headers="headings" :items="items" item-key="id" :loading="isLoading">
    <template #items="props">
      <bubble-table-item
        :id="props.item.id"
        :selected="props.selected"
        :key="props.item.id"
        @click.native="$emit('click-row', props.item.id)"
        @update:selected="props.selected = $event"
      />
    </template>
  </rs-data-table>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue';
import BubbleTableItem from './BubbleTableItem.vue';
import useModelWrapper from '@/composables/model-wrapper';
import useStore from '@/composables/vuex';

export default defineComponent({
  components: { BubbleTableItem },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: Array,
      default: () => {},
    },
  },
  emits: {
    'update:modelValue': (payload) => Array.isArray(payload),
  },
  setup(props, { emit }) {
    const store = useStore();
    const headings = ref([
      { width: '80px', sortable: false },
      { text: 'Bubble Name', value: 'promo_title', sortable: false },
      { text: 'Dates', value: 'dates', sortable: false, align: 'center' },
      { text: 'Status', value: 'status', width: '132px', sortable: false, align: 'center' },
    ]);
    const items = computed(() => store.getters.getBubbles());

    const [selectedRows] = useModelWrapper(props, emit);
    const isLoading = computed(() => store.getters.getStatus().isLoading);

    onMounted(async () => {
      await store.dispatch('fetchBubbles');
    });

    return { selectedRows, headings, items, isLoading };
  },
});
</script>
