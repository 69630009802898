<template>
  <sale-confirm-modal v-bind="$attrs" v-on="$listeners">
    <template #header>
      You have selected a live sale that is currently live. Any changes you made will go into effect immediately.
    </template>
    <template #body>Are you sure you want to continue?</template>
  </sale-confirm-modal>
</template>

<script>
import { defineComponent } from 'vue';
import SaleConfirmModal from './SaleConfirmModal.vue';

export default defineComponent({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  components: { SaleConfirmModal },
  setup(_props, _context) {
    return {};
  },
});
</script>
