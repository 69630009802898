import { MATCH_VALUES } from '../constants';

export const isMatchDenied = (match) => {
  switch (match) {
    case MATCH_VALUES.NO:
    case MATCH_VALUES.NO_OTHER:
    case MATCH_VALUES.NO_OTHER_BRAND:
    case MATCH_VALUES.NO_OTHER_COLOR:
      return true;
    default:
      return false;
  }
};

export const isMatchApproved = (match) => {
  return match === MATCH_VALUES.YES;
};
