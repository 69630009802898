<template>
  <rs-container fluid>
    <rs-flex my-5>
      <h2 class="display-3">{{ $route.meta.title }}</h2>
      <rs-breadcrumbs class="px-0" :items="$route.matched" divider=">">
        <rs-breadcrumbs-item
          slot="item"
          slot-scope="{ item }"
          :exact="true"
          :to="{ name: item.name, params: $route.params }"
        >
          {{ item.meta.title }}
        </rs-breadcrumbs-item>
      </rs-breadcrumbs>
    </rs-flex>

    <rs-card v-if="$route.name === 'LtkRecommendations'">
      <rs-card-text>
        <rs-btn inverted :block="false" :to="{ name: 'LtkProfileVectorSearch' }">Adjust Profile Vectors</rs-btn>
      </rs-card-text>
    </rs-card>

    <rs-flex xs12>
      <router-view />
    </rs-flex>
  </rs-container>
</template>

<script>
import recommendationsStore from '../store/ltk-recommendations';
import profilesStore from '../store/profiles';

export default {
  created() {
    this.$store.registerModule('ltk-recommendations', recommendationsStore);
    this.$store.registerModule('ltk-profiles', profilesStore);
  },
  beforeDestroy() {
    this.$store.unregisterModule('ltk-profiles');
    this.$store.unregisterModule('ltk-recommendations');
  },
};
</script>
