<template>
  <rs-text-field
    autofocus
    background-color="white"
    height="20px"
    type="number"
    :style="styles"
    v-model="inputValue"
    @keyup.enter="hide"
    @keyup.escape="cancel"
    @blur="hide"
    min="-1"
    max="1"
    step="0.01"
  />
</template>

<script>
export default {
  props: {
    x: Number,
    y: Number,
    value: String,
  },
  data() {
    return {
      inputValue: this.value,
      previousValue: this.value,
    };
  },
  computed: {
    styles() {
      return {
        position: 'absolute',
        'max-width': '50px',
        left: `${this.x}px`,
        top: `${this.y - 15}px`,
      };
    },
  },
  methods: {
    hide() {
      this.$emit('hide');
    },
    cancel() {
      this.$emit('input', this.previousValue);
      this.hide();
    },
  },
  watch: {
    inputValue(newValue) {
      let value = Number(newValue);
      if (value > 1) {
        value = 1;
      } else if (value < -1) {
        value = -1;
      }
      value = value.toFixed(2);

      this.$emit('input', value);
    },
  },
};
</script>
