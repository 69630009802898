import clientFactory from './client';

const client = clientFactory('/api');

class AccountService {
  async getAccounts(params) {
    const response = await client.get('/v1/influencer_account/accounts?include=igs,segment', { params });

    return response.data.data.accounts;
  }

  async updateAccount(id, { office, pod, sub_pod, sub_region }) {
    const payload = {
      data: { office, pod, sub_pod, sub_region },
    };
    const response = await client.put(`/v1/influencer_account/account/${id}/igs`, { ...payload });
    return response.data;
  }
}

export default new AccountService();
