<template>
  <rs-layout column>
    <rs-layout align-center>
      <rs-text-field
        :value="start"
        required
        label="Start Date* (YYYY-MM-DD)"
        class="mr-2"
        :rules="startDateRules"
        :maxlength="maxDateLength"
        @focus="isPickerOpen = true"
        @keydown.esc.stop="isPickerOpen = false"
      />
      &mdash;
      <rs-text-field
        v-model="end"
        required
        label="End Date* (YYYY-MM-DD)"
        class="ml-2"
        :rules="endDateRules"
        :maxlength="maxDateLength"
        @focus="isPickerOpen = true"
        @keydown.esc.stop="isPickerOpen = false"
      />
    </rs-layout>
    <rs-date-picker
      v-if="isPickerOpen"
      :open.sync="isPickerOpen"
      :months-to-show="2"
      date-format="yyyy-MM-dd"
      :date-one.sync="start"
      :date-two.sync="end"
    />
  </rs-layout>
</template>

<script>
import { defineComponent, ref } from 'vue';

import useModelWrapper from '@/composables/model-wrapper';
import { makeRequired, makeMatchFormat } from '@/utils/form-validation';

const ISO8601_DATE_LENGTH = 10;
const maxDateLength = ISO8601_DATE_LENGTH;
const dateFormat = /^\d{4}-\d{2}-\d{2}$/;

export default defineComponent({
  props: {
    startDate: String,
    endDate: String,
  },
  setup(props, { emit }) {
    const minDate = new Date().toISOString().slice(0, ISO8601_DATE_LENGTH);
    const [start] = useModelWrapper(props, emit, 'startDate');
    const [end] = useModelWrapper(props, emit, 'endDate');

    start.value = start.value ?? minDate;
    end.value = end.value ?? minDate;

    const isPickerOpen = ref(false);

    const validateDateFormat = makeMatchFormat(dateFormat, 'Date must match format YYYY-MM-DD');
    const startDateRules = [makeRequired('Start Date is required'), validateDateFormat];
    const endDateRules = [makeRequired('End Date is required'), validateDateFormat];

    return { start, end, maxDateLength, isPickerOpen, startDateRules, endDateRules };
  },
});
</script>
