export const WORK_ITEM_STATUS = {
  INCOMPLETE: 0,
  COMPLETE: 1,
};

export const MATCH_VALUES = {
  YES: 'yes',
  NO: 'no',
  NO_OTHER_BRAND: 'no.other-brand',
  NO_OTHER: 'no.other',
};

export const QUALITY_CONTROL_TABS = {
  INCOMPLETE: 0,
  COMPLETE: 1,
  STAT: 2,
};

export const INCOMPLETE_PAGINATION_STORAGE_KEY = 'CATALOG_QUALITY_CONTROL_INCOMPLETE_PAGINATION';
export const COMPLETE_PAGINATION_STORAGE_KEY = 'CATALOG_QUALITY_CONTROL_COMPLETE_PAGINATION';
