<template>
  <div class="editor">
    <editor-menu-bar v-slot="{ commands, isActive }" :editor="editor">
      <div class="buttons-container">
        <button :class="{ 'is-active': isActive.bold() }" @click="commands.bold">
          <rs-icon class="pa-1 formattingButton" color="#404040" small> rsfont-bold </rs-icon>
        </button>
        <button :class="{ 'is-active': isActive.italic() }" @click="commands.italic">
          <rs-icon class="pa-1 formattingButton" color="#404040" small> rsfont-italic </rs-icon>
        </button>
        <button :class="{ 'is-active': isActive.underline() }" @click="commands.underline">
          <rs-icon class="pa-1 formattingButton" color="#404040" small> rsfont-underlined </rs-icon>
        </button>
        <button :class="{ 'is-active': isActive.bullet_list() }" @click="commands.bullet_list">
          <rs-icon class="pa-1 formattingButton" color="#404040" small> rsfont-list-bulleted</rs-icon>
        </button>
        <button :class="{ 'is-active': isActive.ordered_list() }" @click="commands.ordered_list">
          <rs-icon class="pa-1 formattingButton" color="#404040" small> rsfont-list-numbered</rs-icon>
        </button>
      </div>
    </editor-menu-bar>
    <rs-divider />
  </div>
</template>

<script>
import { EditorMenuBar } from 'tiptap';

export default {
  components: {
    EditorMenuBar,
  },
  props: {
    editor: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="sass" scoped>
::v-deep.ProseMirror:focus
  outline: none
.formattingButton
  border-style: solid
  border-color: #E9E9E9
  border-width: 0 0 0 0.5px
.is-active
  background-color: #f0f0f0

button
  i
    line-height:19px
</style>
