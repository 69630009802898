<template>
  <tr
    :active="props.selected"
    @click="$emit('click')"
    @keyup.enter="$emit('click')"
    v-if="application"
    :class="`influencer-applicant-row ${numOfAppsColor}`"
    :aria-expanded="props.selected"
    tabindex="0"
  >
    <td>
      <!-- eslint-disable-next-line vue/no-mutating-props -->
      <rs-checkbox primary hide-details v-model="props.selected" @click.stop=""></rs-checkbox>
    </td>
    <td>{{ application.created_at | moment(dateFormat) }}</td>
    <td>
      {{ applicant.first_name }}
      {{ applicant.last_name }}
    </td>
    <td>{{ applicant.email }}</td>
    <td>{{ primaryPlatformName }}</td>
    <td>{{ primaryPlatformUrl }}</td>
    <td>{{ largestFollowerCountCompact }}</td>
    <td>{{ country.name }}</td>
    <td>
      <rs-alert :value="true" :color="statusColor">{{ application.status }}</rs-alert>
    </td>
    <td>{{ application.updated_at | moment(dateFormat) }}</td>
  </tr>
</template>

<script>
import { mapState } from 'vuex';
import { formatFollowerCount } from '@/internal-influencers-apps/utils/followerCount';

const dateFormat = 'MMM DD, YYYY';

export default {
  props: ['id', 'props'],
  computed: {
    ...mapState({
      applicationsMap: (state) => state['influencer-application'].applicationsMap,
      applicationPlatformsMap: (state) => state['influencer-application'].applicationPlatformsMap,
      applicantsMap: (state) => state['influencer-application'].applicantsMap,
      platformsMap: (state) => state['influencer-application'].platformsMap,
      countriesMap: (state) => state['influencer-application'].countriesMap,
    }),
    application() {
      return this.applicationsMap[this.id] ?? {};
    },
    largestFollowerCountCompact() {
      const count = this.application?.largest_follower_count;

      if (!count) {
        return '-';
      }

      return formatFollowerCount(count);
    },
    applicant() {
      return this.applicantsMap[this.application?.applicant_id] ?? {};
    },
    applicationPlatforms() {
      return this.application?.application_platform_ids?.map((id) => this.applicationPlatformsMap[id]) ?? [];
    },
    primaryPlatform() {
      return this.applicationPlatforms[0] ?? {};
    },
    primaryPlatformUrl() {
      return this.primaryPlatform?.url;
    },
    primaryPlatformName() {
      return this.platformsMap[this.primaryPlatform.platform_id]?.name;
    },
    country() {
      return this.countriesMap[this.application.country_id.toLowerCase()] ?? {};
    },
    statusColor() {
      switch (this.application?.status) {
        case 'approved':
          return 'rs-success black--text';

        case 'declined':
          return 'rs-error black--text';

        case 'pending':
          return 'rs-information black--text';

        default:
          return 'rs-links-primary';
      }
    },
    numOfAppsColor() {
      switch (this.application?.previous_application_ids?.length) {
        case 0:
          return 'transparent';

        case 1:
          return 'ltk-tertiary-accent-creamy-tan';

        case 2:
          return 'ltk-brand-accent-terracotta lighten-2';

        default:
          return 'ltk-brand-accent-wine lighten-2';
      }
    },
    dateFormat() {
      return dateFormat;
    },
  },
};
</script>

<style lang="scss" scoped>
.influencer-applicant-row:focus {
  outline: dotted 1px black;
}
</style>
